<template>
  <div id="index">
    <div class="map-container">
      <div class="header">
        <i @click="goBack"></i>
        <span @click="goBack">返回</span>
      </div>
      <div class="point-nav">
        <div v-for="item in list" :key="item.key" :class="['point',item.key, {'selected': selected.key === item.key}]" @click="handleClick(item)">
          <img :src="getImage(item.key)" />
          <span>{{item.title}}</span>
        </div>
      </div>
    </div>
    <div class="modal" v-if="showModal">
      <div class="modal-header">
        <div class="tab">{{selected.title}}</div>
        <i @click="close" class="close"></i>
      </div>
      <div class="modal-con">
        <img src="http://dssq.ikeqiao.net/data/image/h5/images/map/comm-mbg.jpg" class="image" />
        <div>
          <div class="row">
            <div class="label">营运时间：</div>
            <div>上午9:00-12:00<br />下午14:00-17:00</div>
          </div>
          <div class="row">
            <div class="label">详细位置：</div>
            <div>东盛社区9幢1单元1104</div>
          </div>
          <div class="row">
            <div class="label">联系电话：</div>
            <div>18888888888</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [{
        title: '自助一体机',
        key: 'zzytj'
      }, {
        title: '快递点',
        key: 'kdd'
      }, {
        title: '志愿活动',
        key: 'zyhd'
      }, {
        title: '社区学堂',
        key: 'sdbxt'
      }, {
        title: '邻里中心',
        key: 'llzx'
      }, {
        title: '医护养老院',
        key: 'yhyly'
      }, {
        title: '垃圾投放点',
        key: 'ljtfd'
      }, {
        title: '共享车位',
        key: 'gxcw'
      }, {
        title: '共享资源点',
        key: 'gxzyd'
      }, {
        title: '资源回收',
        key: 'zyhs'
      }, {
        title: '物业站',
        key: 'wyz'
      }],
      selected: {},
      showModal: false,
    };
  },
  components: {},
  mounted() {},
  methods: {
    gotoLink(path) {
      this.$router.push(path);
    },
    goBack() {
      this.$router.go(-1);
    },
    showAlert() {
      this.$messagebox("提示", "系统正在建设中，尽情期待~");
    },
    getImage(key) {
      return `http://dssq.ikeqiao.net/data/image/h5/images/map/${key}.png`;
    },
    handleClick(data) {
      if(data.key === this.selected.key) {
        this.selected = {};
        this.showModal = false;
      } else {
        this.selected = data;
        this.showModal = true;
      }
    },
    close() {
      this.showModal = false;
      this.selected = {};
    }
  },
};
</script>

<style lang="scss">
@import "~assets/scss/map.scss";
</style>
