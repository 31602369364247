const data = [
  {
    lon: 120.516962,
    lat: 30.089431,
    icon_path: 'http://dssq.ikeqiao.net/data/image/h5/images/hong/xunzhaozuimei-icon.png',
    title: '区级绿色家庭（张海妹）',
    subTitle: '平安家训：“绿色环保，从我做起”',
    content: [
      '“绿色环保，从我做起”，这是我们一家三口人的口号。虽说是普通的家庭，但我们一直把营建“绿色家庭”作为家庭建设的主要目标，并收到了一定的效果。',
      '一、营造绿色氛围，引领绿色新风尚',
      '我们家里在洗衣物、洗浴时，尽量使用香皂、药皂、洗衣皂等，在清洁餐具卫生设施时都选用无磷洗衣粉和含氧清洁剂，通过自己使用的效果和经验，经常向亲戚朋友及周围熟悉的人讲解如何使用环保型卫生用品。平时把家里打扫得整洁有序，在屋内主要是放些小盆栽，小绿植，这样既不占地方，而且还能美化家里的环境，净化家里的空气。每天把家里打扫干净、每天勤于打扫，保持一尘不染。',
      ' 二、注意言传身教，勇当绿色传播者。 ',
      '“一花独放不是春，万紫千红春满园”，仅仅自己一家创建成绿色家庭是远远不够的，只有全社会成员都拥有了绿色观念，整个社会才会更文明、更和谐。如何做一名绿色使者，把“绿”的种子播撒到每一个角落。我们家每个人都是宣传员，平时在社区里宣传有关环保的知识、废旧电池的危害和回收方法，建议小区里设置统一的废旧电池回收点，还向居民提出绿色消费的倡议。',
      '虽然，我们一家的力量微不足道，但努力的过程是快乐的。我们相信，以后随着人们环保意识的增强，参与绿色行动的家庭会越来越多，我们社区的环境建设一定会越来越美，我们的社会必然更加美丽、和谐。'
    ],
    pictures: [
      'http://dssq.ikeqiao.net/data/image/h5/images/hong/zhanghaimei1.png',
      'http://dssq.ikeqiao.net/data/image/h5/images/hong/zhanghaimei2.png',
    ]
  },
  {
    lon: 120.517775,
    lat: 30.084884,
    icon_path: 'http://dssq.ikeqiao.net/data/image/h5/images/hong/xunzhaozuimei-icon.png',
    title: '区级平安家庭（胡杰丰）',
    subTitle: '平安家训：“守护平安、从我做起”',
    content: [
      '胡杰丰一家，家庭人口共4人，夫妻相敬相爱，遵纪守法，平等互助，倡导健康礼貌的生活方式，在工作上互相照顾，共同进步，共同提高，在事业上取得了成功在生活中家庭和睦、尊老爱幼，教子有方，团结邻里，积极倡导健康文明的生活方式，取得了家庭、事业的双丰收。尤其是在社区建立“平安家庭”的活动中树立了榜样，成为社区所有家庭的楷模。',
      '在工作中，作为一位律师，胡杰丰兢兢业业，并且作为社区法律援助律师，平时积极参与小区各类公益活动，通过给居民讲座、法律援助宣传各类法律知识；在家庭中，胡杰丰是一位好丈夫、好爸爸，她们夫妻俩在家里时常交流工作体会，取长补短，遇到困惑相互开导，相互支持；在生活中，他和邻里的关系相处的也非常好，每当谁家有困难，他都热心帮助，尽自己所能为她们解决矛盾，为社区和小区的平安建设奉献自己的力量，得到小区居民的信任与好评。',
    ],
    pictures: [
      'http://dssq.ikeqiao.net/data/image/h5/images/hong/hujiefeng1.png',
    ]
  },
  {
    lon: 120.515293,
    lat: 30.085283,
    icon_path: 'http://dssq.ikeqiao.net/data/image/h5/images/hong/xunzhaozuimei-icon.png',
    title: '区级平安家庭（张国）',
    subTitle: '平安家训：“尊老爱幼，为人谦虚，诚实守信，勤俭持家，积德行善，家庭和睦。”',
    content: [
      '住东盛社区张国家庭，一家三口，夫妻相敬相爱，遵纪守法，平等互助，倡导健康礼貌的生活方式，在工作上互相照顾，共同进步，共同提高，在事业上取得了成功；在生活中尊老爱幼，教子有方，取得了家庭、事业的双丰收。尤其是在社区建立“平安家庭”的活动中树立了榜样，成为社区所有家庭的楷模。',
      '在工作中，带头遵守党和国家的政策法令，遵守各项规章制度，用心参加政治领悟，拥护党的改革开放的一系列的方针、政策，发奋践行科学发展观。张国于1997年参军，成为一名浙江总队武警部队的消防战士。在消防队抢险班期间，曾在1998-2002年间的全省武警大比武中被评为“全能新兵训练标兵，优秀士兵”、武警总队汽车骨干班长；荣立个人三等功一次，多次拿到部队嘉奖。后调入袍江消防总队基地，成为一名优秀的消防教官，并被评为“全省十佳优秀班长标兵”、 “全省红旗车驾驶员”、“全省十佳优秀士官标兵”。 十多年兵营的磨砺使其成为一名经验丰富的抢险尖兵。张国利用自己工作经验，参与到社区的消防安全等工作，用心配合物业搞好社区的治安巡逻，提醒居民防盗、防火。',
      '在家庭中，张国是一名好儿子、好丈夫、好父亲。对双方家庭的老人十分孝顺，经常与老人家谈心，不仅仅在生活上关心、体贴老人，而且在思想上关心老人，让老人生活的愉悦、健康、不孤单。作为父亲，虽然平时工作比较忙碌，但是无论工作多忙，一回到家，他总要抽出时刻问问孩子的领悟状况，了解孩子的思想状况，重视对孩子世界观、人生观、价值观的思想教育，教育她学习并领悟做人、学习并领悟做事，做对社会有所作为、有所贡献的人。',
      '在小区里，他和邻里之间的关系也相处的很好，谁家有困难，他都能热心帮忙，为他们解决矛盾。楼上楼下邻里相处得很融洽，大家互相尊重、互相关心，楼里有事主动调解，并热心参与社区的义务宣传、扶贫帮困、治安防范、人民调解等志愿服务工作，主动为社区建设服务，为构建社会增添了亮丽的一线，真正做到了为群众做实事、办好事。',
    ],
    pictures: [
      'http://dssq.ikeqiao.net/data/image/h5/images/hong/zhangguo1.png',
    ]
  },
  {
    lon: 120.515334,
    lat: 30.081619,
    icon_path: 'http://dssq.ikeqiao.net/data/image/h5/images/hong/xunzhaozuimei-icon.png',
    title: '区级最美家庭（邬海英）',
    subTitle: '最美家训： “帮助他人，快乐自己”',
    content: [
      '我们家两口子都是教师，工作之余喜欢养花、养鱼，喜欢摄影，常带着孩子外出欣赏祖国大好河山，在这过程中得到了很多好心人的帮助，作为教育者也明白言行身教是最有效教育孩子的方法。我们带着孩子参加了很多公益活动，平时分享一些花花草草，参加护航G20礼让斑马线等，当然记忆最深刻的是在原来社区的时候连续三年送福送对联活动，收到了大家的一致好评，还有不少老人来分享美好故事，这个活动在绍兴市文明网上刊登，还被评为绍兴市未成人思想道德建设最高奖“希望奖”，孩子也是深受鼓舞，在今后的公益活动中继续践行“分享爱，温暖心”。',
      '时间一晃就好多年过去了，回首往事也是感慨万千，从小父母对我们就寄予厚望，格外重视我们的教育，我们初高中这段时间家长都在外面工作，那个时候还没电话，每周以家书形式进行沟通，厚厚的家书我们现在还保留着，这是一份浓浓的爱。',
      '现在通讯技术高速发展，沟通非常方便，物资条件也大幅改善但现在的孩子也很难教育。可能是“纸上得来终觉浅，绝知此事要躬行”吧，我们就带着孩子外出欣赏祖国大好河山，开阔眼界，去践行读万卷书行万里路。我们从跟着旅游团走马观花，到自己规划线路自己出行品质游，在这一过程中我们遇到了很多困难，但都有好心人伸出援助之手，孩子也在这一过程中学到了很多课本上学不到的东西，那就是分享。我们作为大人也明白了言行身教是最有效教育孩子的方法。在随后的日子里节假日除了外出采风还增加了参加不少公益活动。',
      '我们平时喜欢养锦鲤和花草，经常性与身边爱花的邻居朋友分享一些花花草草，朋友是越来越多。孩子看在眼里也记在心里，今年来这边后娘俩报名参加了社区组织的“护航G20礼让斑马线”活动。那天正好是七夕，她们起了个大早在社区里面经过培训和分配任务就开始在社区四周马路上进行劝阻不文明司机的行为，为礼让斑马线的司机点赞，活动历时两个小时，效果还是蛮好的，也是一次记忆深刻的七夕，发微信圈后还收到了很多很多的赞，心里都是满满的正能量。',
      '还有这个暑假区宣传部组织书法爱好者去绍兴市儿童福利院里教那里的孩子手把手写书法活动，我带着丫头参加了。一早赶到绍兴市儿童福利院，在组织者的带领下进教室跟那些孩子见面，丫头回来跟我说感受很深，她自己还是很幸福的。帮助他人还是有很多收获的。我让丫头参加各种公益活动我自己也在参加，这个学期浙江慈善总会联系我说西藏有个学校需要旧校服，我马上发动学生家长进行捐赠，一周后就寄往了西藏，我觉得这是我应该做的，必须做的事情。',
      '当然记忆最深刻的是在原来社区的时候连续三年送福送对联活动。我们开始搞这个活动的时候是网上买来对联纸和空白福字，让丫头和三四个小朋友在家里写好一半，到时候边送边写，初次开展一开始看热闹多，看着觉得挺好的，就怕是骗钱的，观望的很多，经过一番解释慢慢送出去了，二个小时近几百个福，近百付对联送完了，很多老人对这些孩子是啧啧称赞。活动结束了，大伙虽然很累，但嘴上都洋溢着微笑。',
      '第二年活动的时候参加的人又多了不少，第三年活动的时候人气更高了，这次我们吸取了上次的教训，在活动现场拉了横幅，制作了宣传片在社区大屏幕上滚动播放。我们的活动现场是里三层外三层，开发委和社区工作人员路过也忍不住过来一看究竟，一看都直言：我们搞活动怎么没有这么多人来的。这次我们的活动进行了分工，分别是现场书写组（可以根据社区居民要求写对联），安保组（负责活动现场次序），分发组（分现场分发和送货上门组，外出送对联送福小组安排家长跟随并拍照记录），活动进行井然有序。活动现场也来了很多老师来参与，或分发或技痒亲自写上几幅对联。更让大家欣喜的是有好些家长带着孩子到活动现场，现场教育孩子要好好向这些大哥哥大姐姐学习，还有现场报名参加下次活动的。更让大家意外的是一个老太太拄着拐杖来拿福字和对联，她说：自从去年拿你们送的福字和对联，家里一年太太平平的，儿子的生意还好了很多，要感谢你们，今年无论如何我都要再来拿几个福和几付对联，让我女儿家也要贴起来，以后我每年都要来，走不动了让儿子扶着都要来。多么淳朴的话语，现场好多人都激动了，我们以后年年搞这样的活动。今年来诸暨了打算两边都搞这样的活动，不忘老朋友，认识新朋友，相信这样锻炼孩子，温暖邻居的活动会得到大家认可的。',
      '这个活动后来被绍兴市文明办评为绍兴市未成人思想道德建设最高奖“希望奖”，孩子也是深受鼓舞，我们大人看着孩子在活动中成长也是深感欣慰，在今后的公益活动中我们将继续践行这种“分享爱，温暖心”的理念，带着孩子投身公益活动。',
    ],
    pictures: [
      'http://dssq.ikeqiao.net/data/image/h5/images/hong/wuhaiying1.png',
      'http://dssq.ikeqiao.net/data/image/h5/images/hong/wuhaiying2.png',
      'http://dssq.ikeqiao.net/data/image/h5/images/hong/wuhaiying3.png',
    ]
  },
  {
    lon: 120.515375,
    lat: 30.080097,
    icon_path: 'http://dssq.ikeqiao.net/data/image/h5/images/hong/xunzhaozuimei-icon.png',
    title: '全国抗击新冠肺炎疫情先进个人（张晓鸿）',
    content: [
      '张晓鸿，女，1971年3月出生，2004年10月加入中国共产党，现任浙江省绍兴市诸暨市浣东街道东盛社区党总支书记。曾获浙江省优秀共产党员、绍兴市首批社区工作“十佳”领军人才、诸暨市新时代优秀共产党员等荣誉称号。',
      '疫情发生以来，张晓鸿仅凭借着简单雨衣口罩和一腔热血与心中的责任感，毅然带领社区干部和志愿者们冲锋在社区防疫“一线”，牢牢守住诸暨市最大城市社区的疫情防线，彰显了共产党员的初心本色。',
      '她是商贸型城市社区抓防疫工作的典范',
      '绍兴市诸暨市有着全球最大的轻纺市场，外来人口众多。东盛社区是浙江省范围内最大的“移民社区”，住户6300余户，居民超2.5万人，60%以上是来自五湖四海的新诸暨人，仅湖北一地就有1428人，温州、台州等省内重点地区有1700多人，来自韩国、日本等国家300余人，人员情况复杂。疫情发生后，张晓鸿有效发挥党组织在关键时刻的核心作用，当好基层治理最底层单元中的指挥官，带领9名社区干部，组织社区300余名志愿者投入到全科网格当中，对来自国内外重点地区人员建立起细致、严苛地地毯式摸排防控体系，掌握关键信息。第一时间发现3例输入型新冠肺炎患者，占全区确诊病人的1/3，摸排出疑似病例10人，做到期间未接触其他人员，有效阻止了疫情在这个2万多人的社区扩散。作为国际化程度较高的社区，理念不同、语言不通成为疫情防控的难点。3月6日，社区接到通知，一户已居家隔离的韩国籍密切接触者需马上送集中隔离点，初步沟通后极其不配合，张晓鸿带上一位朝鲜族社区党员，上门疏导情绪，做通思想工作。 ',
      '她是浙江省智能管控在基层的原型代表',
      '针对大量的重点地区人员，张晓鸿率先提出智能管控措施。一方面，对于重点地区人员通过打电话人工摸排和公安局大数据、社区智慧平台比对，及时确定活动轨迹。如诸暨市第一例新冠患者，就是张晓鸿通过200个电话联系及公安信息这种“线上+线下”模式发现的。在大年三十晚上，锁定了从湖北返程的疑似病例，并在高速口成功拦下送医确诊；另一方面，巧用“人防＋技防”高效率实现715户2680余名居家医学观察对象管控全覆盖，充分整合基层党员、商会、物业公司、退役军人等多方资源合力破解管控难题，确保管控对象不出门，做到“精密智控”。',
      '她是特殊时期下“舍小家、为大家”的平凡社区干部代表',
      '作为社区书记，张晓鸿始终把居民的需求放在第一位，第一时间组建上门随访、物资运送、心理疏导等工作组，光是每天采购运送的物资就需要6辆皮卡车装载，还想方设法为居民解决急需药品、婴幼儿物品等特殊需求。同时，她不厌其烦地做好社区居民的思想工作，疫情期间光是微信好友就增加了500余个，每天接打电话300通以上，通过贴心、细心的服务赢得了广大社区居民的理解与认可，部分隔离对象甚至是确诊患者家属纷纷帮助社区干部做其他居民的工作。这背后是张晓鸿70多个日夜无休，每天工作14个小时以上，平均步数2万+，即使脚扭伤、嘴巴起泡，仍然坚守岗位，从大年三十开始到3月底，不曾与家人吃上一餐团圆饭。',
    ],
    pictures: [
      'http://dssq.ikeqiao.net/data/image/h5/images/hong/zhangxiaohong1.jpg',
    ]
  },
  {
    lon: 120.514752,
    lat: 30.079796,
    icon_path: 'http://dssq.ikeqiao.net/data/image/h5/images/hong/xunzhaozuimei-icon.png',
    title: '省级最美家庭（钟伟）',
    content: [
      '钟伟家庭公道正派、诚实守信，廉洁自律、爱岗敬业，无不良征信记录与行为、能自觉抵制歪风邪气，尊老爱幼，与邻里和睦相处，在社区起到引领和示范作用。该家庭恪守的清廉“三字经”，更是被邻里们津津乐道。',
      '一、思想常“新”，严把“政治关”。',
      '该家庭夫妻俩均为中共党员，受党教育多年。女儿就读浙江大学二年级，担任第六团总支副书记、班团支部书记，已向党组织提交入党申请书，还获得2019-2020学年浙江大学优秀团干部、优秀学生等荣誉。在大是大非面前，夫妻俩都能站稳政治立场，自觉杜绝生活奢靡、贪图享乐、追求低级趣味等。身处纪检监察和服务业统计一线，夫妻俩面临的诱惑和考验相对较多，但坚持做到政治上“清醒”，政治能力相对过硬。在认识上，能够把好思想信念“总开关”，始终保持政治上的清醒和坚定，任何时候都把政治纪律、政治规矩放在第一位。在行动上，始终慎独慎微，紧握戒尺，严守政治纪律政治规矩，严守保密纪律，严守廉洁纪律，决不以权谋私，做到忠诚、干净、担当，没有因为纪检和统计工作容易得罪人或者辛苦而消散热情。一直来，夫妻俩能积极参加学习强国、党员干部网络学院学习，认真参加2020年度诸暨市新提任领导干部培训班、2020年诸暨市区管领导干部党的十九届四中全会精神轮训等。该家庭还热心参与社区工作，疫情期间，能绝对服从社区党组织安排，认真联系服务疫情隔离户，还主动参与文明创建、安全生产等社区活动。',
      '二、本色常“在”，严把“廉政关”。',
      '作为党的忠诚卫士，夫妻俩能带头遵守党纪国法，带头执行反腐倡廉各项规定，带头接受党组织、党员和人民群众监督，确保经济上“清白”。能够自觉做到敬畏人民、敬畏法纪、敬畏组织、敬畏权力，始终坚守个人干净的底线，守住法律和道德的底线。能够坚持慎小慎微，做到在“小”“微”处用心，不让细微邪念萌发，避免逐步蜕变。能够注重道德底线和行为准则，时刻检点思想和言行的变化，慎独慎微，慎权慎利，慎始慎终。去年11月底，钟伟同志进入诸暨市纪委区监委驻区政协机关纪检监察组工作，同年12月11日-2020年9月30日，在绍兴市纪委市监委干部监督室挂职锻炼，负责全市纪检监察干部警示教育信息、周报、总结等工作，撰写《“绍兴范本”凸显派驻权威——绍兴市推进派驻机构规范化建设综述》等20多个材料，以及《以落实“五个要”为契机，努力打造派驻机构规范化建设绍兴“金名片”》等20多条信息。该家庭还积极打造清廉庭院，自觉营造健康向上的居家环境，把房前屋后打理成别致的小菜园、小果园、小花园。',
      '三、心灵常“净”，严把“兴趣关”。',
      '“慎己所好，不为物役”。在坚守权力关、金钱关、地位关、亲情关等的基础上，该一家三口喜好读书，家庭配有2个“清风书房”，父女俩是写作“父女兵”。钟伟同志坚持含辛茹苦“爬格子”这个爱好已有25个年头，历经了从写消息通讯为主到写评论理论为主的转变，唯独无怨无悔的心境，笔耕不辍的热情屹然不动。仅2020年，就在绍兴日报、诸暨日报等媒体刊发文章（图片）330余篇（幅）。同时，形成“每周一评”习惯，共撰写《让“驻村指导员”制度焕发新生机》《“荣誉退休”彰显人文关怀》等89篇评论，公开发表近150篇次，被绍兴日报、诸暨日报评为优秀撰稿人。良好的家风让女儿爱上了阅读，铸成了精神世界的根基。闲暇时，女儿喜欢看报刊、听广播、写文章，公开发表文章超过200篇，曾获得“叶圣陶杯”三等奖，绍兴市大中学生暑期社会实践先进个人等荣誉。在2020年“我心中的清廉诸暨”——“优秀传统文化与古代政德典范”廉政主题征文中，夫妻俩分别获得二等奖、三等奖；在“两手硬两战赢”记录最美瞬间绍兴市职工摄影比赛中，钟伟同志获得一等奖。',
    ],
    pictures: [
      'http://dssq.ikeqiao.net/data/image/h5/images/hong/zhongwei1.jpg',
      'http://dssq.ikeqiao.net/data/image/h5/images/hong/zhongwei2.jpg',
    ]
  },
  {
    lon: 120.516823,
    lat: 30.079209,
    icon_path: 'http://dssq.ikeqiao.net/data/image/h5/images/hong/xunzhaozuimei-icon.png',
    title: '市级道德模范（周玲华）',
    content: [
      '周玲华热心公益事业具有奉献精神。自2008年参加医院志工服务队，2013年成为微动力志愿者以来，一直秉持“奉献、友爱、互助、进步”的志愿服务精神参与志愿活动，是志愿者公益活动的骨干参与者。2008年到2018年期间参与了大小百余次志愿者公益活动，先后被评为绍兴市优秀志愿者、诸暨市最美志愿者、浣东街道劳动模范等称号，受到各级组织和社会的好评，也赢得了广大志愿者的普遍赞誉。',
      '2008年，周玲华开始参加公益活动并走上志工道路，她总是利用双休日时间，自发跑到当时的诸暨华宇医院门诊大厅，身穿黄背心，戴着蓝口罩，认真负责地做好咨询引导工作。起初由于人手少，大家的服务范围也有限，周玲华主要做的是针对病患及家属的疑问解答工作。.5学雷锋志愿活动、敬老院给老人送温暖活动、黄玫瑰拒绝车窗抛物等志愿者活动。她的志愿义举逐渐影响了身边人，就连家人也被感染和带动了起来，她儿子每次寒暑假回来也总会跟着妈妈一起参加志愿服务。',
      '“事情琐碎，但不容易做好，忙起来有时连吃饭的空儿都没有。”周玲华说，曾有一位病人因没带医保卡被医院拒诊，该病人觉得医院是在故意刁难他，情绪有些激动，还把周玲华当作出气筒数落了一通。然而，她非但不生气、不反驳，还把病人请进了办公室，用一杯清茶、几句窝心话缓解气氛，最后想出用传真机接收证件复印的对策，让病人顺利就诊。这名病人临走时对她好一通感谢。',
      '志工就像是病人和医院之间的一道润滑剂。工作熟悉以后，周玲华做起志工来就变得轻车熟路、得心应手起来，她不仅能够熟练地为病人及家属答疑解惑、缴费挂号、推轮椅、办好入院手续，而且还针对一些因家庭经济困难，病情无法得到及时治疗的困难户，组织动员进行义卖募捐活动。她还向公益组织申请资金，解决了一些贫困户的就医难题，有位稽东镇的老人就在医院意见本上写了这样一段话：“感谢可爱的周玲华志工，你像一抹暖阳，温暖着来往病人，给我带来了温暖和感动……”',
      '现在，周玲华不光是医院志工队的一员，更是一名微动力志愿者，积极参加各类志愿者活动。2014年她报名成为了一名省运会志愿者，2015年的市运会，她又做了7天的志愿者。Ｇ２０峰会期间，她顶着炎炎烈日牺牲了自己的休息时间，冒着酷暑进行巡逻，为G20峰会的顺利召开做出自己的贡献。',
      '特别是2014年周玲华报名成为了一名省运会志愿者，在这9天内，她每天6:00起床，7:00准时到达会场，晚上10:00结束才能回家。也许因为太累，最后一天起来发起了高烧，她本想请假，但是想到这是省运会的最后一天，事情繁多，大家都很忙，不能给大家添麻烦，最终还是坚持带病上场，获得了其他志愿者的一致好评。',
      'G20峰会期间，她得知笛扬社区需要平安志愿者，二话不说立马报名参加。每天顶着炎炎烈日，放弃了自己的休息时间，冒着酷暑巡逻。而且在巡逻过程中认真负责，一丝不苟，发现可疑情况就立即通知社区民警。从早到晚，人们总能看到她忙碌的身影：替邻居锁上没锁好的门；领着居民清洁打扫楼道；义务帮助子女不在身边的生病老人，带他们到医院去看病；热心为不识路的群众指路、带路；协助社区工作人员开展各项工作……等等。这里发生的大小事情似乎都逃不过她那双小小的眼睛，经常深入居民群众中，了解群众的心声，并将群众的心声反映到社区。',
      '在诸暨“创建文明城市”期间，她义不容辞地参与到这项活动中，积极参与创建活动及社会公益活动，挨家挨户派发宣传资料，协助举办各种宣传活动，看到有人随手丢垃圾就前去提醒人家要讲文明、创建文明城市人人有责等。发现社区里有垃圾和卫生死角就主动去清理，难以清理的就上报给社区居委会。每天她总是早早起床，检查下小区环境，有一天早上在巡查的时候发现了地面的小广告，立即报告社区工作人员，帮助社区人员一起清理广告，为创建文明城市贡献出自己微薄的力量。',
      '2012年以来，一到春运期间，周玲华都会去诸暨汽车站、绍兴北站等地做志愿者。帮助返乡的人们搬运行李、搀扶老弱病残、维护秩序等等。',
      '绍兴北站自助售票机刚启用，有不少人来寻求帮助想在自动售票机上买票，周玲华也是新手上路，自己也不会熟练操作，这让她感到十分的惭愧。为此她暗下决心一定要学会这个。于是她利用中午休息时间，积极向年轻志愿者学习，经过几天的摸索，终于能够从容的为需要买票的乘客在自助售票机上操作。因此她常常告诫自己：做志愿者在帮助别人的同时，一定也要不断的学习，这样才能进步，更好地为大家服务！',
      '2014年底周玲华参加“点滴微动力 温暖黄玫瑰”关爱环卫工人全省大型活动，从整理衣物开始到最后一站，她都全程参与。这次的活动时间长、任务重，但她总能早早到场，跟着团队奔赴杭州、嘉兴、越城区亲手为环卫工人穿上冬衣。有次因家中有事实在来不及吃早饭，后来饿到胃疼，但她却独自忍着，没有告诉一个人。后来还是同行的志愿者有人看到她脸色不对才发现她身体不适，尽管如此，她还是坚持到了最后。',
      '这些年来，周玲华参加了很多公益活动，诸如图书馆志愿活动、稽东镇向阳花义卖活动、三五学雷锋活动、敬老院给老人送温暖活动等等。她的义举和付出逐渐影响了身边的人，甚至连家人也被感染和带动了起来。在她的影响下，儿子也十分热心公益事业，平时常常参加各种志愿活动，有时间就去敬老院看望老人，暑假回家会跟着妈妈去医院做志工，寒假会和妈妈一起去汽车站做志愿者。',
      '“志愿服务像一粒种子，要播撒出去，让更多的人知晓并受益，才会开花结果。做志愿者最有价值的是可以帮助他人，每当听到对方真诚的道一句‘谢谢’，我别提有多满足了。只要有需要，我就会再出发！”周玲华期待自己能成为一名出色的志工，奉献出更多的正能量！'
    ],
    pictures: [
      'http://dssq.ikeqiao.net/data/image/h5/images/hong/zhoulinghua1.jpg',
      'http://dssq.ikeqiao.net/data/image/h5/images/hong/zhoulinghua2.jpg',
      'http://dssq.ikeqiao.net/data/image/h5/images/hong/zhoulinghua3.jpg',
    ]
  },
  {
    lon: 120.514663,
    lat: 30.080451,
    icon_path: 'http://dssq.ikeqiao.net/data/image/h5/images/hong/xunzhaozuimei-icon.png',
    title: '市级最美家庭（宋芳萍）',
    subTitle: '家训家规： “尊老爱幼，宽容博爱”',
    content: [
      '“这样的好媳妇，真当比亲女儿还亲哉！”在浣东街道东盛社区，有这样一位邻里交口称赞、无不钦佩的好儿媳——宋芳萍。',
      '10多年前，宋芳萍与丈夫结婚后，一起努力打拼，用心经营着一家三口的幸福生活。两年前，一场突如其来的意外，打破了这个小家的宁静。宋芳萍的公公老朱在工地上晕倒近10小时后，被工友发现，紧急送往了医院进行抢救。当时，老朱被诊断为脊髓压迫，病情十分危急，需要连夜送往杭州。与死神整整搏斗了一天一夜后，老朱才被转入重症监护室，捡回了性命。',
      '可是，手术后的老朱虽然意识清楚，但因伤到了脊椎，身体几乎不能动弹，需要家人的长期陪护。宋芳萍当下立断辞掉了工作，将刚上小学的女儿托付给母亲，悉心照顾起公公的生活起居。每天，她隔两个小时就要为老朱翻身，一日三餐给老朱喂饭。“其实，公公那时候心里才是最难受的。”宋芳萍说，原本身体健朗的老朱一时间难以接受自己几乎瘫痪的事实，心情低落到了极点，除了生活上照料好公公，更多的是对他的心理进行疏导。“我总说，意外不能避免，这些都是生活对我们的磨难，我们要坦然接受。”',
      '住院两个月后，老朱转回绍兴人民医院康复中心进行治疗，从最初的不能站，到积极康复后的慢慢站立，再到鼓励其他康复病人，做起了表率。半年的康复时间里，宋芳萍始终陪伴、鼓励着老朱，无微不至，无怨无悔。去年回到家后，每天早晨5点，宋芳萍都要推着老朱去瓜渚湖散散心，练习走路。说起自己的媳妇，宋芳萍的丈夫夸赞地说，她实心实意、细心贴心地照顾着父亲，犹如亲生女儿一样，让他都有些自叹不如。',
      '“日子最难过的时候都已经过去了，现在的每一天，都是向上的。”回顾这两年的生活，宋芳萍说道，两年中，要全身心的照料公公其实她也有过很多迷失自己的时刻，但从小父母的言传身教和“致良知”的传统文化的学习，让她也在不断成长，有了克服这些磨难的勇气。今年，老朱基本恢复生活自理能力，她便重新回归了工作。'
    ],
    pictures: [
      'http://dssq.ikeqiao.net/data/image/h5/images/hong/songfangping1.jpg',
      'http://dssq.ikeqiao.net/data/image/h5/images/hong/songfangping2.jpg',
    ]
  },
];

export default data;