<template>
  <div>
    <!-- <van-list :finished="finished" finished-text="没有更多了" @load="onLoad">
      <div class="list">
        <div
          class="card-nav"
          v-for="item in list"
          :key="item.invitationId"
          @click="gotoLink('xlyzDetail?key='+ item.invitationId)"
        >
          <img v-if="item.avatarUrl" :src="item.avatarUrl" class="image" alt="头像" />
          <img v-else src="http://dssq.ikeqiao.net/data/image/h5/images/defaultUser.png" class="image" alt="头像" />
          <div class="card-content">
            <div class="title">{{item.nickName}}</div>
            <div>擅长：{{item.ability}}</div>
            <div class="footer">
              <span>
                服务人次
                <i>{{item.serviceUp}}</i>
              </span>
              <span>
                费用（次）¥
                <i>{{item.invitationMoney}}</i>
              </span>
            </div>
          </div>
        </div>
      </div>
    </van-list> -->
    <van-dropdown-menu>
      <van-dropdown-item v-model="status"  @change="handleChange($event,'status')" :options="option1" />
      <van-dropdown-item v-model="sortField" @change="handleChange($event,'sortField')" :options="option2" />
    </van-dropdown-menu>
    <ul class="detail-img-box-two">
      <li v-for="item in list" :key="item.key" @click="onQuestionClick(item)">
        <div
          class="img"
        >
          <div class="img-top">
            <div class="img-status" :style="{background: colors[item.status].background,color: colors[item.status].text}"><p class="img-text">{{ item.activityStatus }}</p></div>
            <img :src="item.image" />
          </div>
          <div class="img-title left">{{ item.title }}</div>
          <div class="img-des">
            <!-- <span
              >已有<span class="blue">{{ item.number }}</span
              >人报名</span
            > -->
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import llyjrApi from "api/llyjr/index";
import xlyzApi from "api/llyjr/xlyz";
import yqpddApi from "api/yqpdd";
import { List, Toast } from "vant";
import { mapMutations } from "vuex";

export default {
  data() {
    return {
      page: 1,
      size: 10,
      total: 0,
      list: [],
      finished: false,
      status: '',
      sortField: '',
      option1: [
        { text: '全部活动', value: '' },
        { text: '报名中', value: 0 },
        { text: '进行中', value: 1 },
        { text: '已结束', value: 2 },
      ],
      option2: [
        { text: '默认排序', value: '' },
        { text: '最近开始', value: 'start_time' },
        { text: '报名人数高', value: 'number' },
      ],
      colors: {
        '0': { text: '#4479ED', background: '#E3EFFD' },
        '1': { text: '#E5B20A', background: '#FFF6D9' },
        '2': { text: 'rgba(0,0,0,0.45)', background: '#EDEDED' },
        '4': { text: 'rgba(0,0,0,0.45)', background: '#EDEDED' },
      },
    };
  },
  components: {},
  mounted() {
    this.getList({ page: 1, size: 10 });
  },
  methods: {
    ...mapMutations(["setQuestionInfo"]),
    onQuestionClick(item) {
      // this["setQuestionInfo"](item);
      this.gotoLink("wyslDetail?key="+item.activityId);
    },
    getList({ page = this.page, size = this.size } = {}) {
      llyjrApi
        .getPlhd({
          page,
          size,
          object: {
            activityType: '陪聊活动',
            status: this.status,
            sortField: this.sortField,
            sort: 'desc'
          }
        })
        .then(res => {
          const { list = [], total = 0 } = res.data || {};
          this.list = this.list.concat(list);
          this.total = total;
          if (this.list.length >= this.total) {
            // 数据全部加载完成
            this.finished = true;
          }
        });
    },
    onSearch(value) {
      const params = {
        page: 1,
        size: 10,
        object: {
          activityType: '陪聊活动',
          status: this.status,
          sortField: this.sortField,
          sort: 'desc'
        },
      };
      llyjrApi
        .getPlhd(params)
        .then(res => {
          const { list = [], total = 0 } = res.data || {};
          this.list = list;
          this.total = total;
          if (this.list.length >= this.total) {
            // 数据全部加载完成
            this.finished = true;
          }
        });
    },
    onLoad() {
      this.page += 1;
      this.getList();
    },
    handleChange(e, type) {
      this[type] = e;
      this.onSearch();
    },
    gotoLink(path) {
      this.$router.push(path);
    },
    goBack() {
      this.$router.go(-1);
    },
    showAlert() {
      this.$messagebox("提示", "系统正在建设中，尽情期待~");
    }
  }
};
</script>

<style lang="scss" scoped>
.detail-img-box-two {
    height: auto !important;
    background: #FFFFFF;
    width: 100%;
    li {
      display: inline-flex;
      align-items: center;
      width: 50% !important;
      height: auto !important;
      .img {
        height: auto !important;
        width:100% !important;
        text-align: center;
        .img-top {
          margin: 0 0.6rem;
          position: relative;
          overflow: hidden;
          .img-status {
            width: 20rem;
            height: 6rem;
            max-width: 50%;
            // max-height: 100%;
            // background-color: red;
            transform: rotate(45deg);
            position: absolute;
            right: -2.8rem;
            top: -3.2rem;
          }
          .img-text {
            width: 20rem;
            height: 5.5rem;
            padding: 0;
            margin: 0;
            display: table-cell; /*按照单元格的样式显示元素*/
            vertical-align: bottom; /*底对齐*/
          }
        }
        img {
          width: auto !important;
          height: auto !important;
          max-height:10rem;
          max-width: 100%;
        }
        .img-title {
          text-align: center;
          overflow: hidden;
          color: rgba(0, 0, 0, 0.85);
          line-height: 1.2rem;
          font-size: 1rem;
          font-weight: bold;
          padding: 0.5rem 0.6rem 0 0.6rem;
        }
        .img-des {
          text-align: center;
          color: rgba(0, 0, 0, 0.85);
          line-height: 1.2rem;
          font-size: 0.8rem;
          padding: 0.5rem 0.6rem;
          display: flex;
          align-items: center;
          img {
            width: 1rem !important;
            height: 1rem !important;
            margin-right: 0.2rem;
            padding: 0;
          }
          img:nth-child(3) {
            margin-left: 0.6rem;
          }
        }
      }
    }
  }
.list {
  .card-nav {
    background: #ffffff;
    padding: 0.8rem;
    padding-bottom: 0;
    display: flex;
    .image {
      height: 4rem;
      width: 4rem;
      border-radius: 2rem;
    }
    .card-content {
      flex: 1;
      // padding: 0.6rem;
      margin: 0 0.6rem;
      padding-bottom: 0.8rem;
      border-bottom: 1px solid rgba(17, 31, 44, 0.1);
     
      font-size: 14px;
      color: rgba(0, 0, 0, 0.65);
      text-align: left;
      line-height: 22px;
      .title {
       
        font-size: 16px;
        color: rgba(0, 0, 0, 0.85);
        text-align: left;
        line-height: 22px;
        font-weight: 600;
      }
      .footer {
       
        font-size: 12px;
        color: rgba(0, 0, 0, 0.65);
        text-align: left;
        line-height: 12px;
        margin: 0.4rem 0;
        span {
          margin-right: 1rem;
          i {
            color: #4479ed;
            margin: 0 2px;
          }
        }
      }
    }
  }
}
</style>
