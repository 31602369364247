<template>
  <div class="page-container" style="padding-top: 2.7rem !important;margin-top: 0 !important;">
    <div class="header-nav">
      <i @click="goBack"></i>
      <h1>发布服务</h1>
    </div>
    <div class="form-bar">
        <van-form @submit="onSubmit">
          <van-field
            size="large"
            v-model="title"
            name="title"
            label="需求标题"
            placeholder="请输入"
            class="input-bar"
            maxlength="20"
            :rules="[{ required: true, message: '请输入需求标题' }]"
          />
          <van-field
            size="large"
            v-model="name"
            name="needName"
            label="需求人"
            placeholder="请输入"
            class="input-bar"
            :rules="[{ required: true, message: '请输入需求人' }]"
          />
          <van-field
            size="large"
            v-model="phone"
            name="needPhone"
            type="tel"
            label="联系电话"
            placeholder="请输入"
            :rules="[{ pattern, message: '请输入正确的联系电话' }]"
            class="input-bar"
          />
          <van-field
            size="large"
            v-model="className"
            name="className"
            is-link
            readonly
            label="需求类型"
            placeholder="请选择"
            @click="showType = true"
            :rules="[{ required: true, message: '请选择需求类型' }]"
            class="input-bar"
          />
          <div class="section" style="padding: 0.8rem">
            <div class="title">需求内容</div>
            <van-field
              size="large"
              v-model="description"
              name="content"
              rows="3"
              autosize
              label=""
              type="textarea"
              maxlength="200"
              placeholder="请输入需求内容"
              show-word-limit
              :rules="[{ required: true, message: '请输入具体内容' }]"
            />
          </div>
          <div class="section" style="padding: 0.8rem">
            <div class="title">图片</div>
            <van-uploader
              v-model="fileList"
              name="imageList"
              multiple
              :max-count="5"
              :rules="[{ required: true, message: '请选择图片' }]"
            />
          </div>
          <div class="footer-button">
            <van-button
              round
              block
              type="info"
              style="font-size: 16px"
              native-type="submit"
              >立即发布</van-button
            >
          </div>
        </van-form>
      </div>
      <van-popup v-model="showType" position="bottom">
        <van-picker
          title="问题类别"
          show-toolbar
          :columns="classNameList"
          @cancel="showType = false"
          @confirm="handlePick"
        />
      </van-popup>
    </div>
</template>
<script>
import { Toast } from "vant";
import commonApi from "api/common";
import nrbApi from 'api/llyjr/nrb';
import { mapState, mapActions } from 'vuex';
export default {
  data() {
    return {
      className: "",
      showType: false,
      name: "",
      title: "",
      phone: "",
      showDate: false,
      description: "",
      fileList: [],
      pattern: /^[1]([3-9])[0-9]{9}$/,
    };
  },
  components: {},
  mounted() {},
  created() {
    this.getCategoryList();
  },
  methods: {
    ...mapActions(["getCategoryList"]),
    goBack() {
      this.$router.go(-1);
    },
    handlePick(data) {
      this.className = data
      this.showType = false;
    },
    onSubmit(values) {
      if(this.fileList && this.fileList.length < 1) {
        Toast('请选择活动图片后提交');
        return ;
      }
      const formData = new FormData();
      this.fileList.forEach((item) => formData.append("files", item.file));
      commonApi.uploadMultiFile(formData).then((res) => {
        const params = {
          ...values,
          image: res.data,
        };
        nrbApi.addNrbInfo(params).then((res) => {
          const { message,data, flag } = res;
          if (flag) {
            const timer = setTimeout(() => {
               this.$router.go(-1);
               clearTimeout(timer);
            }, 1000);
            Toast('发布成功');
          } else {
            Toast(message);
          }
        });
      });
    }
  },
  computed: {
    ...mapState({
      classNameList: (state) => state.llyjr.classNameList,
    })
  },
};
</script>

<style lang="scss">
@import "~assets/scss/commit.scss";
.page-container {
  padding-top: 3.2rem;
  height: calc(100vh - var(--window-top));
  margin-top: calc(var(--window-top));
  // background: #f6f7fa;
  .form-bar {
    height: 100%;
    
    .section {
      width: 100%;
      margin-top: 0.8rem;
      background: #ffffff;
      .title {
        margin: 0.5rem 0;
        font-size: 16px;
        color: #646566;
        letter-spacing: 0;
      }
    }
    .footer-button {
      position: fixed;
      bottom: 0.8rem;
      right: 0.8rem;
      left: 0.8rem;
    }
  }
}
</style>
