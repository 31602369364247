<template>
  <div class="memberManage-container">
    <van-nav-bar title="先锋指数" left-arrow @click-left="onClickLeft" />
    <div class="member-manage-body-container">
      <!-- top bar -->
      <!-- <div class="top-bar">
        <div
          v-for="(item, index) in barList"
          :key="`${item.name}_${index}}`"
          :class="['bar-item', item.class]"
          @click="gotoLink(item.path)"
        >
          <div>{{ item.name }}</div>
        </div>
      </div> -->

      <!-- 先锋指数 -->
      <div class="ahead-index-con">
        <!-- 头部卡片 -->
        <div class="ahead-index-top">
          <!-- <div class="ahead-index-title">先锋指数</div> -->
          <div class="award-con">
            <div class="award-item">
              <div class="avatar-second">
                <van-image
                  class="img-head"
                  round
                  width="44px"
                  height="44px"
                  fit="cover"
                  :src="second.image" />
                <!-- <img :src="`http://dssq.ikeqiao.net/data/image/dangjian/${second.belongs_to_party_branch_users.name}.jpg`" alt=""> -->
              </div>
              <div class="name">
                {{ second.userName }}
              </div>
              <van-rate
                size="10px"
                v-model="second.starts"
                readonly
                allow-half
                void-icon="star"
                void-color="#eee"
              />
              <div class="score">{{ second.pioneerIndex }}</div>
              <div class="tip">先锋指数</div>
            </div>
            <div class="award-item">
              <div class="avatar-first">
                <van-image
                  class="img-head"
                  round
                  width="60px"
                  height="60px"
                  fit="cover"
                  :src="first.image" />
                <!-- <img :src="`http://dssq.ikeqiao.net/data/image/dangjian/${first.belongs_to_party_branch_users.name}.jpg`" alt=""> -->
              </div>
              <div class="name">
                {{ first.userName }}
              </div>
              <van-rate
                size="10px"
                v-model="first.starts"
                readonly
                allow-half
                void-icon="star"
                void-color="#eee"
              />
              <div class="score">{{ first.pioneerIndex }}</div>
              <div class="tip">先锋指数</div>
            </div>
            <div class="award-item">
              <div class="avatar-third">
                <van-image
                  class="img-head"
                  round
                  width="44px"
                  height="44px"
                  fit="cover"
                  :src="third.image" />
                <!-- <img :src="`http://dssq.ikeqiao.net/data/image/dangjian/${third.belongs_to_party_branch_users.name}.jpg`" alt=""> -->
              </div>
              <div class="name">
                {{ third.userName }}
              </div>
              <van-rate
                size="10px"
                v-model="third.starts"
                readonly
                allow-half
                void-icon="star"
                void-color="#eee"
              />
              <div class="score">{{ third.pioneerIndex }}</div>
              <div class="tip">先锋指数</div>
            </div>
          </div>
        </div>
        <!-- 列表 -->
        <div class="ahead-index-list">
          <div
            class="ahead-index-item"
            v-for="(item, index) in data"
            :key="`${item.id}_${index}`"
          >
            <div class="rank">{{ index + 4 }}</div>
            <div class="avatar-rate">
              <div class="avatar">
                <van-image
                  class="img-head"
                  round
                  width="44px"
                  height="44px"
                  fit="cover"
                  :src="item.image" />
                <!-- <img :src="item.belongs_to_party_branch_users ? `http://dssq.ikeqiao.net/data/image/dangjian/${item.belongs_to_party_branch_users.name}.jpg` : ''" alt=""> -->
              </div>
              <div class="name-rate">
                <div>
                  {{
                    item.userName
                  }}
                </div>
                <van-rate
                  size="10px"
                  v-model="item.starts"
                  readonly
                  allow-half
                  void-icon="star"
                  void-color="#eee"
                />
              </div>
            </div>
            <div class="score">
              <div>{{ item.pioneerIndex }}</div>
              <div class="tip">先锋指数</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Api from "api";
import { mapMutations, mapState } from "vuex";
import aheadIndex from "assets/json/xianfengzhishu.json";
export default {
  data() {
    return {
      barList: [
        {
          name: "党费缴纳",
          class: "pay",
          path: "duesPayment",
        },
        {
          name: "“红小二”义工",
          class: "volunteer",
          path: "hongxiaoer",
        },
      ],
      data: [], // 先锋指数列表数据
      first: {}, // 🥇
      second: {}, // 🥈
      third: {}, // 🥉
    };
  },
  computed: {},
  components: {},
  mounted() {
    this.getList();
  },
  watch: {},
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    handleTab() {},
    gotoLink(path) {
      if (path) {
        this.$router.push(path);
      }
    },
    getRate(num) {
      return num / 20;
    },
    getList() {
      Api.getMemberManageList({
        page: 0,
        pageSize: 100,
        searchText: ''
      }).then(res => {
        if(res.flag) {
          this.data = res.data.records ? res.data.records.slice(3) : [];
          this.first = res.data.records && res.data.records[0] ? res.data.records[0] : {};
          this.second = res.data.records && res.data.records[1] ? res.data.records[1] : {};
          this.third = res.data.records && res.data.records[2] ? res.data.records[2] : {};
        }
      })
    }
  },
};
</script>

<style lang="scss" scoped>
.memberManage-container {
  height: 100vh;
  width: 100vw;

  .member-manage-body-container {
    height: calc(100vh - 2.7rem);
    width: 100vw;
    padding-bottom: 10px;
    background: url("http://dssq.ikeqiao.net/data/image/h5/images/coordination/coordination-bg.png") no-repeat;
    background-size: 100% 100%;
    overflow-y: auto;
    overflow-x: hidden;

    .top-bar {
      width: calc(100% - 20px);
      margin: 10px;
      display: flex;
      justify-content: space-between;
      .bar-item {
        width: calc(50% - 5px);
        height: 82px;
        border-radius: 4px;
        padding: 1rem;
        font-size: 18px;
        color: #fff;
      }
      .pay {
        background: url("http://dssq.ikeqiao.net/data/image/h5/images/coordination/pay.png") no-repeat;
        background-size: 100%;
      }
      .volunteer {
        background: url(http://dssq.ikeqiao.net/data/image/h5/images/coordination/volunteer.png) no-repeat;
        background-size: 100%;
      }
    }

    .ahead-index-con {
      width: calc(100% - 20px);
      margin: 10px;
      background-color: #fff;
      .ahead-index-top {
        width: 100%;
        height: 218px;
        background: url("http://dssq.ikeqiao.net/data/image/h5/images/coordination/ahead-index-top.png")
          no-repeat;
        background-size: 100%;
        .ahead-index-title {
          font-size: 18px;
          color: rgba(0, 0, 0, 0.85);
          text-align: center;
          font-weight: 500;
          line-height: 44px;
        }
        .award-con {
          display: flex;
          justify-content: space-around;
          align-items: flex-end;
          .award-item {
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: center;
            .avatar-first {
              width: 66px;
              height: 88px;
              background: url("http://dssq.ikeqiao.net/data/image/h5/images/coordination/no1.png") no-repeat;
              background-size: 100%;
              position: relative;
              .img-head {
                position: absolute;
                bottom: 6px;
                left: 3px;
              }
            }
            .avatar-second,
            .avatar-third {
              width: 48px;
              height: 68px;
              background: url("http://dssq.ikeqiao.net/data/image/h5/images/coordination/no2.png") no-repeat;
              background-size: 100%;
              position: relative;
              .img-head {
                position: absolute;
                bottom: 2px;
                left: 2px;
              }
            }
            .avatar-third {
              background: url("http://dssq.ikeqiao.net/data/image/h5/images/coordination/no3.png") no-repeat;
              background-size: 100%;
            }
            .name {
              font-size: 14px;
              color: rgba(0, 0, 0, 0.65);
              letter-spacing: 0;
              font-weight: 500;
              text-align: center;
              line-height: 24px;
            }
            .score {
              font-size: 18px;
              color: rgba(0, 0, 0, 0.85);
              letter-spacing: 0;
              text-align: center;
              font-weight: 500;
              line-height: 24px;
            }
            .tip {
              font-size: 12px;
              color: rgba(0, 0, 0, 0.4);
              letter-spacing: 0;
              text-align: center;
              font-weight: 400;
            }
          }
        }
      }

      .ahead-index-list {
        width: 100%;
        .ahead-index-item {
          width: 100%;
          display: flex;
          align-items: center;
          height: 76px;

          .avatar-rate {
            flex: 1;
            display: flex;
            align-items: center;
            height: 100%;
            border-bottom: 1px solid rgba(17, 31, 44, 0.1);
            .avatar {
              margin: 10px;
              .img-head {
                border-radius: 30px;
              }
            }
            .name-rate {
              font-size: 16px;
              color: rgba(0, 0, 0, 0.85);
              letter-spacing: 0;
              font-weight: 500;
              line-height: 20px;
            }
          }
          .rank {
            width: 15%;
            font-size: 16px;
            color: rgba(0, 0, 0, 0.45);
            letter-spacing: 0;
            font-weight: 500;
            text-align: center;
          }
          .score {
            width: 30%;
            font-size: 18px;
            color: rgba(0, 0, 0, 0.85);
            letter-spacing: 0;
            text-align: center;
            font-weight: 500;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            border-bottom: 1px solid rgba(17, 31, 44, 0.1);
            line-height: 20px;
            .tip {
              font-size: 12px;
              color: rgba(0, 0, 0, 0.4);
              font-weight: 400;
            }
          }
        }
      }
    }
  }
}
</style>
