import service from "utils/service";

const courtApi = {
  getCourtList: (params) => {
    return service.post("/bourtLitigation/allLitigation", params);
  }, // 我的诉讼
  addCourt: params => {
    return service.post('/bourtLitigation/litigate', params);
  }, // 发起诉讼
  getCounts: params => {
    return service.get('/bourtLitigation/getCount', { params });
  }, // 获取诉讼总数
  cancelCourt: params => {
    return service.get('/bourtLitigation/delectOrRevoke', { params });
  }, // 删除/撤回诉讼
  getCountInfo: params => {
    return service.get('/bourtLitigation/getDetail', { params });
  }, // 获取诉讼详情
};
export default courtApi;
