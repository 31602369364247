<template>
  <div
    class="zxzxIndex"
    style="background: #fff; height: 100vh; padding-top: 2.7rem"
  >
    <div class="header-nav">
      <i @click="goBack"></i>
      <h1>党建资讯</h1>
    </div>
    <!-- <van-tabs color="#5278e5" @click="tabClick">
      <van-tab title="全部" name="" />
      <van-tab
        v-for="item in selectList"
        :title="item.dictLabel"
        :name="item.dictValue"
      >
      </van-tab>
    </van-tabs> -->
    <!-- <mt-navbar v-model="selected">
      <mt-tab-item :id="item.id" v-for="(item, index) in selectList" :key="index">{{item.name}}</mt-tab-item>
    </mt-navbar> -->
    <div class="zxList">
      <div
        class="item"
        v-for="(item, index) in eventList || []"
        :key="index"
        @click="showDetailPage(item)"
      >
        <div class="title">{{ item.title }}</div>
        <div class="desc">{{ item.subhead }}</div>
        <div class="image"><img :src="item.cover" alt="" /></div>
        <div class="time">{{ item.publishTime }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
import { mapMutations } from 'vuex';
import homeApi from "api/home";
export default {
  data() {
    return {
      selected: "",
      selectList: [],
      eventList: [],
    };
  },
  components: {},
  mounted(opt) {
    // homeApi.getAllDictByType({ type: "column_type" }).then((res) => {
    //   const { data, flag } = res;
    //   if (flag) {
    //     this.selectList = (data || []).filter(item => item.dictLabel !== '党建资讯') || [];
    //   }
    // });
    homeApi
      .getArticleList({
        page: 1,
        pageSize: 1000000,
        type: 12,
        columnType: "2",
      })
      .then((res) => {
        const { flag, data } = res;
        if (data && flag) {
          this.eventList = data.records || [];
        }
      });
  },
  methods: {
    ...mapMutations(['setDataInfo']),
    tabClick(value, name) {
      console.log(value, name);
      homeApi
        .getArticleList({
          page: 1,
          pageSize: 1000000,
          type: 12,
          columnType: Number(value),
        })
        .then((res) => {
          console.log(res);
          const { flag, data } = res;
          if (data && flag) {
            this.eventList = data.records || [];
          }
        });
    },
    goBack() {
      this.$router.go(-1);
    },
    showDetailPage(data) {
      this.setDataInfo(data);
      if (data.contentType === 1) {
        window.location.href = data.content;
      } else {
        this.$router.push(`/zxzxDetail`);
      }
    },
  },
};
</script>

<style lang="scss">
@import "assets/scss/index.scss";

.zxzxIndex {
  height: 100% !important;
  position: relative;
  .mint-tab-item-label {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.65);
    letter-spacing: 0;
    font-weight: 400;
  }
  .zxList {
    padding: 10px;
    .item {
      width: 100%;
      height: 260px;
      margin-top: 10px;
      background: #ffffff;
      border-radius: 8px;
      border-radius: 8px;
      .title {
        font-size: 16px;
        color: #000000;
        line-height: 30px;
      }
      .desc {
        color: rgba(0, 0, 0, 0.65);
        line-height: 20px;
        font-size: 14px;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      .image {
        width: 100%;
        height: 170px;
        > img {
          width: 100%;
          height: 100%;
        }
      }
      .time {
        line-height: 20px;
        text-align: right;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.65);
      }
    }
  }
}
</style>
