<template>
  <div class="statistic-chart-container">
    <van-nav-bar
      title="数据统计"
    />
    <div class="statistic-chart-body-container">
      <!-- top bar -->
      <div class="top-bar">
        <van-tabs v-model="selected" color="#4379ed">
          <van-tab title="系统日活" name="system">
            <div class="shujudongtai-bar">
              <!-- 选项 -->
              <div class="type-btn-con">
                <div
                  v-for="(item, i) in typeList"
                  :key="`${item.label}_${i}`"
                  :class="['type-btn', {'selected': isSelected(item, 'system')}]"
                  @click="handleSelect(item, 'system')"
                >
                  {{item.label}}
                </div>
                <!-- <div :class="['type-btn', 'arrow-down', {'selected': isSelected('自定义')}]" @click="handleSelect('自定义')">
                  自定义
                  <img src="http://dssq.ikeqiao.net/data/image/h5/images/arrow_down.png" alt="">
                </div> -->
              </div>
              <div class="zhibiaotongji-bar-list">
                <div
                  :class="['zhibiaotongji-bar-list-card', 'dailyActiveUser']"
                >
                  <div>
                    <span>{{`${systemCardData.activeNumber}`}}</span>
                    <span>人</span>
                  </div>
                  <p>{{`${system}访问次数`}}</p>
                  <!-- <img :src="item.icon" alt=""> -->
                </div>
                <div
                  :class="['zhibiaotongji-bar-list-card', 'dailyActivePercent']"
                >
                  <div>
                    <span>{{systemCardData.activeRate}}</span>
                    <span>%</span>
                  </div>
                  <p>{{`${system}活跃率`}}</p>
                  <!-- <img :src="item.icon" alt=""> -->
                </div>
              </div>
              <div class="chart-con">
                <StatisticLineChart1 :cdata="systemDataList" />
              </div>
            </div>
            <!-- 统计详情 -->
            <div class="zhibiaotongji-bar">
              <h1>统计详情</h1>
              <div class="zhibiaotongji-bar-list">
                <table class="list-table">
                  <th>
                    <td>日期</td>
                    <td>访问次数(次)</td>
                    <td>活跃率</td>
                  </th>
                  <tr v-for="(item, index) in systemDataList" :key="`tr_${index}`" :class="[`tr${index%2}`]">
                    <td>{{item.everyDate}}</td>
                    <td>{{item.activeNumber}}</td>
                    <td>{{item.activeRate}}</td>
                  </tr>
                </table>
              </div>
            </div>
          </van-tab>
          <van-tab title="应用日活" name="app">
            <!-- <div class="shujudongtai-bar"> -->
              <!-- 选项 -->
              
              <!-- <div class="chart-con">
                <StatisticLineChart2 :cdata="appDataList" />
              </div> -->
            <!-- </div> -->
            <!-- 应用活跃用户排名 -->
            <div class="zhibiaotongji-bar">
              <h1>应用活跃用户排名</h1>
              <div class="type-btn-con">
                <div
                  v-for="(item, i) in typeList"
                  :key="`${item.label}_${i}`"
                  :class="['type-btn', {'selected': isSelected(item, 'app')}]"
                  @click="handleSelect(item, 'app')"
                >
                  {{item.label}}
                </div>
                <!-- <div :class="['type-btn', 'arrow-down', {'selected': isSelected('自定义')}]" @click="handleSelect('自定义')">
                  自定义
                  <img src="http://dssq.ikeqiao.net/data/image/h5/images/arrow_down.png" alt="">
                </div> -->
              </div>
              <div class="active-module-list">
                <div class="active-module-line" v-for="(item,index) in appDataList" :key="item.applyName">
                  <div class="active-module-icon">
                    <img :src="index < 3 ? `http://dssq.ikeqiao.net/data/image/h5/images/number${index}.png` : 'http://dssq.ikeqiao.net/data/image/h5/images/number.png'" alt="">
                    <p>{{index + 1}}</p>
                  </div>
                  <h1 class="active-module-name">{{item.applyName}}</h1>
                  <span class="active-module-number">{{`${item.activeNumber}人`}}</span>
                </div>
              </div>
            </div>
          </van-tab>
        </van-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import api from 'api';
import { mapMutations, mapState } from "vuex";
import StatisticLineChart1 from './statisticLineChart1';
import StatisticLineChart2 from './statisticLineChart2';
export default {
  data() {
    return {
      selected: '',
      systemCard: [
        {
          title: '访问次数',
          key: 'activeNumber',
          unit: '次',
          class: 'dailyActiveUser',
          color: "#fff7f5"
        },
        {
          title: '活跃率',
          key: 'activeRate',
          unit: '%',
          class: 'dailyActivePercent',
        },
      ],
      systemData: [
        {
          date: '2021-10-11',
          activeNum: 213,
          activePercent: '68%'
        },
        {
          date: '2021-10-10',
          activeNum: 213,
          activePercent: '68%'
        },
        {
          date: '2021-10-09',
          activeNum: 213,
          activePercent: '68%'
        },
        {
          date: '2021-10-08',
          activeNum: 213,
          activePercent: '68%'
        },
      ],
      typeList: [
        {
          label: '今日',
          value: 0
        }, {
          label: '昨日',
          value: 1
        },
        {
          label: '近7日',
          value: 7
        }, 
        {
          label: '近30日',
          value: 30
        }
      ],
      moduleList: [
        {
          name: '一起拼多多',
          value: 1213
        },
        {
          name: '邻里陪聊',
          value: 939
        },
        {
          name: '红脉动',
          value: 829
        },
        {
          name: '共享工具箱',
          value: 821
        },
        {
          name: '邻里聊',
          value: 782
        },
        {
          name: '享协同',
          value: 678
        },
        {
          name: '惠学习',
          value: 213
        },
      ],
      system: '今日',
      app: '今日',
      systemDate: 0,
      appDate: 0,
      systemDataList: [],
      systemCardData: {},
      appDataList: [],
    };
  },
  computed: {
  },
  components: { StatisticLineChart1, StatisticLineChart2 },
  mounted() {
    this.getSystemStatisticData();
    this.getAppStatisticData();
  },
  watch: {
   
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    handleTab() {

    },
    gotoLink(path) {
      if(path) {
        this.$router.push(path);
      }
    },
    isSelected(item, type) {
      return this[type] === item.label;
    },
    handleSelect(data, name) {
      this[name] = data.label;
      this[`${name}Date`] = data.value;
      if(this.selected === 'system') {
        this.getSystemStatisticData();
      } else if (this.selected === 'app') {
        this.getAppStatisticData();
      }
    },
    getSystemStatisticData() {
      api.getStatisticChartData({
        date: this.systemDate,
        type: '系统应用'
      }).then(res => {
        this.systemDataList = res.data.list;
        this.systemCardData = res.data.date ? { ...res.data.date, activeRate: (res.data.date.activeRate * 100).toFixed(2)} : {};
      })
    },
    getAppStatisticData() {
      api.getStatisticChartData({
        date: this.appDate,
        type: '程序应用'
      }).then(res => {
        this.appDataList = res.data.list;
      })
    }
  }
};
</script>

<style lang="scss" scoped>
.statistic-chart-container {
  height: 100vh;
  width: 100vw;
  
  .statistic-chart-body-container {
    height: calc(100vh - 46px);
    width: 100vw;
    padding-bottom: 10px;
    background: url('http://dssq.ikeqiao.net/data/image/h5/images/coordination/coordination-bg.png') no-repeat;
    background-size: 100% 100%;
    overflow-y: auto;
    overflow-x: hidden;
    .type-btn-con {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      .type-btn {
        background: #F4F5F8;
        width: calc(20% - 5px);
        border-radius: 4px;
        line-height: 32px;
        text-align: center;
        font-size: 14px;
        color: rgba(0,0,0,0.65);
        margin: 0 5px 10px 0;
      }
      .arrow-down {
        display: flex;
        align-items: center;
        justify-content: center;
        >img {
          width: 14px;
          height: 14px;
        }
      }
      .selected {
        color:  #4479ED;
        background:  rgba(68,121,237,0.15);
      }
    }
    .shujudongtai-bar {
      width: 100vw;
      padding: 15px 8px;
      background-color: #FFF;
      margin-top: 10px;
      .chart-con {
        height: 202px;
        width: 100%;
      }
      >p {
        color: rgba(0,0,0,0.45);
        font-size: 12px;
      }
    }
    .zhibiaotongji-bar-list {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      
      .zhibiaotongji-bar-list-card, .zhibiaotongji-bar-list-card4 {
        width: 48%;
        border-radius: 4px;
        height: 78px;
        margin: 5px 0;
        background-color: rgba(0,0,0,0.03);
        padding: 1rem;
        position: relative;
        >div {
          font-size: 14px;
          color: rgba(0,0,0,0.85);
          line-height: 20px;
          font-weight: 600;
          >span:nth-child(1) {
            font-size: 20px;
            color: rgba(0,0,0,0.85);
            font-weight: 600;
          }
        }
        >img {
          width: 48px;
          height: 48px;
          position: absolute;
          bottom: 0;
          right: 0;
        }
        >p {
          font-size: 12px;
          color: rgba(0,0,0,0.65);
          letter-spacing: 0;
          line-height: 24px;
        }
      }
      .dailyActiveUser {
        background: url('http://dssq.ikeqiao.net/data/image/h5/images/hong/dailyActiveUser.png') no-repeat;
        background-size: 100% 100%;
      }
      .dailyActivePercent {
        background: url('http://dssq.ikeqiao.net/data/image/h5/images/hong/dailyActivePercent.png') no-repeat;
        background-size: 100% 100%;
      }
      .zhibiaotongji-bar-list-card4 {
        width: 32%;
      }
      .list-table {
        text-align: center;
        width: 100%;
        >th {
          width: 100%;
          height: 48px;
          background: #F4F5F8;
          border-radius: 4px;
          display: table;
          font-size: 16px;
          color: #4479ED;
          display: flex;
          align-items: center;
          >td {
            flex: 1;
          }
        }
        .tr1 {
          width: 100%;
          height: 48px;
          background: #F4F5F8;
          border-radius: 4px;
          display: flex;
          align-items: center;
          >td {
            flex: 1;
          }
        }
        .tr0 {
          width: 100%;
          height: 48px;
          background: #FFFFFF;
          border-radius: 4px;
          display: flex;
          align-items: center;
          >td {
            flex: 1;
          }
        }
      }
    }
    .zhibiaotongji-bar {
      width: 100vw;
      padding: 15px;
      background-color: #FFF;
      margin-top: 10px;
      >h1 {
        font-size: 16px;
        color: rgba(0,0,0,0.85);
        font-weight: 500;
        text-align: center;
        margin-bottom: 10px;
      }
      .active-module-list {
        .active-module-line {
          width: 100%;
          display: flex;
          align-items: center;
          height: 48px;
          background: #FFFFFF;
          border-bottom: 1px solid rgba(0,0,0,0.10);
        }
        .active-module-icon {
          position: relative;
          width: 15%;
          >img {
            width: 25px;
          }
          >p {
            position: absolute;
            top: 3px;
            left: 8px;
            font-size: 14px;
            color: #FFFFFF;
            font-weight: 500;
          }
        }
        .active-module-name {
          width: 60%;
          font-size: 16px;
          color: rgba(0,0,0,0.85);
          letter-spacing: 0;
          line-height: 16px;
          font-weight: 500;
          text-align: left;
        }
        .active-module-number {
          width: 20%;
          font-size: 16px;
          color: rgba(0,0,0,0.65);
        }
      }
    }
  }
}
</style>
