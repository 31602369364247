<template>
  <div id="index">
    <div class="luck-xqyg-container">
      <div class="header-nav">
        <i @click="goBack"></i>
        <h1>下期预告</h1>
      </div>
      <div class="detailInfo" v-if="luckyActiveItem.ptype === '自定义'">
        <div class="title">{{luckyActiveItem.ptitle}}</div>
        <!-- <div class="time">
          <img src="http://dssq.ikeqiao.net/data/image/h5/images/hotNew/time.png" alt="">
          <span>2021-5-20 12:00</span>
          <img src="http://dssq.ikeqiao.net/data/image/h5/images/hotNew/see.png" alt="">
          <span>23</span>
        </div> -->
        <div class="desc"  v-html="luckyActiveItem.pdetail"></div>
      </div>
      <div style="width: 100%;height: 100%">
        <iframe
          id="myiframe"
          scrolling="auto"
          frameborder="0"
          width="100%"
          height="100%"
          :src="luckyActiveItem.plink"
        ></iframe>
      </div>
    </div>
  </div>
</template>

<script>
import homeApi from "api/home";
import luckyApi from 'api/lucky';
export default {
  data() {
    return {
      luckyActiveItem: {},
    };
  },
  components: {
  },
  mounted() {
    const { key } = this.$route.query;
    luckyApi.drawLotteryInformationListPage({
        sort: 1,
        lid: key,
      }).then((res) => {
        const { data } = res;
        const { records } = data || {};
        const list = records || [];
        this.luckyActiveItem = list.length > 0 ? list[0] : {};
      });
  },
  methods: {
    goBack(){
          this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss">
@import "~assets/scss/index.scss";
.luck-xqyg-container{
  padding-top: 2.7rem;
  padding-bottom: 10px;
  height: 100vh;
  overflow: scroll;
  .detailInfo{
    padding: 10px;
    .title{
      font-size: 16px;
      color: rgba(0,0,0,0.85);
      text-align: left;
      line-height: 24px;
      font-weight: 500;
    }
    .time{
      line-height: 30px;
      display: flex;
      font-size: 12px;
      color: rgba(0,0,0,0.45);
      text-align: left;
      font-weight: 400;
      >img{
        width: 20px;
        height: 20px;
        margin: 5px;
      }
    }
    .desc{
      font-size: 14px;
      color: rgba(0,0,0,0.65);
      text-align: left;
      line-height: 22px;
      font-weight: 400;
      img{
        width: 100% !important;
      }
    }
  }
}
</style>
