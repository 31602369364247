<template>
  <div class="main-container flex flex-col">
    <van-nav-bar title="社区公约" left-arrow @click-left="$router.go(-1)" />
    <section class="flex-1 flex flex-col items-center">
      <img src="~assets/images/communityConvention/title1.svg" alt="" />
      <img src="~assets/images/communityConvention/title1_1.svg" alt="" />
      <div class="citizen-convention">
        <div>爱国爱乡，爱岗敬业；重情重义，崇德尚贤；</div>
        <div>遵纪守法，诚信友善；艰苦奋斗，勤俭节约；</div>
        <div>敬老爱幼，家庭和睦；志愿服务，邻里守望；</div>
        <div>崇尚科学，移风易俗；保护环境，低碳生活。</div>
      </div>
      <img src="~assets/images/communityConvention/title2.svg" alt="" />
      <div class="resident-convention">
        <div v-for="convention in conventions" :key="convention.title">
          <div>{{ convention.title }}</div>
          <p>{{ convention.content }}</p>
        </div>
      </div>
    </section>
    <div>
      <van-checkbox v-if="showText !== '您已签约'" v-model="checked" shape="square">
        勾选后代表您将遵守社区公约
      </van-checkbox>
      <van-button :disabled="disabled || showText === '您已签约'" type="info" round @click="onSubmit">
        {{showText}}
        <van-count-down v-if="disabled && showText !== '您已签约'" :time="time" @finish="disabled = false">
          <template #default="timeData">
            <span>({{ timeData.seconds }})</span>
          </template>
        </van-count-down>
      </van-button>
    </div>
  </div>
</template>
<script>
import { List, Dialog, Toast } from "vant";
import homeApi from 'api/home';
export default {
  data() {
    return {
      showText: '签约公约',
      checked: false,
      conventions: [
        {
          title: '第一条',
          content:
            '坚持党的领导，坚持法治、德治、自治相结合，培育和践行社会主义核心价值观和当代浙江人共同价值观，倡导富强、民主、文明、和谐、自由、平等、公正、法治、爱国、敬业、诚信、友普的社会主义价值观。',
        },
        {
          title: '第二条',
          content:
            '居住在本社区的人员应当遵守本公约。党员居民要带头遵守本公约，充分发挥先锋模范作用。',
        },
        {
          title: '第三条',
          content:
            '遵守计划生育政策，提倡优生优育。提倡移风易俗，反对封建迷信，倡导喜事新办、丧事简办。',
        },
        {
          title: '第四条',
          content:
            '社区和居民，逐步养成“减量、循环、自觉、自治”的行为规范，创新垃圾分类处理模式，做好垃圾分类工作。',
        },
        {
          title: '第五条',
          content:
            '坚持互尊互爱、互帮互助、互让互谅，发生纠纷主动调节，共建和睦融洽的邻里关系。自觉维护社区秩序，积极参与社区公共活动，共建良好的公共秩序。',
        },
        {
          title: '第六条',
          content:
            '子女应尽赡养老人义务，关心老人、尊重老人。外出子女要经常回家看望父母。父母应尽抚养未成年人子女和无生活能力子女的义务，不虐待儿童。',
        },
        {
          title: '第七条',
          content:
            '开车进入住宅区要减速慢行、不鸣笛，规范停放汽车、电瓶车、自行车，不将车辆停放在楼道口、绿化带、消防通道。电瓶车不乱私拉电线充电。',
        },
        {
          title: '第八条',
          content:
            '文明饲养宠物，不饲养大型非温和犬类；文明遛狗，注意牵绳，及时清理犬类排泄物；及时为宠物办理合法证件，定期注射疫苗。不在公共场所放养家禽。',
        },
        {
          title: '第九条',
          content:
            '共同遵守社区整体规划，不搞违章搭建；文明装修，不得改动承重墙，未经批准不得改变房屋结构。',
        },
        {
          title: '第十条',
          content:
            '共同维护社区整洁，不在楼道或小区公共地方堆放物品，不随地吐痰，不随地乱丢烟蒂，并实行门前“三包”。实行垃圾源头分类、减量处理、定点投放；保持公共场所环境整洁，自觉带走废弃物品。',
        },
        {
          title: '第十一条',
          content:
            '积极配合参与“五水共治”、“三改一拆”、“四边三化”，共建美丽家园、共创美好生活。用餐养成使用公筷公勺的好习惯，倡导“一菜一筷，一汤一勺”，各取所食，做到碗筷专用，及时消毒。',
        },
        {
          title: '第十二条',
          content:
            '爱护社区的公共设施和一草一木，不随意践踏草坪和采摘花木，搬运家具时，不得损坏单元门、扶梯和过道墙面。',
        },
        {
          title: '第十三条',
          content:
            '居民应节约用电、用水、用气，不使用柴薪烧火做饭；不要在江边洗涤，污染水质，低碳生活，并注意安全防范。',
        },
        {
          title: '第十四条',
          content:
            '出租房房东应自觉配备消防设施，告知出租户应做好安全防范工作，并遵守社区公约，出租房屋不得群租，不得租给他人进行非法活动。',
        },
        {
          title: '第十五条',
          content:
            '大力发扬主人翁精神，积极参与平安社区创建活动，积极参加平安志愿者、义工、义务巡逻等群防群治活动，发现食品药品安全、环境污染问题，应及时上报有关部门。',
        },
        {
          title: '第十六条',
          content:
            '自觉抵制“黄、赌、毒”，如发现“黄、赌、毒”情况立即上报有关部门。家庭有易肇事肇祸精神病人、刑释人员、社区服刑人员或误入邪教人员的，应积极配合社区组织和社居委会做好管理、教育和帮扶等工作。',
        },
        {
          title: '第十七条',
          content:
            '晨练、晚练及看电视、听戏曲，要主动降低电视、音响的音量；晚归人员进楼道轻脚步、轻说话、轻关门，防止影响他人正常休息；不得往窗外抛撒物品及垃圾，不在阳台晾晒、浇灌，防止滴水。',
        },
        {
          title: '第十八条',
          content:
            '社区居委会每年组织相关评比活动，经社区两委联席会议商议后，由社区居委会表彰奖励模范遵守本公约的家庭和社区居民。',
        },
        {
          title: '第十九条',
          content:
            '居民应自觉交纳卫生清运费，有物业管理的小区，居民应自觉缴纳物业管理费，遵守小区管理规章制度。',
        },
        {
          title: '第二十条',
          content:
            '凡违反本公约的，经社区两委联席会议商议后，社区居民委员会对行为人酌情作出批评教育。',
        },
        {
          title: '第二十一条',
          content: '本公约由社区党组织和社区居民委员会负责解释。',
        },
        {
          title: '第二十二条',
          content: '本公约自社区居民代表会议通过之日起施行。',
        },
      ],
      time: 10000,
      disabled: true,
    };
  },
  mounted() {
    homeApi.getClassifySignStatus({ ruleType: 1 }).then(res => {
      if (res.flag && res.data) {
        this.showText = '您已签约';
        this.disabled = false;
      }
    })
  },
  methods: {
    onSubmit() {
      if (!this.checked) {
        this.$toast('请先勾选');
        return;
      }
        Dialog.confirm({
          title: "签约提示",
          message: "是否签约社区公约？",
          'confirm-button-text': '立即签约',
        }).then(() => {
          homeApi.classifySign({ ruleType: 1 }).then(res => {
            if (res.flag) {
              Toast('签约成功！')
              this.showText = '您已签约';
              this.disabled = false;
            }
          });
        });
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/llg.scss';
.main-container {
  background-image: url('~assets/images/communityConvention/bg.png');
  background-repeat: repeat;
  background-size: contain;

  ::v-deep.van-nav-bar .van-icon {
    color: rgba(0, 0, 0, 0.85);
  }
  section {
    padding-top: 20px;
    > img {
      margin-bottom: 20px;
    }
    .citizen-convention,
    .resident-convention {
      box-sizing: border-box;
      width: calc(100% - 40px);
      margin: 0 20px 20px 20px;
      font-size: 1rem;
      line-height: 2rem;
      > div:not(:last-child) {
        border-bottom: 1px dashed#899cce;
      }
    }
    .citizen-convention {
      padding: 10px 0;
      background-image: url('~assets/images/communityConvention/bg1.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      > div {
        // white-space: nowrap;
        color: #ffffff;
        text-align: center;
        font-weight: 500;
        letter-spacing: -0.5px;
      }
    }
    .resident-convention {
      padding: 0 15px;
      background-image: url('~assets/images/communityConvention/bg2_1.png'),
        url('~assets/images/communityConvention/bg2_2.png'),
        url('~assets/images/communityConvention/bg2_3.png');
      background-repeat: no-repeat;
      background-position: top, center, bottom;
      background-size: contain, 100% 98%, contain;
      // background-color: #ffffff;
      // border: 2px solid rgba(32, 75, 188, 0.45);
      > div {
        padding: 10px;
        > div:first-child {
          color: #204bbc;
          text-align: center;
          font-weight: 500;
        }
      }
    }
  }
  > div:last-child {
    padding: 10px;
    text-align: center;
    ::v-deep .van-checkbox {
      span {
        line-height: 22px;
        font-size: 16px;
        color: #204bbc;
        font-weight: 400;
      }
      .van-checkbox__icon .van-icon {
        border-color: #3e71f9;
      }
    }
    .van-button {
      margin: 10px 0;
      width: 80%;
      font-family: PingFangSC-Regular;
      font-size: 18px;
      color: #ffffff;
    }
    .van-count-down {
      display: inline-block;
      font-size: 18px;
      color: #ffffff;
    }
  }
}
</style>
