<template>
  <div class="memberManage-container">
    <van-nav-bar
      title="寻找“最美”"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="body-container">
      <div class="findzuimei-map-con" id="findzuimeiContain" tabindex="0"></div>
    </div>
    <van-overlay :show="show">
      <div class="wrapper">
        <div class="records-window">
          <div class="records-window-top">
            <b>{{records.title}}</b>
            <van-icon size="16" color="#4379ed"  @click="show = false" class="close" name="cross" />
          </div>
          <div class="records-window-content" @touchmove="testTouch">
            <h3 v-if="records.subTitle">{{records.subTitle}}</h3>
            <p v-for="(item, index) in records.content" :key="index">{{item}}</p>
            <img v-for="(item, index) in records.pictures" :key="`pic_${index}`" :src="item" alt="">
          </div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import homeApi from 'api/home';
import { mapMutations, mapState } from "vuex";
import data from 'assets/json/zuimeiMarker';
export default {
  data() {
    return {
      mapObj: {},
      show: false,
      records: {},
    };
  },
  computed: {
  },
  components: {},
  mounted() {
    this.mapObj = new window.AMap.Map('findzuimeiContain', {
      resizeEnable: true,
      zoom: 15,
      center: [120.51539,30.080082],//中心点坐标
      scrollWheel: false,
    });
    this.getMarkerList();
  },
  watch: {
   
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    handleTab() {

    },
    testTouch(e) {
      e.stopPropagation();
    },
    gotoLink(path) {
      if(path) {
        this.$router.push(path);
      }
    },
    getMarkerList() {
      this.markerList = data;
      this.drawMark(this.markerList);
    },
    drawMark(list) {
      if (this.markerList.length > 0) {
        this.mapObj.remove(this.markerList);
        this.markerList = [];
      }
      list.forEach(v => {
        const { lon, lat, icon_path} = v;
        console.log('data', lon)
        if(lon && lat) {
          const marker = new window.AMap.Marker({
            icon: new window.AMap.Icon({
              // image: require(`http://dssq.ikeqiao.net/data/image/h5/images/educationSource/map/marker_${type}.png`),
              image: icon_path,
              imageSize: new window.AMap.Size(22,26),
            }),
            // icon: icons.icon || '../../../yhcz/static/mapIcon/marker_yfp.png',
            position: [Number(lon),Number(lat)],
            offset: new window.AMap.Pixel(0, 0)
          });
          marker.on('click', (e) => {
            this.records = v;
            this.show = true;
          })
          marker.setMap(this.mapObj);
          this.markerList.push(marker);
        }
      })
    },
  }
};
</script>

<style lang="scss" scoped>
.memberManage-container {
  height: 100vh;
  width: 100vw;
  
  .body-container {
    height: calc(100vh - 46px);
    width: 100vw;
    padding-bottom: 10px;
    background: url('http://dssq.ikeqiao.net/data/image/h5/images/coordination/coordination-bg.png') no-repeat;
    background-size: 100% 100%;
    overflow-y: auto;
    overflow-x: hidden;
    .findzuimei-map-con {
      width: 100%;
      height: 100%;
    }

    
  }
  .wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      .records-window {
        background-color: #FFF;
        border-radius: 8px;
        text-align: center;
        font-size: 14px;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 90vw;
        .records-window-top {
          width: 100%;
          height: 48px;
          // background-color:  #4479ED;
          font-size: 16px;
          // color: #FFF;
          line-height: 48px;
          border-radius: 8px 8px 0 0;
          position: relative;
          .close {
            position: absolute;
            top: 16px;
            right: 10px;
          }
        }
        .records-window-content {
          width: 100%;
          height: calc(80vh - 48px);
          overflow-y: scroll;
          overflow-x: hidden;
          text-align: left;
          line-height: 20px;
          padding: 0 16px 10px 16px;
          >p {
            text-indent: 28px;
            line-height: 16px;
          }
          >img {
            width: 100%;
          }
        }
      }
    }
}
</style>
