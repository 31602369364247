<template>
  <div class="qingnianyishiting-container">
    <van-nav-bar
      title="青年议事厅"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="qingnianyishiting-body-container">
      <!-- top bar -->
      <div class="top-bar">
        <img src="http://dssq.ikeqiao.net/data/image/h5/images/hong/qingtuanjiagou.png" alt="">
      </div>
      <!-- 青年议事厅 -->
      <div class="qingtuan-bar">
        <div :class="['qingtuan-bar-title', { opened: show }]">
          <div class="decoration-title">
            <img src="http://dssq.ikeqiao.net/data/image/h5/images/hong/decoration-l.png" alt="">
            <h1>青年议事厅</h1>
            <img src="http://dssq.ikeqiao.net/data/image/h5/images/hong/decoration-r.png" alt="">
          </div>
          <p>{{ desc }}</p>
          <div class="organization-button" @click="show = !show">
            <span>{{ show ? "收起" : "展开" }}</span>
            <img
              src="http://dssq.ikeqiao.net/data/image/h5/images/hong/arrow.png"
              :class="['organization-arrow', { rollover: show }]"
              alt=""
            />
          </div>
        </div>
      </div>
      <!-- 成员公示 -->
      <!-- <div class="qingtuan-card">
        <h1>成员公示</h1>
        <div class="gongshi-list">
          <div class="gongshi-item" v-for="item in list" :key="item.id">
            <img :src="item.picture" alt="">
            <h2>{{item.name}}</h2>
            <span class="tag">{{item.tag}}</span>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import homeApi from 'api/home';
import { mapMutations, mapState } from "vuex";
export default {
  data() {
    return {
      desc: '青年议事厅是社区议事协商的平台，是青年参与基层治理的实践，通过线上线下开展社区协商，让青年参与到社区各项事务中，成为社区管理服务创新的参与者、实施者和受益者。青年议事厅在社区居委会领导下组织开展议事协商活动，成员一般由年满18周岁，未满35周岁，居住在东盛社区的青年组成。',
      list: [
        {
          picture: 'http://dssq.ikeqiao.net/data/image/h5/images/ykyy/tsd.png',
          name: '屠苏旦',
          tag: '书记',
          id: 1,
        },
        {
          picture: 'http://dssq.ikeqiao.net/data/image/h5/images/ykyy/gjy.png',
          name: '高佳音',
          tag: '副书记',
          id: 1,
        },
        {
          picture: 'http://dssq.ikeqiao.net/data/image/h5/images/ykyy/lym.png',
          name: '李银曼',
          tag: '委员',
          id: 3,
        },
      ],
      honorList: [
        {
          title: '全国五四红旗团支部'
        },
        {
          title: '第四届中国青年志愿服务项目大赛铜奖'
        },
        {
          title: '浙江省五四红旗团支部'
        },
        {
          title: '浙江省先进团支部'
        },
        {
          title: '浙江省第八批“青少年维权岗”创建单位'
        },
        {
          title: '践行三服务，青春显担当共青团项目大赛决赛团(总)支部组别优秀奖'
        },
        {
          title: '诸暨市青年大学习先进单位'
        },
      ],
      show: false,
    };
  },
  computed: {
  },
  components: {},
  mounted() {
    
  },
  watch: {
   
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    handleTab() {

    },
    gotoLink(path) {
      if(path) {
        this.$router.push(path);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.qingnianyishiting-container {
  height: 100vh;
  width: 100vw;
  
  .qingnianyishiting-body-container {
    height: calc(100vh - 46px);
    width: 100vw;
    padding-bottom: 10px;
    background: url('http://dssq.ikeqiao.net/data/image/h5/images/coordination/coordination-bg.png') no-repeat;
    background-size: 100% 100%;
    overflow-y: auto;
    overflow-x: hidden;
    .top-bar {
      >img {
        width: 100vw;
      }
    }
    .qingtuan-bar-title {
      width: calc(100% - 30px);
      // background: url(http://dssq.ikeqiao.net/data/image/h5/images/hong/organization-bg.png) no-repeat;
      // background-size: 100% 100%;
      background: #FFF;
      border-radius: 4px;
      padding: calc(2rem - 15px);
      color: rgba(0,0,0,0.85);
      margin: -50px 15px 15px 15px;
      position: relative;
      z-index: 2;
      .decoration-title {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 1rem;
        margin-top: 10px;
        > h1 {
          font-size: 18px;
          color: #4479ED;
          font-weight: 600;
          margin: 0 10px;
        }
        >img {
          height: 14px;
        }
      }
      
      > p {
        width: calc(100vw - 4rem);
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0;
        max-height: 60px;
        min-height: 60px;
        margin-bottom: 10px;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .organization-button {
        margin: 0px auto;
        width: 60px;
        height: 24px;
        background: rgba(255, 255, 255, 0.6);
        border-radius: 12px;
        color: #4479ed;
        line-height: 24px;
        text-align: center;
        font-size: 14px;
        > img {
          width: 12px;
          height: 12px;
        }
      }
    }
    .opened {
      > p {
        max-height: 100vh;
      }
      .organization-button {
        > img {
          transform: rotate(180deg);
        }
      }
    }
    .qingtuan-card {
      width: 100vw;
      padding: 1rem;
      background-color: #FFF;
      margin-bottom: 16px;
      >h1 {
        font-size: 16px;
        color: rgba(0,0,0,0.85);
        font-weight: 500;
        margin: 10px 0;
      }
      .gongshi-list {
        width: 100%;
        display: flex;
        .gongshi-item {
          flex: 1;
          display: flex;
          flex-direction: column;
          align-items: center;
          >img {
            width: 70%;
            height: 100px;
            margin-bottom: 5px;
          }
          >h2 {
            font-size: 14px;
            margin-bottom: 5px;
          }
          .tag {
            background: rgba(232,239,255,0.9);
            border-radius: 12px;
            padding: 2px 6px;
            font-size: 12px;
            color: #4479ED;
            font-size: 12px;
            margin-bottom: 5px;
          }
        }
      }
    }
  }
}
</style>
