<template>
    <!-- 2021年第二季度数据动态情况 -->
    <Echart
      :options="options"
      id="basicLine"
      height="100%"
      width="100%"
    ></Echart>
</template>

<script>
import Echart from '@/common/echart';
import moment from 'moment';
export default {
  name: 'StatisticLineChart1',
  data () {
    return {
      options: {},
    };
  },
  components: {
    Echart,
  },
  props: {
    cdata: {
      type: Array,
      default: () => ([])
    },
  },
  computed: {
    dateList() {
      const result = [];
      for(let i = 0;i < 7; i += 1) {
        const startDay = moment().week(moment().week()).startOf('week');
        const current = startDay.add(i, 'days');
        const day = current.format('MM.DD');
        result.push(day);
      }
      return result;
    },
  },
  watch: {
    cdata: {
      handler (newData) {
        this.options = {
          title: "",
          tooltip: {
              trigger: 'axis',
          },
          legend: {
              data: ['访问次数', '活跃率'],
              bottom: 5,
              icon: 'rect',
              itemHeight: 1,
              itemWidth: 16,
              textStyle: {
                color: 'rgba(0,0,0,0.65)',
                fontSize: 12
              }
          },
          grid: {
              left: '2%',
              right: '2%',
              bottom: 30,
              top: 30,
              containLabel: true
          },
          xAxis: {
              type: 'category',
              boundaryGap: false,
              data: this.cdata.map(item => item.everyDate),
              axisLabel: {
                show: true,
                textStyle: {
                  color: 'rgba(0,0,0,0.65)',
                  fontSize: 12
                }
              }
          },
          yAxis: [{
              type: 'value',
              name: '人',
              nameTextStyle: {
                color: 'rgba(0,0,0,0.45)',
                fontSize: 12,
              },
              splitLine: {
                show: true,
                lineStyle: {
                  type: 'dotted'
                }
              },
              axisLine: {
                show: false
              },
              axisLabel: {
                show: true,
                textStyle: {
                  color: 'rgba(0,0,0,0.65)',
                  fontSize: 12
                }
              }
          }, {
              type: 'value',
              name: '%',
              nameTextStyle: {
                color: 'rgba(0,0,0,0.45)',
                fontSize: 12,
              },
              splitLine: {
                show: true,
                lineStyle: {
                  type: 'dotted'
                }
              },
              axisLine: {
                show: false
              }, 
              min: 0,
              max: 1,
              interval: 0.2,
              axisLabel: {
                show: true,
                textStyle: {
                  color: 'rgba(0,0,0,0.65)',
                  fontSize: 12
                },
                formatter: function (value, index) {
                    return value * 100 + '%';
                }
              },
          },],
          series: [
            {
              name: "访问次数",
              type: "line",
              yAxisIndex: 0,
              itemStyle: {
                normal: {
                  color: "#4479ED"
                }
              },
              data: this.cdata.map(item => item.activeNumber),
              symbol: 'circle',
              smooth: false
            },
            {
              name: "活跃率",
              type: "line",
              yAxisIndex: 1,
              itemStyle: {
                normal: {
                  color: "#2AD1BC",
                }
              },
              data: this.cdata.map(item => item.activeRate),
              symbol: 'circle',
              smooth: false
            },
          ]
        }
      },
      immediate: true,
      deep: true
    },
  },
}
</script>