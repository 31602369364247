<template>
  <div id="index">
    <div class="page-container">
      <div class="header-nav">
        <i @click="goBack"></i>
        <h1>长乐东盛</h1>
      </div>
      <div class="nav cont-nav">
        <div class="index-tab">
          <b class="">长乐东盛</b>
        </div>
        <div class="nav-list clearfix">
          <ul>
            <li @click="gotoLink('workRule')">
              <i class="icon1"></i><span>三三工作法</span>
            </li>
            <li @click="gotoLink('road')">
              <i class="icon2"></i><span>十里清廉路</span>
            </li>
            <li @click="gotoLink('ddnews')">
              <i class="icon3"></i><span>东盛民生面对面</span>
            </li>
            <li @click="gotoLink('ddStory')">
              <i class="icon4"></i><span>东盛的故事</span>
            </li>
            <li @click="gotoLink('ddxd')">
              <i class="icon5"></i><span>东盛小渡的故事</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  components: {},
  mounted() {},
  methods: {
    gotoLink(path) {
      this.$router.push(path);
    }, goBack(){
          this.$router.go(-1);
      }
  },
};
</script>

<style lang="scss">
@import "~assets/scss/index.scss";
</style>
