<template>
  <div id="index">
    <div class="page-container">
      <div class="header-nav">
        <i @click="goBack"></i>
        <h1>我要点赞</h1>
      </div>
      <div class="info-bar">
        <span>
          共有
          <span class="number">{{total}}</span>条借用记录
        </span>
      </div>
      <van-list :finished="finished" finished-text="没有更多了" @load="onLoad">
        <div class="list">
          <div v-for="item in list" :key="item.invitationId" class="card-con">
            <div class="card-con-left">
              <img :src="item.invitationContent" class="image" />
              <div style="flex: 1">
                <div class="card-title">
                  <span>{{item.invitationTitle}}</span>
                </div>
                <div class="row">
                  <span>数量：{{item.toolNumber}}</span>
                </div>
                <div class="row">
                  <span>位置：{{item.toolPlace}}</span>
                </div>
              </div>
            </div>
            <div
              style="
              width: 3rem;
              color: rgba(0, 0, 0, 0.65);
              font-size: 12px;
              text-align: center;
            "
              @click="handleAddThumbsUp(item)"
            >
              <i :class="[item.hasThumbsUp === '已点赞' ? 'dz-active' : 'dz']"></i>
              <div>{{ item.hasThumbsUp === '未点赞' ? "点赞" : "已点赞" }}</div>
            </div>
          </div>
        </div>
      </van-list>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
import gjxApi from "api/gjx";

export default {
  data() {
    return {
      page: 0,
      size: 10,
      total: 0,
      list: [],
      finished: false
    };
  },
  components: {},
  mounted() {},
  methods: {
    getList({ page = this.page, size = this.size } = {}) {
      gjxApi
        .getToolCasePage({
          page,
          size,
          object: {
            thumbsTool: true
          }
        })
        .then(res => {
          const { records = [], total = 0 } = res.data || {};
          this.list = this.list.concat(records);
          this.total = total;
          if (this.list.length >= this.total) {
            // 数据全部加载完成
            this.finished = true;
          }
        });
    },
    onLoad() {
      this.page += 1;
      this.getList();
    },
    gotoLink(path) {
      this.$router.push(path);
    },
    goBack() {
      this.$router.go(-1);
    },
    showAlert() {
      this.$messagebox("提示", "系统正在建设中，尽情期待");
    },
    gotoDetail(info) {
      this.$router.push("gjxxq");
    },
    onConfirm(value, index) {
      this.selectArea = value;
      this.showArea = false;
    },
    onCancel() {
      this.showArea = false;
    },
    showPicker() {
      this.showArea = true;
    },
    updateList(invitationId, flag) {
      this.list = this.list.map(v => ({
        ...v,
        hasThumbsUp:
          v.invitationId === invitationId
            ? flag === "addThumbsUp"
              ? "已点赞"
              : "未点赞"
            : v.hasThumbsUp
      }));
    },
    addThumbsUp(invitationId) {
      gjxApi.addThumbsUp({ invitationId }).then(res => {
        if (res.data == 1) {
          Toast.success("点赞成功");
          this.updateList(invitationId, "addThumbsUp");
        }
      });
    },
    cancelThumbsUp(invitationId) {
      gjxApi.cancelThumbsUp({ invitationId }).then(res => {
        if (res.data == 1) {
          Toast.success("取消点赞成功");
          this.updateList(invitationId, "cancelThumbsUp");
        }
      });
    },
    handleAddThumbsUp(item) {
      const { hasThumbsUp, invitationId } = item;
      if (hasThumbsUp === "已点赞") {
        this.cancelThumbsUp(invitationId);
      }
      if (hasThumbsUp === "未点赞") {
        this.addThumbsUp(invitationId);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.page-container {
  background: #ffffff;
  padding: 0.6rem;
  height: calc(100vh - 40px);
  width: 100vw;
  margin-top: 40px;
  .info-bar {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.65);
    letter-spacing: 0;
    line-height: 20px;
    margin-bottom: 0.8rem;
    .number {
      color: #4479ed;
      margin: 0 0.5rem;
    }
  }
  .list {
    width: 100%;
    .card-con {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 0.8rem;

      .card-con-left {
        display: flex;
        align-items: flex-start;
      }
      .card-title {
        display: flex;
        justify-content: space-between;
        font-size: 16px;
        font-weight: bold;
        color: rgba(0, 0, 0, 0.85);
        text-align: left;
        height: 16px;

        .tag {
          background: #d5f4e2;
          border-radius: 4px 2px 8px 2px;
          font-size: 12px;
          color: #37d477;
          letter-spacing: 0;
          text-align: center;
          line-height: 12px;
          padding: 0.2rem 0.8rem;
          height: 1.2rem;
        }
      }
      .image {
        height: 4.6rem;
        width: 4.6rem;
        border-radius: 2px;
        margin-right: 0.5rem;
      }
      .row {
        font-size: 13px;
        line-height: 1.4rem;
        display: flex;

        font-size: 14px;
        color: rgba(0, 0, 0, 0.85);
        text-align: left;
        .label {
          color: rgba(0, 0, 0, 0.45);
          text-align: left;
          line-height: 18px;
          margin-right: 0.6rem;
        }
      }
      .dz,
      .dz-active {
        width: 1.2rem;
        height: 1.2rem;
        display: inline-block;
        vertical-align: middle;
        margin-bottom: 0.3rem;
      }
      .dz {
        background: url(http://dssq.ikeqiao.net/data/image/h5/images/icons/dz.png) no-repeat;
        background-size: 100%;
      }
      .dz-active {
        background: url(http://dssq.ikeqiao.net/data/image/h5/images/icons/dz_active.png) no-repeat;
        background-size: 100%;
      }
    }
  }
}
</style>
