<template>
  <div class="ddya-index">
    <div class="page-container">
      <div class="header-nav">
        <i @click="goBack"></i>
        <h1>东盛有爱</h1>
      </div>
      <div class="park-main">
        <div class="banner">
          <img src="http://dssq.ikeqiao.net/data/image/h5/images/banner/ddya_banner.png" />
        </div>
        <div class="all-nav clearfix">
          <ul>
            <li @click="gotoLink('loveActivity')">
              <i class="fqhd"></i><span>发起活动</span>
            </li>
            <li @click="gotoLink('hdbm')">
              <i class="hdbm"></i><span>活动报名</span>
            </li>
            <li @click="gotoLink('loveDonate')">
              <i class="axjz"></i><span>爱心捐赠</span>
            </li>
            <li @click="gotoLink('jfdh')">
              <i class="jfdh"></i><span>积分兑换</span>
            </li>
          </ul>
        </div>
        <div class="daryLine"></div>
        <div class="all-nav" style="marginBottom:0">
          <div class="index-tab tab">
            <b>积分兑换</b>
          </div>
        </div>
        <ul class="homeGift—box">
          <li v-for="item in pageDataList" :key="item.key">
            <div class="img" @click="gotoLink(`dhDetail?id=${item.id}`)">
              <img :src="item.image" />
              <div class="img-title">{{item.title}}</div>
              <div class="img-des">
                <img src="http://dssq.ikeqiao.net/data/image/h5/images/hotNew/jifen.png" />
                <span>{{item.score}}积分</span>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>  
  </div>
</template>

<script>
import jfdhApi from 'api/ddya/jfdh';
export default {
  data() {
    return {
       pageDataList:[],
    };
  },
  components: {},
  mounted() {
    //查询商品
    jfdhApi.getShopping({
      "page": 1,
      "size": 10,
      "object": {
        "title": ""
      }
    }).then(res => {
      this.pageDataList = res.data.records
    })
  },
  methods: {
    gotoLink(path) {
      this.$router.push(path);
    },
    goBack() {
      this.$router.go(-1);
    },
    showAlert() {
      this.$messagebox("提示", "系统正在建设中，尽情期待~");
    },
  },
};
</script>

<style lang="scss">
@import "~assets/scss/index.scss";
.ddya-index{
  overflow-x: hidden;
}
.homeGift—box {
  height: auto !important;
  li {
    display: inline-flex;
    align-items: center;
    width: 50% !important;
    height: auto !important;
    margin-bottom: 0.8rem;
    .img {
      height: auto !important;
      width:100% !important;
      text-align: center;
      img {
        width: auto !important;
        height: auto !important;
        max-height:10rem;
        max-width: 100%;
        padding: 0 0.6rem;
      }
      .img-title {
        text-align: left;
        color: rgba(0, 0, 0, 0.85);
        line-height: 1.2rem;
        font-size: 0.8rem;
        padding: 0.5rem 0 0 0.6rem;
      }
      .img-des {
        text-align: center;
        color: #E5B20A;
        line-height: 1.2rem;
        font-size: 0.8rem;
        padding: 0.5rem 0 0 0.6rem;
        display: flex;
        align-items: center;
        img {
          width: 1rem !important;
          height: 1rem !important;
          margin-right: 0.2rem;
          padding: 0;
        }
        img:nth-child(3) {
          margin-left: 0.6rem;
        }
      }
    }
  }
}
</style>
