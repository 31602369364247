<template>
  <div id="index">
    <div class="page-container">
      <div class="header-nav">
        <i @click="goBack"></i>
        <h1>维权统计</h1>
      </div>
      <div class="ykyy-main">
        <div class="total-info">
          <div class="info-item" style="border-right:2rpx solid #e5e5e5">
            <div>本月收益人数</div>
            <div><span>13</span> 人</div>
          </div>
          <div class="info-item" bindtap="typeChange" data-type="2">
            <div>本月维权金额</div>
            <div><span>10000</span> 元</div>
          </div>
        </div>
        <div class="mian-tab">本月调解数量</div>
        <div class="canvasBox">
          <div class="chart"></div>
        </div>
        <div class="list">
          <div class="item item1">
            <div><b>10</b>人</div>
            <div style="color:#799dee">志愿者总人数</div>
          </div>
          <div class="item item2">
            <div><b>20</b>次</div>
            <div style="color:#ffae73">本月活动频次</div>
          </div>
          <div class="item item3">
            <div><b>0</b>次</div>
            <div style="color:#fc97be">本月预约数量</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  components: {},
  mounted() {},
  methods: {
    gotoLink(path) {
      this.$router.push(path);
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss">
@import "~assets/scss/index.scss";
</style>
