<template>
  <div id="index">
    <div class="page-container paddclose">
      <div class="header-nav">
        <i @click="goBack"></i>
        <h1>最美客厅</h1>
      </div>
      <div class="ykyy-main map">
        <el-amap
          vid="amap"
          class="map-area"
          :center="center"
          :amap-manager="amapManager"
          :zoom="zoom"
          mapStyle="fresh"
        >
          <el-amap-marker
            v-for="(item, index) in orgList"
            :key="index"
            :position="item.coordinate"
            :title="item.company"
            :events="events"
            :extData="item"
            :icon="item.icon"
          >
          </el-amap-marker>
        </el-amap>
        <div class="map-deatil clearfix" v-if="showOrg.id">
          <span class="close" @click="closed()"><van-icon name="cross" /></span>
            <div class="title">{{ showOrg.title }}</div>
            <p>{{ showOrg.date }}</p>
            <div class="img"><img :src="showOrg.imgurl" /></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { AMapManager, lazyAMapApiLoaderInstance } from "vue-amap";
let amapManager = new AMapManager(); // 新建生成地图画布
import dataJson from "@/data/djMap.json";
export default {
  data() {
    let self = this;
    return {
      value: 4.5,
      imgH: 0,
      search: "",
      zoom: 16,
      center: [120.505064, 30.087792],
      amapManager,
      showOrg: {},
      searchTags: [],
      orgList: [],
      moreSearch: false,
      selectTag: null,
      events: {
        click: (a) => {
          var orgDetail = a.target.getExtData();
          self.showOrg = orgDetail;
          // setTimeout(function() {
          //   self.imgH = self.$refs.mapdesc.offsetHeight;
          //   console.log(this.imgH);
          // }, 300);
        },
      },
    };
  },
  components: {},
  mounted() {
    this.orgList = dataJson;
  },
  methods: {
    getOrgList() {
      let _this = this;
    },
    goBack() {
      this.$router.go(-1);
    },
    gotoLink(path) {
      this.$router.push(path);
    },
    clearSearch() {
      this.searchTags = [];
    },
    closed() {
      this.showOrg = false;
    },
  },
};
</script>

<style lang="scss">
@import "~assets/scss/index.scss";
.map {
  /*background: url(../../http://dssq.ikeqiao.net/data/image/h5/images3/map-tbg.png) no-repeat top;*/
  background-size: 100% 100%;
  position: relative;
  z-index: 99;
  width: 100%;
  .header-nav {
    background: transparent;
  }
  .padd-top {
    padding-top: 2.8rem;
  }
}

.map-area {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
}
.amap-icon {
  img {
    width: 1.6rem;
    height: 2rem;
  }
}
</style>
