<template>
  <div id="index">
    <div class="page-container" style="height: auto !important;margin-top: 0px !important;">
      <div class="header-nav">
        <i @click="goBack"></i>
        <h1>邻里车位</h1>
      </div>
      <div class="park-main">
        <div class="banner">
          <img src="http://dssq.ikeqiao.net/data/image/h5/images/banner/gxcw_banner.png" />
        </div>
        <div class="car-nav clearfix">
          <ul>
            <li @click="gotoLink('park')">
              <i class="wytc"></i><span>我要停车</span>
            </li>
            <li @click="gotoLink('wdyy')">
              <i class="wdyy"></i><span>我的预约</span>
            </li>
            <li @click="gotoLink('wdcw')">
              <i class="wdcw"></i><span>我的车位</span>
            </li>
            <li><i class="cwfb"
                 @click="gotoLink('addCw')"></i><span>车位发布</span></li>
            <li @click="gotoLink('yjnc')"><i class="zync"></i><span>占用挪车</span></li>
          </ul>
        </div>
      </div>
    </div>
    <div class="divider-block"></div>
    <!-- <div style="width: 100%; padding: 16px;">
      <img style="width: 100%;" src="http://dssq.ikeqiao.net/data/image/h5/images/carPark.jpg" alt="">
    </div> -->
    <!-- <div class="note-box">
      <div class="tab">我的预约</div>
      <div class="more" @click="gotoLink('wdyy')">
        <span>更多 > </span>
      </div>
      <div class="list">
        <div v-for="item in list"
             :key="item.name"
             class="card-con">
          <img style="width: 7.4rem; height:5.3rem"
               :src="item.image || 'http://dssq.ikeqiao.net/data/image/h5/images/defaultPark.jpg'"
               class="image" />
          <div>
            <div class="card-title">
              <span>车位号：{{item.parkingCode || ''}}</span>
            </div>
            <div class="row">
              <span>车位地址：{{item.location || ''}}</span>
            </div>
            <div class="row">
              <div>预约时间：</div><div>{{`${item.orderDate || ''} ${item.startTime || ''}~${item.overTime || ''}`}}</div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import getOtherToken from '@/utils/index';
export default {
  data () {
    return {
      list: []
    };
  },
  components: {},
  mounted () {
    this.$store.dispatch('llcw/getMyOrders', {
      params: {
        page: 1,
        size: 5,
      },
      obj: this,
    });
  },
  methods: {
    gotoLink (path) {
      this.$router.push(path);
    },
    goBack () {
      this.$router.go(-1);
    },
    showAlert () {
      this.$messagebox("提示", "系统正在建设中，尽情期待~");
    },
  },
};
</script>

<style lang="scss">
@import "~assets/scss/index.scss";
#index {
  position: relative;
}
.divider {
  position: absolute;
  top: 34px;
  background-color: #e7e8e8;
  width: 100%;
  height: 7px;
}
.divider-block {
  height: 0.8rem;
  width: 100%;
}
.banner {
  height: 9rem !important;
  img {
    height: 9rem !important;
    border-radius: 0.5rem;
  }
  margin-bottom: 0.6rem;
}
.note-box {
  .tab {
    display: inline-block;
    border-left: 0.2667rem solid #4479ed;
    height: 1.4667rem;
    font: 600 1.0667rem/1.4667rem "PingFangSC-Medium";
    color: rgba(0, 0, 0, 0.85);
  }
  .more {
    display: inline-block;
    margin-left: 14rem;
    color: #4479ed;
    font-weight: 500;
  }
  .list {
    margin-top: 1rem;
    .card-title {
      :nth-child(1) {
        font: 600 1.0667rem/0.8rem "PingFangSC-Medium";
      }
      :nth-child(2) {
        color: #13bb49 !important;
      }
    }
    .row {
      font: 0.9333rem/0.8rem "PingFangSC-Regular";
      display: flex;
      div:nth-child(1) {
        width: 6rem;
      }
    }
  }
}
</style>
