<template>
  <div class="my-court-container">
    <van-nav-bar
      title="我的诉求"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="my-court-body-container">
      <van-tabs v-model="active" color="#4479ED">
        <van-tab :title="`全部${counts.allReview || 0}`" name="1">
          <courtList type="" />
        </van-tab>
        <van-tab :title="`审核中${counts.underReview || 0}`" name="2">
          <courtList type="0" />
        </van-tab>
        <van-tab :title="`已通过${counts.passReview || 0}`" name="3">
          <courtList type="1" />
        </van-tab>
        <van-tab :title="`已驳回${counts.dismissReview || 0}`" name="4">
          <courtList type="2" />
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>
import courtApi from "api/court";
import { mapMutations, mapState } from "vuex";
import CourtList from './courtList';
export default {
  data() {
    return {
      pageDataList: [],
      active: 0,
      colors: {
        '待使用': { text: '#4479ED', background: '#E3EFFD' },
        '已使用': { text: '#E5B20A', background: '#FFF6D9' },
        '已过期': { text: 'rgba(0,0,0,0.45)', background: '#EDEDED' },
      },
      counts: {}
    };
  },
  computed: {
  },
  components: { CourtList },
  mounted() {
    courtApi.getCounts().then(res => {
      if (res.flag) {
        this.counts = res.data || {};
      }
    })
  },
  watch: {
   
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    handleTab() {

    },
    gotoLink(path) {
      if(path) {
        this.$router.push(path);
      }
    },
  }
};
</script>

<style lang="scss" scoped>
.my-court-container {
  height: 100vh;
  width: 100vw;
  
  .my-court-body-container {
    height: calc(100vh - 46px);
    width: 100vw;
    padding-bottom: 10px;
    // background: url('http://dssq.ikeqiao.net/data/image/h5/images/coordination/coordination-bg.png') no-repeat;
    // background-size: 100% 100%;
    overflow-y: auto;
    overflow-x: hidden;


  }
}
</style>
