<template>
  <div id="index">
    <div class="page-container">
      <div class="header-nav">
        <i @click="goBack"></i>
        <h1>我要咨询</h1>
      </div>
      <van-form @submit="onSubmit">
        <van-field
          v-model="invitationName"
          name="username"
          required
          label="姓名"
          placeholder="请输入"
          input-align="right"
          :rules="[{ required: true, message: '请填写姓名' }]"
        />
        <van-field
          v-model="invitationCard"
          name="idCard"
          required
          label="身份证号"
          placeholder="请输入"
          input-align="right"
          :rules="[{ required: true, message: '请填写身份证号' }]"
        />
        <van-field
          v-model="invitationPhone"
          name="phone"
          required
          label="联系电话"
          placeholder="请输入"
          input-align="right"
          :rules="[{ required: true, message: '请填写联系电话' }]"
        />
        <van-field
          v-model="marriage"
          name="marriage"
          label="婚姻状况"
          required
          placeholder="请选择"
          input-align="right"
          is-link
          readonly
          @click="showType = true"
          :rules="[{ required: true, message: '请选择婚姻状况' }]"
        />
        <van-field
          v-model="invitationAddress"
          name="address"
          label="家庭地址"
          required
          placeholder="请输入"
          input-align="right"
          :rules="[{ required: true, message: '请填写家庭地址' }]"
        />
        <div class="section-textArea">
          <div class="section-label">维权需求</div>
          <van-field
            v-model="invitationContent"
            name="content"
            rows="2"
            autosize
            label
            type="textarea"
            maxlength="400"
            placeholder="请输入"
            show-word-limit
          />
        </div>
        <div class="foot-button">
          <van-button block type="info" native-type="submit">我要咨询</van-button>
        </div>
      </van-form>
    </div>
    <van-popup v-model="showType" position="bottom">
      <van-picker
        title="婚姻状况"
        show-toolbar
        :columns="typeList"
        value-key="dictLabel"
        @confirm="onTypeConfirm"
        @cancel="onCancel('showType')"
      />
    </van-popup>
  </div>
</template>

<script>
import { Toast } from "vant";
import Api from 'api';

export default {
  data() {
    return {
      marriage: '',
      marriageId: '',
      invitationName: '',
      invitationPhone: "",
      invitationCard: "",
      invitationAddress: "",
      invitationContent: "",
      invitationImage: [],
      showType: false,
      typeList: [],
      selected: 'question',
      anonymous: false,
    };
  },
  components: {},
  mounted() {
    this.getTypeList();
  },
  methods: {
    gotoLink(path) {
      this.$router.push(path);
    },
    goBack() {
      this.$router.go(-1);
    },
    getTypeList() {
      Api.getMaritalState().then(res => {
        this.typeList = res.data;
      })
    },
    onTypeConfirm(e, index) {
      this.marriage = e.dictLabel;
      this.marriageId = e.dictValue;
      this.showType = false;
    },
    onCancel(type) {
      this[type] = false;
    },
    pushThinkTank(values) {
    },
    onSubmit(values) {
      Api.addConsult({...values, marriage: this.marriageId}).then(res => {
        if(res.flag) {
          Toast.success("上报成功");
          const timer = setTimeout(() => {
            this.$router.go(-1);
            clearTimeout(timer);
          }, 1000);
        }
      })
      
    }
  }
};
</script>

<style lang="scss" scoped>
.page-container {
  background: #f6f7fa;
  // padding: 0.6rem 0;
  height: calc(100vh - 40px);
  width: 100vw;
  margin-top: 40px;
  .white-page {
    height: 30rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    > img {
      width: 80vw;
    }
  }
  // .van-field__error-message {
  //   text-align: right;
  // }

  .section-textArea {
    background: #ffffff;
    width: 100%;
    border-top: 0.8rem solid #f6f7fa;

    .section-label {
      padding: 0.8rem 0 0 0.8rem;
      line-height: 2rem;

      font-size: 14px;
      color: #646566;
      letter-spacing: 0;
    }
    .van-cell {
      padding-top: 0;
    }
  }
  .section {
    background: #ffffff;
    width: 100%;
    border-top: 0.8rem solid #f6f7fa;
    .image-title {
      line-height: 2rem;

      font-size: 14px;
      color: #646566;
      letter-spacing: 0;
    }
  }

  .foot-button {
    position: fixed;
    bottom: 0.8rem;
    right: 0.8rem;
    left: 0.8rem;
    height: 2.4rem;
    line-height: 2.4rem;
    text-align: center;
    background: #4479ed;
    border-radius: 8px;
    color: #ffffff;

    font-size: 18px;
    letter-spacing: 0;
  }
}
</style>
