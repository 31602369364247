<template>
  <div class="dues-payment-container">
    <van-nav-bar
      title="党费缴纳"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="dues-payment-body-container">
      <div class="top-bar">
        <!-- <img src="http://dssq.ikeqiao.net/data/image/h5/images/hong/dues-payment-topbar.png" alt=""> -->
        <div class="notice-bar">
          <img class="left-icon" src="http://dssq.ikeqiao.net/data/image/h5/images/hong/dues-payment-notice.svg" alt="">
          <p class="right-text">目前党费缴纳只支持对公账户转账。请复制对公账户后，前往各大网银APP进行转账，感谢您的理解。</p>
        </div>
      </div>
      <div class="body-bar">
        <div class="row">
          <div class="title">户名</div>
          <div class="value"><p>诸暨市浣东街道东盛社区居民委员会</p></div>
        </div>
        <div class="row">
          <div class="title">税号</div>
          <div class="value">
            <p>55330681081685979K</P>
            <van-image class="copy-btn" src="http://dssq.ikeqiao.net/data/image/h5/images/hong/dues-payment-copy.svg" @click="onCopy('55330681081685979K')" />
          </div>
        </div>
        <div class="row">
          <div class="title">地址</div>
          <div class="value"><p>诸暨市东祥路86-92号 87596310</p></div>
        </div>
        <div class="row">
          <div class="title">账号</div>
          <div class="value">
            <p>201000115163190</P>
            <van-image class="copy-btn" src="http://dssq.ikeqiao.net/data/image/h5/images/hong/dues-payment-copy.svg" @click="onCopy('201000115163190')" />
          </div>
        </div>
        <div class="row">
          <div class="title">开户行</div>
          <div class="value"><p>浙江诸暨农村商业银行股份有限公司浣东支行</p></div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import Clipboard from 'clipboard';
import { Toast } from 'vant';
export default {
  mounted() {

  },
  data() {
    return {

    }
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    onCopy(text){
      const clipboard = new Clipboard('.copy-btn', {
        text: () => text,
      }) 
      clipboard.on('success', e => {
        Toast('复制成功');
        // 释放内存
        clipboard.destroy();
      })
      clipboard.on('error', e => {  
        // 不支持复制
        Toast('该浏览器不支持自动复制')  
        // 释放内存  
        clipboard.destroy()  
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.dues-payment-container {
  height: 100vh;
  width: 100vw;
  
  .dues-payment-body-container {
    height: calc(100vh - 46px);
    width: 100vw;
    background: #f4f7fb;
    overflow-y: auto;
    overflow-x: hidden;
    .top-bar {
      width: 100vw;
      height: 274px;
      background: url('http://dssq.ikeqiao.net/data/image/h5/images/hong/dues-payment-topbar.png') no-repeat;
      background-size: 100% 100%;
      padding: 12px;
      .notice-bar {
        width: 100%;
        display: flex;
        .left-icon {
          width: 20px;
          height: 20px;
        }
        .right-text {
          flex: 1;
          margin-left: 8px;
          font-size: 1rem;
          color: #FFF;
          line-height: 1.4rem;
        }
      }
    }
    .body-bar {
      margin: -48px 12px 0;
      width: calc(100vw - 24px);
      height: 446px;
      border-radius: 8px;
      background: #FFF;
      overflow: hidden;
      padding: 4px;
      .row {
        width: calc(100% - 32px);
        border-bottom: 1px solid rgba(17,31,44,0.12);
        font-size: 1.06rem;
        color: #383B40;
        line-height: 1.33rem;
        padding: 16px 0;
        margin: 0 16px;
        display: flex;
        .title {
          width: 50px;
        }
        .value {
          flex: 1;
          word-break: break-all;
          white-space: wrap;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          p {
            text-align: right;
          }
          .copy-btn {
            width: 16px;
            height: 16px;
            margin-left: 7px;
          }
        }
      }
    }
  }
}
</style>