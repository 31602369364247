<template>
  <div id="index">
    <div class="page-index-container">
      <div class="pic"><img src="http://dssq.ikeqiao.net/data/image/h5/images/comm-mbg.jpg" /></div>
      <div class="comm-float">
        <div class="comm-tit">东盛社区,欢迎您！</div>
        <div class="comm-desc">
          东盛社区建成于2010年9月，社区主要由锦盛蝶庄、香水湾、润泽大院、香林花园、镜水湾五个小区组成,目前共有住户6332户,常住人口2.5万左右，其中流动人口占70%。成立以来，社区始终坚持以党建为引领，以创建“全国一流社区”为宗旨，积极探索实践“三三工作法”,统筹社区各方力量，当好＂和美家园＂的“摆渡人”，助推社区各项工作开展．充分发挥三支队伍的作用、以三治融合推进基层治理、通过三社联动达到社区治理新格局。近年来，社区先后获得“全国综合减灾示范社区”、“省级基层治理先进单位”、“省级绿色社区”、“省级书法社区”、“绍兴市新时代基层党组织”、“市级和美家园”、“市级和谐社区”等诸多荣誉。
        </div>
        <div class="total-info">
          <div class="info-item line"><b>5</b><span>小区数量</span></div>
          <div class="info-item line"><b>6332</b><span>居民人数</span></div>
          <div class="info-item"><b>571</b><span>党员人数</span></div>
        </div>
        <div class="swiper-box">
          <div class="tab">小区简介</div>
          <div class="comm-list clearfix">
            <ul>
              <li>
                <a href="#">
                  <p class="title">锦盛蝶庄</p>
                  <p class="desc">
                    蝶庄坐落于瓜渚湖直江畔，南枕繁华畅达的裕民路，东邻宁静悠长的双渎路，共有高层30幢，户数1000户，占地面积8.8万平方米，建筑面积18.4万平方米。
                  </p>
                </a>
              </li>
              <li>
                <a href="#">
                  <p class="title">镜水湾</p>
                  <p class="desc">
                    镜水湾位于诸暨市裕民路以南,镜水路以西，该小区内共有高层和别墅11幢，户数372户，占地面积2.1万平方米，建筑面积5.23万平方米。
                  </p>
                </a>
              </li>
              <li>
                <a href="#">
                  <p class="title">润泽大院</p>
                  <p class="desc">
                    润泽大院位于兴越路888号，内设高层、别墅，共计35幢，户数1675户，占地面积10.2万平方米，建筑面积28万平方米。
                  </p>
                </a>
              </li>
              <li>
                <a href="#">
                  <p class="title">香水湾</p>
                  <p class="desc">
                    香水湾位于诸暨兴越东路以北,双渎路以西，该小区内设有多层和高层，共计幢数31幢，户数1450户，占地面积10.2万平方米，建筑面积26万平方米。
                  </p>
                </a>
              </li>
              <li>
                <a href="#">
                  <p class="title">赞成香林</p>
                  <p class="desc">
                    赞成香林花园位于诸暨市裕民路和双渎路交叉口，该小区内共有高层26幢，共计户数1856户，占地面积11.8万平方米，建筑面积32.2万平方米。
                  </p>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  components: {},
  mounted() {},
  methods: {},
};
</script>

<style lang="scss">
@import "~assets/scss/index.scss";
</style>
