<template>
  <div id="index">
    <div class="page-container">
      <div class="header-nav">
        <i @click="goBack"></i>
        <h1>车位发布</h1>
      </div>
      <div class="search-bar">
        <van-form ref="form">
          <van-field
            size="large"
            v-model="sharePakringObj.parkingCode"
            name="carPlate"
            is-link
            readonly
            label="车位号"
            :placeholder="carPlates.length == 0 ? '暂无车位可共享':'请选择'"
            @click="showCarPlates = true"
            :rules="[{ required: true, message: '请选择车位号' }]"
            class="input-bar"
            input-align="right"
          />

          <van-field
            size="large"
            v-model="sharePakringObj.communityName"
            name="community"
            
            label="所属小区"
            placeholder="请确认"
            class="input-bar"
            input-align="right"
          />
          <van-field
            size="large"
            v-model="sharePakringObj.registerPhone"
            name="phone"
            label="联系号码"
             
            placeholder="请输入"
            class="input-bar"
            input-align="right"
          />
          <van-field
            size="large"
            v-model="sharePakringObj.location"
            name="address"
            label="车位地址"
            placeholder="请输入"
            :rules="[{ required: true, message: '请输入车位地址' }]"
            class="input-bar"
            input-align="right"
          />
          <van-field
            size="large"
            v-model="sharePakringObj.startTime"
            name="start"
            is-link
            readonly
            label="共享时间"
            placeholder="请选择开始时间"
            @click="showStart = true"
            :rules="[{ required: true, message: '请选择开始时间' }]"
            class="input-bar"
            input-align="right"
          />
          <van-field
            size="large"
            v-model="sharePakringObj.overTime"
            name="end"
            is-link
            readonly
            label=" "
            placeholder="请选择结束时间"
            @click="handleEnd"
            :rules="[{ required: true, message: '请选择结束时间' }]"
            class="input-bar"
            input-align="right"
          />
         <van-field name="checkboxGroup" label="开放周期">
          <template #input>
            <van-checkbox-group direction="horizontal" v-model="checkList">
              <!-- <van-checkbox v-for="(item, index) in dateList" :checked-color="colorList[index]" :key="item.value" :name="item.value"> -->
                <!-- <span :style="'color:' + colorList[index]">{{item.name}}</span> -->
              <van-checkbox v-for="(item) in dateList"  :key="item.value" :name="item.value">
                {{item.name}}
              </van-checkbox>
            </van-checkbox-group>
          </template>
        </van-field>
          <div class="section" style="padding: 0.8rem;">
            <div class="image-title">车位图片</div>
            <van-uploader @delete="deletePic" :after-read="picUpload" v-model="file" name="file" max-count="1" />
          </div>
          <div class="foot-button">
            <van-button round block type="info" style="font-size: 16px;" @click="onSubmit">提交</van-button>
          </div>
        </van-form>
      </div>
      
    </div>
    <van-calendar v-model="showDate" @confirm="onDateConfirm" />
    <van-popup v-model="showStart" position="bottom">
      <van-datetime-picker
        v-model="sharePakringObj.startTime"
        type="time"
        title="选择开始时间"
        @cancel="onCancel('showStart')"
        @confirm="onStartConfirm"
      />
    </van-popup>
    <van-popup v-model="showEnd" position="bottom">
      <van-datetime-picker
        v-model="sharePakringObj.overTime"
        type="time"
        :min-hour="minEndHour"
        :min-minute="minEndMin"
        title="选择结束时间"
        @cancel="onCancel('showEnd')"
        @confirm="onEndConfirm"
      />
    </van-popup>
    <van-popup v-model="showCarPlates" position="bottom">
      <van-picker
        title="车牌号"
        show-toolbar
        value-key="parkingCode"
        :columns="carPlates"
        @confirm="onCarPlatesConfirm"
        @cancel="onCancel('showCarPlates')"
      />
    </van-popup>
  </div>
</template>

<script>
import { Toast } from 'vant';
export default {
  data() {
    return {
      file: [],
      checkList: [],
      colorList: ['#ee0a24', '#EE82EE', '#9370DB', '#3299CC', '#93DB70', '#238E23', '#9932CD'],
      sharePakringObj: {
        parkingId:'',
        parkingCode: '',
        communityName: '',
        communityId: '',
        register: '',
        registerPhone: '',
        registerName: '',
        location: '',
        image: '',
        mon: '',
        tue: '',
        wed: '',
        thur: '',
        fri: '',
        sat: '',
        sun: '',
        startTime: '',
        overTime: ''
      },

      community: '',
      phone: '',
      address: '',
      start: '',
      end: '',
      date: '',
      showCarPlates: false,
      showStart: false,
      showEnd: false,
      showDate: false,
      carPlates: [],
      minEndHour: '',
      minEndMin: '',
      dateList: [ { name: '周一', value: 'mon' }, { name: '周二', value: 'tue' }, { name: '周三', value: 'wed' }, 
      { name: '周四', value: 'thur' }, { name: '周五', value: 'fri' }, { name: '周六', value: 'sat' }, { name: '周日', value: 'sun' }],
    };
  },
  components: {},
  mounted() {
    this.initPakringInfo();
  },
  methods: {
    deletePic(){
      this.$store.dispatch('llcw/deleteImage', {path: this.sharePakringObj.image, obj: this})
    },
    picUpload(file){
      file.status = 'uploading';
      file.message = '上传中...';
      this.$store.dispatch('llcw/uploadImage', {file: file, obj: this})
    },
    initPakringInfo(){
      this.$store.dispatch('llcw/getMyParking', {obj: this})
    },
    gotoLink(path) {
      this.$router.push(path);
    },
    goBack() {
      this.$router.go(-1);
    },
    showAlert() {
      this.$messagebox("提示", "系统正在建设中，尽情期待~");
    },
    gotoDetail(info) {
      this.$router.push('gjxxq')
    },
    onCancel(type) {
      this[type] = false;
    },
    onDateConfirm(value, index) {
      this.date = this.formatDateTime(value);
      this.showDate = false;
    },
    onStartConfirm(value, index) {
      this.sharePakringObj.startTime = value;
      this.minEndHour = Number(this.sharePakringObj.startTime.substring(0,2));
      this.minEndMin = Number(this.sharePakringObj.startTime.substring(3));
      this.end = '';
      this.showStart = false;
    },
    onEndConfirm(value, index) {
      this.sharePakringObj.overTime = value;
      this.showEnd = false;
    },
    handleEnd() {
      if(!this.sharePakringObj.startTime) {
        Toast('请先选择开始时间')
      } else {
        this.showEnd = true;
      }
    },
    onCarPlatesConfirm(value, index) {
      this.sharePakringObj.communityId = value.communityId;
      this.sharePakringObj.communityName = value.communityName;
      this.sharePakringObj.registerName = value.ownerName;
      this.sharePakringObj.registerPhone = value.ownerPhone;
      this.sharePakringObj.parkingCode = value.parkingCode;
      this.sharePakringObj.location = value.location;
      this.sharePakringObj.parkingId = value.parkingId;
      this.sharePakringObj.register = value.userId;

      this.showCarPlates = false;

    },
    onSubmit() {
      if (this.checkList.length <= 0){
        Toast.fail('请选择至少一个周期开放！');
        return;
      }
      this.$refs.form.validate().then(() => {
        const start = this.sharePakringObj.startTime.split(':');
        const over = this.sharePakringObj.overTime.split(':');
        console.log(Number(over[0]) - Number(start[0]) < 1);
        if(Number(over[0]) - Number(start[0]) < 1){
          Toast.fail('开放时长最小为一小时！');
          return;
        }

        this.dateList.forEach(element => {
          this.sharePakringObj[element.value] = this.checkList.filter(v => v === element.value).length > 0 ? '1' : '0';
        });
        this.$store.dispatch('llcw/addCw', {item: this.sharePakringObj, obj: this})
        this.sharePakringObj.startTime = this.sharePakringObj.startTime.substring(0, this.sharePakringObj.startTime.lastIndexOf(":"));
        this.sharePakringObj.overTime = this.sharePakringObj.overTime.substring(0, this.sharePakringObj.overTime.lastIndexOf(":"));
      }).catch(() => {
        Toast.fail('缺少必要参数！');
      })
    },
    formatter(type, val) {
      if (type === 'year') {
        return val + '年';
      }
      if (type === 'month') {
        return val + '月';
      }
      if (type === 'day') {
        return val + '日';
      }
      if (type === 'hour') {
        return val + '时';
      }
      if (type === 'minute') {
        return val + '分';
      }
      return val;
    },
    formatDateTime(date) {  
        var y = date.getFullYear();  
        var m = date.getMonth() + 1;  
        m = m < 10 ? ('0' + m) : m;  
        var d = date.getDate();  
        d = d < 10 ? ('0' + d) : d;  
        var h = date.getHours();  
        var minute = date.getMinutes();  
        minute = minute < 10 ? ('0' + minute) : minute;  
        return y + '-' + m + '-' + d;  
    },
  },
};
</script>

<style lang="scss" scoped>
.page-container {
  background: #FFFFFF;
  padding: 0.6rem 0;
  height: calc(100vh - 40px);
  width: 100vw;
  margin-top: 40px;
  .section {
    width: 100%;
    .image-title {
      margin: 0.5rem 0;
      
      font-size: 16px;
      color: #646566;
      letter-spacing: 0;
    }
  }
  .foot-button {
    position: fixed;
    bottom: 0.8rem;
    right: 0.8rem;
    left: 0.8rem;
    height: 2.4rem;
    line-height: 2.4rem;
    text-align: right;
    border-radius: 8px;
    color: #FFFFFF;
    font-size: 18px;
    letter-spacing: 0;
  }
}
</style>
