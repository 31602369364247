<template>
  <div class="scjgzz-container">
    <van-nav-bar
      class="scjgzz-head-bar"
      title="市场监管职责"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="scjgzz-body-container">
      <!-- top bar -->
      <div class="top-bar">
        <div class="top-title">
          <img src="http://dssq.ikeqiao.net/data/image/h5/images/butler/scjgzz.svg" />
        </div>
      </div>
      <!-- 内容 -->
      <div class="content-bar">
        <div v-for="(item, index) in paragraphData" :key="index" class="paragraph">
          <div class="paragraph-icon">{{index + 1}}</div>
          <span>{{item}}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import homeApi from 'api/home';
import { mapMutations, mapState } from "vuex";
export default {
  data() {
    return {
      paragraphData: [
        '宣传、贯彻和落实市场监督管理法律、法规和规章等；',
        '负责辖区内食品安全监督管理工作，组织实施生产加工、流通、餐饮服务环节食品安全现场检查、监督抽查、隐患排摸、专项整治及应急事件处置工作；承担辖区内重大活动食品安全保障监督工作，做好辖区内农村集体聚餐食品安全风险防控工作；',
        '负责辖区内个体工商户的设立、变更、注销登记，承担本辖区食品流通许可、食品生产加工小作坊生产许可以及餐饮服务许可；',
        '按照法律法规、规章的规定和部门职责分工，负责对辖区内各类市场主体经营活动日常监督管理，开展市场主体登记事项的监管，开展本辖区无证无照经营整治工作；依法查处辖区内违反市场监督管理法律、法规和规章的行为，维护正常的市场交易秩序；',
        '负责指导辖区内各类商品交易市场的监督管理；开展区局委托下放的动产抵押物、户外广告等登记事项工作；依法规范市场主体的合同、商标、广告、经纪、拍卖等经营行为，查处违法活动；',
        '负责受理、调解、查处消费者投诉申诉举报。依法开展辖区消费者权益保护工作；',
        '负责本辖区市场主体有关信息数据的搜集、整理和录入工作，建立健全经济户口，开展网络经济监管，建立健全诚信体系并实行信用监管；',
        '协助开展辖区内药品、医疗器械、化妆品安全的监督检查，负责对辖区内特种设备及重点监控设备日常监察，协助开展特种设备各类专项监察，依法查处相关违法行为；',
        '开展计量、标准化、品牌建设、质量管理、检验检测等质量监管服务工作，负责辖区内质量安全监管信息档案建设工作；',
        '负责辖区内的信访维稳工作，充分发挥市场监督管理职能，协助地方政府支持服务地方经济发展；',
        '指导个体劳动者协会基层分会、民营企业协会基层分会等开展工作；',
        '完成上级交办的其他任务。'
      ],
    };
  },
  computed: {
  },
  components: {},
  mounted() {
    
  },
  watch: {
   
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    handleTab() {

    },
    gotoLink(path) {
      if(path) {
        this.$router.push(path);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.scjgzz-container {
  height: 100vh;
  width: 100vw;
  .scjgzz-head-bar {
    background: #7483FF;
    ::v-deep .van-nav-bar__title {
      color: white;
    }
    ::v-deep .van-icon {
      color: white;
    }
  }
  .scjgzz-body-container {
    height: calc(100vh - 46px);
    width: 100vw;
    background-image: linear-gradient(0deg, #547DF5 12%, #7483FF 97%);
    overflow-y: auto;
    overflow-x: hidden;
    .top-bar {
      width: 100%;
      .top-title {
        width: 100%;
        margin: 20px 0;
        img {
          width: 80%;
          margin-left: 10%;
        }
      }
    }
    .content-bar {
      margin: 0 20px 20px;
      width: calc(100% - 40px);
      padding: 16px;
      background-image: linear-gradient(180deg, #F1F3F9 0%, rgba(255,255,255,0.20) 100%);
      box-shadow: 0px 1px 8px 0px rgba(19,43,107,0.2);
      border-radius: 4px;
      .paragraph {
        background: #FFFFFF;
        border-radius: 4px;
        padding: 8px;
        border: 1px dashed #4A4FFF;
        box-shadow: 0 10px 10px #8699F4;
        margin-bottom: 20px;
        font-size: 16px;
        color: rgba(0,0,0,0.85);
        line-height: 24px;
        display: flex;
        align-items: center;
        .paragraph-icon {
          width: 36px;
          height: 36px;
          border-radius: 18px;
          background-image: linear-gradient(0deg, #547DF5 0%, #9778FF 95%);
          font-size: 18px;
          line-height: 36px;
          color: #FFFFFF;
          text-align: center;
          font-weight: 500;
          margin-right: 8px;
        }
        span {
          width: calc(100% - 44px);
        }
      }
    }
  }
}
</style>
