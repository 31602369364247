<template>
  <van-list
    v-model="loading"
    :finished="listFinished"
    finished-text="没有更多了"
    @load="onLoad"
    class="activity-list"
  >
    <ul>
      <li v-for="(item, index) in (dataList || []).filter((v, i) => i < 5)" :key="index" @click="gotoLink('huodongzhongxinDetail?key='+item.activityId)">
        <div class="img">
          <img :src="item.image" />
          <van-tag v-if="item.status == '0'" type="off">报名中</van-tag>
          <van-tag v-if="item.status == '1'" type="on">进行中</van-tag>
          <van-tag v-if="item.status == '2'" type="finish">已完成</van-tag>
          <div
            v-if="item.status == '0' || item.status == '1'"
            class="time"
          >
            {{ item.deadlineTime }}
          </div>
          <div v-if="item.status == '1'" class="end">报名已截止</div>
        </div>
        <div class="desc">
          <div class="title">
            <b>{{ item.title }}</b><span>已报名 <i>{{ item.number }}</i></span>
          </div>
          <div class="info">
            <label>发布单位：</label><span>{{ item.createUserName }}</span>
          </div>
          <div class="info">
            <label>活动时间：</label><span>{{ item.activityTime }}</span>
          </div>
        </div>
      </li>
    </ul>
  </van-list>
</template>
<script>
import nrbApi from "api/llyjr/nrb";
import wyslApi from "api/llyjr/wysl";
import { mapState } from 'vuex';
export default {
  name: 'activityList',
  data() {
    return {
      current: 1,
      pageSize: 10,
      total: 0,
      dataList: [],
      loading: false,
      listFinished: false,
    }
  },
  computed: {
    ...mapState({
      selectedTab: (state) => state.llyjr.selectedTab,
    }),
    belongOwn() {
      return this.selectedTab === '我的发布' ? 'yes' : 'no'
    }
  },
  mounted() {
    // this.getDataList();
  },
  methods: {
    onLoad() {
      this.getDataList({page: this.current});
      this.current += 1;
    },
    gotoLink(path) {
      this.$router.push(path);
    },
    getDataList({ page = this.page, size = this.size } = {}) {
      const { status = '', category = '' } = this;
      this.loading = false;
      wyslApi
        .getGamePage({
          page,
          size,
          object: {
            activityType: '假日课堂',
            status: '',
            sortField: '',
            sort: 'desc'
          }
        })
        .then((res) => {
          console.log('res', res);
          
          this.loading = false;
          const { list = [], total = 0 } = res.data || {};
          if(page === 1) {
            this.dataList = list;
          } else {
            this.dataList = Array.from(new Set(this.dataList.concat(list)))
          }
          this.total = total;
          if (this.dataList.length >= this.total) {
            // 数据全部加载完成
            this.listFinished = true;
          }
        })
        .catch((err) => {
          console.log('error====>', err);
          this.loading = false;
        });
    },
  }
}
</script>
<style lang="scss" scoped>
.activity-list {
  width: 100%;
  margin: 0 auto;
  li {
    margin-bottom: 0.8rem;
    // box-shadow: 0 0 10px 0 rgba(68, 121, 237, 0.6);
    border-radius: 4px;
    .img {
      height: 8rem;
      position: relative;
      img {
        width: 100%;
        height: 8rem;
      }
      .van-tag {
        position: absolute;
        top: 0;
        left: 0;
        padding: 0 0.4rem;
      }
      .van-tag--on {
        background: #e3effd;
        color: #4479ed;
      }
      .van-tag--off {
        background: #d3f1e3;
        color: #40c76d;
      }
      .van-tag--finish {
        background: #ededed;
        color: rgba(0, 0, 0, 0.45);
      }
      .time {
        background: rgba(0, 0, 0, 0.45);
        padding: 0 0.4rem;
        color: #fff;
        height: 1.6rem;
        line-height: 1.6rem;
        border-radius: 0.3rem;
        position: absolute;
        bottom: 0;
        left: 0;
        font-size: 0.4rem;
      }
      .end {
        background: rgba(0, 0, 0, 0.45);
        padding: 0 0.4rem;
        color: #fff;
        height: 1.6rem;
        line-height: 1.6rem;
        border-radius: 0.3rem;
        position: absolute;
        bottom: 0;
        right: 0;
        font-size: 0.4rem;
      }
    }
    .desc {
      background: #fff;
      border-radius: 0 0 0.3rem 0.3rem;
      padding: 0.5rem 0.7rem;
      .title {
        width: 100%;
        height: 1.4rem;
        overflow: hidden;
        display: flex;
        justify-content: space-between;
        align-items: center;
        b {
          color: rgba(0, 0, 0, 0.85);
          font-size: 1rem;
          width: 16.4rem;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        span {
          color: rgba(0, 0, 0, 0.65);
          font-size: 0.4rem;
          i {
            color: #4479ed;
            font-family: arial;
            font-style: initial;
          }
        }
      }
      .info {
        font-size: 0.9rem;
        line-height: 1.4rem;
        color: rgba(0, 0, 0, 0.85);
        label {
          color: rgba(0, 0, 0, 0.45);
        }
      }
    }
  }
}
</style>