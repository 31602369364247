<template>
  <div id="index">
    <div class="party-page-container">
      <div class="header-nav">
        <i @click="goBack"></i>
        <h1>支部介绍</h1>
      </div>
      <div class="party-main">
        <div class="party-tab">
          <div class="left-tag" />
          <i></i>东盛社区党支部
          <div class="right-tag" />
        </div>
        <div class="info-title">基本信息</div>
        <div class="content-info">
          <div class="info-list">
            <div class="info-item">
              <i class="icon1"></i>
              <div class="info-num">
                <div>3</div>
                <div>党支部成员数量</div>
              </div>
            </div>
            <!--<div class="info-item">-->
            <!--<i class="icon2"></i>-->
            <!--<div><b>99</b><span>党员数量</span></div>-->
            <!--</div>-->
          </div>
        </div>
        <div class="split-line" />
        <div class="info-title">党支部成员</div>
        <div class="imgList">
          <ul>
            <li>
              <!-- <img src="http://dssq.ikeqiao.net/data/image/h5/images/photo-temp1.jpg" /> -->
              <img src="../../assets/images/diyiming.png" class="other-img" />
              <div>张晓鸿<br /><span>党总支书记</span></div>
            </li>
            <li>
              <!-- <img src="http://dssq.ikeqiao.net/data/image/h5/images/photo-temp2.jpg" /> -->
              <img src="../../assets/images/dierming.png" class="middle-img" />
              <div>邱琴琴<br /><span>副书记</span></div>
            </li>
            <li>
              <!-- <img src="http://dssq.ikeqiao.net/data/image/h5/images/photo-temp3.jpg" /> -->
              <img src="../../assets/images/disanming.png" class="other-img" />
              <div>李银曼<br /><span>委员</span></div>
            </li>
          </ul>
        </div>
        <div class="split-line" />
        <div class="info-title">支部介绍</div>
        <div class="info-detail">
          <div>组织名称：东盛社区党支部</div>
          <!--<div>组织类别：党支部</div>-->
          <!--<div>组织管理员：张晓鸿</div>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  components: {},
  mounted() {},
  methods: {
    gotoLink(path) {
      this.$router.push(path)
    },
    goBack() {
      this.$router.go(-1)
    },
  },
}
</script>

<style lang="scss">
.party-page-container {
  .split-line {
    height: 1px;
    width: 100%;
    margin: 20px 0;
    background: rgba($color: black, $alpha: 0.1);
  }
  padding: 0 0.8rem 0rem;
  background: #fff;
  .party-main {
    padding-top: 3.2rem;
    height: 100vh;
    .party-tab {
      font: 500 1.1rem/2rem 'Microsoft Yahei';
      color: #000;
      display: flex;
      align-items: center;
      justify-content: center;
      .left-tag {
        height: 2vh;
        width: 11.6vw;
        background: url(../../assets/images/zhuangshi-left.png) no-repeat;
        background-size: 100% 100%;
        margin-right: 10px;
      }
      .right-tag {
        height: 2vh;
        width: 11.6vw;
        background: url(../../assets/images/zhuangshi-right.png) no-repeat;
        background-size: 100% 100%;
        margin-left: 10px;
      }
      i {
        display: inline-block;
        background: url(http://dssq.ikeqiao.net/data/image/h5/images/community_party_icon.png)
          no-repeat;
        background-size: 100%;
        width: 1rem;
        height: 1rem;
        margin-right: 0.5rem;
      }
    }
    .content-info {
      position: relative;
      background-color: white;
      z-index: 10;
      padding: 0.6rem 0.25rem;
      box-sizing: border-box;
      border-radius: 0.25rem;
      //box-shadow: 0 0 0.1rem 0.1rem #ddd;
      //border: 2px solid #ccc;
    }
    .info-title {
      font-size: 1rem;
      margin: 1rem 0 0.8rem;
    }
    .info-list {
      align-items: center;
    }
    .info-item {
      width: 48%;
      display: flex;
      align-items: center;
    }
    .info-item div {
      display: inline-block;
      font: 500 0.8rem/1.2rem 'Microsoft Yahei';
      color: #333;
      vertical-align: middle;
    }
    .info-item i {
      width: 1.8rem;
      height: 1.8rem;
      display: inline-block;
      margin-right: 0.6rem;
      vertical-align: middle;
    }
    .info-item i.icon1 {
      background: url(../../assets/images/lingdao.png) no-repeat;
      background-size: 100%;
    }
    .info-num {
      display: flex !important;
      justify-content: center;
      flex-direction: column;
      :first-child {
        font-weight: bolder;
      }
    }
    .info-item i.icon2 {
      background: url(http://dssq.ikeqiao.net/data/image/h5/images/branchIcon2.png)
        no-repeat;
      background-size: 100%;
    }
    .imgList {
      display: flex;
      justify-content: space-between;
      align-items: center;
      // border-radius: 0.3rem;
      // box-shadow: 0 0 0.1rem 0.1rem #ddd;
      // border: 2px solid #ccc;
      overflow: hidden;
      padding: 0.6rem 0;
      .other-img {
        width: 11.7vw;
        height: 6.6vh;
      }
      .middle-img {
        width: 17.1vw;
        height: 9.6vh;
      }
      ul {
        width: 100%;
        display: flex;
        justify-content: space-around;
        align-items: flex-end;
      }
    }
    .imgList li {
      float: left;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      width: 30%;
      height: 100%;
      margin: 0 0.3rem;
      text-align: center;
      div {
        margin-top: 10px;
        font: 500 0.9rem/1.2rem 'Microsoft Yahei';
        span {
          color: rgba($color: black, $alpha: 0.4);
        }
      }
    }
    .imgList li img {
      width: 7rem;
      height: 9rem;
    }
    .info-detail {
      font: 500 0.9rem/1.4rem 'Microsoft Yahei';
      color: #333;
      // text-indent: 1em;
      box-sizing: border-box;
    }
    .info-detail div {
      // padding: 0.25rem 0;
      color: rgba($color: black, $alpha: 0.4);
    }
  }
}
</style>
