<template>
  <div id="index">
    <div class="page-container">
      <div class="header-nav">
        <i @click="goBack"></i>
        <h1>列表</h1>
      </div>
      <div class="kgh-main">
        <div class="commnewlist" style="margin-top:0; padding:0;">
          <ul>
            <li v-for="(item, index) in list" :key="index"  @click="openArticleUrl(item)">
              <div class="desc">
                <div class="title">
                  {{ item.title }}
                </div>
                <div class="time">时间：{{ item.createTime }}</div>
              </div>
              <div class="img">
                <img :src="item.cover" />
              </div>
            </li>
          </ul>
        </div>
        <div class="getmore-box"><span>- 暂无更多消息 - </span></div>
      </div>
    </div>
  </div>
</template>

<script>
import Api from 'api';
export default {
  data() {
    return {
      list: [
        // {
        //   id: "711f9230-53bc-11eb-8866-2f789ceec9b2",
        //   title: "爱心车位",
        //   image:
        //     "https:\/\/wxbucket.oss-cn-hangzhou.aliyuncs.com\/zhsq\/0OFXEY2tiBV8.jpg",
        //   created_at: "2021-01-11 11:23:58",
        // },
        // {
        //   id: "b3ef5120-53bc-11eb-a652-3b58ef2ca78f",
        //   title: "公公眼中的好媳妇",
        //   image:
        //     "https:\/\/wxbucket.oss-cn-hangzhou.aliyuncs.com\/zhsq\/vtsy5DTeNik4.jpg",
        //   created_at: "2021-01-11 11:25:50",
        // },
        // {
        //   id: "d28ebcd0-53bc-11eb-82c6-11b4d4becf8d",
        //   title: "抗疫战士",
        //   image:
        //     "https:\/\/wxbucket.oss-cn-hangzhou.aliyuncs.com\/zhsq\/G3lsOapqLth2.jpg",
        //   created_at: "2021-01-11 11:26:41",
        // },
        // {
        //   id: "e7ca1dd0-53bc-11eb-9159-8b11321f005a",
        //   title: "社区里最动听的话语",
        //   image:
        //     "https:\/\/wxbucket.oss-cn-hangzhou.aliyuncs.com\/zhsq\/0vx6jFNWTEtm.jpg",
        //   created_at: "2021-01-11 11:27:17",
        // },
        // {
        //   id: "0c2099c0-53bd-11eb-8d56-bd9fa5f60b7f",
        //   title: "最美楼道",
        //   image:
        //     "https:\/\/wxbucket.oss-cn-hangzhou.aliyuncs.com\/zhsq\/sQuCA16kGva5.jpg",
        //   created_at: "2021-01-11 11:28:18",
        // },
      ],
      showData: [],
    };
  },
  components: {},
  mounted() {
    this.initList();
  },
  methods: {
    initList() {
      let type = this.$route.params.type;
      // console.log(type);
      // this.showData = this.list[type];
      this.getDataList(type);
    },
    gotoLink(path) {
      this.$router.push(path);
    },
    goBack() {
      this.$router.go(-1);
    },
    getDataList(type) {
      Api.getHongArticleList({
        page: 1,
        pageSize: 100,
        type,
      })
      .then((res) => {
        const { records = [] } = res.data || {};
        this.list = records;
      })
      .catch((err) => {
        console.log('error====>', err);
      });
    },
    openArticleUrl(data) {
      if (data.content && data.contentType === 1) {
        // 增加浏览量
        Api.addArticleVisit({ id: data.id }).then((res) => {});
        window.location.href = data.content;
      } else if (data.contentType === 0) {
        this.$router.push(`resouArticleDetail?id=${data.id}`);
      }
    },
  },
};
</script>

<style lang="scss">
@import "~assets/scss/index.scss";
</style>
