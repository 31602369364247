<template>
  <div id="index" class="vangbg">
    <div class="page-container">
      <div class="header-nav">
        <i @click="goBack"></i>
        <h1>先锋指数</h1>
      </div>
      <div class="vang-main">
        <div class="top-list clearfix">
          <ul>
            <li class="second">
              <div class="img"><img src="http://dssq.ikeqiao.net/data/image/h5/images/t1.jpg" /></div>
              <i></i>
              <div>
                <p>邱琴琴</p>
                <p>先锋指数 96</p>
                <div class="star star-5"></div>
              </div>
            </li>
            <li class="first">
              <div class="img"><img src="http://dssq.ikeqiao.net/data/image/h5/images/t2.jpg" /></div>
              <i></i>
              <div>
                <p>张晓鸿</p>
                <p>先锋指数 98</p>
                <div class="star star-5"></div>
              </div>
            </li>
            <li class="third">
              <div class="img"><img src="http://dssq.ikeqiao.net/data/image/h5/images/hong/chenhengchao.jpg" /></div>
              <i></i>
              <div>
                <p>陈恒超</p>
                <p>先锋指数 96</p>
                <div class="star star-4"></div>
              </div>
            </li>
          </ul>
        </div>
        <div class="vang-list">
          <ul>
            <div v-for="(item, index) in rankList" :key="index">
              <li
                class="rank-item"
                v-if="index > 2"
                style="height: 5.2rem !important;"
              >
                <i style="width: 20px !important;text-align: right;">{{
                  index + 1
                }}</i>
                <div class="info float-l">
                  <div v-if="item.belongs_to_party_branch_users">
                    <img
                      :src="item.belongs_to_party_branch_users.image"
                      style="height: auto !important; max-height: 42px;"
                    />
                    <b> {{ item.belongs_to_party_branch_users.name }} </b>
                  </div>
                  <div>
                    先锋指数 <span>{{ item.total_score }}</span>
                  </div>
                </div>
                <div :class="'star star-' + item.star + ' float-r'"></div>
              </li>
            </div>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      rankList: [],
    };
  },
  components: {},
  mounted() {
    this.getPartyList();
  },
  methods: {
    gotoLink(path) {
      this.$router.push(path);
    },
    goBack() {
      this.$router.go(-1);
    },
    getPartyList() {
      let that = this;
      this.axios
        .get("/authApi/sdk-auth/djlb?communityId=417817877553348609&notPage=1")
        .then(function(response) {
          console.log(response);
          let rankList = [];
          let star = 0;
          response.data.data.forEach((val, index) => {
            if (val.total_score >= 95) {
              star = 5;
            } else if (val.total_score < 95 && val.total_score >= 85) {
              star = 4;
            } else if (val.total_score < 85 && val.total_score >= 75) {
              star = 3;
            } else if (val.total_score < 75 && val.total_score >= 65) {
              star = 2;
            } else {
              star = 1;
            }
            // var star = Math.floor(val.total_score/20)
            rankList.push({
              belongs_to_party_branch_users: val.belongs_to_party_branch_users,
              star: star,
              total_score: val.total_score,
            });
          });

          console.log(rankList);
          that.rankList = rankList;
        })
        .catch(function(err) {
          // eslint-disable-line no-unused-vars
        });
    },
  },
};
</script>

<style lang="scss">
@import "~assets/scss/index.scss";
</style>
