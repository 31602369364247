<template>
  <div class="volunteer-rule-container">
    <van-nav-bar
      title="志愿者管理制度"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="body-container">
      <!-- top bar -->
      <div class="top-bar">
        <p>东盛社区志愿者管理制度</p>
      </div>
      <div class="v-rule-content">
        <h1>第一章 总则</h1>
        <p><b>第一条</b> 为倡导“奉献、友爱、互助、进步”的志愿者精神，规范和促进社区志愿服务活动，加快推进社区共建共治共享，特制定本制度。</p>
        <p><b>第二条</b> 社区志愿服务从社区居民多种需求出发，开展各种无偿公益服务，协助解决社区问题，倡导社区居民互助，共同推动和谐社区建设的公益服务活动。</p>
        <p><b>第三条</b> 社区志愿者是由社区组织登记并获得其同意，自愿为他人和社会提供无偿服务的人员。</p>
        <h1>第二章  志愿者的申请与管理、权利与义务</h1>
        <p><b>第四条</b> 志愿者申请基本条件：</p>
        <p class="little-p"><b>（一）</b>年龄18周岁以上(以下的可跟随监护人或在有组织的情况下参加)；</p>
        <p class="little-p"><b>（二）</b>热心于公益事业，不怕困难，具有奉献精神；</p>
        <p class="little-p"><b>（三）</b>具备与所参加的志愿服务项目及活动相适应的基本素质;</p>
        <p class="little-p">符合以上条件者均可到社区填写志愿者报名表，或通过专有链接进行报名。</p>

        <p><b>第五条</b> 志愿者管理：</p>

        <p>志愿者完成报名后，社区对申请人情况进行初步审核，对审核通过的志愿者录入志愿者电子档案，并颁发志愿者《时间银行存折》。</p>
        <p><b>第六条</b> 志愿者享有的权利：</p>

<p><b>（一）</b>参与社区或其他组织机构组织的培训（如服务技能培训等）；</p>

<p><b>（二）</b>在生活和服务活动中遇到困难和问题时，可请求社区或组织帮助；</p>

<p><b>（三）</b>享有监督、建议、批评、出入组织自由的权利。</p>

<p><b>第七条</b> 志愿者应履行的义务：</p>

<p><b>（一）</b>在法律法规规定的范围内开展志愿服务；</p>

<p><b>（二）</b>完成社区组织安排的服务工作；</p>

<p><b>（三）</b>维护社区、志愿者组织的声誉和形象，保证服务质量。</p>

 

<h1>第三章  服务范围和重点对象</h1>

<p><b>第八条</b> 社区志愿服务范围包括扶弱济困、宣传教育、文化娱乐、治安维稳、环境维护等其他社会公益性服务。</p>

<p><b>第九条</b> 社区志愿服务的重点对象是残疾人、老年人、优抚对象和其他具有特殊困难需要救助的社会成员。志愿者组织根据服务对象的申请或者实际需要，提供力所能及的志愿服务。志愿者，志愿者组织与服务对象之间是自愿、平等的服务与被服务关系，应当互相尊重、平等相待。</p>

 

<h1>第四章  积分兑换及奖励办法</h1>

<p><b>第十条</b> 对参与积分管理的志愿者每次参与志愿服务活动进行认定后由社区工作人员进行记载，根据服务时间、内容、质量等为志愿者量化积分。原则上1小时记1分，1.5小时及以上按2小时计分，以此类推，另外可根据志愿者提供服务的难易程度和质量适当增加积分。</p>

<p><b>第十一条</b> 志愿者积分可用于兑换服务或真实物品。“服务”是指由社区、志愿服务组织、社会组织、企业、合作商家等提供的服务。“物品”是指由社区对接或者购买的货物，如纸巾、食盐、米油等。</p>

<p><b>第十二条</b> 志愿者积分可实行家庭内部转移，如父母的积分可转移到子女的账户中，供子女享受积分兑换的服务内容。</p>

<p><b>第十三条</b> 原则上积分每季度可兑换一次，同时将已兑换的积分扣减，服务时长保留，并定期由专人进行积分的录入和更新电子档案。</p>

<p><b>第十四条</b> 成功报名志愿服务后无故缺席三次者予以警告，缺席五次者取消其东盛社区志愿者资格，并收回《时间银行存折》。</p>

<p><b>第十五条</b> 每年年底进行一次积分、服务时长的评比，综合选出优秀志愿者进行表彰，并将其《时间银行存折》进行上墙公示。</p>
      </div>
    </div>
  </div>
</template>

<script>
import homeApi from 'api/home';
import { mapMutations, mapState } from "vuex";
export default {
  data() {
    return {
      
    };
  },
  computed: {
  },
  components: {},
  mounted() {
    
  },
  watch: {
   
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    gotoLink(path) {
      if(path) {
        this.$router.push(path);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.volunteer-rule-container {
  height: 100vh;
  width: 100vw;
  font-size: 1.1rem;
  line-height: 1.4rem;
  color: rgba(0, 0, 0, 0.85);
  
  .body-container {
    height: calc(100vh - 46px);
    width: 100vw;
    background: #f6f7fa;
    overflow-y: auto;
    overflow-x: hidden;
    .top-bar {
      height: 160px;
      width: 100vw;
      background: url("http://dssq.ikeqiao.net/data/image/dongsheng/volunteerism/volunteer_rule_bg.png") no-repeat;
      background-size: 100% 100%;
      color: #FFF;
      text-align: center;
      font-size: 24px;
      line-height: 100px;
      font-weight: 500;
    }
    .v-rule-content {
      margin: -60px 12px 12px;
      background: #FFF;
      padding: 12px;
      border-radius: 4px;
      >p {
        line-height: 1.5rem;
        margin: 10px 0;
      }
      >h1 {
        font-size: 1.2rem;
        line-height: 2rem;
        margin: 24px 0;
        font-weight: 500;
      }
      .litter-p {
        line-height: 1.2rem;
        text-indent: 16px;
        margin: 10px 0;
      }
    }
  }
}
</style>
