<template>
  <div id="index">
    <div class="page-container">
      <div class="header-nav">
        <i @click="goBack"></i>
        <h1>一键挪车</h1>
      </div>
      <div class="pic-nav">
        <img src="http://dssq.ikeqiao.net/data/image/h5/images/yjnc.png" class="image" />
        <div>将联系物业通知车主进行挪车</div>
        <div>是否确认要挪车？</div>
      </div>
      <div class="foot-button" @click="handleBorrow">联系物业</div>
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant';
export default {
  data() {
    return {
    };
  },
  components: {},
  mounted() {},
  methods: {
    gotoLink(path) {
      this.$router.push(path);
    },
    goBack() {
      this.$router.go(-1);
    },
    showAlert() {
      this.$messagebox("提示", "系统正在建设中，尽情期待~");
    },
    handleBorrow() {
      // this.$messagebox("提示", "联系成功~");
      window.location.href = `tel://1867517898`;
    }
  },
};
</script>

<style lang="scss" scoped>
.page-container {
  background: #FFFFFF;
  padding: 0.6rem;
  height: calc(100vh - 40px);
  width: 100vw;
  margin-top: 40px;
  .pic-nav {
    padding: 10rem 1rem;
    font-weight: 600;
    
    font-size: 16px;
    color: #000000;
    text-align: center;
    line-height: 24px;
    text-align: center;
    .image {
      width: 100%;
      margin-bottom: 2rem;
    }
  }
  .foot-button {
    position: fixed;
    bottom: 0.8rem;
    right: 0.8rem;
    left: 0.8rem;
    height: 2.4rem;
    line-height: 2.4rem;
    text-align: center;
    background: #4479ED;
    border-radius: 8px;
    color: #FFFFFF;
   
    font-size: 18px;
    letter-spacing: 0;
  }
}
</style>
