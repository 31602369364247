<template>
  <div id="index">
    <div class="page-container paddclose">
      <div class="header-nav">
        <i @click="goBack"></i>
        <h1>工晴驿站</h1>
      </div>
      <div class="kgh-main">
        <div class="banner">
          <img src="http://dssq.ikeqiao.net/data/image/h5/images/kgh/groupServicebanner-gqyz.png" />
        </div>
        <div class="navlist">
          <div><img src="http://dssq.ikeqiao.net/data/image/h5/images/kgh/btn-gqyz.png" /></div>
        </div>
        <div class="commnewlist" style="margin-top:0rem">
          <div class="tab">心理老师简介</div>
          <ul>
            <li
              @click="gotoLink('kghDetail')"
              v-for="(item, index) in newsList"
              :key="index"
            >
              <div class="desc">
                <div class="title">
                  {{ item.title }}
                </div>
                <div class="time">{{ item.content }}</div>
              </div>
              <div class="img">
                <img :src="item.imgurl" />
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      newsList: [
        {
          id: 0,
          title: "丁小瑶",
          content: "我是一个很好的心理老师，请大家来咨询我",
          imgurl:
            "https://wxbucket.oss-cn-hangzhou.aliyuncs.com/zhsq/headImg2.jpg",
        },
      ],
    };
  },
  components: {},
  mounted() {},
  methods: {
    gotoLink(path) {
      this.$router.push(path);
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss">
@import "~assets/scss/index.scss";
</style>
