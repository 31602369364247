<template>
  <div id="index">
    <div class="page-container">
      <div class="header-nav">
        <i @click="goBack"></i>
        <h1>我的预约</h1>
      </div>
      <div class="list">
        <div v-for="(item) in list" :key="item.id" class="card-con">
          <div class="tag" :style="{color: colors[item.useStatus] && colors[item.useStatus].text, background: colors[item.useStatus] && colors[item.useStatus].background}">
            {{getStatus(item.useStatus)}}
          </div>
          <div>
            <div class="card-title">
              <span>车位号：{{item.parkingCode || ''}}</span>
            </div>
            <div class="row">
              <div>所属小区：{{item.communityName || ''}}</div>
            </div>
            <div class="row">
              <div>车位地址：{{item.location || ''}}</div>
            </div>
            <div class="row">
              <span>预约时间：{{`${item.orderDate || ''} ${item.startTime || ''}~${item.overTime || ''}`}}</span>
            </div>
          </div>
          <div class="card-foot">
            <div class="foot" @click="onDelete">删除记录</div>｜
            <div v-if="item.useStatus === '2' || item.useStatus === '-1'" @click="gotoLink('cwyy',item)" class="foot">重新预约</div>
            <div v-if="item.useStatus === '0'" @click="handleCancel(item)" class="foot">取消预约</div>
            <div v-if="item.useStatus === '1'" class="foot">结束泊车</div>
          </div>
        </div> 
        <div v-if="list.length === 0" style="margin-top:40%; text-align:center; font-size: 18px">暂无预约记录</div> 
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant';
import llcwApi from 'api/llcw/llcw';
export default {
  data() {
    return {
      list: [{
        parkingCode: 'C165',
        communityName: '江南华庭',
        location: '江南华庭1栋旁',
        orderDate: '工作日',
        startTime: '09:00',
        overTime: '11:00',
      }],
      colors: {
        '0': {text: '#13BB49', background: '#D5F4E2'}, // 已预约
        '2': {text: 'rgba(0,0,0,0.45)', background: '#EDEDED'}, // 已结束
        '-1': {text: 'rgba(0,0,0,0.45)', background: '#EDEDED'}, // 被取消
        '1': {text: 'rgba(0,0,0,0.45)', background: '#EDEDED'}, // 已泊车
      },
      current: 1,
      selectItem: {
        startTime: "",
        overTime: "",
        date: "",
        communityId: "",
        stayTime: '',
        order: "",
        parkingCode: ""
      }, // 预约信息
    };
  },
  components: {},
  mounted() {
    // this.getList();
  },
  methods: {
    gotoLink(path, item) {
      this.$router.push({name: path, params: {item, info: item}});
    },
    goBack() {
      this.$router.go(-1);
    },
    onDelete(){
      this.$messagebox('提示', '删除成功')
      this.list = [];
    },
    showAlert() {
      this.$messagebox("提示", "系统正在建设中，尽情期待~");
    },
    getList(params) {
      this.$store.dispatch('llcw/getMyOrders',{
        params: {
          page: this.current,
          size: 10,
          ...params
        },
        obj: this
      });
    },
    getStatus(status) {
      switch(status){
        case '-1':
          return '被取消';
        case '0':
          return '已预约';
        case '1':
          return '已泊车';
        case '2':
          return '已结束';
        default:
          return '';
      }
    },
    handleCancel(item) {
      llcwApi.deleteOrder({shareParkingOrderId: item.id}).then(res => {
        if(res.data.flag) {
          this.getList({ page: 1});
          Toast('取消预约成功');
          this.current = 1;
        } else {
          Toast('取消预约失败');
        }
      })
    }
    
  },
};
</script>

<style lang="scss" scoped>
.page-container {
  background: #f6f7fa;
  padding: 0.6rem;
  height: calc(100vh - 40px);
  width: 100vw;
  margin-top: 40px;
  
  .list {
    width: 100%;
    .list-title {
      border-left: 4px solid #4479ED;
      padding-left: 0.4rem;
      
      font-size: 16px;
      color: rgba(0,0,0,0.85);
      margin: 0.4rem;
    }
    .card-con {
      align-items: center;
      padding: 0.4rem 0.6rem;
      position: relative;
      background: #FFFFFF;
      margin-bottom: 0.6rem;
      .tag {
        position: absolute;
        top: 0;
        right: 0;
        padding: 0.2rem 0.8rem;
        border-radius: 4px;
      }
      .card-title {
        display: flex;
        justify-content: space-between;
        font-size: 16px;
        color: rgba(0,0,0, 0.85);
        text-align: left;
        line-height: 1.8rem;
        font-weight: 600;
        .tag {
          background: #D5F4E2;
          border-radius: 4px 2px 8px 2px;
          font-size: 12px;
          color: #37D477;
          letter-spacing: 0;
          text-align: center;
          line-height: 12px;
          padding: 0.2rem 0.8rem;
          height: 1.2rem;
        }
      }
      .image {
        height: 5.6rem;
        border-radius: 2px;
        margin-right: 0.5rem;
      }
      .row {
       
        font-size: 13px;
        line-height: 1.4rem;
        display: flex;
       
        font-size: 14px;
        color: rgba(0,0,0,0.65);
        text-align: left;
        .label {
          color: rgba(0,0,0,0.45);
          text-align: left;
          line-height: 18px;
          margin-right: 0.6rem;
        }
      }
      .card-foot {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0.6rem 0;
        color: rgba(0,0,0,0.65);
        .foot {
          font-size: 14px;
          color: #4479ED;
          letter-spacing: 0;
          text-align: center;
          line-height: 20px;
          text-align: center;
          width: 49%;
        }
      }
    }
  }
}
</style>
