<template>
  <div id="index">
    <div class="page-container">
      <div class="header-nav">
        <i @click="goBack"></i>
        <h1>服务详情</h1>
      </div>
      <div class="card-nav">
        <div class="question">
          <div class="question-title">{{questionInfo.invitationTitle}}</div>
          <div class="person">
            <img
              v-if="questionInfo.avatarUrl"
              :src="questionInfo.avatarUrl"
              class="avatar"
              alt="头像"
            />
            <img v-else src="http://dssq.ikeqiao.net/data/image/h5/images/defaultUser.png" class="avatar" alt="头像" />
            <span>{{questionInfo.nickName}}</span>
          </div>
          <div class="question-little-title">
            <div class="piece">发布时间：{{questionInfo.createTime}}</div>
          </div>
          <div class="paragraph">
            <p>{{questionInfo.invitationContent}}</p>
          </div>
          <div class="image-list">
            <img
              v-for="image in invitationImage"
              :key="image"
              :src="image"
              class="image"
              alt="暂无图片"
            />
          </div>
          <div class="foot">
            <div>
              <van-icon name="location" />
              <span>{{questionInfo.detailPlace}}</span>
            </div>
            <div class="question-foot">
              <div>
                <span>
                  已服务
                  <i style="color: #4479ed;">{{questionInfo.serviceUp}}</i>人
                </span>
              </div>
              <!-- <div @click="handleAddThumbsUp(questionInfo)">
                <img
                  v-if="questionInfo.hasThumbsUp === '未点赞'"
                  src="http://dssq.ikeqiao.net/data/image/h5/images/llyjr/zan.png"
                />
                <img v-else src="http://dssq.ikeqiao.net/data/image/h5/images/llyjr/zan-active.png" />
                <span>{{questionInfo.thumbsUp}}</span>
              </div> -->
            </div>
          </div>
        </div>
      </div>
      <div class="foot-button" @click="handleAddServiceUp(questionInfo)">联系TA</div>
    </div>
  </div>
</template>

<script>
import llyjrApi from "api/llyjr/index";
import nrbApi from "api/llyjr/nrb";
import { mapState, mapMutations } from "vuex";
import { Toast } from "vant";

export default {
  data() {
    return {
      invitationImage: []
    };
  },
  computed: {
    ...mapState({
      questionInfo: state => {
        return state.llyjr.questionInfo;
      }
    })
  },
  mounted() {
    this.invitationImage = this.questionInfo.invitationImage
      ? this.questionInfo.invitationImage.split("#")
      : [];
  },
  methods: {
    handleAddServiceUp(item) {
      this.$store.dispatch('login/checkRealName', () => {
        llyjrApi
          .addServiceUp({ invitationId: item.invitationId })
          .then(res => {});
      })
    },
    ...mapMutations(["setQuestionInfo"]),
    updateQuestionInfo(flag) {
      this["setQuestionInfo"]({
        ...this.questionInfo,
        hasThumbsUp: flag === "addThumbsUp" ? "已点赞" : "未点赞",
        thumbsUp:
          flag === "addThumbsUp"
            ? this.questionInfo.thumbsUp + 1
            : this.questionInfo.thumbsUp - 1
      });
    },
    addThumbsUp(invitationId) {
      nrbApi.addThumbsUp({ invitationId }).then(res => {
        if (res.data == 1) {
          Toast.success("点赞成功");
          this.updateQuestionInfo("addThumbsUp");
        }
      });
    },
    cancelThumbsUp(invitationId) {
      nrbApi.cancelThumbsUp({ invitationId }).then(res => {
        if (res.data == 1) {
          Toast.success("取消点赞成功");
          this.updateQuestionInfo("cancelThumbsUp");
        }
      });
    },
    handleAddThumbsUp(item) {
      const { hasThumbsUp, invitationId } = item;
      this.$store.dispatch('login/checkRealName', () => {
        if (hasThumbsUp === "已点赞") {
          this.cancelThumbsUp(invitationId);
        }
        if (hasThumbsUp === "未点赞") {
          this.addThumbsUp(invitationId);
        }
      })
    },
    gotoLink(path) {
      this.$router.push(path);
    },
    goBack() {
      this.$router.go(-1);
    },
    showAlert() {
      this.$messagebox("提示", "系统正在建设中，尽情期待~");
    }
  }
};
</script>

<style lang="scss" scoped>
.page-container {
  // padding: 0.8rem 0rem;
  padding-top: 3rem;
  background: #f6f7fa;
  .card-nav {
    width: 100%;
    // border-top: 0.8rem solid #f6f7fa;
    // background: #FFFFFF;
    // padding: 0.8rem;
    .question {
      margin-bottom: 0.8rem;
      background: #ffffff;
      padding: 0.8rem;
      .question-title {
        font-size: 16px;
        color: rgba(0, 0, 0, 0.85);
        text-align: left;
        line-height: 12px;
        line-height: 2rem;
        font-weight: 600;
      }
      .question-little-title {
        display: flex;
        color: rgba(0, 0, 0, 0.45);
        align-items: center;
        height: 1.5rem;
        .piece {
          margin-right: 0.4rem;
          vertical-align: middle;
          display: flex;
          align-items: center;
          img {
            height: 1rem;
            margin: 0 0.2rem;
          }
        }
      }
      .person {
        display: flex;
        align-items: center;

        font-size: 14px;
        color: rgba(0, 0, 0, 0.65);
        text-align: left;
        line-height: 22px;
        height: 2.5rem;
        .avatar {
          height: 2rem;
          width: 2rem;
          border-radius: 1rem;
          margin-right: 5px;
        }
      }
      .foot {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: rgba(0, 0, 0, 0.45);

        .question-foot {
          height: 1.5rem;
          margin: 0.4rem 0;
          display: flex;
          align-items: center;

          div {
            margin-right: 0.4rem;
          }
          img {
            height: 1rem;
            margin-right: 0.2rem;
          }
        }
      }
      .paragraph {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.65);
        text-align: left;
        line-height: 22px;
      }
      .image-list {
        display: flex;
        margin: 0.8rem 0;
        .image {
          height: 4rem;
          width: 4rem;
          border-radius: 4px;
          margin-right: 0.4rem;
        }
      }
    }
  }
  .foot-button {
    position: fixed;
    bottom: 0.8rem;
    right: 0.8rem;
    left: 0.8rem;
    height: 2.4rem;
    line-height: 2.4rem;
    text-align: center;
    background: #4479ed;
    border-radius: 8px;
    color: #ffffff;

    font-size: 18px;
    letter-spacing: 0;
  }
}
</style>
