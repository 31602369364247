<template>
  <div>
    <van-nav-bar
      fixed
      class="butler-nav-bar"
      title="创建约运动"
      left-arrow
      @click-left="$router.go(-1)"
    />
    <div class="yueyund-add-form">
      <van-form @submit="onSubmit">
        <van-field
          required
          v-model="title"
          label="发布标题"
          placeholder="请输入标题"
          :rules="[{ required: true, message: '请输入标题' }]"
        />
        <van-field
          readonly
          required
          clickable
          v-model="address.name"
          name="address"
          label="活动场地"
          placeholder="请选择活动场地"
          :rules="[{ required: true, message: '请选择活动场地' }]"
          @click="showAddressPicker = true"
        />
        <van-popup v-model="showAddressPicker" position="bottom">
          <van-picker
            show-toolbar
            value-key="name"
            :columns="addressList"
            @confirm="addressConfirm"
            @cancel="showAddressPicker = false"
          />
        </van-popup>
        <van-field
          readonly
          required
          v-model="startTime"
          clickable
          name="startTime"
          label="开始时间"
          placeholder="请选择开始时间"
          :rules="[{ required: true, message: '请选择开始时间' }]"
          @click="showStartTime = true"
        />
        <van-popup v-model="showStartTime" position="bottom">
          <van-datetime-picker
            type="datetime"
            title="选择开始时间"
            :min-date="startMinDate"
            @confirm="changeStartTime"
            @cancel="showStartTime = false"
            :formatter="formatter"
          />
        </van-popup>
        <van-field
          readonly
          required
          clickable
          v-model="endTime"
          name="endTime"
          label="结束时间"
          placeholder="请选择结束时间"
          :rules="[{ required: true, message: '请选择结束时间' }]"
          @click="handleClickEndTime"
        />
        <van-popup v-model="showEndTime" position="bottom">
          <van-datetime-picker
            type="datetime"
            :min-date="minDate"
            @confirm="changeEndTime"
            title="选择结束时间"
            @cancel="showEndTime = false"
            :formatter="formatter"
          />
        </van-popup>
        <van-field
          v-model="num"
          required
          type="digit"
          label="参与人数"
          placeholder="请输入参与人数"
          :rules="[{ required: true, message: '请输入参与人数' }]"
        />
        <van-field required label="活动费用" :rules="[{ required: true, message: '请选择活动费用' }]">
          <template #input>
            <van-radio-group v-model="cost" direction="horizontal">
              <van-radio name="1">我请客</van-radio>
              <van-radio name="2">分摊价格</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <van-field
          v-model="rules"
          required
          rows="3"
          autosize
          label="规则说明"
          type="textarea"
          :rules="[{ required: true, message: '请输入规则说明' }]"
          maxlength="50"
          placeholder="请输入规则说明"
          show-word-limit
        />
        <div class="section" style="padding: 0.8rem">
          <div class="image-title">场地照片</div>
          <van-uploader v-model="files" name="files" :max-count="1" />
        </div>

        <div class="foot-button-yyd">
          <van-checkbox v-model="checked" shape="square"
            >已同意相关条款</van-checkbox
          >
          <van-button block type="info" round native-type="submit"
            >发布</van-button
          >
        </div>
      </van-form>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
import changGuanApi from 'api/changguan';
export default {
  data() {
    return {
      showAddressPicker: false,
      showStartTime: false,
      showEndTime: false,
      startMinDate: new Date(),
      minDate: "",
      title: "",
      address: {},
      startTime: "",
      endTime: "",
      num: null,
      cost: 1,
      rules: "",
      files: [],
      checked: false,
      addressList: [
        // { id: 1, address: "西湖公园" },
        // { id: 2, address: "云栖小镇运动场" },
        // { id: 3, address: "西湖区运动场" },
        // { id: 4, address: "阿里巴巴运动场" },
        // { id: 5, address: "云飞游泳馆" },
      ],
    };
  },
  mounted() {
    this.getAddressList();
  },
  methods: {
    formatDateTime(date) {
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      var d = date.getDate();
      d = d < 10 ? "0" + d : d;
      var h = date.getHours();
      h = h < 10 ? "0" + h : h;
      var minute = date.getMinutes();
      minute = minute < 10 ? "0" + minute : minute;
      console.log("y  ", y + "-" + m + "-" + d + " " + h + ":" + m + ":00");
      return y + "-" + m + "-" + d + " " + h + ":" + m + ":00";
    },
    onSubmit() {
        if(!checked){
            Toast('请同意相关条款！')
        }
    },
    addressConfirm(e) {
      this.address = e;
      this.showAddressPicker = false;
    },
    changeStartTime(e) {
      this.startTime = this.formatDateTime(e);
      this.minDate = new Date(e);
      this.endTime = "";
      this.showStartTime = false;
    },
    changeEndTime(e) {
      this.endTime = this.formatDateTime(e);
      this.showEndTime = false;
    },

    handleClickEndTime() {
      if (this.startTime) {
        this.showEndTime = true;
      } else {
        Toast("请先选择开始时间！");
      }
    },
    getAddressList() {
      changGuanApi
        .getChangGuanList({})
        .then((res) => {
          this.addressList = res.data;
        })
        .catch((err) => {
          console.log('error====>', err);
        });
    },
    formatter(type, val) {
      if (type === "year") {
        return val + "年";
      }
      if (type === "month") {
        return val + "月";
      }
      if (type === "day") {
        return val + "日";
      }
      if (type === "hour") {
        return val + "时";
      }
      if (type === "minute") {
        return val + "分";
      }
      return val;
    },
  },
};
</script>

<style lang="scss" scoped>
.yueyund-add-form {
  margin: 3.75rem 0.2rem 0.2rem;

  .section {
    width: 100%;
    border-top: 0.8rem solid #f6f7fa;
    .image-title {
      line-height: 2rem;

      font-size: 14px;
      color: #646566;
      letter-spacing: 0;
    }
  }

  .foot-button-yyd {
    margin: 0 1rem;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    > button {
      margin-top: 0.5rem;
    }
  }
}
</style>