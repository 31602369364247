<template>
  <div id="index">
    <div class="page-container">
      <div class="header-nav">
        <i @click="goBack"></i>
        <h1>关系转接</h1>
      </div>
      <div class="event-list-detial">
        <div class="desc">
          同学，你是否面临毕业要参加工作了、要升学了，不知道该怎么转团组织关系？
青年，你是否面临要换单位了，迁户口了，不知道该怎么转团组织关系？
工作单位没有团组织怎么办？
升学或就业去向暂未确定怎么办？
别慌！
这张一图读懂
帮你轻松解答
        </div>
        <div>
          <img src="http://dssq.ikeqiao.net/data/image/h5/images/ykyy/gxzj1.jpg" alt="" style="width: 100%">
          <img src="http://dssq.ikeqiao.net/data/image/h5/images/ykyy/gxzj2.jpg" alt="" style="width: 100%">
          <img src="http://dssq.ikeqiao.net/data/image/h5/images/ykyy/gxzj3.jpg" alt="" style="width: 100%">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";

export default {
  data() {
    return {
      eventDetail: {},
    };
  },
  components: {},
  mounted() {
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
  }
};
</script>

<style lang="scss" scoped>
.page-container {
  background: #f6f7fa;
  // padding: 0.6rem 0;
  height: calc(100vh - 40px);
  width: 100vw;
  margin-top: 40px;
  .event-list-detial{
    padding: 16px;
    background: #fff;
    .desc{
      line-height: 40px;
      font-size: 16px;
      color: rgba(0,0,0,0.85);
    }
    .time{
        line-height: 20px;
        font-size: 12px;
        color: #707070;
    }
  }
}
</style>
