<template>
  <div class="service-container">
    <van-nav-bar
      class="service-nav-bar"
      title="惠服务"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="service-body-container">
      <div class="image-bar">惠 服务</div>
      <!-- 按钮栏 -->
      <div class="icon-bar">
        <div
          v-for="(icon, index) in iconList"
          @click="icon.toast ? showToast(icon.toast) : gotoLink(icon.path)"
          :key="`${icon.name}_${index}`"
        >
          <img class="icon" :src="icon.icon" :alt="icon.name" />
          <p>{{ icon.name }}</p>
        </div>
      </div>
      <!-- 培训地图 -->
      <!-- <div class="coin-bar-con">
        <div class="coin-bar">
          <h1>培训地图</h1>
          <h3>汇聚周边优质培训机构</h3>
          <button>查看</button>
        </div>
      </div> -->
      <!-- 最新活动 -->
      <!-- <div class="card-bar">
        <div class="card-bar-title">
          <span class="text-title">热门课程<i class="hot"></i></span>
          <span class="text-more">
            更多
            <van-icon name="arrow" color="rgba(0,0,0,0.45)" size="14px" />
          </span>
        </div>
        <div class="card-bar-content">
          <courseList></courseList>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import homeApi from "api/home";
import { mapMutations, mapState } from "vuex";
import courseList from "./courseList.vue";
export default {
  data() {
    return {
      iconList: [
        {
          name: "入园入学",
          icon: require('../../../assets/images/educate/rxry.png'),
          path: 'guideIndex?type=入园入学',
        },
        {
          name: "入学缴费",
          icon: require('../../../assets/images/educate/rxjf.png'),
          path: 'guideIndex?type=入学缴费'
        },
        {
          name: "学籍查询",
          icon: require('../../../assets/images/educate/xjcx.png'),
          path: 'guideIndex?type=学籍查询'
        },
        {
          name: "学位查询",
          icon: require('../../../assets/images/educate/xwcx.png'),
          path: 'guideIndex?type=学位查询'
        },
      ],
    };
  },
  computed: {},
  // components: { courseList },
  mounted() {},
  watch: {},
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    handleTab() {},
    gotoLink(path) {
      if (path) {
        this.$router.push(path);
      }
    },
    showToast(message) {
      this.$toast(message);
    }
  },
};
</script>

<style lang="scss" scoped>
.service-container {
  height: 100vh;
  width: 100vw;
  .service-nav-bar {
    background-image: linear-gradient(90deg, #31edc5 0%, #3a9ef8 100%);
    ::v-deep .van-nav-bar__title {
      color: white;
    }
    ::v-deep .van-icon {
      color: white;
    }
  }
  .service-body-container {
    height: calc(100vh - 46px);
    width: 100vw;
    padding-bottom: 10px;
    background: url("http://dssq.ikeqiao.net/data/image/h5/images/coordination/coordination-bg.png") no-repeat;
    background-size: 100% 100%;
    overflow-y: auto;
    overflow-x: hidden;

    .image-bar {
      width: 100%;
      height: 176px;
      background: url("http://dssq.ikeqiao.net/data/image/h5/images/service/service-bar.png") no-repeat;
      background-size: 100% 100%;
      font-size: 32px;
      color: #ffffff;
      padding: 38px 34px;
      line-height: 32px;
      text-shadow: 0 2px 4px rgba(0, 0, 0, 0.13);
      font-weight: 600;
    }

    .icon-bar {
      margin: 10px;
      width: calc(100% - 20px);
      height: 108px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      background: #fff;
      border-radius: 4px;
      > div {
        width: 20%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 15px;
        .icon {
          width: 47px;
          height: 47px;
          margin-bottom: 5px;
        }
        > p {
          text-align: center;
          font-size: 10px;
          font-weight: 400;
        }
      }
    }

    .coin-bar-con {
      display: flex;
      .coin-bar {
        width: 100%;
        margin: 10px;
        height: 100px;
        background: url("http://dssq.ikeqiao.net/data/image/h5/images/service/map-bar.png") no-repeat;
        background-size: 100% 100%;
        padding: 10px;
        color: #fff;
        > h1 {
          font-size: 16px;
          letter-spacing: 0;
          line-height: 32px;
          text-shadow: 0 2px 4px rgba(0, 0, 0, 0.13);
          font-weight: 600;
        }
        > h3 {
          font-size: 12px;
          letter-spacing: 0.92px;
          text-align: left;
          font-weight: 200;
        }
        > button {
          border: 0.5px solid #ffffff;
          box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.07);
          border-radius: 14px;
          padding: 2px 8px;
          font-size: 12px;
          letter-spacing: 0.92px;
          text-align: center;
          background: none;
          margin-top: 10px;
        }
      }

      .map {
        background: url("http://dssq.ikeqiao.net/data/image/h5/images/building/map-bg.png") no-repeat;
        background-size: 100% 100%;
        margin-left: 0;
      }
    }

    .card-bar {
      width: calc(100% - 20px);
      margin: 10px;
      border-radius: 10px;
      .card-bar-title {
        width: 100%;
        height: 33px;
        padding: 10px;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        .text-title {
          font-size: 16px;
          color: #4479ed;
          width: 50%;
          font-weight: 500;
          .hot {
            width: 21px;
            height: 12px;
            background: url(http://dssq.ikeqiao.net/data/image/h5/images/service/hot.png) no-repeat;
            background-size: 100% 100%;
            display: inline-block;
            margin-left: 5px;
          }
        }
        .text-more {
          color: rgba(0, 0, 0, 0.45);
          font-size: 14px;
          float: right;
          display: flex;
          align-items: center;
        }
      }
      .card-bar-content {
        width: 100%;
      }
    }
  }
}
</style>
