import service from "utils/service";

const changGuanApi = {
  getChangGuanList: (params) => {
    return service.post("/venue/pageList", params);
  },
  getVenueListType: (params) => {
    return service.get("/venue/listType", params);
  },
  getReserveByVenueId: (params) => {
    return service.get("/venueReserve/getReserveByVenueId", {params});
  },
  getSports: (params) => {
    return service.get("/dataProvide/getSport", {params});
  },
  previewChangGuan: params => {
    return service.post('/venueReserve/add', params);
  }, // 场馆预约
  getReserveById: params => {
    return service.get('/venueReserve/getReserveByVenueId', { params });
  }, // 根据场馆id查询近七天预约信息
  getUserReserve: params => {
    return service.get('/venueReserve/getUserReserve', { params });
  }, // 查询当前用户预约信息
  removeReserve: params => {
    return service.get('/venueReserve/remove', { params });
  }, // 删除预约记录
};
export default changGuanApi;
