<template>
  <div id="index">
    <div class="page-container">
      <div class="header-nav">
        <i @click="goBack"></i>
        <h1>医疗服务</h1>
      </div>
      <div class="park-main">
        <div class="banner" style="height: 10rem">
          <img style="height: 10rem" src="http://dssq.ikeqiao.net/data/image/h5/images/banner/ylfw_banner.png" />
        </div>
        <div class="prop-nav clearfix">
          <ul>
            <li @click="turnToUrl('19')"><i class="ylfw-yygh"></i><span>预约挂号</span></li>
            <li @click="turnToUrl('2')"><i class="ylfw-mbccf"></i><span>慢病长处方</span></li>
            <li @click="turnToUrl('1')"><i class="ylfw-hlwcl"></i><span>互联网+护理</span></li>
            <!-- <li><i @click="turnToUrl('22')" class="ylfw-yyjc"></i><span>医技预约</span></li> -->
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { openUrl } from 'utils/index';
export default {
  data() {
    return {};
  },
  components: {},
  mounted() {},
  methods: {
    gotoLink(path) {
      this.$router.push(path);
    },
    goBack() {
      this.$router.go(-1);
    },
    turnToUrl(url) {
      openUrl(url);
    }
  },
};
</script>

<style lang="scss">
@import "~assets/scss/index.scss";
.ylfw-yygh {
  background: url('http://dssq.ikeqiao.net/data/image/h5/images/jk/sub/ylfw_yygh.png') no-repeat;
  background-size: 100%;
}
.ylfw-yyjc {
  background: url('http://dssq.ikeqiao.net/data/image/h5/images/jk/sub/ylfw_yyjc.png') no-repeat;
  background-size: 100%;
}
.ylfw-hlwcl {
  background: url('http://dssq.ikeqiao.net/data/image/h5/images/jk/sub/ylfw_hlwcl.png') no-repeat;
  background-size: 100%;
}
.ylfw-mbccf {
  background: url('http://dssq.ikeqiao.net/data/image/h5/images/jk/sub/ylfw_mbccf.png') no-repeat;
  background-size: 100%;
}
</style>
