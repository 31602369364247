<template>
  <div class="micro-energy-container">
    <van-nav-bar
      title="微动力"
      left-arrow
      @click-left="goBack"
    />
    <div class="micro-energy-body-container">
      <div class="image-bar"></div>
      <!-- 青年荣誉 -->
      <div class="rongyuyijia-con">
        <div class="rongyu-title">青年<br />荣誉</div>
        <div class="scroll-con" ref="honor">
          <div class="honor-list">
            <div class="honor-list-item" v-for="item in honorList" :key="item">
              <i class="honor-tag"></i>
              <h3>{{item}}</h3>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="scroll-con" ref="honor">
        <div class="honor-list">
          <div class="honor-list-item" v-for="item in honorList" :key="item">
            <i class="honor-tag"></i>
            <h3>{{item}}</h3>
          </div>
        </div>
      </div> -->
      <!-- 按钮栏 -->
      <div class="icon-bar">
        <div v-for="(icon, index) in iconList" @click="icon.url ? openUrl(icon.url) : gotoLink(icon.path)" :key="`${icon.name}_${index}`">
          <img class="icon" :src="icon.icon" :alt="icon.name">
          <p>{{icon.name}}</p>
        </div>
      </div>
      <!-- 青团快讯 -->
      <div class="card-bar">
        <div class="card-bar-title">
          <div>
            <span class="title-text">青团快讯</span>
          </div>
          <!-- <div @click="gotoLink('moreBuilding')">
            <span class="more">更多</span>
            <img src="http://dssq.ikeqiao.net/data/image/h5/images/home/more.png" alt="">
          </div> -->
        </div>
        <div class="card-bar-content">
          <ul>
              <li class="fl-item" v-for="(item, index) in list" :key="index" @click="openArticleUrl(item)">
                <div class="fl-title">
                  <div>
                    <h1>{{item.title}}</h1>
                    <div class="fl-desc">
                      <i class="time"></i>
                      <span style="margin-right: 10px;">{{item.updateTime}}</span>
                      <i class="view"></i>
                      <span>{{item.visitsUp}}</span>
                    </div>
                  </div>
                  <img v-if="item.cover" :src="item.cover" alt="">
                </div>
              </li>
            </ul>
        </div>
      </div>
      <!-- 百名青年讲党史 -->
      <div class="card-bar">
        <div class="card-bar-title">
          <div>
            <!-- <span class="title-bar"> </span> -->
            <span class="title-text">百名青年讲党史</span>
          </div>
          <!-- <div @click="gotoLink('moreBuilding')">
            <span class="more">更多</span>
            <img src="http://dssq.ikeqiao.net/data/image/h5/images/home/more.png" alt="">
          </div> -->
        </div>
        <div class="card-bar-content">
          <ul>
              <li class="fl-item" v-for="(item, index) in list2" :key="index" @click="openArticleUrl(item)">
                <div class="fl-title">
                  <div>
                    <h1>{{item.title}}</h1>
                    <div class="fl-desc">
                      <i class="time"></i>
                      <span style="margin-right: 10px;">{{item.updateTime}}</span>
                      <i class="view"></i>
                      <span>{{item.visitsUp}}</span>
                    </div>
                  </div>
                  <img v-if="item.cover" :src="item.cover" alt="">
                </div>
              </li>
            </ul>
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>
import Api from 'api';
export default {
  data() {
    return {
      list: [
        // {
        //   id: 0,
        //   title: "团省委副书记何黎斌在绍调研共青团工作！",
        //   update: "2021-04-24",
        //   view: 43,
        //   url: 'https://mp.weixin.qq.com/s/gMbF7NPtISqebowLZ4EHBA',
        // },
        // {
        //   id: 1,
        //   title: "【瓜渚专栏】以青春之名 筑梦未来——“家燕归巢”暑期实践活动实习札记",
        //   update: "2021-08-16",
        //   view: 22,
        //   url: 'https://mp.weixin.qq.com/s/GnYk58y5X_Ia2tOxxHGEgA',
        // },
        // {
        //   id: 2,
        //   title: "【瓜渚专栏】第九期假日爱心学校圆满收官啦！",
        //   update: "2021-08-17",
        //   view: 53,
        //   url: 'https://mp.weixin.qq.com/s/QAjcgedVrcfOmsZBUGmlog',
        // },
        // {
        //   id: 3,
        //   title: "【瓜渚专栏】“我”将成为“你”，志愿会延续 | 三五学雷锋，工青志愿行",
        //   update: "2021-03-03",
        //   view: 43,
        //   url: 'https://mp.weixin.qq.com/s/MLJqs2gOhBdSW_NXoYLu5A',
        // },
      ],
      list2: [
        // {
        //   id: 1,
        //   title: "“百名青年讲党史”系列 | 第一讲：五四运动 唤醒群众",
        //   update: "2021-05-12",
        //   view: 13,
        //   url: 'https://mp.weixin.qq.com/s/H-r0OdXe6lnCAxy5JsQHaA',
        // },
        // {
        //   id: 2,
        //   title: "“百名青年讲党史”系列 | 第二讲：首译宣言 传播真理",
        //   update: "2021-05-15",
        //   view: 66,
        //   url: 'https://mp.weixin.qq.com/s/60TP6y8YyldUO6aysIpJjw',
        // },
        // {
        //   id: 3,
        //   title: "“百名青年讲党史”系列 | 第三讲：酝酿建党 各地响应",
        //   update: "2021-05-19",
        //   view: 25,
        //   url: 'https://mp.weixin.qq.com/s/8VtHcSquZvpj_SDmsvP8hA',
        // },
        // {
        //   id: 4,
        //   title: "“百名青年讲党史”系列 | 第四讲：一大首聚 开天辟地",
        //   update: "2021-05-21",
        //   view: 19,
        //   url: 'https://mp.weixin.qq.com/s/pf-FcMIZW698QjBax6lZMg',
        // },
      ],
      iconList: [
        {
          name: '青团架构',
          icon: 'http://dssq.ikeqiao.net/data/image/h5/images/hong/qtjg.png',
          path: 'qingtuanjiagou'
        },
        {
          name: '青年学习',
          icon: 'http://dssq.ikeqiao.net/data/image/h5/images/hong/qnxx.png',
          url: 'https://qczj.h5yunban.com/answer_apply_zj/index.php?id=338344&from=groupmessage&isappinstalled=0',
        },
        {
          name: '青年议事厅',
          icon: 'http://dssq.ikeqiao.net/data/image/h5/images/hong/qnyst.png',
          path: 'qingnianyishiting',
        },
        {
          name: '关系转接',
          icon: 'http://dssq.ikeqiao.net/data/image/h5/images/hong/gxzj.png',
          path: 'gxzj'
        },
        {
          name: '微动力志愿',
          icon: 'http://dssq.ikeqiao.net/data/image/h5/images/hong/wdlzy.png',
          url: 'https://www.wedo0575.com/'
        },
      ],
      honorList: ['全国五四红旗团支部', '第四届中国青年志愿服务项目大赛铜奖', '浙江省五四红旗团支部', '浙江省先进团支部', '浙江省第八批“青少年维权岗”创建单位', '践行三服务，青春显担当共青团项目大赛决赛团(总)支部组别优秀奖', '诸暨市青年大学习先进单位'],
      honorScroll: 0,
      timer: null,
    };
  },
  components: {},
  mounted() {
    this.createInterval();
    this.getDataList(10);
    this.getDataList(11);
  },
  beforeDestroy() {
    clearInterval(this.timer);
    this.timer = null;
  },
  methods: {
    gotoLink(path) {
      if(path.indexOf('http') === -1) {
        this.$router.push(path);
      } else {
        window.open(path);
      }
    },
    goBack() {
      this.$router.go(-1);
    },
    showAlert() {
      this.$messagebox("提示", "系统正在建设中，尽情期待~");
    },
    openUrl(url) {
      window.location.href = url;
    },
    openArticleUrl(data) {
      if (data.content && data.contentType === 1) {
        // 增加浏览量
        Api.addArticleVisit({ id: data.id }).then((res) => {});
        window.location.href = data.content;
      } else if (data.contentType === 0) {
        this.$router.push(`resouArticleDetail?id=${data.id}`);
      }
    },
    getDataList(type) {
      Api.getHongArticleList({
        page: 1,
        pageSize: 4,
        type,
      })
      .then((res) => {
        const { records = [] } = res.data || {};
        if(type === 10) {
          this.list = records;
        }
        if(type === 11) {
          this.list2 = records;
        }
      })
      .catch((err) => {
        console.log('error====>', err);
      });
    },
    createInterval() {
      // 滚动荣誉计时器
      this.timer = setInterval(() => {
        this.honorScroll = (this.honorScroll + 0.08) % (24 * (this.honorList.length-1.9));
        this.$refs.honor.scrollTop = this.honorScroll;
      }, 10);
    },
  },
};
</script>

<style lang="scss" scope>
.micro-energy-container {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: url('http://dssq.ikeqiao.net/data/image/h5/images/coordination/coordination-bg.png') no-repeat;
  background-size: 100% 100%;
  .micro-energy-body-container {
    height: calc(100vh - 46px);
    width: 100vw;
    overflow-y: auto;
    overflow-x: hidden;
    .image-bar {
      width: 100%;
      height: 138px;
      background: url('http://dssq.ikeqiao.net/data/image/h5/images/hong/weidongli.png') no-repeat;
      background-size: 100% 100%;
    }
    .rongyuyijia-con {
      width: calc(100vw - 20px);
      margin: 10px;
      height: 92px;
      border-radius: 4px;
      background: url('http://dssq.ikeqiao.net/data/image/h5/images/hong/qingnianrongyu.png') no-repeat;
      background-size: 100% 100%;
      padding: 15px;
      display: flex;
      .rongyu-title {
        width: 5rem;
        display: flex;
        align-items: center;
        margin-left: 5px;
        font-size: 20px;
        line-height: 22px;
        color: #4479ED;
      }
      .scroll-con {
        width: 100%;
        height: 100%;
        // padding: 5px 1rem;
        overflow-x: hidden;
        overflow-y: scroll;
        transition: all 1s;
        .honor-list {
          width: 100%;
          .honor-list-item {
            width: 100%;
            display: flex;
            align-items: center;
            min-height: 24px;
            color: rgba(0,0,0,0.85);
            .honor-tag {
              width: 24px;
              height: 24px;
              background: url('http://dssq.ikeqiao.net/data/image/h5/images/hong/rongyu.png') no-repeat;
              background-size: 100% 100%;
              display: inline-block;
              margin-right: 6px;
            }
            >h3 {
              word-break: break-all;
              flex: 1;
              font-size: 16px;
              font-weight: 400;
              line-height: 18px;
            }
            >span {
              width: 60px;
              text-align: right;
              font-size: 14px;
            }
          }
        }
      }
      
    }
    .icon-bar {
      margin: 10px;
      width: calc(100% - 20px);
      height: 108px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      background: #FFF;
      border-radius: 4px;
      >div {
        width: 20%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 15px;
        .icon {
          width: 47px;
          height: 47px;
          margin-bottom: 5px;
        }
        >p {
          text-align: center;
          font-size: 10px;
          font-weight: 400;
        }
      }
    }

    .card-bar {
      width: calc(100% - 20px);
      margin: 10px;
      border-radius: 4px;
      background-color: #FFF;
      .card-bar-title {
        width: 100%;
        // height: 2rem;
        padding: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        > div {
          height: 100%;
          display: flex;
          align-items: center;
          .title-bar {
            height: 18px;
            width: 4px;
            background: #4479ED;
            margin-right: 10px;
          }
          .title-text {
            font-size: 16px;
            color: rgba(0,0,0,0.85);
          }
          .more {
            font-size: 14px;
            color: rgba(0,0,0,0.45);
          }
          > img {
            height: 12px;
            width: 12px;
          }
          i {
            color: #4479ED;
          }
        }
      }
      .card-bar-content {
        width: 100%;
        .fl-item {
          margin: 10px 10px 0 10px;
          padding: 10px 0;
          .fl-title {
            display: flex;
            align-items: flex-start;
            >img {
              width: 7rem;
              margin-left: 10px;
            }
            >div {
              flex: 1;
              >h1 {
                font-size: 16px;
                line-height: 32px;
                color: rgba(0,0,0,0.85);
                font-weight: 500;
              }
              .fl-desc {
                display: flex;
                height: 24px;
                align-items: center;
                font-size: 12px;
                color: rgba(0,0,0,0.45);
                .time, .view {
                  width: 16px;
                  height: 16px;
                  display: inline-block;
                  background: url(http://dssq.ikeqiao.net/data/image/h5/images/hong/time.png) no-repeat;
                  background-size: 100% 100%;
                }
                .view {
                  background: url(http://dssq.ikeqiao.net/data/image/h5/images/hong/view.png) no-repeat;
                  background-size: 100% 100%;
                }
              }
            }
            
          }
          
        }
        
      }
    }
  }
}
</style>
