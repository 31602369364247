<template>
  <div id="index">
    <div class="page-container">
      <div class="header-nav">
        <i @click="goBack"></i>
        <h1>发布服务</h1>
      </div>
      <van-form @submit="onSubmit">
        <van-field
          v-model="invitationTitle"
          name="invitationTitle"
          label="服务标题"
          placeholder="请输入"
          input-align="right"
          :rules="[{ required: true, message: '请填写服务标题' }]"
        />
        <van-field
          v-model="createUserName"
          name="createUserName"
          label="发起人"
          placeholder="请输入"
          input-align="right"
        />
        <van-field
          v-model="createUserPhone"
          name="createUserPhone"
          label="联系电话"
          placeholder="请输入"
          input-align="right"
          :rules="[{ required: true, message: '请填写联系电话' }]"
        />
        <van-field
          v-model="detailPlace"
          name="detailPlace"
          is-link
          readonly
          label="具体地址"
          placeholder="请选择"
          @click="show = true"
          :rules="[{ required: true, message: '请选择具体地址' }]"
          input-align="right"
        />
        <van-popup v-model="show" round position="bottom">
          <van-cascader
            title="请选择"
            active-color="#1989fa"
            :options="options"
            :field-names="fieldNames"
            @close="show = false"
            @finish="onFinish"
          />
        </van-popup>
        <div class="section-textArea">
          <div class="section-label">服务详情</div>
          <van-field
            v-model="invitationContent"
            name="invitationContent"
            rows="2"
            autosize
            label
            type="textarea"
            maxlength="50"
            placeholder="请输入"
            show-word-limit
          />
        </div>
        <div class="section" style="padding: 0.8rem;">
          <div class="image-title">图片</div>
          <van-uploader v-model="invitationImage" name="invitationImage" multiple :max-count="4" />
        </div>
        <div class="foot-button">
          <van-button block type="info" native-type="submit">立即发布</van-button>
        </div>
      </van-form>
    </div>
  </div>
</template>

<script>
import commonApi from "api/common";
import llyjrApi from "api/llyjr";
import nrbApi from "api/llyjr/nrb";
import { Toast } from "vant";
import { mapState } from "vuex";
import context from '@/main';

export default {
  data() {
    return {
      invitationTitle: "",
      createUserName: "",
      createUserPhone: "",
      detailPlace: "",
      invitationContent: "",
      invitationImage: [],
      // 级联
      show: false,
      fieldNames: {
        text: "value",
        value: "value",
        children: "children"
      },
      options: []
    };
  },
  computed: {
    ...mapState({
      userInfo: state =>
        (state.login.userInfo.nickname ? state.login.userInfo : null) ||
        (context.getSessionData("userInfo"))
    })
  },
  components: {},
  mounted() {
    this.createUserName = this.userInfo ? this.userInfo.nickname : "";
    commonApi.getAddress().then(res => {
      this.options = res.data;
    });
  },
  methods: {
    gotoLink(path) {
      this.$router.push(path);
    },
    goBack() {
      this.$router.go(-1);
    },
    // 全部选项选择完毕后，会触发 finish 事件
    onFinish({ selectedOptions }) {
      this.show = false;
      this.detailPlace = selectedOptions.map(option => option.value).join("");
    },
    pushAbilityTeam(values) {
      nrbApi.pushAbilityTeam(values).then(res => {
        if (res.data == 1) {
          Toast.success("提交成功");
          this.gotoLink("nrb");
        }
      });
    },
    onSubmit(values) {
      if (this.invitationImage.length) {
        const formData = new FormData();
        this.invitationImage.forEach(file => {
          formData.append("files", file.file);
        });
        commonApi.uploadMultiFile(formData).then(res => {
          if (res.flag) {
            values.invitationImage = res.data;
            this.pushAbilityTeam(values);
          }
        });
      } else {
        this.pushAbilityTeam(values);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.page-container {
  background: #ffffff;
  padding: 0.6rem 0;
  height: calc(100vh - 40px);
  width: 100vw;
  margin-top: 40px;

  .section-textArea {
    width: 100%;
    border-top: 0.8rem solid #f6f7fa;

    .section-label {
      padding: 0.8rem 0 0 0.8rem;
      line-height: 2rem;

      font-size: 14px;
      color: #646566;
      letter-spacing: 0;
    }
    .van-cell {
      padding-top: 0;
    }
  }
  .section {
    width: 100%;
    border-top: 0.8rem solid #f6f7fa;
    .image-title {
      line-height: 2rem;

      font-size: 14px;
      color: #646566;
      letter-spacing: 0;
    }
  }

  .foot-button {
    position: fixed;
    bottom: 0.8rem;
    right: 0.8rem;
    left: 0.8rem;
    height: 2.4rem;
    line-height: 2.4rem;
    text-align: center;
    background: #4479ed;
    border-radius: 8px;
    color: #ffffff;

    font-size: 18px;
    letter-spacing: 0;
  }
}
</style>
