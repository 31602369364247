<template>
    <div
    class="zxzxIndex"
    style="background: #fff; height: 100vh; padding-top: 2.7rem"
  >
    <div class="header-nav">
      <i @click="goBack"></i>
      <h1>{{titleName}}</h1>
    </div>
    <div class="erweima">
      <img :src="`http://dssq.ikeqiao.net/data/image/ydy/${titleName}.png`" alt="">
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showData: {},
      titleName: '',
    };
  },
  mounted(opt) {
    const { type } = this.$route.query;
    this.titleName = type;
  },
  methods: {
    goBack() {
      this.$router.go('-1');
    },
  },
}
</script>

<style lang="less" scoped>
.zxzxIndex {
  position: relative;
  display: flex;
  flex-direction: column;
  .erweima{
    img{
      width: 100%;
    }
  }
}
</style>