<template>
    <!-- 2021年第二季度数据动态情况 -->
    <Echart
      :options="options"
      id="basicLine"
      height="100%"
      width="100%"
    ></Echart>
</template>

<script>
import Echart from '@/common/echart';
import moment from 'moment';
export default {
  name: 'StatisticLineChart1',
  data () {
    return {
      options: {},
      moduleList: ['一起拼多多', '邻里陪聊', '红脉动', '共享工具箱','邻里聊', '享协同', '惠学习']
    };
  },
  components: {
    Echart,
  },
  props: {
    cdata: {
      type: Array,
      default: () => ([])
    },
  },
  computed: {
    dateList() {
      const result = [];
      for(let i = 0;i < 7; i += 1) {
        const startDay = moment().week(moment().week()).startOf('week');
        const current = startDay.add(i, 'days');
        const day = current.format('MM.DD');
        result.push(day);
      }
      return result;
    },
  },
  watch: {
    cdata: {
      handler (newData) {
        this.options = {
          title: "",
          tooltip: {
            trigger: 'axis',
          },
          grid: {
            left: '2%',
            right: '2%',
            bottom: '2%',
            top: '2%',
            containLabel: true
          },
          xAxis: {
            type: 'category',
            boundaryGap: ['10%', '10%'],
            data: this.cdata.map(item => item.applyName),
            axisLabel: {
              show: true,
              width: 5,
              textStyle: {
                color: 'rgba(0,0,0,0.65)',
                fontSize: 12
              },
              overflow: 'breakAll',
              interval: 0,
              formatter(value) {
                //debugger
                let ret = ''; //拼接加\n返回的类目项
                const maxLength = 3; //每项显示文字个数
                const valLength = value.length; //X轴类目项的文字个数
                const rowN = Math.ceil(valLength / maxLength); //类目项需要换行的行数
                if (rowN > 1) {
                  for (let i = 0; i < rowN; i += 1) {
                    let temp = ''; //每次截取的字符串
                    const start = i * maxLength; //开始截取的位置
                    const end = start + maxLength; //结束截取的位置
                    //这里也可以加一个是否是最后一行的判断，但是不加也没有影响，那就不加吧
                    temp = `${value.substring(start, end)}\n`;
                    ret += temp; //凭借最终的字符串
                  }
                  return ret;
                }
                return value;
              },
            },
            
          },
          yAxis: [{
            type: 'value',
            name: '人',
            nameTextStyle: {
              color: 'rgba(0,0,0,0.45)',
              fontSize: 12,
            },
            splitLine: {
              show: true,
              lineStyle: {
                type: 'dotted'
              }
            },
            axisLine: {
              show: false
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: 'rgba(0,0,0,0.65)',
                fontSize: 12
              }
            }
          }],
          series: [
            {
              name: "活跃用户",
              type: "bar",
              barWidth: 14,
              itemStyle: {
                normal: {
                  color: {
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [{
                        offset: 0, color: '#4479ED' // 0% 处的颜色
                    }, {
                        offset: 1, color: '#5396FF' // 100% 处的颜色
                    }],
                    global: false // 缺省为 false
                  },
                },
                borderRadius: [5, 5, 5, 5]
              },
              data: this.cdata.map(item => item.activeNumber),
            },
          ]
        }
      },
      immediate: true,
      deep: true
    },
  },
}
</script>