<template>
  <div id="index">
    <div class="page-container">
      <div class="header-nav">
        <i @click="goBack"></i>
        <h1>支部介绍</h1>
      </div>
      <div class="party-main">
        <div class="party-tab"><i></i>东盛社区党支部</div>
        <div class="info-title">基本信息</div>
        <div class="content-info">
          <div class="info-list">
            <div class="info-item">
              <i class="icon1"></i>
              <div><b>3</b><span>领导班子数量</span></div>
            </div>
            <div class="info-item">
              <i class="icon2"></i>
              <div><b>99</b><span>党员数量</span></div>
            </div>
          </div>
        </div>
        <div class="info-title">领导班子</div>
        <div class="imgList">
          <ul>
            <li>
              <img src="http://dssq.ikeqiao.net/data/image/h5/images/photo-temp1.jpg" />
              <div>张晓鸿<br />支部书记</div>
            </li>
            <li>
              <img src="http://dssq.ikeqiao.net/data/image/h5/images/photo-temp2.jpg" />
              <div>邱琴琴<br />副书记</div>
            </li>
            <li>
              <img src="http://dssq.ikeqiao.net/data/image/h5/images/photo-temp3.jpg" />
              <div>李银曼<br />委员</div>
            </li>
          </ul>
        </div>
        <div class="info-title">支部介绍</div>
        <div class="info-detail">
          <div>组织名称：东盛社区党支部</div>
          <div>组织类别：党支部</div>
          <div>组织管理员：张晓鸿</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  components: {},
  mounted() {},
  methods: {
    gotoLink(path) {
      this.$router.push(path);
    }, goBack(){
          this.$router.go(-1);
      }
  },
};
</script>

<style lang="scss">
@import "~assets/scss/index.scss";
</style>
