<template>
  <div id="index">
    <div class="page-container paddclose">
      <div class="header-nav">
        <i @click="gotoLink('garbage')"></i>
        <h1>易腐垃圾</h1>
      </div>
      <div class="gar-info">
        <img src="http://dssq.ikeqiao.net/data/image/h5/images/yf-list.png" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  components: {},
  mounted() {},
  methods: {
    gotoLink(path) {
      this.$router.push(path);
    },
  },
};
</script>

<style lang="scss">
@import "~assets/scss/index.scss";
</style>
