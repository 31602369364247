<template>
  <van-list
    v-model="loading"
    :finished="listFinished"
    finished-text="没有更多了"
    @load="onLoad"
    class="court-list"
    v-if="dataList && dataList.length > 0"
  >
    <ul>
      <li v-for="(item, index) in dataList" :key="index">
        <div class="court-list-top">
          <span>{{typeObj[item.litigationType]}}</span>
          <span :style="{color: colors[item.status]}">{{statusList[item.status]}}</span>
        </div>
        <div class="court-list-row van-multi-ellipsis--l3">
          <span>{{item.matter}}</span>
        </div>
        <div class="court-list-row" style="display: flex;width: 100%;flex-wrap: wrap;">
          <van-image
            width="30%"
            height="28vw"
            fit="cover"
            position="center"
            style="margin: 0 1.5% 10px"
            v-for="image in (item.attachedPicture ? item.attachedPicture.split('#') : [])"
            :key="image"
            :src="image"
            />
        </div>
        <div class="court-list-row">
          <span class="label">提交时间：</span>
          <span>{{item.submitTime}}</span>
        </div>
        <!-- <div class="court-list-row">
          <span class="label">归还时间：</span>
          <span>{{item.returnTime}}</span>
        </div>
        <div class="court-list-row">
          <span class="label">借用用途：</span>
          <span>{{item.rentReason}}</span>
        </div> -->
        <div class="court-list-footer">
          <div class="btn" style="color: #ce1a21"  v-if="item.status === '0'" @click="handleCancel(item)">撤回诉求</div>
          <div class="btn" style="color: #ce1a21" v-else @click="handleCancel(item)">删除</div>
          <div class="btn"  style="color: #3E71F9;border-left: 1px solid rgba(0,0,0,0.1);" @click="gotoLink(`courtDetail?id=${item.litigationId}`)">查看详情</div>
        </div>
      </li>
    </ul>
  </van-list>
  <div v-else >
    <van-empty description="暂无数据" />
    <van-button type="info" round style="width: 40%;margin-left: 30%;" @click="$router.push('/court/add')">发起诉讼</van-button>
  </div>
</template>
<script>
import courtApi from "api/court";
import { mapState } from 'vuex';
import {data} from 'assets/json/court';
export default {
  name: 'borrowList',
  data() {
    return {
      current: 1,
      pageSize: 50,
      data: data,
      total: 0,
      dataList: [],
      loading: false,
      listFinished: false,
      colors: {
        '3': '#4479ED',
        '0': '#E5B20A',
        '2': '#ce1a21',
        '1': '#4479ED',
      },
      statusList: {
        '0': '审核中',
        '1': '已通过',
        '2': '已驳回',
      },
      typeObj: {
        '0': '经济纠纷',
        '1': '宠物纠纷',
        '2': '买卖纠纷',
        '3': '刑事纠纷'
      }
    }
  },
  props: {
    type: {
      type: String,
      default: function() {
        return '';
      }
    },
  },
  computed: {
    ...mapState({
      selectedTab: (state) => state.llyjr.selectedTab,
    }),
    belongOwn() {
      return this.selectedTab === '我的发布' ? 'yes' : 'no'
    }
  },
  mounted() {
    this.getDataList();
    
  },
  methods: {
    onLoad() {
      this.getDataList({page: this.current});
      this.current += 1;
    },
    gotoLink(path) {
      console.log(path);
      
      this.$router.push(path);
    },
    handleCheck(data, status) {
      // courtApi.getCourtList({
      //   id: data.id,
      //   checked: status,
      // }).then(res => {
      //   if(res.flag) {
      //     this.$toast('处理成功');
      //     this.current = 1;
      //     this.getDataList({page: 1});
      //   }
      // })
    },
    getDataList({ page = this.current, pageSize = this.pageSize, status = this.type } = {}) {
      this.loading = false;
      courtApi
        .getCourtList({
          page,
          pageSize,
          status,
        })
        .then((res) => {
          console.log('res', res);
          
          this.loading = false;
          const { records = [], total = 0 } = res.data || {};
          if(page === 1) {
            this.dataList = records;
          } else {
            this.dataList = Array.from(new Set(this.dataList.concat(records)))
          }
          this.total = total;
          if (this.dataList.length >= this.total) {
            // 数据全部加载完成
            this.listFinished = true;
          }
        })
        .catch((err) => {
          console.log('error====>', err);
          this.loading = false;
        });
    },
    handleGoto(data) {
      this.$router.push(`${this.type}Detail`);
    },
    handleCancel(data) {
      const type = data.status === '0' ? '撤回' : '删除';
      this.$dialog.confirm({
        title: '提示',
        message: `是否${type}本次诉求`,
        confirmButtonText: `立即${type}`
      }).then(() => {
        courtApi.cancelCourt({ litigationId: data.litigationId }).then(res => {
          if (res.data) {
            this.$toast(`${type}成功`);
            this.$router.go(-1);
          }
        })

      })
      .catch(() => {

      })
    }
  }
}
</script>
<style lang="scss" scoped>
.court-list {
  width: 100%;
  margin: 0 auto;
  padding: 0.6rem 0;
  border: none;
  li {
    padding: 0.3rem 0;
    // border-bottom: 1px solid #ddd;
    font: 500 0.9rem/1.4rem "microsoft yahei";
    background-color: #FFF;
    margin: 10px;
    border-radius: 4px;
    .court-list-top {
      height: 36px;
      line-height: 36px;
      display: flex;
      justify-content: space-between;
      border-bottom: 0.5px solid rgba(0,0,0,0.1);
      padding: 0 10px;
      margin-bottom: 10px;
      font-size: 16px;
    }
    .court-list-row {
      line-height: 2rem;
      color: rgab(0,0,0,0.85);
      font-size: 1rem;
      padding: 0 10px;
      font-weight: 400;
      .label {
        color: rgba(0,0,0,0.45)
      }
    }
    .court-list-footer {
      width: 100%;
      display: flex;
      border-top: 1px solid rgba(0,0,0,0.1);
      margin-top: 10px;
      .btn{
        width: calc(50% - 0.5px);
        text-align: center;
        height: 50px;
        line-height: 50px;
        font-size: 16px;
        color: #3E71F9;
        font-weight: 400;
      }
    }
    
  }
}
</style>