<template>
  <div class="sourceStore-container">
    <van-nav-bar
      class="hong-nav-bar"
      :title="pageTitle"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="pulse-list-body-container">
      <div class="pulse-sub" v-if="!groupId">
        <div class="sub">{{name}}</div>
        <div class="num">
          <b>{{ num }}</b>党员总数
        </div>
      </div>
      <div class="pulselist">
        <van-collapse v-model="activeNames">
          <van-collapse-item
            v-bind:title="item.groupName"
            v-bind:name="item.index"
            v-for="(item, index) in rowList"
            :key="index"
          >
            <ul class="clearfix">
              <li v-for="(v, i) in item.members" :key="i">
                <img :src="v.image" />
                <div>
                  <b>{{ v.userName }}</b>
                  <p v-if="v.education" style="margin-top: 10px;">学历：<span>{{v.education}}</span></p>
                  <p v-if="v.joinTime">入党时间：<span>{{v.joinTime}}</span></p>
                  <div v-if="v.otherOrganization && v.otherOrganization === '0'" class="item-footer">
                    <img src="http://dssq.ikeqiao.net/data/image/h5/images/hong/flag.png" alt="" />
                    <span>{{v.otherOrganizationName}}</span>
                  </div>
                </div>
              </li>
            </ul>
          </van-collapse-item>
        </van-collapse>
        <div class="van-list__finished-text">已经到底啦~</div>
      </div>
    </div>
  </div>
</template>

<script>
import api from 'api';
export default {
  data() {
    return {
      showIndex: 0,
      showPart: null,
      name: "",
      num: 36,
      activeNames: [],
      rowList: [],
      pageTitle: '党支部',
      groupId: '',
    };
  },
  components: {},
  mounted() {
    const { organizationId, title, groupId } = this.$route.query;
    this.showIndex = organizationId;
    this.showPart = title;
    this.groupId = groupId;
    if(title) { this.pageTitle = '楼道党小组';}
    this.getCommunityDetail({ organizationId: this.showIndex, groupId });
  },
  methods: {
    tip() {
      this.$message("页面正在建设中");
    },
    onClickLeft() {
      this.$router.go(-1);
    },
    gotoLink(path) {
      this.$router.push(path);
    },
    getCommunityDetail(params) {
      const { part } = params;
      api.getCoummunityDetail(params).then(res => {
        if(res.flag) {
          if(part && res.data.group) {
            // console.log('da', part, res.data.group, res.data.group.filter(item => item.groupName === part));
            
            this.rowList = res.data.group.filter(item => item.groupName === part);
            this.num = this.rowList[0].members.length;
            this.name = `${part}楼道党小组`;
          } else {
            this.rowList = res.data.group;
            this.num = res.data.memberNum;
            this.name = res.data.organizationName;
          }
          this.activeNames = [0]
        }
      })
    }
  },
};
</script>

<style lang="scss">
.sourceStore-container {
  height: 100vh;
  width: 100vw;

  .pulse-list-body-container {
    height: calc(100vh - 2.7rem);
    width: 100vw;
    padding-bottom: 10px;
    background: url("http://dssq.ikeqiao.net/data/image/h5/images/coordination/coordination-bg.png") no-repeat;
    background-size: 100% 100%;
    overflow-y: auto;
    overflow-x: hidden;

    .pulse-sub {
      width: 100%;
      padding: 0.5rem 0.8rem 0.8rem;
      background: url(http://dssq.ikeqiao.net/data/image/h5/images/hong/party-branch.png) no-repeat;
      background-size: 100% 100%;
      height: 8rem;
      .sub {
        margin-top: 1.2rem;
        font: 500 1rem/2rem "microsoft yahei";
        color: #fff;
      }
      .num {
        box-sizing: content-box;
        font: 500 0.95rem/1.6rem "microsoft yahei";
        color: #fff;
        display: inline-block;
        b {
          font-family: arial;
          font-size: 1.2rem;
          display: block;
        }
      }
    }

    .pulselist {
      padding: 0.5rem 0.8rem;
      .van-collapse {
        border-top: 0;
        .van-collapse-item {
          margin-bottom: 0.5rem;
          border-radius: 0.3rem;
          background: #fff;
          .van-cell,
          .van-collapse-item__content {
            padding: 0.5rem 0.6rem;
            background: transparent;
          }
          .van-cell__title {
            span {
              border-left: 4px solid #4479ed;
              padding-left: 0.4rem;
              color: #000;
            }
          }
        }
        .van-collapse-item__header {
          color: #000;
          font: 500 0.9rem/2.8rem "microsoft yahei";
          padding-left: 1rem;
        }
      }
      .van-list__finished-text {
        color: #333;
      }
      ul {
        li {
          display: flex;
          width: 100%;
          margin: 0 0.4rem;
          overflow: hidden;
          padding: 10px;
          border-bottom: 0.5px solid rgba(0,0,0,0.05);
          img {
            // height: 6rem;
            width: 5rem;
            height: 7rem;
            border-radius: 0.3rem;
          }
          div {
            font: 500 0.7rem/1.2rem "microsoft yahei";
            color: #333;
            white-space: nowrap;
            margin-left: 14px;
            b {
              font-size: 16px;
              color: rgba(0, 0, 0, 0.85);
            }
            p {
              color: rgba(0,0,0,0.65);
              line-height: 20px;
              span {
                color:rgba(0, 0, 0, 0.85);
              }
            }
            .item-footer {
              font-size: 12px;
              color: rgba(0, 0, 0, 0.85);
              margin: 5px 0 0 0;
              > img {
                width: 12px;
                height: 12px;
                border-radius: 0;
              }
            }
          }
        }
      }
    }
  }
}
</style>
