<template>
  <div>
    <van-nav-bar
      fixed
      class="butler-nav-bar"
      title="场馆"
      left-arrow
      @click-left="$router.go(-1)"
    >
      <!-- <template #right>
        <van-icon
          name="location-o"
          color="#555"
          size="16"
          style="margin-left: 1rem"
        />
        <van-icon
          name="search"
          color="#555"
          size="16"
          style="margin-left: 1rem"
        />
      </template> -->
    </van-nav-bar>
    <div class="rule">
      <van-dropdown-menu>
        <van-dropdown-item v-model="value2" :options="option2" @change="opt2Change" />
        <van-dropdown-item v-model="value3" :options="option3" @change="opt3Change" />
      </van-dropdown-menu>
    </div>
    <div class="body">
      <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
        >
          <div
            class="list_item"
            v-for="(item, index) in dataList"
            :key="index"
            @click="gotoLink(item)"
          >
            <img :src="item.venueImage" />
            <div class="right">
              <div class="title">
                {{ item.venueName }}
                <span style="font-size: 12px; color: #08f;margin-left:5px">已预约{{item.num || 0}}次</span>
              </div>
              <div v-if="item.venueCondition === 1" class="time">
                营业时间：闭馆中
              </div>
              <div v-else class="time">
                营业时间：{{item.businessTime}}
              </div>
              <div class="time" @click="callPhone($event, item.venuePhone)">
                联系电话：{{item.venuePhone}}
              </div>
              <div class="sale">
                <img :src="piaoImg" />
                <div class="sale-msg">{{ item.venueCost || 0}}积分/小时</div>
              </div>
            </div>
          </div>
        </van-list>
      </van-pull-refresh>
    </div>
  </div>
</template>

<script>
import changGuanApi from 'api/changguan';
export default {
  data() {
    return {
      defaultImg: require("../../../assets/images/yundong/yundong_default.svg"),
      changguanImg: require("../../../assets/images/yundong/changguan.svg"),
      piaoImg: require("../../../assets/images/yundong/piao.png"),
      phoneImg: require("../../../assets/images/yundong/piao.png"),
      value1: 0,
      value2: '',
      value3: 0,
      option2: [],
      option3: [
        { text: "发布时间", value: 0 },
        { text: "预约次数", value: 1 },
      ],
      loading: false,
      finished: false,
      isLoading: false,
      tableData: [],
      dataList: [],
      pageSize: 50,
    };
  },
  methods: {
    onLoad() {
      //请求
      // this.tableData = this.allData;
      this.finished = true;
    },
    onRefresh() {
      this.isLoading = false;
    },
    opt2Change(value){
      this.value2 = value;
      this.getDataList();
    },
    opt3Change(value){
      this.value3 = value;
      this.getDataList();
    },
    callPhone(e, number) {
      e.stopPropagation();
      window.location.href = `tel://${number}`;
    },
    getDataList() {
      this.loading = false;
      changGuanApi
        .getChangGuanList({
          page: 1,
          size: this.pageSize,
          sorted: this.value3,
          venueTypeId: this.value2,
        })
        .then((res) => {
          const { records } = res.data || {};
          this.dataList = records || [];
          this.finished = true;
        })
        .catch((err) => {
          console.log('error====>', err);
          this.loading = false;
        });
    },
    getTypeList(){
      changGuanApi
        .getVenueListType()
        .then((res) => {
          const data = res.data || [];
          this.option2 = [{ text: '全部场馆', value: ''}].concat(data.map(v => ({ text: v.venueTypeName, value: v.id })));
        })
        .catch((err) => {
        });
    },
    gotoLink(item) {
      console.log(item);
      
      this.$router.replace(`/changguan/detail?id=${item.id}`)
    }
  },
  created() {
    this.getTypeList();
    this.getDataList();
  },
};
</script>

<style lang="scss" scoped>
.rule {
  position: relative;
  margin-top: 40px;
}
.body {
  padding: 0.8rem;
  ::v-deep .van-pull-refresh__track {
    min-height: calc(100vh - 150px);
  }

  .list_item {
    padding: 1.2rem 0.8rem;
    margin-bottom: 0.8rem;
    background: #ffffff;
    display: flex;
    align-items: center;

    > img {
      width: 5.4rem;
      height: 5.4rem;
    }
    .right {
      flex: 1;
      font-size: 1rem;
      margin-left: 0.75rem;
      display: flex;
      flex-flow: column nowrap;

      .title {
        font-size: 1.2rem;
      }
      .time{
        line-height: 24px;
        color: rgba(0,0,0,0.65);
      }
      .grade {
        margin-top: 0.4rem;
        > span {
          margin-left: 0.6rem;
        }
      }

      .type {
        margin-top: 0.3rem;
      }

      .info {
        margin-top: 0.3rem;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-between;
      }
      .sale {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        margin-top: 0.5rem;

        img {
          width: 1.1rem;
          height: 1.1rem;
        }
        .sale-msg {
          margin-left: 0.6rem;
        }
      }
    }
  }
}
</style>