<template>
  <div id="index">
    <div class="page-container">
      <div class="header-nav">
        <i @click="goBack"></i>
        <h1>行政服务</h1>
      </div>
      <div class="park-main">
        <div class="banner">
          <img src="http://dssq.ikeqiao.net/data/image/h5/images/banner/xzfw_banner.png" />
        </div>
        <div class="all-nav clearfix">
          <ul>
            <li @click="router('order/index')"><i class="yybl"></i><span>预约办理</span></li>
            <li @click="router('order/online')"><i class="zxbl"></i><span>在线办理</span></li>
            <li><i class="sxzn"></i><span>事项指南</span></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  components: {},
  mounted() {},
  methods: {
    gotoLink(path) {
      this.$router.push(path);
    },
    goBack() {
      this.$router.go(-1);
    },
    router(vote_url) {
      window.location.href = `http://ued.bywin.cn:38126/#/pages/${vote_url}`;
    },
  },
};
</script>

<style lang="scss">
@import "~assets/scss/index.scss";
</style>
