<template>
  <div :class="$style.governanceStructureContainer">
    <van-nav-bar
      title="治理架构"
      left-arrow
      @click-left="onClickLeft"
    />
    <div :class="$style.bodyContainer">
      <!-- title -->
      <div :class="$style.topTitle">
        <img :src="`${imageUrl}decoration-left.png`" alt="">
        <h1>支部建在小区上<br />"1+5+N"治理架构</h1>
        <img :src="`${imageUrl}decoration-right.png`" alt="">
      </div>
      <!-- structure -->
      <div :class="$style.structureWrapper">
        <div :class="$style.structureLevel" v-for="(level, index) in cardList" :key="`level_${index}`">
          <div :class="$style.structureRow" v-for="(row, index2) in level" :key="`row_${index2}`">
            <div
              :class="$style.structureCard" 
              v-for="item in row" 
              :key="item.title"
              @click="gotoPath(item.path)"
              :style="{'background': `url(${imageUrl}${item.active ? 'jiagou-card-active' : 'jiagou-card'}.png) no-repeat`, 'background-size': '100% 100%'}"
            >
              {{item.title}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import homeApi from 'api/home';
import { mapMutations, mapState } from "vuex";
export default {
  data() {
    return {
      imageUrl: 'http://dssq.ikeqiao.net/data/image/dongsheng/partyBuilding/',
      cardList: [
        [
          [
            {
              title: '党总支',
              active: true,
              path: '/zljgPeople?key=98&name=党总支'
            }
          ]
        ],
        [
          [
            {
              title: '党支部',
              active: true,
              path: '/zljgGs?key=99&name=党支部'
            }
          ]
        ],
        [
          [
            {
              title: '业委会', 
              active: true,
              path: '/zljgYwh?key=101&name=业委会'
            }, {
              title: '物业公司', 
              active: true,
              path: '/zljgGs?key=102&name=物业公司'
            },
          ],
          [
            {
              title: '监委会', 
              active: true,
              path: '/zljgPeople?key=103&name=监委会'
            },
          ],
          [
            {
              title: '配套组织', 
              active: false
            }, {
              title: '团支部',
              active: true,
              path: '/zljgPeople?key=104&name=团支部'
            }, {
              title: '妇联',
              active: true,
              path: '/zljgPeople?key=105&name=妇联'
            }, {
              title: '关工委',
              active: true,
              path: '/zljgPeople?key=106&name=关工委'
            }, {
              title: '调解委员会',
              active: true,
              path: '/zljgPeople?key=107&name=调解委员会'
            }, {
              title: '义警分会',
              active: true,
              path: '/zljgPeople?key=108&name=义警分会'
            }
          ],
          [
            {
              title: '顾问团', 
              active: false
            }, {
              title: '法律顾问',
              active: true,
              path: '/zljgPeople?key=109&name=法律顾问'
            }, {
              title: '金融顾问',
              active: true,
              path: '/zljgPeople?key=110&name=金融顾问'
            }, {
              title: '健康顾问',
              active: true,
              path: '/zljgPeople?key=111&name=健康顾问'
            }, {
              title: '政务顾问',
              active: true,
              path: '/zljgPeople?key=112&name=政务顾问'
            },
          ],
        ]
      ]
    };
  },
  computed: {
  },
  components: {},
  mounted() {
    
  },
  watch: {
   
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    gotoPath(path) {
      if(path) {
        this.$router.push(path);
      }
    }
  }
};
</script>

<style lang="scss" module>
.governanceStructureContainer {
  height: 100vh;
  width: 100vw;
  font-size: 0.9rem;
  line-height: 1.4rem;
  color: rgba(0, 0, 0, 0.85);
  
  .bodyContainer {
    height: calc(100vh - 46px);
    width: 100vw;
    // padding-bottom: 10px;
    background: url('http://dssq.ikeqiao.net/data/image/dongsheng/partyBuilding/zljg-bg.png') no-repeat;
    background-size: 100% 100%;
    overflow-y: auto;
    overflow-x: hidden;
    .topTitle {
      display: flex;
      align-items: center;
      margin: 20px 4rem;
      >h1 {
        font-size: 1.2rem;
        color: #4479ED;
        margin: 0 8px;
        text-align: center;
      }
      >img {
        width: 2.8rem;
        height: 17px;
      }
    }

    .structureWrapper {
      width: 100%;
      background: url('http://dssq.ikeqiao.net/data/image/dongsheng/partyBuilding/jiagou-line.png') no-repeat;
      background-size: 100% calc(100% - 30px);
      padding: 0 6px;
      .structureLevel {
        width: 100%;
        display: flex;
        justify-content: center;
        // margin-bottom: 28px;
        .structureRow {
          flex: 1;
          display: flex;
          flex-direction: column;
          align-items: center;
          .structureCard {
            width: 5.4rem;
            height: 30px;
            margin-bottom: 28px;
            font-size: 0.87rem;
            color: #FFFFFF;
            font-weight: 500;
            text-align: center;
            line-height: 32px;
          }
        }
      }
    }

  }
}
</style>
