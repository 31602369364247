<template>
  <div class="publish-gold-idea-container">
    <van-nav-bar
      title="我有金点子"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="publish-golden-idea-body-container">
      <div class="image-bar">
        我有金点子
        <p>填写您的金点子，成功采纳后您可获得<b>相应</b>积分</p>
      </div>
      <van-form @submit="onSubmit">
        <van-field
          size="large"
          v-model="type"
          name="proposalType"
          label-class="input-label"
          is-link
          readonly
          label="类型"
          placeholder="请选择类型"
          :rules="[{ required: true, message: '' }]"
          @click="show = true"
        />
        <div class="section" style="padding: 0.8rem;margin-bottom: 0.8rem;">
          <div class="title">金点子想法</div>
          <van-field
            size="large"
            v-model="remark"
            rows="3"
            autosize
            name="ideaIntro"
            type="textarea"
            maxlength="200"
            placeholder="请填写您的金点子想法"
            :rules="[{ required: true, message: '' }]"
            show-word-limit 
          />
        </div>
        <div class="section" style="padding: 0.8rem">
          <div class="title">相关图片</div>
          <van-uploader
            v-model="imageList"
            name="imageList"
            multiple
            :max-count="5"
          />
        </div>
        <div class="footer-button">
          <van-button
            block
            type="info"
            plain
            style="font-size: 16px;margin-right: 10px;border-radius: 4px;"
            @click="gotoLink('myGoldenIdeaList')"
          >
            我的金点子
          </van-button>
          <van-button
            block
            type="info"
            style="font-size: 16px;border-radius: 4px;"
            native-type="submit"
          >
            提交金点子
          </van-button>
        </div>
      </van-form>
      <van-popup v-model="show" round position="bottom">
        <van-picker
          title
          show-toolbar
          :columns="options"
          @confirm="onConfirm"
          @cancel="show = false"
        />
      </van-popup>
    </div>
  </div>
</template>

<script>
import api from 'api';
import { mapMutations, mapState } from "vuex";
import moment from 'moment';
export default {
  data() {
    return {
      remark: '',
      type: '',
      show: false,
      options: ['社区建设', '邻里互动', '其他'],
      imageList: [],
    };
  },
  computed: {
    dateList() {
      const result = [];
      for(let i = 0;i < 7; i += 1) {
        const startDay = moment().week(moment().week()).startOf('week');
        const current = startDay.add(i, 'days');
        const day = current.format('MM-DD');
        const week = current.format('dddd').replace('星期','周');
        result.push({ day, week });
      }
      return result;
    }
  },
  components: {},
  mounted() {
    
  },
  watch: {
   
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    publishGoldenIdea(values) {
      const _this = this;
      api.addProposal(values).then(res => {
        console.log('success', res);
        
        if (res.data == 1) {
          _this.$toast("提交成功");
          _this.$router.replace("myGoldenIdeaList");
        }
      })
    },
    onSubmit(values) {
      if (this.imageList.length) {
        const formData = new FormData();
        this.imageList.forEach(file => {
          formData.append("files", file.file);
        });
        api.uploadMultiFile(formData).then(res => {
          if (res.flag) {
            values.image = res.data;
            this.publishGoldenIdea(values);
          }
        });
      } else {
        this.publishGoldenIdea(values);
      }
    },
    gotoLink(path) {
      this.$router.push(path);
    },
    onConfirm(value) {
      this.type = value;
      this.show = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.publish-gold-idea-container {
  height: 100vh;
  width: 100vw;
  background: url('http://dssq.ikeqiao.net/data/image/h5/images/coordination/coordination-bg.png') no-repeat;
  background-size: 100% 100%;

  .publish-golden-idea-body-container {
    height: calc(100vh - 109px);
    width: 100vw;
    overflow-y: auto;
    overflow-x: hidden;

    .image-bar {
      width: 100%;
      height: 92px;
      background: url('http://dssq.ikeqiao.net/data/image/h5/images/coordination/form-top-bg.png') no-repeat;
      background-size: 100% 100%;
      font-size: 18px;
      color: #FFFFFF;
      font-weight: 500;
      text-align: center;
      padding: 20px;
      line-height: 28px;
      >p {
        font-size: 14px;
        >b {
          font-size: 16px;
          color: #FFFFFF;
          margin: 0 5px;
        }
      }
    }
    ::v-deep .input-label {
      >span {
        font-size: 16px;
        color: rgba(0,0,0,0.85);
      }
    }
    .section {
      width: 100%;
      background: #ffffff;
      .title {
        margin: 0.5rem 0;
        font-size: 16px;
        color: rgba(0,0,0,0.85);
        letter-spacing: 0;
        .required {
          color: #e13f30;
        }
      }
      .date-btn-con {
        width: 100%;
        padding: 10px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .date-btn {
          width: calc(50% - 5px);
          background: #F4F5F8;
          border-radius: 4px;
          line-height: 32px;
          text-align: center;
          font-size: 16px;
          color: rgba(0,0,0,0.85);
          margin-bottom: 10px;
        }
        .selected {
          color:  #4479ED;
          background:  rgba(68,121,237,0.15);
        }
      }
      .divider {
        height: 1px;
        width: 100%;
        background-color:#ebedf0;
      }
    }
    .footer-button {
      position: fixed;
      bottom: 0.8rem;
      right: 0.8rem;
      left: 0.8rem;
      font-size: 18px;
      display: flex;
    }
  }
}
</style>
