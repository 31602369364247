<template>
  <div id="index">
    <div class="page-container">
      <div class="header-nav">
        <i @click="goBack"></i>
        <h1>物业服务</h1>
      </div>
      <div class="park-main">
        <div class="banner">
          <img src="http://dssq.ikeqiao.net/data/image/h5/images/prop-banner.png" />
        </div>
        <div class="prop-nav clearfix">
          <ul>
            <li @click="gotoLink('wytp')"><i class="wyfw-wytp"></i><span>物业投票</span></li>
            <li @click="gotoLink('decoration')"><i class="wyfw-wyba"></i><span>装修备案</span></li>
            <li @click="gotoLink('service')"><i class="wyfw-wybx"></i><span>物业报修</span></li>
            <li @click="gotoLink('complain')"><i class="wyfw-wyts"></i><span>物业投诉</span></li>
            <li @click="gotoLink('release')"><i class="wyfw-wyfx"></i><span>物品放行</span></li>
            <li @click="gotoLink('payment')"><i class="wyfw-wyjf"></i><span>物业缴费</span></li>
            <li @click="gotoLink('comments')"><i class="wyfw-wypj"></i><span>物业评价</span></li>
          </ul>
        </div>
        <div class="note-box">
          <div class="tab">通知公告</div>
          <div class="list">
            <ul>
              <li>通知公告通知公告通知公告通知公告通知公告通知公告</li>
              <li>通知公告通知公告通知公告通知通知公告</li>
              <li>通知公告通知知公告通知公告</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  components: {},
  mounted() {},
  methods: {
    gotoLink(path) {
      this.$router.push(path);
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss">
@import "~assets/scss/index.scss";
.wyfw-wytp {
  background: url('http://dssq.ikeqiao.net/data/image/h5/images/wyfw/wyfw-wytp.png') no-repeat;
  background-size: 100%;
}
.wyfw-wyba {
  background: url('http://dssq.ikeqiao.net/data/image/h5/images/wyfw/wyfw-zxba.png') no-repeat;
  background-size: 100%;
}
.wyfw-wybx {
  background: url('http://dssq.ikeqiao.net/data/image/h5/images/wyfw/wyfw-wybx.png') no-repeat;
  background-size: 100%;
}
.wyfw-wyts {
  background: url('http://dssq.ikeqiao.net/data/image/h5/images/wyfw/wyfw-wyts.png') no-repeat;
  background-size: 100%;
}
.wyfw-wyfx {
  background: url('http://dssq.ikeqiao.net/data/image/h5/images/wyfw/wyfw-wyfx.png') no-repeat;
  background-size: 100%;
}
.wyfw-wyjf {
  background: url('http://dssq.ikeqiao.net/data/image/h5/images/wyfw/wyfw-wyjf.png') no-repeat;
  background-size: 100%;
}
.wyfw-wypj {
  background: url('http://dssq.ikeqiao.net/data/image/h5/images/wyfw/wyfw-wypj.png') no-repeat;
  background-size: 100%;
}
</style>
