<template>
  <div class="memberManage-container">
    <van-nav-bar
      title="社区活动"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="community-activity-body-container">
      <wyslList activityType="" />
    </div>
  </div>
</template>

<script>
import homeApi from 'api/home';
import { mapMutations, mapState } from "vuex";
import WyslList from "./wyslList";
export default {
  data() {
    return {
      
    };
  },
  computed: {
  },
  components: { WyslList },
  mounted() {
    
  },
  watch: {
   
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    handleTab() {

    },
    gotoLink(path) {
      if(path) {
        this.$router.push(path);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.memberManage-container {
  height: 100vh;
  width: 100vw;
  
  .community-activity-body-container {
    height: calc(100vh - 46px);
    width: 100vw;
    padding-bottom: 10px;
    background-color: #FFF;
    // background: url('http://dssq.ikeqiao.net/data/image/h5/images/coordination/coordination-bg.png') no-repeat;
    // background-size: 100% 100%;
    overflow-y: auto;
    overflow-x: hidden;


  }
}
</style>
