<template>
	<div class="sqxzIndex" style="background: #fff; height: 100vh; padding-top: 2.7rem">
	  <div class="header-nav">
	    <i @click="goBack"></i>
	    <h1>查看详情</h1>
	  </div>
	  <div class="detail-con">
		<img :src="detailInfo.image" class="detail-img">
		<div class="person-con">
			<img :src="detailInfo.image">
			<div class="info-con">
				<div class="">
					王大可
				</div>
				<div class="">
					2021-12-01 18:22:34
				</div>
				<div class="ff-con">
					<span>置换条件：</span>
					<span>{{detailInfo.name}}</span>
					&nbsp;&nbsp;
					<span>置换方式：</span>
					<span>{{detailInfo.ff}}</span>
				</div>
			</div>
		</div>
		<div class="desc-con">
			{{detailInfo.desc}}
		</div>
		<div class="title">
			认领情况
		</div>
		<div class="person-con">
			<img :src="detailInfo.image">
			<div class="info-con">
				<div class="">
					王大可
				</div>
				<div class="">
					2021-12-01 18:22:34
				</div>
			</div>
		</div>
		<div class="btn-con">
			<van-button type="info" class="btn-class">我要认领</van-button>
		</div>
	  </div>
	  </div>
</template>

<script>
	import { Button } from 'vant';
	import { mapState } from 'vuex'
	export default {
		data() {
			return {};
		},
		mounted() {
			console.log(this.detailInfo);
		},
		computed: {
			...mapState({
				detailInfo: state => {
					console.log(state.sqxz);
					return state.sqxz.detailInfo;
				},
			}),
		},
		methods: {
			goBack() {
				this.$router.go(-1);
			},
		},
	}
</script>

<style scoped lang="less">
	.sqxzIndex{
	  height: 100% !important;
	  position: relative;
	  .detail-con {
		  display: flex;
		  flex-direction: column;
		  font-size: 1rem;
		  padding: 8px;
		  margin-bottom: 60px;
		  .detail-img {
			  width: 100%;
			  height: 251px;
		  }
		  .person-con {
			  display: flex;
			  margin: 10px 0;
			  img {
				  width: 64px;
				  min-height: 64px;
				  border-radius: 100px;
				  margin-right: 5px;
			  }
			  .info-con {
				  display: flex;
				  font-size: 1rem;
				  flex-grow: 1;
				  flex-wrap: wrap;
				  justify-content: space-around;
				  flex-direction: column;
				  .ff-con {
					  display: flex;
					  flex-wrap: wrap;
				  }
			  }
		  }
		  .desc-con {
		  				  line-height: 1.3rem;
						  margin: 20px 0;
		  }
		  .title {
			  border-left: 3px solid #3E71F9;
			  padding-left: 10px;
			  font-weight: bolder;
			  font-size: 1.2rem;
			  line-height: 1.2rem;
			  margin-top: 10px;
			  display: flex;
			  align-items: center;
		  }
		  .btn-con {
			  position: fixed;
			  width: 100%;
			  bottom: 0;
			  display: flex;
			  align-items: center;
			  justify-content: center;
			  margin-bottom: 8px;
			  .btn-class {
				  border-radius: 30px;
				  font-size: 1.2rem;
				  width: 90%;
				  display: flex;
				  align-items: center;
				  justify-content: center;
				  font-weight: bolder;
				  letter-spacing: 8px;
			  }
		  }
	  }
	}
</style>
