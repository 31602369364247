<template>
  <div>
    <van-nav-bar
      fixed
      class="butler-nav-bar"
      title="共享法庭"
      left-arrow
      @click-left="$router.go(-1)"
    />

    <div class="form" style="width: 100vw;height: calc(100vh - 3.2rem - 50px);overflow-y: auto;overflow-x: hidden;">
      <van-form @submit="onSubmit">
        <van-field
          readonly
          required
          clickable
          is-link
          v-model="type.type"
          name="litigationType"
          label="矛盾类型"
          input-align="right"
          placeholder="请选择矛盾类型"
          :rules="[{ required: true, message: '' }]"
          @click="showTypePicker = true"
        />
        <van-popup v-model="showTypePicker" position="bottom">
          <van-picker
            show-toolbar
            value-key="type"
            :columns="typeList"
            @confirm="typeConfirm"
            input-align="right"
            @cancel="showTypePicker = false"
          />
        </van-popup>
        <van-field
          required
          v-model="plaintiff"
          name="plaintiffName"
          label="原   告"
          placeholder="请输入原告姓名"
          input-align="right"
          :rules="[{ required: true, message: '' }]"
        />
        <van-field
          required
          v-model="plaintiffPhone"
          name="plaintiffPhonenumber"
          input-align="right"
          label="联系方式"
          placeholder="请输入联系方式"
          :rules="[{ required: true, message: '' }]"
        />
        <van-field
          required
          v-model="defendant"
          name="defendantName"
          input-align="right"
          label="被   告"
          placeholder="请输入被告姓名"
          :rules="[{ required: true, message: '' }]"
        />
        <van-field
          required
          v-model="defendantPhone"
          name="defendantPhonenumber"
          input-align="right"
          label="联系方式"
          placeholder="请输入联系方式"
          :rules="[{ required: true, message: '' }]"
        />
        <div class="section" style="padding: 0.8rem">
          <div class="image-title">
            <span style="color: #ee0a24">*</span>
            原告主诉
          </div>
          <van-field
            v-model="rules"
            rows="3"
            autosize
            label=""
            name="matter"
            type="textarea"
            :rules="[{ required: true, message: '' }]"
            maxlength="100"
            placeholder="请输入原告主诉"
            show-word-limit
          />
        </div>
        <div class="section" style="padding: 0.8rem">
          <div class="image-title">附件</div>
          <van-uploader v-model="files" name="files" :max-count="1" />
        </div>

        <div class="foot-btn">
          <van-button block type="info" round native-type="submit"
            >提交</van-button
          >
        </div>
      </van-form>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
import courtApi from 'api/court';
import commonApi from 'api/common';
export default {
  data() {
    return {
      type: {},
      plaintiff: "",
      plaintiffPhone: "",
      defendant: "",
      defendantPhone: "",
      rules: "",
      files: [],
      showTypePicker: false,
      typeList: [
        { id: 0, type: "经济纠纷" },
        { id: 1, type: "宠物纠纷" },
        { id: 2, type: "买卖纠纷" },
        { id: 3, type: "刑事纠纷" },
      ],
    };
  },
  methods: {
    typeConfirm(e) {
      this.type = e;
      this.showTypePicker = false;
    },
    addMeet(values) {
      courtApi.addCourt({ ...values, litigationType: this.type.id }).then(res => {
        if (res.flag) {
          Toast.success('提交成功');
          setTimeout(() => {
            this.$router.go(-1);
          }, 500);
        }
      })
    },
    onSubmit(values) {
      if (this.files.length) {
        const formData = new FormData();
        this.files.forEach(file => {
          formData.append("files", file.file);
        });
        commonApi.uploadMultiFile(formData).then(res => {
          if (res.flag) {
            values.attachedPicture = res.data;
            this.addMeet(values);
          }
        });
      } else {
        this.addMeet(values);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.form {
  margin-top: 45px;
  .section {
    background: #ffffff;
    width: 100%;
    border-top: 0.8rem solid #f6f7fa;
    .image-title {
      line-height: 2rem;

      font-size: 14px;
      color: #646566;
      letter-spacing: 0;
    }
  }
}
.foot-btn {
  position: fixed;
  bottom: 0.8rem;
  right: 0.8rem;
  left: 0.8rem;
  height: 2.4rem;
  line-height: 2.4rem;
  text-align: center;

  font-size: 18px;
  letter-spacing: 0;
}
</style>