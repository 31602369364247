<template>
  <div id="index">
    <div class="page-container paddclose">
      <div class="header-nav">
        <i @click="goBack"></i>
        <h1>伊柯有约</h1>
      </div>
      <div class="ykyy-main">
        <div class="banner">
          <img src="http://dssq.ikeqiao.net/data/image/h5/images/banner-ykyy.png" />
        </div>
        <div class="content-box">
          <div class="title">妇联服务</div>
          <ul>
            <li @click="gotoLink('Wfed')">
              <i class="icon1"></i><span>妇联介绍</span>
            </li>
            <li @click="gotoLink('wqcs')">
              <i class="icon2"></i><span>维权超市</span>
            </li>
            <li @click="gotoLink('zmkt')">
              <i class="icon3"></i><span>最美客厅</span>
            </li>
            <li @click="gotoLink('wqtj')">
              <i class="icon4"></i><span>维权统计</span>
            </li>
          </ul>
        </div>
        <div class="commnewlist">
          <div class="tab">普法课堂</div>
          <ul>
            <li @click="gotoLink('ddnews-detail')">
              <div class="desc">
                <div class="title">
                  婚姻法
                </div>
                <div class="time">时间：2021-02-01 21:16:41</div>
              </div>
              <div class="img">
                <img
                  src="https://wxbucket.oss-cn-hangzhou.aliyuncs.com/zhsq/poSVRvZ2y5mk.jpg"
                />
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  components: {},
  mounted() {},
  methods: {
    gotoLink(path) {
      this.$router.push(path);
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss">
@import "~assets/scss/index.scss";
</style>
