<template>
  <div id="index">
    <div class="page-container">
      <div class="header-nav">
        <i @click="goBack"></i>
        <h1>妇幼健康服务</h1>
      </div>
      <div class="park-main">
        <div class="banner">
          <img src="http://dssq.ikeqiao.net/data/image/h5/images/banner/zdrqgl_banner.png" />
        </div>
        <div class="all-nav clearfix">
          <ul>
            <li @click="router('18')">
              <i class="ycf"></i><span>孕妇产前检查</span>
            </li>
            <!-- <li @click="showAlert">
              <i class="ycf"></i><span>孕产妇</span>
            </li> -->
            <li @click="router('17')">
              <i class="xsefs"></i><span>新生儿访视</span>
            </li>
            <li @click="router('16')">
              <i class="etmy"></i><span>儿童预防接种</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>
import { openUrl } from '@/utils/index';
export default {
  data() {
    return {};
  },
  components: {},
  mounted() {},
  methods: {
    gotoLink(path) {
      this.$router.push(path);
    },
    goBack() {
      this.$router.go(-1);
    },
    showAlert() {
      this.$messagebox("提示", "系统正在建设中，尽情期待~");
    },
    router(vote_url) {
      openUrl(vote_url);
      
    },
  },
};
</script>

<style lang="scss">
@import "~assets/scss/index.scss";
</style>
