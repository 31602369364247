<template>
  <div id="index">
    <div class="page-container">
      <div class="header-nav">
        <i @click="goBack"></i>
        <h1>党日活动</h1>
      </div>
      <div class="comm-banner banner">
        <img src="http://dssq.ikeqiao.net/data/image/h5/images/banner-active.png" />
      </div>
      <div class="act-box">
        <div class="index-tab">
          支部活动
        </div>
        <div class="clearfix active">
          <div class="float-l"><b>12</b>总开展（场）</div>
          <div class="float-l">
            <div>参与人次（人）</div>
            <div>平均参与人次（人）</div>
          </div>
          <div class="float-r">
            <div>570
<img src="https://wxbucket.oss-cn-hangzhou.aliyuncs.com/zhsq/party/activity/dayUp.png" style="width: 10vw; margin-left: 5px; height: 8.71795px;" />
            </div>
            <div>52</div>
          </div>
        </div>
      </div>
      <div class="act-box">
        <div class="index-tab">
          活动展示
        </div>
        <div class="commnewlist">
          <ul>

              <li v-for="item in commnewlist" :key="item.title">
              <div class="desc">
                  <div class="title">{{item.title}}</div>
                  <div class="time">时间：{{item.created_at}}</div>
              </div>
              <div class="img">
                  <img :src="item.image" />
              </div>
            </li>


          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
        commnewlist:[]

    };
  },
  components: {},
  mounted() {
      this.getCommNews();
  },
  methods: {
    gotoLink(path) {
      this.$router.push(path);
    }, goBack(){
          this.$router.go(-1);
      },
      getCommNews(){
          let that = this;
          this.axios
              .get("/authApi/sdk-auth/getDrhd?communityId=417817877553348609&type=7&notPage=1")
              .then(function(response) {
                  that.commnewlist = response.data.data;
              })
              .catch(function(err) {
                  // eslint-disable-line no-unused-vars
              });
      },
  },
};
</script>

<style lang="scss">
@import "~assets/scss/index.scss";
</style>
