<template>
  <div id="index">
    <div class="page-container paddclose">
      <div class="header-nav">
        <i @click="goBack"></i>
        <h1>东盛民生面对面</h1>
      </div>
      <div class="ddnews-main">
        <div class="banner">
          <img src="http://dssq.ikeqiao.net/data/image/h5/images/msmdm-banner.png" />
        </div>
        <div class="content-box">
          <div class="wxparse-box">
            通过“民生面对面”服务，集聚浣东街道领导、站所负责人、物业、居民代表等，以解决社区居民的操心事、揪心事、烦心事为出发点和落脚点，通过职能办线与居民“面对面”交流的方式，零距离收集民声民情，面对面解决实质问题，提高问题收集处置效率。
          </div>
        </div>
        <div class="commnewlist">
          <div class="tab">民生资讯</div>
          <ul>
            <li @click="gotoLink('ddnews-detail')">
              <div class="desc">
                <div class="title">
                    11月1日，曹俊、蒋兴光、潘国昌、邵正峰、陈杭峰到社区参加“东盛民声面对面”活动
                </div>
                <div class="time">时间：2021-01-11 11:23:25</div>
              </div>
              <div class="img">
                <img
                  src="https://wxbucket.oss-cn-hangzhou.aliyuncs.com/zhsq/m7cIvMN4ZpDS.JPG"
                />
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  components: {},
  mounted() {},
  methods: {
    gotoLink(path) {
      this.$router.push(path);
    },goBack(){
          this.$router.go(-1);
      }
  },
};
</script>

<style lang="scss">
@import "~assets/scss/index.scss";
</style>
