<template>
  <div style="height:calc(100vh - 44px - 2.7rem);width: 100%;overflow-y: auto;overflow-x: hidden;">
    <div class="xlyz-comm-banner">
      <img src="http://dssq.ikeqiao.net/data/image/h5/images2/chat-banner.jpg" />
    </div>
    <div class="xlyz-list">
      <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
        <ul>
          <li v-for="item in list" :key="item.chatId">
            <div class="detail">
              <div class="tit">
                <span>{{item.title || ''}}</span>
                <van-button round size="small" type="info" @click="gotoLink(`joinTalk?chatId=${item.chatId}`)">申请加入</van-button>
                <!-- <van-button v-if="" round size="small" type="danger" @click="cancelJoinOrNot()">取消加入</van-button> -->
                <!-- <van-button v-if="" round size="small" color="#bbb">申请中</van-button> -->
              </div>
              <div class="desc">
                陪聊团成员：<span v-for="member in (item.enrollName || [])" :key="member">{{member}}</span>
              </div>
            </div>
            <div class="img">
              <img v-for="(picture, index) in (item.image ? item.image.split('#') : [])" :key="`${index}-image`" :src="picture" />
            </div>
          </li>
        </ul>
      </van-list>
    </div>
    <van-dialog
      v-model="showDialog"
      title="提示"
      message="是否取消加入"
      show-cancel-button
      @cancel="showDialog = false"
      @confirm="cancelJoin()"
    >
    </van-dialog>
  </div>
</template>

<script>
import llyjrApi from "api/llyjr/index";
import xlyzApi from "api/llyjr/xlyz";
import { List, Toast } from "vant";

export default {
  data() {
    return {
      page: 0,
      size: 10,
      total: 0,
      list: [],
      loading: false,
      finished: false,
      showDialog: false,
    };
  },
  components: {},
  created() {

  },
  methods: {
    getList({ page = this.page, size = this.size } = {}) {
      this.loading = false;
      this.$api.getChatList({
          page,
          size,
        })
        .then(res => {
          const { records = [], total = 0 } = res.data || {};
          if(page === 1) {
            this.list = records;
          } else {
            this.list = this.list.concat(records);
          }
          this.total = total;
          // console.log('total', total, this.list)
          if (this.list.length >= total) {
            // 数据全部加载完成
            this.finished = true;
          }
        });
    },
    onLoad() {
      this.page += 1;
      this.getList();
    },
    gotoLink(path) {
      this.$router.push(path);
    },
    cancelJoinOrNot() {
      this.showDialog = true;
    },
    cancelJoin() {
      console.log('取消加入')
    },
    goBack() {
      this.$router.go(-1);
    },
    showAlert() {
      this.$messagebox("提示", "系统正在建设中，尽情期待~");
    }
  }
};
</script>

<style lang="scss" scoped>
.xlyz-comm-banner {
    width: 100%;
    padding: 0.7rem;
    height: 7.8rem;
    margin-bottom: 0.2rem;
    img {
      height: 7.8rem;
      width: 100%;
      border-radius: 0.5rem;
    }
  }
.xlyz-list {
    width: 23.4rem;
    height: calc(100vh - 14rem);
    margin: 0 auto;
    li {
      padding: 0.7rem 0;
      border-bottom: 1px solid #ddd;
      .img {
        height: 6.4rem;
        img {
          width: 8.4rem;
          height: 6.4rem;
          border-radius: 0.5rem;
          margin-right: 0.7rem;
        }
      }
      .detail {
        margin-bottom: 0.6rem;
        .tit {
          height: 24px;
          margin: 5px 0;
          color: #333;
          font: 500 1rem/2rem "microsoft yahei";
          display: flex;
          align-items: center;
          justify-content: space-between;
          .van-button--small {
            height: 24px;
          }
        }
        .desc {
          font: 500 0.8rem/1.4rem "microsoft yahei";
          color: #333;
          span {
            margin-right: 0.8rem;
            margin-bottom: 5px;
            color: #0796db;
            display: inline-block;
            padding: 0 0.7rem;
            border-radius: 0.6rem;
            background: #e5f2f8;
          }
        }
      }
    }
  }
</style>
