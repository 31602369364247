<template>
  <div id="index">
    <div class="page-container">
      <div class="header-nav">
        <i @click="goBack"></i>
        <h1>妇联介绍</h1>
      </div>
      <div class="party-main">
        <div class="party-tab"><i></i>鉴湖园妇联</div>
        <div class="info-title">基本信息</div>
        <div class="content-info">
          <div class="info-list">
            <div class="info-item-w">
              <i class="icon1"></i>
              <div><b>3</b><span>领导班子</span></div>
            </div>
            <div class="info-item-w">
              <i class="icon2"></i>
              <div><b>3</b><span>执委数量</span></div>
            </div>
            <div class="info-item-w">
              <i class="icon3"></i>
              <div><b>50</b><span>巾帼志愿者</span></div>
            </div>
          </div>
        </div>
        <div class="info-title">领导班子</div>
        <div class="imgList">
          <ul>
            <li>
              <img src="http://dssq.ikeqiao.net/data/image/h5/images/peo1.png" />
              <div>主席<br />王斌</div>
            </li>
            <li>
              <img src="http://dssq.ikeqiao.net/data/image/h5/images/peo2.png" />
              <div>副主席<br />潘梦洁</div>
            </li>
            <li>
              <img src="http://dssq.ikeqiao.net/data/image/h5/images/peo3.png" />
              <div>副主席<br />袁肖平</div>
            </li>
          </ul>
        </div>
        <div class="info-title">支部介绍</div>
        <div class="info-detail">
          <div>组织名称：鉴湖园妇联</div>
          <div>组织负责人：王斌</div>
          <div>组织介绍：鉴湖园社区妇联</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  components: {},
  mounted() {},
  methods: {
    gotoLink(path) {
      this.$router.push(path);
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss">
@import "~assets/scss/index.scss";
</style>
