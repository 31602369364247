<template>
  <div id="index">
    <div class="page-container">
      <div class="header-nav">
        <i @click="goBack"></i>
        <h1>东盛的故事</h1>
      </div>
      <div class="ddStory-main">
        <div class="menu" @click="gotoStory(0)">
          <img src="http://dssq.ikeqiao.net/data/image/h5/images/comStory1.png" />
        </div>
        <div class="menu" @click="gotoStory(1)">
          <img src="http://dssq.ikeqiao.net/data/image/h5/images/comStory2.png" />
        </div>
        <div class="menu" @click="gotoStory(2)">
          <img src="http://dssq.ikeqiao.net/data/image/h5/images/comStory3.png" />
        </div>
        <div class="menu" @click="gotoStory(3)">
          <img src="http://dssq.ikeqiao.net/data/image/h5/images/comStory4.png" />
        </div>
        <div class="menu" @click="gotoStory(4)">
          <img src="http://dssq.ikeqiao.net/data/image/h5/images/comStory5.png" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  components: {},
  mounted() {},
  methods: {
      gotoStory(type) {
        this.$router.push(
            { name: "ddStoryList", params: { type: type } }
        )
    },goBack(){
          this.$router.go(-1);
      },
      gotoLink(path) {
          this.$router.push(path);
      }
  },
};
</script>

<style lang="scss">
@import "~assets/scss/index.scss";
</style>
