<template>
  <div id="index">
    <div class="page-container paddclose">
      <div class="header-nav">
        <i @click="goBack"></i>
        <h1>微匠文化</h1>
      </div>
      <div class="kgh-main">
        <div class="banner">
          <img src="http://dssq.ikeqiao.net/data/image/h5/images/kgh/groupServicebanner-wjwh.png" />
        </div>
        <div class="wj-info clearfix">
          <ul>
            <li>
              <img src="http://dssq.ikeqiao.net/data/image/h5/images/kgh/wj-1.jpg" />
              <div>茹凤丹</div>
              <p>工匠</p>
            </li>
            <li>
              <img src="http://dssq.ikeqiao.net/data/image/h5/images/kgh/wj-2.png" />
              <div>王银友</div>
              <p>工匠</p>
            </li>
          </ul>
        </div>
        <div class="commnewlist" style="margin-top:0rem">
          <div class="tab">微匠风采<span>更多 &gt;</span></div>
          <ul>
            <li
              @click="gotoLink('kghDetail')"
              v-for="(item, index) in newsList"
              :key="index"
            >
              <div class="desc">
                <div class="title">
                  {{ item.title }}
                </div>
                <div class="time">时间：{{ item.update }}</div>
              </div>
              <div class="img">
                <img :src="item.imgurl" />
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      newsList: [
        {
          id: 0,
          title: "【金柯忆】陈长根：剪纸抒深情",
          update: "2021-07-12 16:20:12",
          imgurl:
            "https://wxbucket.oss-cn-hangzhou.aliyuncs.com/zhsq/UH8GgKALfECu.png",
        },
        {
          id: 1,
          title: "领跑竞跑“犇”新程--红建篇",
          update: "2021-07-12 16:18:30",
          imgurl:
            "https://wxbucket.oss-cn-hangzhou.aliyuncs.com/zhsq/fMUdBbN2JS8L.png",
        },
        {
          id: 2,
          title: "【金柯忆】百姓故事 · 笔墨丹青，扇艺大师李德顺的“非遗”技艺",
          update: "2021-02-26 09:51:48",
          imgurl:
            "https://wxbucket.oss-cn-hangzhou.aliyuncs.com/zhsq/U8TbCi9EskoX.jpg",
        },
      ],
    };
  },
  components: {},
  mounted() {},
  methods: {
    gotoLink(path) {
      this.$router.push(path);
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss">
@import "~assets/scss/index.scss";
</style>
