<template>
  <div id="index">
    <div class="page-container paddclose">
      <div class="header-nav">
        <i @click="goBack"></i>
        <h1>十里清廉路</h1>
      </div>
      <div class="road-info">
        <div class="pic"><img src="http://dssq.ikeqiao.net/data/image/h5/images/roadBg.png" /></div>
        <div class="info-nav">
          <div class="point1"></div>
          <div class="point2"></div>
          <div class="point3"></div>
          <div class="point4"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  components: {},
  mounted() {},
  methods: {
    gotoLink(path) {
      this.$router.push(path);
    }, goBack(){
        this.$router.go(-1);
    }
  },
};
</script>

<style lang="scss">
@import "~assets/scss/index.scss";
</style>
