<template>
  <div id="index">
    <div class="page-container paddclose">
      <div class="header-nav">
        <i @click="goBack"></i>
        <h1>东盛小渡的故事</h1>
      </div>
      <div class="ddxd-main">
        <div><img src="http://dssq.ikeqiao.net/data/image/h5/images/dddgs.png" /></div>
        <div><img src="http://dssq.ikeqiao.net/data/image/h5/images/xddgs.png" /></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  components: {},
  mounted() {},
  methods: {
    gotoLink(path) {
      this.$router.push(path);
    },goBack(){
          this.$router.go(-1);
      }
  },
};
</script>

<style lang="scss">
@import "~assets/scss/index.scss";
</style>
