<template>
  <div id="index">
    <div class="page-container" style="margin-top: 0px !important;">
      <div class="header-nav">
        <i @click="goBack"></i>
        <h1>邻里陪聊</h1>
      </div>
      <van-tabs v-model="active" color="#4479ED" style="margin-top: 0px !important;">
        <van-tab title="陪聊团">
          <xlzx />
        </van-tab>
        <van-tab title="陪聊活动">
          <jtpl />
        </van-tab>
        <van-tab title="我要聊天">
          <wylt />
        </van-tab>
      </van-tabs>
      <!-- <div class="float-button" @click="handlePublish">发布服务</div> -->
    </div>
  </div>
</template>

<script>
import llyjrApi from "api/llyjr/index";
import xlyzApi from "api/llyjr/xlyz";
import { List, Toast } from "vant";
import Xlyz from "./xlyz";
import Jtpl from "./jtpl";
import Wylt from "./wylt";

export default {
  data() {
    return {
      active: 0
    };
  },
  components: { xlzx: Xlyz, jtpl: Jtpl, wylt: Wylt },
  mounted() {
    const value = this.getLocalData('plSelect') || '';
    if(value) {
      this.active = parseInt(value);
    }
  },
  watch: {
    active(val, oldVal) {
      this.setLocalData('plSelect', `${val}`);
    },
  },
  methods: {
    gotoLink(path) {
      this.$router.push(path);
    },
    goBack() {
      this.$router.go(-1);
    },
    showAlert() {
      this.$messagebox("提示", "系统正在建设中，尽情期待~");
    },
    handlePublish() {
      this.$store.dispatch('login/checkRealName', () => {
        this.gotoLink('xlyzPublishService');
      })
    }
  }
};
</script>

<style lang="scss" scoped>
.page-container {
  padding-top: 2.7rem;
  background: #f6f7fa;
  .van-tab--active {
    font-weight: 600;
  }
  .float-button {
    position: fixed;
    bottom: 4rem;
    right: 1rem;
    width: 4rem;
    height: 4rem;
    border-radius: 2rem;
    background: #4479ed;
    box-shadow: 0 0 10px 0 rgba(68, 121, 237, 0.6);
   
    font-size: 13px;
    color: #ffffff;
    letter-spacing: -0.31px;
    text-align: center;
    line-height: 15px;
    padding: 1rem;
  }
}
</style>
