<template>
  <div class="yikeyouyue-container">
    <van-nav-bar
      title="伊柯有约"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="yikeyouyue-body-container">
      <!-- top bar -->
      <div class="top-bar">
        <img src="http://dssq.ikeqiao.net/data/image/h5/images/hong/yikeyouyue-bar.png" alt="">
        <!-- 青年荣誉 -->
        <!-- <div class="scroll-con" ref="honor">
          <div class="honor-list">
            <div class="honor-list-item" v-for="item in honorList1" :key="item">
              <i class="honor-tag"></i>
              <h3>{{item.title}}</h3>
              <span>{{item.year}}</span>
            </div>
          </div>
        </div> -->
      </div>
      <!-- 伊家荣誉 -->
      <div class="rongyuyijia-con">
        <div class="rongyu-title">伊家<br />荣誉</div>
        <div class="scroll-con" ref="honor">
          <div class="honor-list">
            <div class="honor-list-item" v-for="item in honorList1" :key="item.title">
              <i class="honor-tag"></i>
              <h3>{{item.title}}</h3>
              <span>{{item.year}}</span>
            </div>
          </div>
        </div>
      </div>
      <!-- 数智伊家 -->
      <div class="yikeyouyue-card">
        <h1>数智伊家</h1>
        <div class="icon-bar">
          <div v-for="(icon, index) in iconList" @click="gotoLink(icon.path)" :key="`${icon.name}_${index}`">
            <img class="icon" :src="icon.icon" :alt="icon.name">
            <p>{{icon.name}}</p>
          </div>
        </div>
      </div>
      <!-- 云上伊家 -->
      <div class="yikeyouyue-card">
        <div class="card-bar-title">
          <div>
            <span class="title-text">云上伊家</span>
          </div>
          <div @click="gotoLink('fcyjMore?key=3')">
            <span class="more">更多</span>
            <img src="http://dssq.ikeqiao.net/data/image/h5/images/home/more.png" alt="">
          </div>
        </div>
        <ul>
            <li class="fl-item" v-for="(item, index) in list" :key="index" @click="openUrl(item)">
              <div class="fl-title">
                <div>
                  <h1>{{item.title}}</h1>
                  <div class="fl-desc">
                    <i class="time"></i>
                    <span style="margin-right: 10px;">{{item.updateTime}}</span>
                    <i class="view"></i>
                    <span>{{item.visitsUp || 0}}</span>
                  </div>
                </div>
                <img v-if="item.cover" :src="item.cover" alt="">
              </div>
            </li>
          </ul>
      </div>
      <!-- 风采伊家 -->
      <div class="yikeyouyue-card">
        <div class="card-bar-title">
          <div>
            <span class="title-text">风采伊家</span>
          </div>
          <div @click="gotoLink('fcyjMore?key=5')">
            <span class="more">更多</span>
            <img src="http://dssq.ikeqiao.net/data/image/h5/images/home/more.png" alt="">
          </div>
        </div>
        <ul>
            <li class="fl-item" v-for="(item, index) in list2" :key="index"  @click="openUrl(item)">
              <div class="fl-title">
                <div>
                  <h1>{{item.title}}</h1>
                  <div class="fl-desc">
                    <i class="time"></i>
                    <span style="margin-right: 10px;">{{item.updateTime}}</span>
                    <i class="view"></i>
                    <span>{{item.visitsUp || 0}}</span>
                  </div>
                </div>
                <img v-if="item.cover" :src="item.cover" alt="">
              </div>
            </li>
          </ul>
      </div>
    </div>
  </div>
</template>

<script>
import Api from 'api';
import { mapMutations, mapState } from "vuex";
export default {
  data() {
    return {
      honorList: [
        {
          title: '诸暨市三八红旗集体',
          year: '2015年'
        },
        {
          title: '绍兴市优秀基层妇联',
          year: '2017年'
        },
      ],
      iconList: [
        {
          name: '伊·架构',
          icon: 'http://dssq.ikeqiao.net/data/image/h5/images/hong/yijiagou.png',
          path: 'yijiagou'
        },
        {
          name: '伊·维权',
          icon: 'http://dssq.ikeqiao.net/data/image/h5/images/hong/yiweiquan.png',
          path: 'yiweiquan',
        },
        {
          name: '伊·数据',
          icon: 'http://dssq.ikeqiao.net/data/image/h5/images/hong/yishuju.png',
          path: 'yishuju'
        },
        {
          name: '伊·足迹',
          icon: 'http://dssq.ikeqiao.net/data/image/h5/images/hong/yizuji.png',
          path: 'yizuji'
        },
      ],
      list: [
        // {
        //   id: 20,
        //   title: "伊家清风小讲坛丨“红色家书 清白家风”第二期——修身篇",
        //   update: "2021-08-06",
        //   view: 22,
        //   icon: "http://dssq.ikeqiao.net/data/image/h5/images/ykyy/ysyj1.png",
        //   url: 'https://mp.weixin.qq.com/s/fpNDfYQJsxenXVBjd7O7pQ',
        // },
        // {
        //   id: 21,
        //   title: "伊家清风小讲坛丨“红色家书 清白家风”第一期——明志篇",
        //   update: "2021-07-30",
        //   view: 11,
        //   icon: "http://dssq.ikeqiao.net/data/image/h5/images/ykyy/ysyj2.webp",
        //   url: 'https://mp.weixin.qq.com/s/qJmWWU9Ed8OUVh4h07lb9g',
        // },
      ],
      list2: [
        // {
        //   id: 10,
        //   title: "伊家引领丨区妇联召开第三场村社退职妇联主席“伊家畅聊会”",
        //   update: "2021-04-07",
        //   view: 3,
        //   icon: "http://dssq.ikeqiao.net/data/image/h5/images/ykyy/fcyj1.png",
        //   url: 'https://mp.weixin.qq.com/s?__biz=MzI4MjAzMDU5Mg==&mid=2652580107&idx=1&sn=5beca6aae900b1bc5d1a9c9b0769bc58&scene=19#wechat_redirect',
        // },
        // {
        //   id: 11,
        //   title: "伊家助力丨“都是伊家”慈爱基金首笔资金发放",
        //   update: "2021-04-03",
        //   view: 13,
        //   icon: "http://dssq.ikeqiao.net/data/image/h5/images/ykyy/fcyj2.png",
        //   url: 'https://mp.weixin.qq.com/s?__biz=MzI4MjAzMDU5Mg==&mid=2652580001&idx=1&sn=b8727bcbfefae8172cd4bcdb533e3557&scene=19#wechat_redirect',

        // },
      ],
      honorList1: [
        {
          title: '第一季度浙江省“最美家庭”',
          year: '2021年'
        },
        {
          title: '绍兴市“最美家庭”',
          year: '2021年'
        },
        {
          title: '诸暨市巾帼文明岗',
          year: '2019年'
        },
        {
          title: '浙江省儿童之家规范化建设试点单位',
          year: '2019年'
        },
        {
          title: '浙江省家长示范学校',
          year: '2019年'
        },
        {
          title: '绍兴市优秀基层妇联',
          year: '2017年'
        },
        {
          title: '诸暨市三八红旗集体',
          year: '2015年'
        }
      ],
      honorScroll: 0,
      timer: null,
    };
  },
  computed: {
  },
  components: {},
  mounted() {
    this.createInterval();
    this.getDataList(3);
    this.getDataList(5);
  },
  beforeDestroy() {
    clearInterval(this.timer);
    this.timer = null;
  },
  watch: {
   
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    handleTab() {

    },
    openUrl(data) {
      if (data.content && data.contentType === 1) {
        // 增加浏览量
        Api.addArticleVisit({ id: data.id }).then((res) => {});
        window.location.href = data.content;
      } else if (data.contentType === 0) {
        this.$router.push(`resouArticleDetail?id=${data.id}`);
      }
    },
    gotoLink(path) {
      if(path.indexOf('http') === -1) {
        this.$router.push(path);
      } else {
        window.open(path);
      }
    },
    createInterval() {
      // 滚动荣誉计时器
      this.timer = setInterval(() => {
        this.honorScroll = (this.honorScroll + 0.08) % (24 * (this.honorList1.length-1.5));
        this.$refs.honor.scrollTop = this.honorScroll;
      }, 10);
    },
    getDataList(type) {
      Api.getHongArticleList({
        page: 1,
        pageSize: 4,
        type,
      })
      .then((res) => {
        const { records = [] } = res.data || {};
        if(type === 3) {
          this.list = records;
        }
        if(type === 5) {
          this.list2 = records;
        }
      })
      .catch((err) => {
        console.log('error====>', err);
      });
    },
  }
};
</script>

<style lang="scss" scoped>
.yikeyouyue-container {
  height: 100vh;
  width: 100vw;
  
  .yikeyouyue-body-container {
    height: calc(100vh - 46px);
    width: 100vw;
    padding-bottom: 10px;
    background: url('http://dssq.ikeqiao.net/data/image/h5/images/hong/yikeyouyue-bg.png') no-repeat;
    background-size: 100% 100%;
    overflow-y: auto;
    overflow-x: hidden;
    .top-bar {
      >img {
        width: 100vw;
      }
      
    }

    .rongyuyijia-con {
      width: calc(100vw - 2rem);
      margin: 1rem;
      height: 92px;
      border-radius: 4px;
      background: url('http://dssq.ikeqiao.net/data/image/h5/images/hong/rongyuyijia-bg.png') no-repeat;
      background-size: 100% 100%;
      padding: 15px;
      display: flex;
      .rongyu-title {
        width: 5rem;
        display: flex;
        align-items: center;
        margin-left: 5px;
        font-size: 20px;
        line-height: 22px;
        color: #E62029;
      }
      .scroll-con {
        width: 100%;
        height: 100%;
        // padding: 5px 1rem;
        overflow-x: hidden;
        overflow-y: scroll;
        transition: all 1s;
        .honor-list {
          width: 100%;
          .honor-list-item {
            width: 100%;
            display: flex;
            align-items: center;
            min-height: 24px;
            color: rgba(0,0,0,0.85);
            .honor-tag {
              width: 24px;
              height: 24px;
              background: url('http://dssq.ikeqiao.net/data/image/h5/images/hong/rongyu.png') no-repeat;
              background-size: 100% 100%;
              display: inline-block;
              margin-right: 6px;
            }
            >h3 {
              word-break: break-all;
              flex: 1;
              font-size: 16px;
              font-weight: 400;
              line-height: 18px;
            }
            >span {
              width: 60px;
              text-align: right;
              font-size: 14px;
            }
          }
        }
      }
      
    }

    .yikeyouyue-card {
      width: calc(100vw - 30px);
      padding: 10px;
      margin: 15px;
      background-color: #FFF;
      border-radius: 4px;
      >h1 {
        font-size: 16px;
        color: #E62029;
        font-weight: 500;
        margin: 10px 0;
      }
      .card-bar-title {
        width: 100%;
        // height: 2rem;
        padding: 10px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        > div {
          height: 100%;
          display: flex;
          align-items: center;
          .title-bar {
            height: 18px;
            width: 4px;
            background: #4479ED;
            margin-right: 10px;
          }
          .title-text {
            font-size: 16px;
            color: #E62029;
            font-weight: 500;
          }
          .more {
            font-size: 14px;
            color: rgba(0,0,0,0.45);
          }
          > img {
            height: 12px;
            width: 12px;
          }
          i {
            color: #4479ED;
          }
        }
      }
      .icon-bar {
        margin: 10px;
        width: calc(100% - 20px);
        height: 108px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        background: #FFF;
        border-radius: 4px;
        >div {
          width: 20%;
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          padding-top: 15px;
          .icon {
            width: 47px;
            height: 47px;
            margin-bottom: 5px;
          }
          >p {
            text-align: center;
            font-size: 10px;
            font-weight: 400;
          }
        }
      }
      .fl-item {
        margin: 10px 10px 0 10px;
        padding: 10px 0;
        .fl-title {
          display: flex;
          align-items: flex-start;
          >img {
            width: 7rem;
            margin-left: 10px;
          }
          >div {
            flex: 1;
            >h1 {
              font-size: 16px;
              line-height: 32px;
              color: rgba(0,0,0,0.85);
              font-weight: 500;
            }
            .fl-desc {
              display: flex;
              height: 24px;
              align-items: center;
              font-size: 12px;
              color: rgba(0,0,0,0.45);
              .time, .view {
                width: 16px;
                height: 16px;
                display: inline-block;
                background: url(http://dssq.ikeqiao.net/data/image/h5/images/hong/time.png) no-repeat;
                background-size: 100% 100%;
              }
              .view {
                background: url(http://dssq.ikeqiao.net/data/image/h5/images/hong/view.png) no-repeat;
                background-size: 100% 100%;
              }
            }
          }
          
        }
        
      }
    }
    

  }
}
</style>
