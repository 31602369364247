<template>
  <div id="index">
    <div class="edu-container">
      <header class="eduheader-nav">
        <i @click="goBack()"></i>
        <h1>我想报名</h1>
      </header>
      <div class="banner m-top">
        <img src="http://dssq.ikeqiao.net/data/image/h5/images/educationSource/images/apply-banner.png" />
      </div>
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="找机构" name="first">
          <div class="search clearbg">
            <div class="box">
              <div class="mint-search">
                <el-input placeholder="请输入内容" v-model="search">
                  <i slot="prefix" class="el-input__icon el-icon-search"></i>
                </el-input>
                <i class="el-icon-error"></i>
              </div>
              <el-button type="primary" icon="icon-cancel">筛选</el-button>
            </div>
            <!--
            <div class="cate">
              <span class="selected">文化科目</span><span>艺体科学</span
              ><span>艺术类</span><span>其他</span>
            </div>
            -->
          </div>
          <div class="organ-detail clearfix">
            <ul>
              <li v-for="showOrg in orgList" :key="showOrg.id">
                <div class="float-l img"><img :src="getSrc(showOrg.id)" /></div>
                <div class="float-l desc">
                  <div class="title">
                    {{ showOrg.orgName }}
                  </div>
                  <div class="star">
                    <el-rate
                      v-model="showOrg.stars"
                      disabled
                      show-score
                      :colors="colors"
                      text-color="#dc6e86"
                      :score-template="showOrg.stars + ''"
                    ></el-rate>
                  </div>
                  <p>{{ showOrg.address }}</p>
                  <div class="state">
                    <div class="float-l">
                      <span class="exam-tag tag1">审批合格</span>
                    </div>
                    <div class="float-r">
                      <span class="commbtn" @click="gotoLink('/organDetail')"
                        >详情</span
                      >
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </el-tab-pane>
        <el-tab-pane label="找课程" name="second">
          <div class="search clearbg">
            <div class="box">
              <div class="mint-search">
                <el-input placeholder="请输入内容" v-model="search">
                  <i slot="prefix" class="el-input__icon el-icon-search"></i>
                </el-input>
                <i class="el-icon-error"></i>
              </div>
              <el-button type="primary" icon="icon-cancel">筛选</el-button>
            </div>
          </div>
          <div class="class-detail clearfix">
            <ul>
              <li>
                <div class="tit"><i></i><b>国舞五级</b></div>
                <div class="info">
                  <div class="tag"><span>艺体科技</span><span>小学</span></div>
                  <div class="desc">
                    简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介
                  </div>
                  <div class="clearfix remark">
                    <div class="float-l">
                      <span><i class="name"></i>赵老师</span>
                      <span><i class="time"></i>周六 9：30；周日 9：30</span>
                    </div>
                    <div class="float-r">
                      <span class="commbtn" @click="tip">详情</span>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div class="tit"><i></i><b>国舞五级</b></div>
                <div class="info">
                  <div class="tag"><span>艺体科技</span><span>小学</span></div>
                  <div class="desc">
                    简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介
                  </div>
                  <div class="clearfix remark">
                    <div class="float-l">
                      <span><i class="name"></i>赵老师</span>
                      <span><i class="time"></i>周六 9：30；周日 9：30</span>
                    </div>
                    <div class="float-r">
                      <span class="commbtn" @click="tip">详情</span>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div class="tit"><i></i><b>国舞五级</b></div>
                <div class="info">
                  <div class="tag"><span>艺体科技</span><span>小学</span></div>
                  <div class="desc">
                    简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介
                  </div>
                  <div class="clearfix remark">
                    <div class="float-l">
                      <span><i class="name"></i>赵老师</span>
                      <span><i class="time"></i>周六 9：30；周日 9：30</span>
                    </div>
                    <div class="float-r">
                      <span class="commbtn" @click="tip">详情</span>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div class="tit"><i></i><b>国舞五级</b></div>
                <div class="info">
                  <div class="tag"><span>艺体科技</span><span>小学</span></div>
                  <div class="desc">
                    简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介
                  </div>
                  <div class="clearfix remark">
                    <div class="float-l">
                      <span><i class="name"></i>赵老师</span>
                      <span><i class="time"></i>周六 9：30；周日 9：30</span>
                    </div>
                    <div class="float-r">
                      <span class="commbtn" @click="tip">详情</span>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div class="tit"><i></i><b>国舞五级</b></div>
                <div class="info">
                  <div class="tag"><span>艺体科技</span><span>小学</span></div>
                  <div class="desc">
                    简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介
                  </div>
                  <div class="clearfix remark">
                    <div class="float-l">
                      <span><i class="name"></i>赵老师</span>
                      <span><i class="time"></i>周六 9：30；周日 9：30</span>
                    </div>
                    <div class="float-r">
                      <span class="commbtn" @click="tip">详情</span>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </el-tab-pane>
        <el-tab-pane label="找老师" name="third">
          <div class="search clearbg">
            <div class="box">
              <div class="mint-search">
                <el-input placeholder="请输入内容" v-model="search">
                  <i slot="prefix" class="el-input__icon el-icon-search"></i>
                </el-input>
                <i class="el-icon-error"></i>
              </div>
              <el-button type="primary" icon="icon-cancel">筛选</el-button>
            </div>
          </div>
          <div class="teacher-detail clearfix">
            <ul>
              <li>
                <div class="float-l img">
                  <img
                    src="http://dssq.ikeqiao.net/data/image/h5/images/educationSource/images/teacher0.jpg"
                  />
                </div>
                <div class="float-l desc">
                  <div class="tit"><i></i><b>赵国灿</b><span>音乐</span></div>
                  <div class="info">
                    <p>上海音乐学院</p>
                    <p class="state">
                      中国音乐家协会考级优秀指导教师
                    </p>
                  </div>
                </div>
              </li>
              <li>
                <div class="float-l img">
                  <img
                    src="http://dssq.ikeqiao.net/data/image/h5/images/educationSource/images/teacher1.jpg"
                  />
                </div>
                <div class="float-l desc">
                  <div class="tit"><i></i><b>余 斐</b><span>音乐</span></div>
                  <div class="info">
                    <p>
                      杭州师范大学
                    </p>
                    <p class="state">
                      浙江歌舞剧院考级优秀指导教师
                    </p>
                  </div>
                </div>
              </li>
              <li>
                <div class="float-l img">
                  <img
                    src="http://dssq.ikeqiao.net/data/image/h5/images/educationSource/images/teacher2.jpg"
                  />
                </div>
                <div class="float-l desc">
                  <div class="tit"><i></i><b>陈学东</b><span>音乐</span></div>
                  <div class="info">
                    <p>
                      西南大学
                    </p>

                    <p class="state">
                      中国音乐家协会考级优秀指导教师
                    </p>
                  </div>
                </div>
              </li>
              <li>
                <div class="float-l img">
                  <img
                    src="http://dssq.ikeqiao.net/data/image/h5/images/educationSource/images/teacher3.jpg"
                  />
                </div>
                <div class="float-l desc">
                  <div class="tit"><i></i><b>梁昕彦 </b><span>美术</span></div>
                  <div class="info">
                    <p>
                      绍兴文理学院
                    </p>

                    <p class="state">
                      国粹杯优秀指导老师
                    </p>
                  </div>
                </div>
              </li>
              <li>
                <div class="float-l img">
                  <img
                    src="http://dssq.ikeqiao.net/data/image/h5/images/educationSource/images/teacher4.jpg"
                  />
                </div>
                <div class="float-l desc">
                  <div class="tit"><i></i><b>王 俊 </b><span>美术</span></div>
                  <div class="info">
                    <p>
                      兰亭书法学院
                    </p>

                    <p class="state">
                      温州市少年宫比赛优秀组织奖
                    </p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeName: "first",
      orgList: [],
      colors: ["#99A9BF", "#dc6e86", "#dc6e86"],
      search: "",
    };
  },
  components: {},
  mounted() {
    this.getOrgList();
  },
  methods: {
    gotoLink(path) {
      this.$router.push(path);
    },
    goBack() {
      this.$router.go(-1);
    },
    tip() {
      this.$message("页面正在建设中");
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    getOrgList() {
      let _this = this;
      //获取机构信息
      this.axios
        .get("http://124.91.150.6:8071/app/train/list")
        .then(function(response) {
          let result = response.data.list;
          result.forEach((val, index) => {
            let gis = val.gis;
          });
          _this.orgList = result;
        })
        .catch(function(err) {
          // eslint-disable-line no-unused-vars
        });
    },
    getSrc(id) {
      return "http://dssq.ikeqiao.net/data/image/h5/images/face-" + id + ".jpg";
    },
    getTeacherList() {},
    getClassList() {},
  },
};
</script>

<style lang="scss">
@import "~assets/scss/educate.scss";
</style>
