<template>
  <van-list
    v-model="loading"
    :finished="listFinished"
    finished-text="没有更多了"
    @load="onLoad"
    class="course-list"
  >
    <ul>
      <li class="card-bar" v-for="(item, index) in dataList" :key="index">
        <div :class="['card-bar-title', {'art': item.type === '艺术类'}]">
          <span class="text-title">{{item.title}}</span>
          <span class="tag">{{item.type}}</span>
        </div>
        <div class="card-bar-content">
          <div class="desc">{{item.desc}}</div>
          <div class="tag-list">
            <span class="tag" v-for="tag in item.tagList" :key="tag">{{tag}}</span>
          </div>
          <div class="row" style="border-bottom: 0.5px solid rgba(17,31,44,0.10);">
            <div class="item">
              <span class="label">机构：</span>{{item.depart}}
            </div>
          </div>
          <div class="row">
            <div class="item">
              <span class="label">老师：</span>{{item.teacher}}
            </div>
            <div class="item">
              <span class="label">时间：</span>{{item.time}}
            </div>
          </div>
        </div>
      </li>
    </ul>
  </van-list>
</template>
<script>
import nrbApi from "api/llyjr/nrb";
import { mapState } from 'vuex';
export default {
  name: 'courseList',
  data() {
    return {
      current: 1,
      pageSize: 10,
      total: 0,
      dataList: [],
      loading: false,
      listFinished: false,
    }
  },
  computed: {
    ...mapState({
      selectedTab: (state) => state.llyjr.selectedTab,
    }),
    belongOwn() {
      return this.selectedTab === '我的发布' ? 'yes' : 'no'
    }
  },
  mounted() {
    this.getDataList();
  },
  methods: {
    onLoad() {
      this.getDataList({page: this.current});
      this.current += 1;
    },
    gotoLink(path) {
      this.$router.push(path);
    },
    getDataList({ page = this.page, size = this.size } = {}) {
      const { status = '', category = '' } = this;
      this.loading = false;
      // nrbApi
      //   .getNrbList({
      //     page,
      //     size,
      //     object: {
      //       className: category,
      //       issueStatus: status,
      //       belongOwn: this.belongOwn,
      //     },
      //   })
      //   .then((res) => {
      //     console.log('res', res);
          
      //     this.loading = false;
      //     const { list = [], total = 0 } = res.data || {};
      //     if(page === 1) {
      //       this.dataList = list;
      //     } else {
      //       this.dataList = Array.from(new Set(this.dataList.concat(list)))
      //     }
      //     this.total = total;
      //     if (this.dataList.length >= this.total) {
      //       // 数据全部加载完成
      //       this.listFinished = true;
      //     }
      //   })
      //   .catch((err) => {
      //     console.log('error====>', err);
      //     this.loading = false;
      //   });
      this.dataList = [
        {
          title: '国舞五级',
          type: '艺术类',
          desc: '课程介绍课程介绍课程介绍课程介绍课程介绍课程介绍课程介绍课程介绍课程介绍',
          tagList: ['一年级', '二年级', '三年级'],
          teacher: '张铭',
          depart: '诸暨中心培训基地',
          time: '周六-周日 9:00-12:00'
        },
        {
          title: '其他类',
          type: '其他类',
          desc: '课程介绍课程介绍课程介绍课程介绍课程介绍课程介绍课程介绍课程介绍课程介绍',
          tagList: ['一年级', '二年级', '三年级'],
          teacher: '张铭',
          depart: '诸暨中心培训基地',
          time: '周六-周日 9:00-12:00'
        },
      ];
      this.total = 2;
      this.listFinished = true;
    },
  }
}
</script>
<style lang="scss" scoped>
.course-list {
  width: 100%;
  margin: 0 auto;
  .card-bar {
    width: calc(100% - 20px);
    margin: 10px;
    border-radius: 10px;
    background-color: #fff;
    .card-bar-title {
      width: 100%;
      height: 33px;
      padding: 10px;
      background: #fff;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      background: url("http://dssq.ikeqiao.net/data/image/h5/images/service/other.png") no-repeat;
      background-size: 100% 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .text-title {
        font-size: 18px;
        color: #fff !important;
        width: 50%;
      }
      .tag {
        background-color: rgba($color: #FFF, $alpha: 0.9);
        font-size: 12px;
        color: #4479ED;
        text-align: center;
        border-radius: 2px 8px;
        padding: 2px;
      }
    }
    .art {
      background: url("http://dssq.ikeqiao.net/data/image/h5/images/service/art.png") no-repeat;
      background-size: 100% 100%;
      .text-title {
        font-size: 18px;
        color: #fff !important;
      }
    }
    
    .card-bar-content {
      width: 100%;
      background-color: #fff;
      font-size: 14px;
      color: rgba($color: #000000, $alpha: 0.85);
      line-height: 22px;
      padding: 10px;
      .tag-list {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        margin: 10px 0;
        .tag {
          background: rgba(68,121,237,0.15);
          border-radius: 2px;
          font-size: 12px;
          color: #4479ED;
          letter-spacing: 0;
          text-align: center;
          line-height: 12px;
          padding: 2px;
          margin-right: 5px;
        }
      }
      .divider {
        height: 1px;
        background: rgba(17,31,44,0.10);
        margin-bottom: 10px;
      }
      .row {
        display: flex;
        .item {
          margin-right: 10px;
          .label {
            color: rgba($color: #000000, $alpha: 0.45);
          }
        }
      }
    }
  }
}
</style>