<template>
  <div style="padding: 3rem 0">
    <van-nav-bar
      fixed
      class="butler-nav-bar"
      title="约运动"
      left-arrow
      @click-left="$router.go(-1)"
    />
    <van-tabs color="#3E71F9" v-model="active">
      <van-tab title="全部"></van-tab>
      <van-tab title="我的"></van-tab>
      <van-tab title="跑步"></van-tab>
      <van-tab title="游泳"></van-tab>
    </van-tabs>
    <div class="body" v-if="tableData.length">
      <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
        >
          <div
            class="list_item"
            v-for="(item, index) in tableData"
            :key="index"
            @click="
              $router.push(`/yundong/detail?id=${item.id}`)
            "
          >
            <div class="top">
              <div class="left">
                <div class="title">{{ item.name }}</div>
                <div class="address circle">{{ item.address }}</div>
                <div class="time circle">{{ item.time }}</div>
              </div>
              <div :class="['right']">
                <div
                  :class="[
                    'semicircle',
                    item.cost == 'AA' ? 'green' : 'yellow',
                  ]"
                ></div>
                <div :class="['con', item.cost == 'AA' ? 'green' : 'yellow']">
                  {{ item.cost }}制
                </div>
              </div>
            </div>
            <div class="bottom">
              <div class="left">
                <van-icon
                  class="icon"
                  name="https://b.yzcdn.cn/vant/icon-demo-1126.png"
                />
                <span class="user">{{ item.publishMan }}</span>
              </div>
              <div class="right">
                仅剩
                <span class="surplu">{{ item.peopleNum }}</span>
                个位置
              </div>
            </div>
          </div>
        </van-list>
      </van-pull-refresh>
      <div class="add">
        <van-button type="primary" @click="$router.push('/yundong/add')" block
          >新增</van-button
        >
      </div>
    </div>
    <div v-else class="no-data">
      <van-empty description="暂无内容">
        <div class="desc">
          <div>该项目还没有约运动，第一个创建成功率更高哦~</div>
          <a @click="$router.push('/yundong/add')">新增</a>
        </div>
      </van-empty>
    </div>
  </div>
</template>

<script>
import changguanApi from 'api/changguan';
export default {
  data() {
    return {
      active: 0,
      loading: false,
      finished: false,
      isLoading: false,
      tableData: [],
      allData: [
        // {
        //   id: 1,
        //   title: "大家一起来打篮球",
        //   address: "转塘健身中心",
        //   time: "今天10:00-11:00",
        //   user: "万大可",
        //   surplu: 3,
        //   type: "AA制",
        // },
        // {
        //   id: 1,
        //   title: "羽毛球运动",
        //   address: "转塘健身中心",
        //   time: "今天19:00-21:00",
        //   user: "黄菡",
        //   surplu: 1,
        //   type: "今日特约",
        // },
        // {
        //   id: 1,
        //   title: "乒乓球比赛",
        //   address: "云栖小镇运动场",
        //   time: "明天19:00-21:00",
        //   user: "阿里巴巴",
        //   surplu: 10,
        //   type: "官方比赛",
        // },
        // {
        //   id: 1,
        //   title: "西湖夜跑",
        //   address: "西湖",
        //   time: "明天22:00-23:00",
        //   user: "peter",
        //   surplu: 1,
        //   type: "今日特约",
        // },
      ],
      myData: [
        // {
        //   id: 1,
        //   title: "大家一起来打篮球",
        //   address: "转塘健身中心",
        //   time: "今天10:00-11:00",
        //   user: "万大可",
        //   surplu: 3,
        //   type: "AA制",
        // },
      ],
      runData: [
        {
          id: 1,
          title: "西湖夜跑",
          address: "西湖",
          time: "明天22:00-23:00",
          user: "peter",
          surplu: 1,
          type: "今日特约",
        },
      ],
      swimData: [
        // {
        //   title: "游泳学习",
        //   address: "西湖游泳馆",
        //   time: "明天10:00-20:00",
        //   user: "西湖游泳馆",
        //   surplu: 20,
        //   type: "AA制",
        // },
      ],
    };
  },
  watch: {
    active(val) {
      console.log(val);
      switch (val) {
        case 0:
          this.tableData = [].concat(this.allData);
          break;
        case 1:
          this.tableData = [].concat(this.myData);
          break;
        case 2:
          this.tableData = [].concat(this.allData.filter(item => item.name.indexOf('长跑') > 0));
          break;
        case 3:
          this.tableData = [].concat(this.swimData);
          break;
      }
    },
  },
  created() {
    this.getAllData();
    this.tableData = [].concat(this.allData);
  },
  methods: {
    onLoad() {
      //请求
      // this.tableData = this.allData;
      this.finished = true;
    },
    onRefresh() {
      this.isLoading = false;
    },
    getAllData() {
      changguanApi.getSports().then(res => {
        if(res.flag) {
          this.allData = res.data;
          this.tableData = res.data;
        }
      })
    }
  },
};
</script>

<style lang="scss" scoped>
.body {
  padding: 0 1rem;

  .circle {
    &::before {
      position: absolute;
      width: 0.4rem;
      height: 0.4rem;
      background: #3e71f9;
      content: "";
      left: -0.8rem;
      top: 0.2rem;
      border-radius: 50%;
    }
  }

  .blue {
    background-color: #1989fa;
  }
  .green {
    background-color: #1989fa;
    color: #fff;
  }

  .yellow {
    background-color: #ffaa36;
  }

  .list_item {
    padding: 0.75rem 0 1rem 0;
    margin-top: 1rem;
    color: #666;
    font-size: 0.88rem;
    background: #ffffff;
    border-radius: 4px;
    border-radius: 4px;

    .top {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;

      .left {
        margin-left: 1rem;

        .title {
          color: #000;
          font-size: 1rem;
        }
        .address {
          margin-top: 0.5rem;
          margin-left: 0.8rem;
          position: relative;
        }
        .time {
          margin-top: 0.5rem;
          margin-left: 0.8rem;
          position: relative;
        }
      }

      .right {
        display: flex;
        height: 2rem;
        .semicircle {
          width: 1rem;
          height: 2rem;
          border-radius: 1rem 0 0 1rem;
          line-height: 2rem;
        }
        .con {
          line-height: 2rem;
          padding-right: 0.75rem;
        }
      }
    }

    .bottom {
      margin-top: 0.8rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .left {
        margin-left: 1.6rem;
        display: flex;
        align-items: center;
        .icon {
          font-size: 1.5rem;
        }
        .user {
          margin-left: 0.6rem;
        }
      }
      .right {
        margin-right: 1rem;

        .surplu {
          font-size: 16px;
          color: #ffaa36;
        }
      }
    }
  }

  .add {
    position: fixed;
    bottom: 1rem;
    width: calc(100% - 2rem);
    .van-button--primary{
      background: #1989fa;
    }
  }
}
.no-data {
  .desc {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;

    > div {
      font-size: 16px;
      color: #555;
    }

    > a {
      margin-top: 2.2rem;
      font-size: 1.13rem;
      color: #3e71f9;
    }
  }
}

::v-deep .van-tabs__line {
  z-index: 0;
}
</style>