<template>
  <div class="own-index" style="background-color:#f5f5f5">
    <div class="page-container">
      <div class="header-nav">
        <i @click="goBack"></i>
        <h1>选择小区</h1>
      </div>
      <div class="park-main">
           <div class="daryLine"></div>
          <ul class="login-list">
               <li v-for="item in data" :key="item.value" @click="handleSelect(item)">
                 {{ item.key }}
              </li>
          </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
        data:[]
    };
  },
  components: {},
  mounted() {
    this.getCommunityData();
  },
  methods: {
    gotoLink(path,detail) {
      this.$router.push({ name: path, params: { detail: detail } });
    },
    goBack() {
      this.$router.go(-1);
    },
    showAlert() {
      this.$messagebox("提示", "系统正在建设中，尽情期待~");
    },
    getCommunityData() {
      this.$store.dispatch('login/getCommunityData', { params:{}, obj: this });
    },
    handleSelect(data) {
      this.$store.commit('login/selectCommunity', data);
      this.$router.push('loginhouse')
    }
  },
};
</script>

<style lang="scss">
@import "~assets/scss/index.scss";
</style>
