<template>
  <div id="index">
    <div class="page-container">
      <div class="header-nav">
        <i @click="goBack"></i>
        <h1>献血报名</h1>
      </div>
      <div class="form-bar">
        <van-form @submit="onSubmit">
          <van-cell title-class="cell" title="献血形式" size="large">
            <template #default>
              <span
                :class="['radio', { selected: type === '全血' }]"
                @click="changeType('全血')"
                >全血</span
              >
              <span
                :class="['radio', { selected: type === '成分血' }]"
                @click="changeType('成分血')"
                >成分血</span
              >
            </template>
          </van-cell>
          <van-field
            size="large"
            v-model="name"
            name="name"
            label="姓名"
            placeholder="请填写"
            :rules="[{ required: true, message: '请填写姓名' }]"
            class="input-bar"
          />
          <van-field
            size="large"
            v-model="phone"
            name="phone"
            label="联系电话"
            placeholder="请输入"
            :rules="[{ required: true, message: '请填写联系电话' }]"
            class="input-bar"
          />
          <van-field
            size="large"
            v-model="idCard"
            name="idCard"
            label="身份证号"
            placeholder="请输入"
            :rules="[{ required: true, message: '请填写身份证号' }]"
            class="input-bar"
          />
          <van-cell title-class="cell" title="性别" size="large">
            <template #default>
              <span
                :class="['radio', { selected: sex === '男' }]"
                @click="changeSex('男')"
                >男</span
              >
              <span
                :class="['radio', { selected: sex === '女' }]"
                @click="changeSex('女')"
                >女</span
              >
            </template>
          </van-cell>
          <div class="divide" />
          <van-field
            size="large"
            v-model="date"
            name="appointTime"
            is-link
            readonly
            label="预约日期"
            placeholder="请选择"
            @click="showDate = true"
            :rules="[{ required: true, message: '请选择日期' }]"
            class="input-bar"
          />
          <van-field
            size="large"
            v-model="depart"
            name="depart"
            is-link
            readonly
            label="献血机构"
            placeholder="请选择"
            @click="showDepart = true"
            :rules="[{ required: true, message: '请选择献血机构' }]"
            class="input-bar"
          />
          <div class="footer-button">
            <van-button
              round
              block
              type="info"
              style="font-size: 16px"
              native-type="submit"
              >提交预约</van-button
            >
          </div>
        </van-form>
      </div>
      <van-calendar v-model="showDate" @confirm="onDateConfirm" />
      <van-popup v-model="showDepart" position="bottom">
        <van-picker
          title="献血机构"
          show-toolbar
          :columns="departList"
          @confirm="onDepartConfirm"
          @cancel="onCancel('showDepart')"
        />
      </van-popup>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
import commonApi from "api/common";
import loveActivityApi from "api/ddya/loveActivity";
import { mapState } from "vuex";
import context from '@/main';
export default {
  data() {
    return {
      type: "全血",
      sex: "男",
      name: "",
      phone: "",
      idCard: "",
      date: "",
      showDate: false,
      showDepart: false,
      depart: "",
      departList: [],
    };
  },
  components: {},
  mounted() {
    loveActivityApi.getMechanism().then((res) => {
      if (res.data.length) {
        const { data } = res;
        data.map((item) => this.departList.push(item.mechanism));
      }
    });
    this.name = this.userInfo.userName || this.userInfo.nickname;
  },
  computed: {
    ...mapState({
      userInfo: (state) =>
        state.login.userInfo || context.getSessionData("userInfo"),
    }),
  },
  methods: {
    gotoLink(path) {
      this.$router.push(path);
    },
    goBack() {
      this.$router.go(-1);
    },
    showAlert() {
      this.$messagebox("提示", "系统正在建设中，尽情期待~");
    },
    changeType(val) {
      this.type = val;
    },
    changeSex(val) {
      this.sex = val;
    },
    onCancel(type) {
      this[type] = false;
    },
    onDateConfirm(value, index) {
      this.date = this.formatDateTime(value);
      this.showDate = false;
    },
    onDepartConfirm(value, index) {
      this.depart = value;
      this.showDepart = false;
    },
    formatDateTime(date) {
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      var d = date.getDate();
      d = d < 10 ? "0" + d : d;
      var h = date.getHours();
      var minute = date.getMinutes();
      minute = minute < 10 ? "0" + minute : minute;
      return y + "-" + m + "-" + d;
    },
    onSubmit(values) {
      const params = {
        sex: this.sex,
        type: this.type,
        ...values,
      };
      loveActivityApi.donateBlood(params).then((res) => {
        if (res.data) {
          this.$messagebox("提示", res.data);
        } 
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.page-container {
  background: #ffffff;
  padding: 0.6rem 0;
  height: calc(100vh - 40px);
  width: 100vw;
  margin-top: 40px;
  .form-bar {
    .cell {
      color: rgba(0, 0, 0, 0.65);
    }
    .radio {
      height: 2rem;
      border-radius: 1rem;
      // background: #4479ED;
      color: rgba(0, 0, 0, 0.35);
      border: 1px solid rgba(0, 0, 0, 0.35);
      padding: 0.1rem 1.2rem;
      margin-left: 8px;
    }
    .selected {
      background: #4479ed;
      color: #ffffff;
      border: 1px solid #4479ed;
    }
    .divide {
      height: 0.8rem;
      background: #f6f7fa;
    }
    .footer-button {
      position: fixed;
      bottom: 0.8rem;
      right: 0.8rem;
      left: 0.8rem;
    }
  }
}
</style>
