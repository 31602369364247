<template>
  <div class="memberManage-container">
    <van-nav-bar
      title="需求详情"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="activity-need-detail-body-container">
      <!-- top bar -->
      <div class="top-bar">
        <div class="item">
          <div class="itemName">
            <div class="image">
              <img :src="data.image" alt="">
            </div>
            <div class="text">
              <div class="title">
                {{data.userName}}
                <span class="tag">{{data.tag}}</span>
              </div>
              <div>{{`发布时间：${data.createTime}`}}</div>
            </div>
          </div>
          <div class="itemDesc">
            {{data.commentContent}}
          </div>
          <div class="image-list-con">
            <img v-for="(item, index) in data.images" :src="item" :key="index" alt="">
          </div>
        </div>
      </div>
      <!-- 接单信息 -->
      <div class="grab-con" v-if="data.result === '联盟已接单'">
        <div class="grab-top">
          <span class="grab-title">接单信息</span>
          <span class="grab-cancel" @click="data.result = '联盟未接单'">放弃接单</span>
        </div>
        <div class="grab-info">
          <div class="info-row">
            <span class="label">接单人：</span>
            <span class="value">{{data.acceptPerson}}</span>
          </div>
          <div class="info-row">
            <span class="label">所属联盟：</span>
            <span class="value">{{data.orginazition}}</span>
          </div>
          <div class="info-row">
            <span class="label">接单时间：</span>
            <span class="value">{{data.acceptTime}}</span>
          </div>
          <div class="info-row">
            <span class="label">接单理由：</span>
            <span class="value">{{data.acceptReason}}</span>
          </div>
        </div>
      </div>
      <!-- 抢单信息 -->
      <div class="grab-con" v-if="data.status === '已抢单'">
        <div class="grab-top">
          <span class="grab-title">抢单信息</span>
          <span class="grab-cancel" @click="data.status = '未抢单'" v-if="data.result === '联盟未接单'">放弃抢单</span>
        </div>
        <div class="grab-info">
          <div class="info-row">
            <span class="label">抢单人：</span>
            <span class="value">{{data.userName}}</span>
          </div>
          <div class="info-row">
            <span class="label">所属支部：</span>
            <span class="value">{{data.depart}}</span>
          </div>
          <div class="info-row">
            <span class="label">抢单时间：</span>
            <span class="value">{{data.time}}</span>
          </div>
          <div class="info-row">
            <span class="label">抢单理由：</span>
            <span class="value">{{data.reason}}</span>
          </div>
        </div>
      </div>
      
      <!-- 按钮 -->
      <div v-if="data.status === '未抢单'" class="footer-button">
        <van-button
          block
          type="info"
          style="font-size: 16px"
          @click="show = true"
        >
          支部抢单
        </van-button>
      </div>
      <div class="footer-button" v-if="data.status === '已抢单' && data.result === '联盟未接单'">
        <van-button
          block
          type="info"
          style="font-size: 16px"
          @click="showAccept = true"
        >
          联盟接单
        </van-button>
      </div>
      <!-- 抢单确认提示 -->
      <van-action-sheet v-model="show" title="确认信息">
        <van-form @submit="onSubmit">
          <div class="section">
            <span class="label">抢单人</span>
            <span class="value">{{data.userName}}</span>
          </div>
          <div class="section">
            <span class="label">联系电话</span>
            <span class="value">{{data.phone}}</span>
          </div>
          <div class="section">
            <span class="label">所属支部</span>
            <span class="value">{{data.depart}}</span>
          </div>
          <div class="section">
            <div class="title">抢单理由</div>
            <van-field
              size="large"
              v-model="reason"
              rows="3"
              autosize
              name="reason"
              type="textarea"
              maxlength="200"
              placeholder="输入抢单理由"
              show-word-limit 
              :rules="[{ required: true, message: '输入抢单理由' }]"
            />
          </div>
          <div class="footer-button">
            <van-button
              block
              type="info"
              style="font-size: 16px"
              native-type="submit"
            >
              确认抢单
            </van-button>
          </div>
        </van-form>
      </van-action-sheet>
      <!-- 接单确认提示 -->
      <van-action-sheet v-model="showAccept" title="确认信息">
        <van-form @submit="handleAccept">
          <div class="section">
            <span class="label">接单人</span>
            <span class="value">{{data.acceptPerson}}</span>
          </div>
          <div class="section">
            <span class="label">联系电话</span>
            <span class="value">{{data.phone}}</span>
          </div>
          <div class="section">
            <span class="label">所属联盟</span>
            <span class="value">{{data.orginazition}}</span>
          </div>
          <div class="section">
            <div class="title">接单理由</div>
            <van-field
              size="large"
              v-model="acceptReason"
              rows="3"
              autosize
              name="acceptReason"
              type="textarea"
              maxlength="200"
              placeholder="输入接单理由"
              show-word-limit 
              :rules="[{ required: true, message: '输入接单理由' }]"
            />
          </div>
          <div class="footer-button">
            <van-button
              block
              type="info"
              style="font-size: 16px"
              native-type="submit"
            >
              确认接单
            </van-button>
          </div>
        </van-form>
      </van-action-sheet>
    </div>
  </div>
</template>

<script>
import homeApi from 'api/home';
import { mapMutations, mapState } from "vuex";
import moment from 'moment';
export default {
  data() {
    return {
      data: {
        image: 'http://dssq.ikeqiao.net/data/image/h5/images/coordination/defaultUser.png',
        userName: '张三',
        createTime: '2021-05-21 12:00',
        commentContent: '半云未来社区9幢2单元急需加装电梯，老年人上楼很不方便希望能安装电梯。半云未来社区9幢2单元急需加装电梯，老年人上楼很不方便希望能安装电梯。半云未来社区9幢2单元急需加装电梯，老年人上楼很不方便希望能安装电梯。半云未来社区9幢2单元急需加装电梯，老年人上楼很不方便希望能安装电梯。',
        hasThumbsUp: '已点赞',
        thumbsUp: 23,
        tag: '党员',
        images: [
          'http://dssq.ikeqiao.net/data/image/h5/images/coordination/placeDefault.png',
          'http://dssq.ikeqiao.net/data/image/h5/images/coordination/placeDefault.png',
          'http://dssq.ikeqiao.net/data/image/h5/images/coordination/placeDefault.png',
        ],
        status: '未抢单',
        depart: '润泽党支部',
        result: '联盟未接单',
        phone: '18822340101',
        acceptPerson: '李四',
        orginazition: '东盛社区党总支',
      },
      show: false,
      reason: '',
      showAccept: false,
      acceptReason: ''
    };
  },
  computed: {
  },
  components: {},
  mounted() {
    
  },
  watch: {
   
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    handleTab() {

    },
    gotoLink(path) {
      if(path) {
        this.$router.push(path);
      }
    },
    onSubmit(values) {
      console.log('values', values);
      this.show = false;
      this.data = {
        ...this.data,
        status: '已抢单',
        result: '联盟未接单',
        time: moment().format('YYYY-MM-DD HH:mm'),
        ...values,
      }
    },
    // 接单
    handleAccept(values) {
      this.data = {
        ...this.data,
        result: '联盟已接单',
        acceptTime: moment().format('YYYY-MM-DD HH:mm'),
        acceptReason: values.reason,
      };
      this.showAccept = false;

    }
  }
};
</script>

<style lang="scss" scoped>
.memberManage-container {
  height: 100vh;
  width: 100vw;
  
  .activity-need-detail-body-container {
    height: calc(100vh - 46px);
    width: 100vw;
    padding-bottom: 10px;
    background: url('http://dssq.ikeqiao.net/data/image/h5/images/coordination/coordination-bg.png') no-repeat;
    background-size: 100% 100%;
    overflow-y: auto;
    overflow-x: hidden;
    .top-bar {
      width: 100%;
      background-color: #FFF;
      padding: 10px;
      .item{
        position: relative;
        padding: 10px;
        background-color: #FFF;
        margin-bottom: 10px;
        border-radius: 4px;
        .itemName{
          display: flex;
          height: 50px;
          padding: 5px;
          .image{
            width: 40px;
            height: 40px;
            margin-right: 10px;
            >img{
              width: 100%;
              height: 100%;
              border-radius: 50%;
            }
          }
          .text{
            width: calc(100% - 50px);
            height: 100%;
            line-height: 20px;
            color: rgba(0,0,0,0.45);
            font-size: 12px;
            .title {
              color: rgba(0,0,0,0.85);
              font-size: 16px;
              font-weight: 500;
              .tag {
                background: #E3EFFD;
                border-radius: 2px;
                font-size: 12px;
                color: #4479ED;
                padding: 3px 6px;
              }
            }
            
            
          }
        }
        .itemDesc{
          width: 100%;
          color: rgba(0,0,0,0.85);
          line-height: 24px;
          font-size: 16px;
          margin-top: 10px;
        }
        .image-list-con {
          width: 100%;
          // padding: 10px;
          display: flex;
          flex-wrap: wrap;
          >img {
            width: 30%;
            height: 79px;
            border-radius: 4px;
            margin: 5px;
          }
        }
        .divider {
          height: 1px;
          width: 100%;
          background-color: rgba(17,31,44,0.10);
          margin: 10px 0;
        }
        .footer {
          font-size: 16px;
          display: flex;
          justify-content: space-between;
          line-height: 50px;
        }
      }

    }
    .grab-con {
      width: 100%;
      background-color: #FFF;
      padding: 10px;
      margin: 10px 0;
      .grab-top {
        width: 100%;
        display: flex;
        justify-content: space-between;
        .grab-title {
          font-size: 16px;
          color: rgba(0,0,0,0.85);
          font-weight: 500;
        }
        .grab-cancel {
          font-size: 16px;
          color: #4479ED;
          text-align: left;
          line-height: 26px;
          font-weight: 500;
          text-decoration: underline;
        }
      }
      .grab-info {
        width: 100%;
        font-size: 14px;
        line-height: 24px;
        .label {
          color: rgba(0,0,0,0.45);
        }
        .value {
          color: rgba(0,0,0,0.85);
        }
      }
    }
    .footer-button {
      position: fixed;
      bottom: 0.8rem;
      right: 0.8rem;
      left: 0.8rem;
      display: flex;
      align-items:  center;
    }
    .section {
      width: 100%;
      margin-bottom: 0.8rem;
      background: #ffffff;
      padding: 0.8rem;
      .title {
        // margin: 0.5rem 0;
        font-size: 16px;
        color: rgba(0,0,0,0.85);
        letter-spacing: 0;
        .required {
          color: #e13f30;
        }
      }
      >span {
        font-size: 16px;
        color: rgba(0,0,0,0.85);
      }
      .label {
        float: left;
      }
      .value {
        float: right;
      }
    }

  }
}
</style>
