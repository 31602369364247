<template>
  <div>
    <van-nav-bar
      fixed
      class="butler-nav-bar"
      :title="info.title"
      left-arrow
      @click-left="$router.go(-1)"
    />
    <div class="banner">
      <img :src="info.image" />
      <div :class="['type', info.cost == 'AA' ? 'green' : 'yellow']">
        {{ info.cost }}制
      </div>
      <div class="con">
        <div class="circle">{{info.name}}</div>
        <div class="circle">{{info.time}}</div>
        <div class="btns">
          <van-button
            v-if="!info.enter"
            size="small"
            round
            type="info"
            @click="show = true"
            >未报名</van-button
          >
          <van-button
            v-if="info.enter"
            size="small"
            round
            type="info"
            @click="cancelEnter"
            >取消报名</van-button
          >
          <van-button
            size="small"
            round
            type="info"
            @click="
              $router.push(`/changguan/detail?id=${info.venueId || 1}`)
            "
            >场馆详情</van-button
          >
        </div>
      </div>
    </div>
    <div class="desc">
      <div class="top">
        <div class="user">
          <van-icon class="icon" :name="head" />
          <span>{{info.publishMan}}</span>
        </div>
        <a href="tel:123">
          <van-icon class="icon" color="#3E71F9" name="phone-o" />
        </a>
      </div>
      <div class="bottom">
        {{info.description}}
      </div>
    </div>
    <van-tabs v-model="active" color="#3E71F9">
      <van-tab title="参赛者（6/6人）"></van-tab>
      <van-tab title="评论（3个）"></van-tab>
      <van-tab title="我要评论"></van-tab>
    </van-tabs>

    <div class="join" v-if="active == 0">
      <div class="join-item" v-for="(item, index) in userList" :key="index">
        <van-icon class="icon" :name="head" />
        <span>{{ item.name }}</span>
      </div>
    </div>

    <div class="comment" v-if="active == 1">
      <div v-if="commentList.length">
        <div
          class="comment-item"
          v-for="(item, index) in commentList"
          :key="index"
        >
          <div class="top">
            <van-icon class="icon" :name="head" />
            <span>{{ item.name }}</span>
          </div>
          <div class="con">
            <span v-if="item.showMore">{{ item.con }}</span>
            <span v-else>{{ item.con.slice(0, 40) }}</span>
            <span
              class="more"
              v-if="item.con.length > 40 && !item.showMore"
              @click="item.showMore = true"
              >更多</span
            >
          </div>
          <div class="time">
            <span class="time-con">{{ item.time }}</span>
          </div>
        </div>
      </div>
      <div v-else>
        <van-empty>
          <div style="font-size: 0.8rem">
            快来 <a style="color: #3e71f9" @click="active = 2">抢沙发</a>
          </div>
        </van-empty>
      </div>
    </div>

    <van-dialog
      v-model="show"
      title="确定要报名吗"
      show-confirm-button
      show-cancel-button
      @confirm="confirm"
    >
      <div style="padding: 1rem">
        <van-checkbox v-model="checked" shape="square"
          >已同意相关条款</van-checkbox
        >
      </div>
    </van-dialog>

    <van-dialog
      v-model="showSuccess"
      title="报名成功"
      show-confirm-button
      show-cancel-button
      cancelButtonText="取消报名"
      @cancel="info.enter = false"
    >
      <div style="padding: 1rem">已报名，等待对方同意。</div>
    </van-dialog>
  </div>
</template>

<script>
import changguanApi from 'api/changguan';
import { Toast } from "vant";
export default {
  name: "YundongDetail",
  data() {
    return {
      id: null,
      info: {},
      head: require("../../assets/images/yundong/head.png"),
      userList: [
        { name: "王大可" },
        { name: "王大可" },
        { name: "王大可" },
        { name: "王大可" },
        { name: "王大可" },
        { name: "王大可" },
      ],
      commentList1: [],
      commentList: [
        {
          name: "小汪",
          con: "不错，上次组织的篮球大家打的都非常好，这次继续参加！友谊第一，比赛第二！不错，上次组织的篮球大家打的都非常好，这次继续参加！友谊第一，比赛第二！",
          time: "22分钟前",
          showMore: false,
        },
        {
          name: "徐畅",
          con: "不错，上次组织的篮球大家打的都非常好，这次继续参加！友谊第一，比赛第二！",
          time: "2小时前",
          showMore: false,
        },
        {
          name: "吴德",
          con: "不错，上次组织的篮球大家打的都非常好，这次继续参加！友谊第一，比赛第二！",
          time: "3小时前",
          showMore: false,
        },
      ],
      active: 0,
      show: false,
      checked: false,
      showSuccess: false,
    };
  },
  created() {
    const id = this.$route.query.id;
    if (!id && id !== 0) {
      this.$router.go(-1);
    }
    this.id = id;
    this.getInfo(id);
  },
  methods: {
    getInfo(id) {
      changguanApi.getSports().then(res => {
        if(res.flag) {
          this.info = res.data.find(item => item.id == id)
        }
      })
    },

    confirm() {
      if (this.checked) {
        this.showSuccess = true;
        this.info.enter = true;
      } else {
        Toast("请同意相关条款！");
      }
    },
    cancelEnter() {
      Toast("取消报名成功！");
      this.info.enter = false;
    },
  },
  watch: {
    active(val) {},
  },
};
</script>

<style lang="scss" scoped>
.blue {
  background-color: #1989fa;
}
.green {
  background-color: #1eca55;
}

.yellow {
  background-color: #ffaa36;
}
.banner {
  position: relative;
  height: 15rem;
  margin: 3.75rem 0.75rem 0.75rem;
  > img {
    border-radius: 5px;
    height: 100%;
    width: 23.5rem;
  }
  .type {
    position: absolute;
    right: 0;
    top: 0.86rem;
    border-radius: 50% 0 0 50%;
    height: 2.2rem;
    line-height: 2.2rem;
    padding: 0 1rem;
    box-sizing: border-box;
    border-top-left-radius: 2rem;
    border-bottom-left-radius: 2rem;
    color: #fff;
  }
  .con {
    position: absolute;
    bottom: 0;
    left: 1rem;

    .circle {
      font-size: 1rem;
      margin-top: 0.8rem;
      margin-left: 1rem;
      position: relative;
      color: #fff;
      &::before {
        position: absolute;
        width: 0.4rem;
        height: 0.4rem;
        background: #3e71f9;
        content: "";
        left: -0.8rem;
        top: 0.3rem;
        border-radius: 50%;
      }
    }
    .btns {
      margin: 1rem 0;
      > button {
        width: 10rem;
        &:nth-child(2) {
          margin-left: 1.2rem;
        }
      }
    }
  }
}

.desc {
  margin: 0.75rem;
  background: #fff;
  border-radius: 10px;
  padding: 0.75rem;
  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .icon {
      font-size: 1.6rem;
    }

    .user {
      display: flex;
      align-items: center;
      > span {
        font-size: 1.1rem;
        margin-left: 0.6rem;
      }
    }
  }
  .bottom {
    font-size: 0.86rem;
    margin-top: 0.8rem;
  }
}

.join {
  background-color: #fff;
  padding: 1.3rem 2rem;
  display: flex;
  flex-flow: row wrap;
  margin: 0.75rem;
  justify-content: space-between;
  .join-item {
    display: flex;
    align-items: center;
    width: 8rem;
    > span {
      font-size: 1rem;
      margin-left: 0.5rem;
    }
  }

  .icon {
    font-size: 1.6rem;
  }
}

.comment {
  margin: 0.75rem;
  .comment-item {
    padding: 1rem;
    margin-top: 1rem;
    background-color: #fff;
    display: flex;
    flex-flow: column wrap;

    .icon {
      font-size: 1.6rem;
    }

    .top {
      display: flex;
      align-items: center;
      > span {
        font-size: 1rem;
        margin-left: 0.5rem;
      }
    }

    .con {
      margin-top: 0.5rem;
      font-size: 1rem;
      line-height: 1.2rem;
      letter-spacing: 0.04rem;
      .more {
        font-size: 0.5rem;
        color: #3e71f9;
      }
      // text-overflow: -o-ellipsis-lastline;
      // overflow: hidden;
      // text-overflow: ellipsis;
      // display: -webkit-box;
      // -webkit-line-clamp: 2;
      // line-clamp: 2;
      // -webkit-box-orient: vertical;
    }

    .time {
      margin-top: 0.6rem;

      .time-con {
        font-size: 0.6rem;
      }
    }
  }
}

// ::v-deep .van-tab {
//   background: rgba(62, 113, 249, 0.1);
// }
</style>