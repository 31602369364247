<template>
  <div id="index">
    <div class="page-container">
      <div class="header-nav">
        <i @click="goBack"></i>
        <h1>职保在线</h1>
      </div>
      <div class="kgh-main">
        <div class="kgh-list">
          <ul>
            <li
              v-for="(item, index) in list"
              :key="index"
              @click="gotoLink('kghDetail')"
            >
              <i></i>
              <div class="desc">
                <div class="title">{{ item.title }}</div>
                <p>{{ item.update }}</p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [
        {
          id: 0,
          title: "绍兴市在职职工住院医疗互助保障参保人员登记表",
          update: "2021-02-26 10:05:23",
        },
        {
          id: 1,
          title: "绍兴市职工互助互济会在职职工住院医疗费用补助申请表",
          update: "2021-02-26 09:34:38",
        },
      ],
    };
  },
  components: {},
  mounted() {},
  methods: {
    gotoLink(path) {
      this.$router.push(path);
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss">
@import "~assets/scss/index.scss";
</style>
