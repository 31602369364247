<template>
  <div id="index">
    <div class="page-container">
      <div class="header-nav">
        <i @click="goBack"></i>
        <h1>十里清廉路</h1>
      </div>
      <div class="road-main">
        <div class="pic"><img src="http://dssq.ikeqiao.net/data/image/h5/images/ZV1LD8xHkwBp.jpg" /></div>
        <div class="box">
          <div class="wxparse-box">
            东盛社区积极传承“互帮互助”的“渡船”精神，结合小区健康步道人流量多的特点，充分利用小品等元素，筑起了一条“十里清廉路”，让居民能更好地了解社区的工作及所取得的成就。清廉路主要分为启航路、正气路、邻里路、永辉路4个部分。
          </div>
          <div class="btn-group">
            <span @click="gotoLink('roadInfo')">开始起航</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  components: {},
  mounted() {},
  methods: {
    gotoLink(path) {
      this.$router.push(path);
    },goBack(){
          this.$router.go(-1);
      }
  },
};
</script>

<style lang="scss">
@import "~assets/scss/index.scss";
</style>
