<template>
  <div class="loveActivity-detail">
    <div class="page-container">
      <div class="header-nav">
        <i @click="goBack"></i>
        <h1>活动详情</h1>
      </div>
      <div class="park-main">
        <div class="detail-img">
          <img :src="showData.image" />
        </div>
        <div class="banner-deatil-title">
          <div class="deatil-title-name">
            <span>{{ showData.title }}</span>
          </div>
          <div class="deatil-title-flex">
            <div>
              <span class="name">发起人：</span>
              <span class="value">{{ showData.name }}</span>
            </div>
            <div>
              <span class="name">联系电话：</span>
              <span class="value">{{ showData.phone }}</span>
            </div>
          </div>
          <div class="deatil-title-flex">
            <span class="name">活动时间：</span>
            <span class="value"
              >{{ showData.beginTime }}-{{ showData.endTime }}</span
            >
          </div>
          <div class="deatil-title-flex">
            <span class="name">活动地址：</span>
            <span class="value">{{ showData.address }}</span>
          </div>
        </div>
        <div class="daryLine"></div>
        <div class="banner-detail-content">
          <div class="all-nav" style="margin: 0; padding: 0">
            <div class="index-tab tab">
              <b>活动详情</b>
            </div>
          </div>
          <div class="content">
            {{ showData.content }}
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="btName != null"
      class="btns-flex"
      style="justify-content: center"
    >
      <div class="save-btn">
        <button
          v-if="btName === '参加活动'"
          class="save"
          form-type="submit"
          @click="handleAdd"
        >
          参加活动
        </button>
        <button
          v-else-if="btName === '已报名'"
          class="cancle"
          form-type="submit"
        >
          已报名
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import loveActivityApi from "api/ddya/loveActivity";

export default {
  data() {
    return {
      showData: {},
      key: null,
      btName: null,
    };
  },
  components: {},
  mounted() {
    const { key } = this.$route.query;
    this.key = key;
    this.getDetail({ vId: key });
    this.getUserDetail({ vId: key });
  },
  methods: {
    getUserDetail(params) {
      loveActivityApi.getUserDetail(params).then((res) => {
        if (res.data && res.data.status === "未报名") {
          this.btName = "参加活动";
        } else if (res.data && res.data.status) {
          this.btName = "已报名";
        } else {
          this.btName = null;
        }
      });
    },
    getDetail(params) {
      loveActivityApi.getDetail(params).then((res) => {
        console.log(res);
        if (res.data) {
          res.data.img = res.data.image;
          this.showData = res.data;
        }
      });
    },
    gotoLink(path) {
      this.$router.push(path);
    },
    goBack() {
      this.$router.go(-1);
    },
    showAlert() {
      this.$messagebox("提示", "系统正在建设中，尽情期待~");
    },
    handleAdd() {
      this.$store.dispatch('login/checkRealName', () => {
        loveActivityApi.addUserActivity({ vId: this.key }).then((res) => {
          if (res.data) {
            this.getUserDetail({ vId: this.key });
          }
        });
      })
      
    },
  },
};
</script>

<style lang="scss">
.loveActivity-detail {
  .page-container {
    padding: 0 0.8rem 0rem;
    background: #fff;
  }
  .detail-img {
    width: 100%;
    overflow-x: hidden;
    img {
      width: 100%;
      height: 13rem;
    }
  }
  .banner-deatil-title {
    padding: 0.8rem;
    .deatil-title-name {
      opacity: 0.85;
      font-size: 1rem;
      color: #000000;
      text-align: left;
      line-height: 2rem;
      font-weight: bold;
    }
    .deatil-title-flex {
      display: flex;
      > div {
        width: 50%;
        padding: 0.5rem 0;
      }
      .name {
        opacity: 0.45;
        font-size: 0.8rem;
        color: #000000;
        text-align: left;
        line-height: 1.5rem;
      }
      .value {
        opacity: 0.85;
        font-size: 0.8rem;
        color: #000000;
        text-align: left;
        line-height: 1.5rem;
      }
    }
  }
  .banner-detail-content {
    padding: 0.8rem;
    .content {
      opacity: 0.65;
      font-size: 0.8rem;
      color: #000000;
      text-align: left;
      line-height: 1.6rem;
      text-indent: 2rem;
      padding-top: 0.5rem;
    }
  }

  .btns-flex {
    position: fixed;
    transform: translateZ(0);
    -webkit-transform: translateZ(0);
    bottom: 0;
    background-color: #ffffff;
    padding: 10px 5px;
    border-top: 1px solid #f5f5f5;
    display: flex;
    justify-content: space-between;
    width: 100vw;
    margin: 0;
    > .cancel-btn,
    > .delete-btn,
    > .save-btn {
      width: 43.2vw;
      > button {
        width: 100%;
        height: 2.5rem;
        border-radius: 22.5px;
        border: none !important;
        font-size: 1rem;
      }
      > .cancel {
        background: #eeeeee;
        color: #ffffff;
      }
      > .delete {
        background: #ff0000;
        color: #ffffff;
      }
      > .save {
        background: #2a70ed;
        color: #fff;
      }
    }
  }
}
</style>
