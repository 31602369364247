<template>
  <div class="liubaochengnuo-container">
    <van-nav-bar
      title="六包承诺"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="liubaochengnuo-body-container">
      <img style="width: 100%;" src="http://dssq.ikeqiao.net/data/image/h5/images/butler/liubaochengnuo.png" alt="六包流程图">
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      
    };
  },
  computed: {
  },
  components: {},
  mounted() {
    
  },
  watch: {
   
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    handleTab() {

    },
    gotoLink(path) {
      if(path) {
        this.$router.push(path);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.liubaochengnuo-container {
  height: 100vh;
  width: 100vw;
  
  .liubaochengnuo-body-container {
    height: calc(100vh - 46px);
    width: 100vw;
    padding-bottom: 10px;
    background: url('http://dssq.ikeqiao.net/data/image/h5/images/coordination/coordination-bg.png') no-repeat;
    background-size: 100% 100%;
    overflow-y: auto;
    overflow-x: hidden;


  }
}
</style>
