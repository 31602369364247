<template>
  <div class="alarm-container">
    <van-nav-bar title="预约详情" left-arrow @click-left="onClickLeft()" />
    <div class="form-bar">
      <van-form label-width="130" @submit="onSubmit">
        <van-field
          size="large"
          v-model="eventtype"
          name="eventtype"
          is-link
          readonly
          label="事项类别"
          placeholder="请选择"
          @click="showType = true"
          :rules="[{ required: true, message: '请选择事项类别' }]"
          class="input-bar"
        />
        <van-field
          readonly
          clickable
          name="datetimePicker"
          :value="value"
          label="预约时间"
          placeholder="点击选择时间"
          @click="showPicker = true"
        />

        <van-field
          size="large"
          v-model="committype"
          name="committype"
          is-link
          readonly
          label="社区选择"
          placeholder="请选择"
          @click="showcommitType = true"
          :rules="[{ required: true, message: '请选择社区选择' }]"
          class="input-bar"
        />

        <van-field
          v-model="policyman"
          name="policyman"
          label="民警选择"
          placeholder="来思远"
          input-align="right"
          :rules="[{ required: true, message: '请填写联系人' }]"
        />
        <van-field
          size="large"
          v-model="content"
          name="content"
          rows="3"
          autosize
          label=""
          type="textarea"
          maxlength="200"
          placeholder="请输入预约事项说明"
          show-word-limit
          :rules="[{ required: true, message: '请输入预约事项说明' }]"
        />
        <van-field
          size="large"
          v-model="phone"
          name="phone"
          label="手机号码"
          placeholder="请输入"
          class="input-bar"
        />
        <div class="footer-button">
          <van-button
            round
            block
            type="info"
            style="font-size: 16px"
            native-type="submit"
            >提交</van-button
          >
        </div>
      </van-form>

      <van-popup v-model="showType" position="bottom">
        <van-picker
          title="事项类别"
          show-toolbar
          :columns="typeList"
          @confirm="onTypeConfirm"
          @cancel="onCancel('showType')"
        />
      </van-popup>

      <van-popup v-model="showcommitType" position="bottom">
        <van-picker
          title="社区选择"
          show-toolbar
          :columns="committypelist"
          @confirm="oncomTypeConfirm"
          @cancel="onCancel('showcommitType')"
        />
      </van-popup>
      <van-popup v-model="showPicker" position="bottom">
        <van-datetime-picker
          type="time"
          @confirm="onConfirm"
          @cancel="showPicker = false"
        />
      </van-popup>
    </div>
    <div class="travelinfo" v-if="showBtn">
      <div class="rules">
        <h3>注意事项</h3>
        <p>
          您好！欢迎使用小区警管家警务预约功能！使用之前请了解一下事项：
        </p>
        <br />
        <p>
          1、预约事项需内容详实，如预约事项填写模糊将有可能会导致预约不成功；
        </p>
        <p>2、为保证您预约民警能够有时间接待，请提前至少两天进行预约；</p>
        <p>
          3、若社区民警未能及时回复您的预约，事项紧急的，请直接拨打110活管家民警电话联系！
        </p>
        <button
          class="read-button"
          :class="{ disabled: !this.canClick }"
          @click="countDown"
        >
          {{ tips }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tips: "预约事项说明",
      totalTime: 15,
      canClick: false,
      showBtn: true,
      badgeId: 5,
      eventtype: "",
      value: "",
      content: "",
      phone: "",
      committype: "",
      policyman: "",
      showcommitType: false,
      showType: false,
      showPicker: false,
      typeList: ["咨询", "求助", "服务", "举报", "其他"],
      committypelist: ["东盛社区"],
    };
  },
  components: {},
  mounted() {
    let clock = window.setInterval(() => {
      this.totalTime--;
      this.tips = this.totalTime + "s阅读后";
      if (this.totalTime < 0) {
        window.clearInterval(clock);
        this.tips = "阅读成功";
        this.totalTime = 10;
        this.canClick = true; //这里重新开启
        this.showBtn = false;
      }
    }, 1000);
  },

  methods: {
    gotoLink(path) {
      this.$router.push(path);
    },
    onConfirm(time) {
      this.value = time;
      this.showPicker = false;
    },
    onClickLeft() {
      this.$router.go(-1);
    },
    onTypeConfirm(value, index) {
      this.eventtype = value;
      this.showType = false;
    },
    oncomTypeConfirm(value, index) {
      this.committype = value;
      this.showcommitType = false;
    },
    countDown() {},
    onSubmit(values) {
      console.log(this.invitationImage);
      Toast.success("上报成功");
    },
  },
};
</script>

<style lang="scss">
.travelinfo {
  background: #00000040;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 1rem 1.2rem 5rem;
  .rules {
    background: #fff;
    padding: 1rem;
    border-radius: 0.5rem;
    position: fixed;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    width: 90%;
    height: 60vh;
    h3 {
      font: 600 1.2rem/2rem "microsoft yahei";
      margin-bottom: 0.8rem;
    }
    p {
      text-align: left;
      font: 500 0.9rem/1.5rem "microsoft yahei";
    }
    .right {
      text-align: right;
    }
  }
}
.read-button {
  position: fixed;
  bottom: 0;
  left: 0;
  display: inline-block;
  width: 100%;
  height: 2.4rem;
  border: 0;
  background: #57a3f3;
  color: #fff;
  font: 500 0.9rem/2.4rem "microsoft yahei";
}
.disabled {
  background: #ddd;
  color: #a3a3a3;
}
</style>
