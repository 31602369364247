<template>
  <div id="index">
    <div class="page-container">
      <div class="header-nav">
        <i @click="goBack"></i>
        <h1>零跑建会</h1>
      </div>
      <div class="kgh-main">
        <div class="banner2">
          <img src="http://dssq.ikeqiao.net/data/image/h5/images/kgh/banner-dwjh.png" />
        </div>
        <div class="navlist">
          <div><img src="http://dssq.ikeqiao.net/data/image/h5/images/kgh/dwjh-text1.png" /></div>
          <div><img src="http://dssq.ikeqiao.net/data/image/h5/images/kgh/dwjh-text2.png" /></div>
          <div><img src="http://dssq.ikeqiao.net/data/image/h5/images/kgh/dwjh-text3.png" /></div>
          <div><img src="http://dssq.ikeqiao.net/data/image/h5/images/kgh/dwjh-text4.png" /></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  components: {},
  mounted() {},
  methods: {
    gotoLink(path) {
      this.$router.push(path);
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss">
@import "~assets/scss/index.scss";
</style>
