<template>
  <div class="memberManage-container">
    <van-nav-bar
      title="场馆预约"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="body-container">
      <div class="card-con">
        <div class="card-title-content">
          <img :src="passImg" alt="" style="width: 64px; margin: 20px auto;">
          <h1>您已预约成功</h1>
          <h3>{{myReserve[0].reserveName}}，线下您将支付</h3>
          <h1 style="color: #FF6C2B;margin-bottom: 50px;">{{myReserve[0].reserveTotalCost}}积分</h1>
        </div>
        <div class="card-row-content">
          <div class="card-row">
            <span>场馆电话：</span>
            <span class="card-value" style="color: #08f;" @click="callPhone($event,info.venuePhone)">{{info.venuePhone}}</span>
          </div>
          <div class="card-row">
            <span>预约时间：</span>
            <span class="card-value">{{myReserve[0].reserveTime}}</span>
          </div>
          <div class="card-row">
            <span>操作时间：</span>
            <span class="card-value">{{myReserve[0].createTime}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import cgApi from 'api/changguan';
import { mapMutations, mapState } from "vuex";
export default {
  data() {
    return {
      info: {}, // 场馆信息
      myReserve: [], // 预约信息
      passImg: require("../../../assets/images/yundong/pass.svg"),
    };
  },
  computed: {
  },
  components: {},
  mounted() {
    const {id} = this.$route.query;
    if (!id && id !== 0) {
      this.$router.go(-1);
    }
    this.id = id;
    this.getInfo(id);
    this.getMyReserve();
  },
  watch: {
   
  },
  methods: {
    onClickLeft() {
      // this.$router.go(-1);
      this.$router.replace('/changguan');
    },
    gotoLink(path) {
      if(path) {
        this.$router.push(path);
      }
    },
    getInfo(id) {
      cgApi
        .getChangGuanList({
          page: 1,
          size: this.pageSize,
        })
        .then((res) => {          
          const { records = [] } =  res.data || {} ;   
          this.info = records.find(item => item.id == id);
        })
        .catch((err) => {
          console.log('error====>', err);
        });
    },
    getMyReserve() {
      cgApi.getUserReserve().then(res => {
        if (res.data) {
          this.myReserve = (res.data || []).filter(item => item.reserveVenueId == this.id) || [];
          
        }
      })
    },
  }
};
</script>

<style lang="scss" scoped>
.memberManage-container {
  height: 100vh;
  width: 100vw;
  font-size: 0.9rem;
  line-height: 1.4rem;
  color: rgba(0, 0, 0, 0.85);
  
  .body-container {
    height: calc(100vh - 46px);
    width: 100vw;
    // padding-bottom: 10px;
    background: url('http://dssq.ikeqiao.net/data/image/h5/images/coordination/coordination-bg.png') no-repeat;
    background-size: 100% 100%;
    overflow-y: auto;
    overflow-x: hidden;
    .card-con {
      margin-top: 10px;
      width: 100vw;
      padding: 20px 16px;
      text-align: center;
      h1 {
        font-size: 1.6rem;
        line-height: 2rem;
      }
      h2 {
        font-size: 1.2rem;
        line-height: 2rem;
      }
      .card-row-content{
        background: #fff;
        margin-top: 10px;
        padding: 10px;
        border-radius: 8px;
      }
      .card-title-content{
        background: #fff;
        padding: 10px;
        border-radius: 8px;
        h3{
          margin-top: 20px;
          margin-bottom: 16px;
        }
      }
      .card-row {
        background: #FFF;
        text-align: left;
        line-height: 30px;
        font-size: 16px;
        color: rgba(0,0,0,0.45);
        font-weight: 400;
        .card-value{
          font-weight: 600px;
          color: rgba(0,0,0,0.85);
        }
      }
    }

  }
}
</style>
