<template>
  <div class="memberManage-container">
    <van-nav-bar
      title="入党申请"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="wxxd-body-container">
      <div class="report-bar">
        <span class="report-title">党组织发展党员工作流程图</span>
        <span class="report-arrow" @click="gotoLink('developPartyMember')">
          查看
          <van-icon name="arrow" color="#FFF" size="16px" />
        </span>
      </div>
      <div class="form-bar">
        <van-form @submit="onSubmit">
          <van-field
            size="large"
            v-model="userName"
            name="userName"
            label="姓名"
            placeholder="请填写姓名"
            required
            :rules="[{ required: true, message: '' }]"
            class="input-bar"
            input-align="right"
          />
          <van-cell title-class="cell" title="性别" required size="large">
            <template #default>
              <span
                :class="['radio', { selected: sex === '男' }]"
                @click="changeSex('男')"
                >男</span
              >
              <span
                :class="['radio', { selected: sex === '女' }]"
                @click="changeSex('女')"
                >女</span
              >
            </template>
          </van-cell>
          <van-field
            size="large"
            v-model="idCard"
            name="celNum"
            label="身份证号"
            placeholder="请填写身份证号"
            required
            :rules="[{ required: true, message: '' }]"
            class="input-bar"
            input-align="right"
          />
          <van-field
            size="large"
            v-model="phone"
            name="phone"
            label="联系电话"
            placeholder="请填写联系电话"
            required
            :rules="[{ required: true, message: '' }]"
            class="input-bar"
            input-align="right"
          />
          <van-field
            size="large"
            v-model="organizationId"
            name="organizationId"
            label="加入的支部"
            placeholder="请选择加入的支部"
            is-link
            readonly
            required
            :rules="[{ required: true, message: '' }]"
            class="input-bar"
            input-align="right"
            @click="show = true"
          />
          <van-field
            size="large"
            v-model="nation"
            name="nation"
            label="民族"
            placeholder="请填写民族"
            required
            :rules="[{ required: true, message: '' }]"
            class="input-bar"
            input-align="right"
          />
          <van-field
            size="large"
            v-model="jiguan"
            name="hometown"
            label="籍贯"
            placeholder="请填写籍贯"
            required
            :rules="[{ required: true, message: '' }]"
            class="input-bar"
            input-align="right"
          />
          <van-field
            size="large"
            v-model="zhiye"
            name="profession"
            label="职业"
            placeholder="请填写职业"
            required
            :rules="[{ required: true, message: '' }]"
            class="input-bar"
            input-align="right"
          />
          <van-field
            size="large"
            v-model="hangye"
            name="industry"
            label="行业"
            placeholder="请填写行业"
            required
            :rules="[{ required: true, message: '' }]"
            class="input-bar"
            input-align="right"
          />
          <van-field
            size="large"
            v-model="xueli"
            name="degree"
            label="学历"
            placeholder="请填写学历"
            required
            :rules="[{ required: true, message: '' }]"
            class="input-bar"
            input-align="right"
          />
          <van-field
            size="large"
            v-model="yuanxiao"
            name="graduateSchool"
            label="毕业院校"
            placeholder="请填写毕业院校"
            required
            :rules="[{ required: true, message: '' }]"
            class="input-bar"
            input-align="right"
          />
          <van-field
            size="large"
            v-model="juzhudi"
            name="nowAddress"
            label="现居住地"
            placeholder="请填写现居住地"
            required
            :rules="[{ required: true, message: '' }]"
            class="input-bar"
            input-align="right"
          />
          <van-field
            v-model="qita"
            size="large"
            name="content"
            rows="2"
            autosize
            label="其他"
            type="textarea"
            maxlength="50"
            placeholder="请输入留言"
            show-word-limit
            class="input-bar"
            input-align="right"
          />
          <div class="footer-button">
            <van-button
              round
              block
              type="info"
              style="font-size: 16px"
              native-type="submit"
            >
              提交申请
            </van-button>
          </div>
        </van-form>
      </div>
      <van-overlay :show="showTip" @click.stop>
        <div class="wrapper">
          <div class="pay-window">
            <div class="pay-window-top">
              <b>入党须知</b>
            </div>
            <div class="pay-window-content">
              <div class="text" @touchmove="testTouch">
                <div>（一）递交入党申请（一年以上）：
                1、党支部派人同入党申请人谈话，对申请人资格进行初审；2、党支部和主管党委（工委）建立《入党申请人名册》</div>

                <div>（二）确定入党积极分子：
                1、党支部组织党内外群众进行民主推荐（推优制、积分制）；2、支委会（不设支委的党员大会）集体讨论确定入党积极分子；3、报主管党委（工委）备案，建立《入党积极分子名册》</div>

                <div>（三）入党积极分子培养教育（一年以上）：
                1、党支部指定2名正式党员作为入党积极分子培养联系人，联系人每半年向支部汇报入党积极分子情况，；2、吸收入党积极分子听党课、参加党内有关活动、分配一定的社会工作、集中培训等；3、入党积极分子每季度汇报思想，党支部每半年进行一次全面考察，填写《培养教育考察登记表》
                </div>

                <div>（四）确定发展对象：
                1、听取党小组、培养联系人、党员和群众意见；2、支委会（不设支委的党员大会）集体讨论确定发展对象；3、报主管党委（工委）审查备案，意见填入《培养教育考察登记表》；4、指定2名正式党员作为发展对象的入党介绍人，一般由培养联系人担任党支部；5、开展联审和公示，进行政治审查（长期外出的需外调并征求意见）并形成结论性材料；
                6、组织发展对象参加集中培训，集中培训时间不少3天或不少于24学时</div>

                <div>（五）接收预备党员：
                1、党支部征求党内外群众意见；2、检查相关资料，通过支委会讨论，报主管党委（工委）预审，填写《接收预备党员预审表》；3、支部负责人对发展对象谈话，填写《入党志愿书》，指导做好支部大会相关准备，召开支部党员大会票决；4、主管党委（工委）审查有关材料，并派人进行谈话；5、主管党委（工委）审批并发文，填发《中国共产党预备党员通知书》，并报区委组织部备案
                </div>

                <div>（六）预备党员培养教育（一年）：
                1、党支部书记与新接收的预备党员谈话；2、入党介绍人继续对预备党员进行教育考察，预备党员每季度向党支部书面汇报情况，党支部每半年进行全面考察，形成书面考察意见；3、主管党委（工委）在6个月内统一组织预备党员入党宣誓
                </div>

                <div>（七）预备党员转正：
                1、预备期满前夕，预备党员提出书面转正申请；2、党支部征求党内外群众意见，支委会审查，提出能否转正的意见；3、召开支部党员大会进行表决，并将有关材料整理后报送主管党委（工委）；4、主管党委（工委）审批并发文，填发《中国共产党预备党员转正通知书》
                </div>

                <div> （八）入党材料归档办理时限：非即办</div>
                <div >特别说明:如有疑问请联系社区工作人员: 0575-81166576</div>
              </div>
              <button class="button-wxxd" @click="onOk">确定</button>
            </div>
          </div>
        </div>
      </van-overlay>
    </div>
    <van-popup v-model="show" position="bottom">
      <van-picker
        title="加入的党支部"
        show-toolbar
        :columns="list"
        @confirm="onPickerConfirm"
        @cancel="onCancel"
      />
    </van-popup>
  </div>
</template>

<script>
import Api from 'api';
import { mapMutations, mapState } from "vuex";
export default {
  data() {
    return {
      type: "全血",
      sex: "男",
      userName: "",
      phone: "",
      idCard: "",
      date: "",
      showDate: false,
      showDepart: false,
      depart: "",
      departList: [],
      organizationId: '',
      nation: '',
      jiguan: '',
      zhiye: '',
      hangye: '',
      xueli: '',
      yuanxiao: '',
      juzhudi: '',
      qita: '',
      show: false,
      showTip: false,
      list: ['东盛社区第一党支部', '君城小区党支部', '江南华庭小区党支部', '阳光花园党支部', '翡翠园党支部', '步升花园小区党支部', '丁香公寓小区党支部'],
    };
  },
  computed: {
  },
  components: {},
  mounted() {
    // this.$dialog.alert({
    //   title: '入党须知',
    //   message: '弹窗内容',
    //   theme: 'round-button',
    // })
  },
  watch: {
   
  },
  methods: {
    testTouch(e) {
      e.stopPropagation();
    },
    onClickLeft() {
      this.$router.go(-1);
    },
    handleTab() {

    },
    onPickerConfirm(e) {
      this.organizationId = e;
      this.show = false;
    },
    onCancel() {
      this.show = false;
    },
    gotoLink(path) {
      if(path) {
        this.$router.push(path);
      }
    },
    changeSex(val) {
      this.sex = val;
    },
    onSubmit(values) {
      // console.log('values', values);
      Api.addPartyMember({ ...values, sex: this.sex === '女' ? 0 : 1, perAddress: '' }).then(res => {
        if(res.flag) {
          this.$toast({
            message: '申请提交成功！',
            position: 'top',
          });
          const timer = setTimeout(() => {
            this.$router.go(-1);
            clearTimeout(timer);
          }, 1000);
        }
      })
      
    },
    onOk() {
      this.show = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.memberManage-container {
  height: 100vh;
  width: 100vw;
  
  .wxxd-body-container {
    height: calc(100vh - 109px);
    width: 100vw;
    // padding-bottom: 10px;
    background: url('http://dssq.ikeqiao.net/data/image/h5/images/coordination/coordination-bg.png') no-repeat;
    background-size: 100% 100%;
    overflow-y: auto;
    overflow-x: hidden;

    .report-bar {
      width: calc(100% - 20px);
      margin: 10px;
      padding: 0 10px;
      height: 50px;
      background: url('http://dssq.ikeqiao.net/data/image/h5/images/hong/wxxd-top-bar.png') no-repeat;
      background-size: 100% 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .report-title {
        font-size: 18px;
        color: #FFF;
      }
      .report-arrow {
        color: #FFF;
        font-size: 16px;
        display: flex;
        align-items: center;
      }
    }
    .form-bar {
      .cell {
        color: rgba(0, 0, 0, 0.65);
      }
      .radio {
        height: 2rem;
        border-radius: 1rem;
        // background: #4479ED;
        color: rgba(0, 0, 0, 0.35);
        border: 1px solid rgba(0, 0, 0, 0.35);
        padding: 0.1rem 1.2rem;
        margin-left: 8px;
      }
      .selected {
        background: #4479ed;
        color: #ffffff;
        border: 1px solid #4479ed;
      }
      .divide {
        height: 0.8rem;
        background: #f6f7fa;
      }
      .footer-button {
        position: fixed;
        bottom: 0.8rem;
        right: 0.8rem;
        left: 0.8rem;
      }
    }
    .wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 10vh;
      
      .pay-window {
        background-color: #FFF;
        border-radius: 8px;
        text-align: center;
        font-size: 14px;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 75vw;
        .pay-window-top {
          width: 100%;
          height: 48px;
          background-color:  #4479ED;
          font-size: 16px;
          color: #FFF;
          line-height: 48px;
          border-radius: 8px 8px 0 0;
          position: relative;
          .close {
            position: absolute;
            top: 16px;
            right: 10px;
          }
        }
        .pay-window-content {
          width: 100%;
          text-align: center;
          line-height: 20px;
          padding: 16px;
          // .image-con {
          //   width: 60vw;
          //   height: 60vw;
          //   padding: 16px;
            >img {
              width: 100%;
              height: 100%;
            }
          // }
          .text {
            height: 60vh;
            overflow-y: auto;
            overflow-x: hidden;
            text-align: left;
          }
          .button-wxxd {
            width: 100%;
            background-color: #4479ED;
            color: #FFF;
            font-size: 18px;
            line-height: 44px;
            border: none;
            border-radius: 4px;
            margin: 10px 0;
          }
        }
      }
    }
  }
}
</style>
