<template>
  <div id="index">
    <div class="page-container">
      <div class="header-nav">
        <i @click="goBack"></i>
        <h1>共享服务</h1>
      </div>
      <div class="park-main" style="padding-top: 0;">
        <div class="banner" style="height: 10rem">
          <img style="height: 10rem" src="http://dssq.ikeqiao.net/data/image/h5/images/banner/gxfw_banner.png" />
        </div>
        <div class="prop-nav clearfix">
          <ul>
            <li @click="router('14')"><i class="gxfw-yyx"></i><span>云影像</span></li>
            <li @click="router('5')"><i class="gxfw-yjc"></i><span>云检验</span></li>
            <li @click="router('7')"><i class="gxfw-yyf"></i><span>云药房</span></li>
            <li @click="router('4')"><i class="gxfw-yjl"></i><span>云记录</span></li>
            <li @click="router('8')"><i class="gxfw-yzx"></i><span>云咨询</span></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { openUrl } from '@/utils/index';
export default {
  data() {
    return {};
  },
  components: {},
  mounted() {},
  methods: {
    openHref() {
      window.location.href = 'https://10037110610.openweimai.com/open-consult/home.html?areaId=912000000187318732&hosId=930500000027722794';
    },
    gotoLink(path) {
      this.$router.push(path);
    },
    goBack() {
      this.$router.go(-1);
    },
    router(vote_url) {
      openUrl(vote_url);
    },
  },
};
</script>

<style lang="scss">
@import "~assets/scss/index.scss";
.gxfw-yyx {
  background: url('http://dssq.ikeqiao.net/data/image/h5/images/jk/sub/gxfw-yyx.png') no-repeat;
  background-size: 100%;
}
.gxfw-yjc {
  background: url('http://dssq.ikeqiao.net/data/image/h5/images/jk/sub/gxfw-yjc.png') no-repeat;
  background-size: 100%;
}
.gxfw-yyf {
  background: url('http://dssq.ikeqiao.net/data/image/h5/images/jk/sub/gxfw-yyf.png') no-repeat;
  background-size: 100%;
}
.gxfw-yjl {
  background: url('http://dssq.ikeqiao.net/data/image/h5/images/jk/sub/gxfw-yjl.png') no-repeat;
  background-size: 100%;
}
.gxfw-yzx {
  background: url('http://dssq.ikeqiao.net/data/image/h5/images/icons/yzx.png') no-repeat;
  background-size: 100%;
}
</style>
