import { render, staticRenderFns } from "./educationSource.vue?vue&type=template&id=4c0a90af&scoped=true&"
import script from "./educationSource.vue?vue&type=script&lang=js&"
export * from "./educationSource.vue?vue&type=script&lang=js&"
import style0 from "./educationSource.vue?vue&type=style&index=0&id=4c0a90af&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c0a90af",
  null
  
)

export default component.exports