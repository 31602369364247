<template>
  <div id="index">
    <div class="page-container paddclose">
      <div class="header-nav">
        <i @click="goBack"></i>
        <h1>详情</h1>
      </div>
      <div class="hot-main">
        <div class="info-box">
          <div class="title">
            <p>{{ data.title }}</p>
            <div class="createTime">{{ data.created_at }}</div>
          </div>
          <div class="wxparse-box">
            <div v-html="data.content"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      data: {
        id: "0",
        title: "绍兴市在职职工住院医疗互助保障参保人员登记表",
        content:
          '<p><img src="https:\/\/wxbucket.oss-cn-hangzhou.aliyuncs.com\/zhsq\/16luOdfweV48.png"></p>',
        created_at: "2021-02-26 10:05:23",
      },
    };
  },
  components: {},
  mounted() {},
  methods: {
    gotoLink(path) {
      this.$router.push(path);
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss">
@import "~assets/scss/index.scss";
</style>
