<template>
  <div id="index">
    <div class="page-container paddclose">
      <div class="header-nav">
        <i @click="goBack"></i>
        <h1>详情</h1>
      </div>
      <div class="hot-main">
        <div class="crumbs">
          首页：<span>家头条</span><span>热点关注</span><b>事件详情</b>
        </div>
        <div class="info-box">
          <div class="title">
            <p>{{ data.title }}</p>
            <div class="createTime">{{ data.created_at }}</div>
          </div>
          <div class="wxparse-box">
            <div v-html="data.content"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      data: {
        id: "5db58960-53bc-11eb-b16f-a552ed4445ec",
        title:
          "11月1日，曹俊、蒋兴光、潘国昌、邵正峰、陈杭峰到社区参加“东盛民声面对面”活动",
        content:
          '<p>11月1日，曹俊、蒋兴光、潘国昌、邵正峰、陈杭峰到社区参加“东盛民声面对面”活动，通过与居民面对面交流，收集解决民生问题。街道相关线分管领导当场回复解决5条，其余问题逐一做了认真记录，尽快协调处理。<\/p><p><img src="https:\/\/wxbucket.oss-cn-hangzhou.aliyuncs.com\/zhsq\/NVhqLbrYcTKO.jpg"><br><\/p><p><img src="https:\/\/wxbucket.oss-cn-hangzhou.aliyuncs.com\/zhsq\/zfSvyN8j9b6s.JPG"><\/p>',
        image:
          "https:\/\/wxbucket.oss-cn-hangzhou.aliyuncs.com\/zhsq\/m7cIvMN4ZpDS.JPG",
        community_id: "417817877553348609",
        type: 13,
        created_at: "2021-01-11 11:23:25",
        updated_at: "2021-01-11 11:23:25",
        source: null,
        add_user: "东盛社区",
        hide: 0,
        party_id: "",
        communitName: "东盛社区",
      },
    };
  },
  components: {},
  mounted() {},
  methods: {
    gotoLink(path) {
      this.$router.push(path);
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss">
@import "~assets/scss/index.scss";
</style>
