<template>
  <div>
    <van-nav-bar
      fixed
      class="butler-nav-bar"
      title="新增场馆"
      left-arrow
      @click-left="$router.go(-1)"
    />

    <div class="form">
      <van-form @submit="onSubmit">
        <van-field
          required
          v-model="title"
          label="场馆名称"
          placeholder="请输入场馆名称"
          :rules="[{ required: true, message: '请输入场馆名称' }]"
        />
        <van-field
          required
          v-model="address"
          label="场馆地址"
          placeholder="请输入场馆地址"
          :rules="[{ required: true, message: '请输入场馆地址' }]"
        />
        <van-field
          required
          v-model="type"
          label="运动类型"
          placeholder="请输入运动类型"
          :rules="[{ required: true, message: '请输入运动类型' }]"
        />

        <div class="section" style="padding: 0.8rem">
          <div class="image-title">场地照片</div>
          <van-uploader v-model="files" name="files" :max-count="1" />
        </div>

        <div class="foot-button">
          <van-button block type="info" round native-type="submit"
            >创建</van-button
          >
        </div>
      </van-form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      title: "",
      address: "",
      type: "",
      files: [],
    };
  },
  methods: {
    onSubmit() {},
  },
};
</script>
<style lang="scss" scoped>
.form {
  margin: 3.75rem 0.2rem 0.2rem;

  .section {
    width: 100%;
    border-top: 0.8rem solid #f6f7fa;
    .image-title {
      line-height: 2rem;

      font-size: 14px;
      color: #646566;
      letter-spacing: 0;
    }
  }

  .foot-button {
    margin: 0 1rem;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    > button {
      margin-top: 0.5rem;
    }
  }
}
</style>