export const data = [
  {
    id: 1,
    toolName: '经济纠纷',
    borrowUser: '张三',
    type: '审核中',
    contactPhone: '13200009393',
    rentTime: '2021-08-30 12:00',
    returnTime: '2021-08-30 12:00',
    borrowReason: '诉求内容诉求内容诉求内容诉求内容诉求内容诉求内容诉求内容诉求内容诉求内容诉求内容诉求内容诉求内容诉求内容诉求内容诉求内容诉求内容诉求内容诉求内容诉求内容诉求内容诉求内容',
  },
  {
    id: 2,
    toolName: '经济纠纷',
    borrowUser: '张三',
    type: '已通过',
    contactPhone: '13200009393',
    rentTime: '2021-08-30 12:00',
    returnTime: '2021-08-30 12:00',
    borrowReason: '诉求内容诉求内容诉求内容诉求内容诉求内容诉求内容诉求内容诉求内容诉求内容诉求内容诉求内容诉求内容诉求内容诉求内容诉求内容诉求内容诉求内容诉求内容诉求内容诉求内容诉求内容',
  },
  {
    id: 3,
    toolName: '经济纠纷',
    borrowUser: '张三',
    type: '已驳回',
    contactPhone: '13200009393',
    rentTime: '2021-08-30 12:00',
    returnTime: '2021-08-30 12:00',
    borrowReason: '诉求内容诉求内容诉求内容诉求内容诉求内容诉求内容诉求内容诉求内容诉求内容诉求内容诉求内容诉求内容诉求内容诉求内容诉求内容诉求内容诉求内容诉求内容诉求内容诉求内容诉求内容',
  },
];

export default { data };