<template>
  <div>
    <van-nav-bar
      fixed
      class="butler-nav-bar"
      title="共享法庭"
      left-arrow
      @click-left="$router.go(-1)"
    />
    <div class="body">
      <img :src="img" />
      <div>
        “共享法庭”建设由浙江省委政法委统一领导，浙江省高级人民法院组织实施，各市、县（市、区）党委政法委牵头成立协调小组，中基层法院会同司法行政等部门成立工作专班，加快推动“共享法庭”全域覆盖。以“不增编、不建房、快落实、广覆盖”为原则，因地制宜地依托镇街村社、行业组织现有硬件设施，以“一根网线、一块屏”为标准配置，集成浙江解纷码、移动微法院、庭审直播系统、裁判文书公开平台等软件模块，聚焦基层矛盾纠纷高发部位和群体，将镇街、村社作为基本布局点，同时向行业协会、金融、保险、邮政、仲裁、律所、工会、调解委员会等组织延伸。
      </div>
    </div>

    <div class="add">
      <van-button type="info" plain round style="width: 48%;margin: 0 1%;" @click="$router.push('myCourt')">我的诉求</van-button>
      <van-button type="info" round style="width: 48%;margin: 0 1%;" @click="$router.push('/court/add')">发起诉求</van-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      img: require("../../assets/images/court/court.png"),
    };
  },
};
</script>

<style lang="scss" scoped>
.body {
  padding: 0.7rem;
  > img {
    width: 100%;
  }
  > div {
    line-height: 1.3rem;
    margin-top: 1rem;
    letter-spacing: 0.06rem;
    font-size: 1rem;
    padding-bottom: 4rem;
  }
}

.add {
  padding: 0 2rem;
  width: 100%;
  position: fixed;
  bottom: 2rem;
}
</style>