<template>
  <div id="index">
    <div class="page-container" style="margin-top: 0px !important">
      <div class="header-nav">
        <i @click="goBack"></i>
        <h1>邻里亲</h1>
      </div>
      <van-tabs color="#4479ED" v-model="selected">
        <van-tab title="学外语">
          <wyslList activityType="学外语" />
        </van-tab>
        <van-tab title="学中文">
          <wyslList activityType="学中文" />
        </van-tab>
        <van-tab title="中外互动">
          <wyslList activityType="中外互动" />
        </van-tab>
        <van-tab title="云直播课堂">
          <wyslList activityType="云直播课堂" />
          <!-- <div class="white-page">
            <img src="http://dssq.ikeqiao.net/data/image/h5/images/jqqd.png" alt="">
          </div> -->
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>
import wyslApi from "api/llyjr/wysl";
import { List } from "vant";
import WyslList from "./wyslList";

export default {
  data() {
    return {
      selected: '学外语',
    };
  },
  components: { wyslList: WyslList },
  mounted() {
    const value = this.getLocalData('zwSelect') || '';
    if(value) {
      this.selected = parseInt(value);
    }
  },
  watch: {
    selected(val, oldVal) {
      console.log(val);
      this.setLocalData('zwSelect', `${val}`);
    },
  },
  methods: {
    gotoLink(path) {
      this.$router.push(path);
    },
    goBack() {
      this.$router.go(-1);
    },
    showAlert() {
      this.$messagebox("提示", "系统正在建设中，尽情期待~");
    }
  }
};
</script>

<style lang="scss" scoped>
.page-container {
  // padding: 0.8rem 0rem;
  padding-top: 2.7rem;
  background: #ffffff;
  .white-page {
    height: 30rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    > img {
      width: 80vw;
    }
  }
  .detail-img-box-two {
    height: auto !important;
    margin: 1rem 0;
    width: 100%;
    li {
      display: inline-flex;
      align-items: center;
      width: 50% !important;
      height: auto !important;
      .img {
        height: auto !important;
        width: 100% !important;
        text-align: center;
        padding: 0 0.8rem;
        img {
          width: auto !important;
          height: auto !important;
          max-height: 10rem;
          max-width: 100%;
        }
        .img-title {
          text-align: left;
          color: rgba(0, 0, 0, 0.85);
          line-height: 1.2rem;
          font-size: 1rem;
          font-weight: bold;
          padding: 0.5rem 0.6rem 0 0.6rem;
        }
        .img-des {
          text-align: center;
          color: rgba(0, 0, 0, 0.85);
          line-height: 1.2rem;
          font-size: 0.8rem;
          padding: 0.6rem;
          display: flex;
          align-items: center;

          .blue {
            color: #4479ed;
            margin: 0 2px;
          }
          img {
            width: 1rem !important;
            height: 1rem !important;
            margin-right: 0.2rem;
            padding: 0;
          }
          img:nth-child(3) {
            margin-left: 0.6rem;
          }
        }
      }
    }
  }
}
</style>
