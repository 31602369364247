<template>
  <div id="index">
    <div class="page-container">
      <van-icon class="back-button" name="arrow-left" @click="goBack" color="#FFFFFF" size="16" />
      <div class="iframe-nav">
        <iframe id="myiframe" scrolling="auto" frameborder="0" width="100%" height="100%" ></iframe>
      </div>
    </div>
    
  </div>
</template>

<script>
import { openUrl } from 'utils/index'
export default {
  data() {
    return {
      url: ''
    };
  },
  components: {},
  mounted() {
    openUrl(1);
  //   this.axios({
  //   method: 'get',
  //   url: '/api/sdk-auth/getUrl?sign=1',
  //   // responseType: 'blob',
  //   headers: { 'Authorization': 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJhdXRoX3VzZXIiOnsidG9rZW4iOm51bGwsInVzZXJuYW1lIjoia3F6Z3l4IiwiYWRtaW4iOmZhbHNlLCJ1c2VySWQiOiIxIn19.kwZ-diYXAsjv6sPWr4vuYT4R4nfTHYzAy-297FUMiZWaWv4OA_i9mtVWqFkb7W0Ec3bBAQ9KXSmpX4K2Ph9_rg' }
  // }).then(function(response) {
  //         // console.log(response.data.data.forecast)
  //         document.getElementById('myiframe').src = response.data.data;
  //         console.log(response)
  //       })
  //       .catch(function(err) {
  //         // eslint-disable-line no-unused-vars
  //         this.$messagebox("提示", "页面加载失败，3秒后为您返回上一页");
  //         const timer = setTimeout(() => {
  //           clearTimeout(timer);
  //           this.$router.go(-1);
  //         },3000);
  //       });
  },
  methods: {
    gotoLink(path) {
      this.$router.push(path);
    },
    goBack() {
      this.$router.go(-1);
    },
    showAlert() {
      this.$messagebox("提示", "系统正在建设中，尽情期待~");
    },
  },
};
</script>

<style lang="scss">
.back-button {
  position: fixed;
  top: 0.4rem;
  left: 0.4rem;
  z-index: 999989999;
}
.iframe-nav {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}
</style>
