<template>
  <div>
    <van-nav-bar
      fixed
      class="butler-nav-bar"
      title="场馆详情"
      left-arrow
      @click-left="$router.replace('/changguan')"
    />
    <div class="body">
      <van-cell-group>
        <van-cell title="场馆名称" :value="info.venueName" />
        <van-cell title="场馆地址" :value="info.venueAddress" />
        <van-cell title="运动类型" :value="info.venueTypeName" />
        <van-cell title="场馆照片">
          <template #label>
            <img v-for="(item, index) in (info.venueImage ?info.venueImage.split('#') : [])" :key="index" :src="item" style="width:5rem;height:5rem"/>
          </template>
        </van-cell>
        <van-cell title="场馆介绍" :value="info.venueDescribe" />
        <van-cell title="营业时间" :value="info.businessTime" />
        <van-cell title="场馆电话" :value="info.venuePhone" />
        <div class="reserveListTitle">预约记录</div>
        <div class="reserveListContent">
          <div v-for="(item, i) in reserveList" :key="i">
            <span style="margin-right: 10px">{{item.reserveName}}</span>
            <span style="margin-right: 10px">{{item.reserveTime}}</span>
            <span style="margin-right: 5px">{{item.reserveConditionText}}</span>
          </div>
        </div>
      </van-cell-group>
    </div>
    <van-button style="position: absolute;bottom: 10px;left: 10px;right: 10px;width: calc(100vw - 20px);" type="info" @click="handlePreview">预约</van-button>
  </div>
</template>

<script>
import changGuanApi from 'api/changguan';
export default {
  name: "ChangguanDetail",
  data() {
    return {
      id: null,
      info: {},
      img: require("../../../assets/images/yundong/changguan.svg"),
      pageSize: 50,
      reserveList: [],
    };
  },
  created() {
    const {id} = this.$route.query;
    if (!id && id !== 0) {
      this.$router.go(-1);
    }
    this.id = id;
    this.getInfo(id);
    this.getYuyueList(id);
  },
  methods: {
    getInfo(id) {
      changGuanApi
        .getChangGuanList({
          page: 1,
          size: this.pageSize,
        })
        .then((res) => {     
          const { records = [] } =  res.data || {} ;   
          this.info = records.find(item => item.id == id);
        })
        .catch((err) => {
          console.log('error====>', err);
        });
    },
    getYuyueList(id){
      changGuanApi
        .getReserveByVenueId({
          venueId: id,
        })
        .then((res) => {     
          this.reserveList =  res.data || [];
        })
        .catch((err) => {
          console.log('error====>', err);
        });
    },
    handlePreview() {
      // this.$dialog.confirm({
      //   title: '拨打电话',
      //   message: "0575-87596310",
      //   confirmButtonText: '拨打',
      //   confirmButtonColor: "#4379ed"
      // }).then(() => {
      //   window.location.href="tel://0575-87596310"
      // }).catch((e) => {
      //   // this.$router.go(-1);
      //   console.log('link',e)
      // })
      this.$router.replace(`/changguan/preview?id=${this.info.id}`)
    }
  },
};
</script>

<style lang="scss" scoped>
.body {
  margin-top: 3rem;
}
.reserveListTitle{
  font-size: 16px;
  font-weight: 600;
  padding: 10px;
  padding-bottom: 0px;
}
.reserveListContent{
  padding: 10px;
  >div{
    line-height: 30px;
    font-size: 14px;
  }
}
</style>