<template>
  <div id="index">
    <div class="myInvite-container">
      <div class="header-nav" style="display: flex">
        <img @click="goBack" style="width: 20px; height: 20px; margin: 10px" src="http://dssq.ikeqiao.net/data/image/h5/images/myInvite/leftWhite.png" />
        <h1 style="margin-left: 7.5%">我的邀请</h1>
      </div>
      <div class="myInvite-main">
        <div class="myInvite-banner"></div>
        <div class="myInvite-share">
          <div class="myInvite-share-top">
            <div class="myInvite-title">
              <svg-icon icon-class="titleBg" />
              <div class="title-name">邀请方式</div>
            </div>
            <div class="share-content">
              <div>
                <div class="share-text">
                  <div class="share-title">邀请码邀请</div>
                  <div class="share-desc" style="color: #08f;font-weight: bold; font-size: 18px;letter-spacing: 2px;" >{{baseInfo.inviteCode || ''}}</div>
                </div>
                <div class="share-image" style="width: 100px;">
                  <div class="share-btn" style="font-size: 13px;" @click="onCopy(baseInfo.inviteCode)">点击复制邀请码</div>
                </div>
              </div>
              <!-- <div>
                <div class="share-text">
                  <div class="share-title">面对面邀请</div>
                  <div class="share-desc">让好友微信扫码右方二维码</div>
                </div>
                <div class="share-image">
                  <div id="myEWM" class="share-image" @click="ImagePreview(ewmImage)">
                  </div>
                  <div id="myEWM2" class="share-image2">
                  </div>
                </div>
              </div>
              <div>
                <div class="share-text">
                  <div class="share-title">链接邀请</div>
                  <div class="share-desc">复制链接发送给好友</div>
                </div>
                <div class="share-image" style="width: 100px">
                  <div class="share-btn" @click="onCopy(`http://dssq.ikeqiao.net/#/wxLogin?pageName=loginPhone&pageId=${userInfo.userId}`)">点击复制链接</div>
                </div>
              </div> -->
            </div>
            <div class="lc-content">
              <div class="item">
                <div class="image"><svg-icon icon-class="one" /></div>
                <div class="text">分享给好友家人</div>
              </div>
              <div class="point"><svg-icon icon-class="right" /></div>
              <div class="item">
                <div class="image"><svg-icon icon-class="two" /></div>
                <div class="text">好友注册东盛社区</div>
              </div>
              <div class="point"><svg-icon icon-class="right" /></div>
              <div class="item">
                <div class="image"><svg-icon icon-class="three" /></div>
                <div class="text">邀请成功</div>
              </div>
            </div>
          </div>
          <div class="myInvite-share-top">
            <div class="myInvite-title">
              <svg-icon icon-class="titleBg" />
              <div class="title-name">邀请记录</div>
            </div>
            <div class="jl-count">已邀请{{inviterCount}}人</div>
            <div class="jl-content">
              <div class="jl-list">
                <div class="item" v-for="(item, index) in list" :key="index">
                  <div class="icon"><img :src="item.image || 'http://dssq.ikeqiao.net/data/image/h5/images/defaultUser.png'" alt=""></div>
                  <div class="name">{{item.inviteeNickName}}</div>
                  <div class="time">
                    注册于
                    <span>{{item.registerTime}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import homeApi from "api/home";
import { ImagePreview, Toast } from 'vant';
import QRCode from 'qrcodejs2';
import { mapState } from 'vuex';
import context from '../main';
import { sharePage } from 'utils';
import Clipboard from 'clipboard';
export default {
  data() {
    return {
      list: [],
      ewmImage: '',
      inviterCount: 0,
      baseInfo: {},
    };
  },
  components: {},
  mounted() {
    console.log('this.userInfo.userId',this.userInfo.userId)
    homeApi.getUserInfo({}).then((res) => {
      const { data } = res;
      this.baseInfo = data || {};
    });
    this.getList(this.userInfo.userId);
    const qrcode = new QRCode(document.getElementById('myEWM'), {
      text: `http://dssq.ikeqiao.net/#/wxLogin?pageName=loginPhone&pageId=${this.userInfo.userId || ''}`, // 需要转换为二维码的内容
      width: 50,
      height: 50,
      colorDark: '#000000',
      colorLight: '#ffffff',
      correctLevel: QRCode.CorrectLevel.H,
    });
    const qrcode2 = new QRCode(document.getElementById('myEWM2'), {
      text: `http://dssq.ikeqiao.net/#/wxLogin?pageName=loginPhone&pageId=${this.userInfo.userId || ''}`, // 需要转换为二维码的内容
      width: 300,
      height: 300,
      colorDark: '#000000',
      colorLight: '#ffffff',
      correctLevel: QRCode.CorrectLevel.H,
    });
    this.ewmImage = document.getElementById('myEWM2').childNodes[0].toDataURL('image/png');//$(’#output’).toDataURL(“image/png”);
    sharePage(this.userInfo.userId || '', 'loginPhone', { orderBaseInfo: { imageIcon: 'http://dssq.ikeqiao.net/data/image/dongsheng/invateBg.png' } });
  },
  methods: {
    gotoLink(path) {
      this.$router.push(path);
    },
    goBack(){
          this.$router.go(-1);
    },
    ImagePreview(image) {
      ImagePreview({
        images: [image],
        closeable: true,
        startPosition: 0,
      });
    },
    onCopy(text){
      const clipboard = new Clipboard('.share-btn', {
        text: () => text,
      }) 
      clipboard.on('success', e => {
        Toast('复制成功');
        // 释放内存
        clipboard.destroy();
      })
      clipboard.on('error', e => {  
        // 不支持复制
        Toast('该浏览器不支持自动复制')  
        // 释放内存  
        clipboard.destroy()  
      })
    },
    getList(inviterId) {
      homeApi.listInviter({ inviterId }).then((res) => {
        const { data = {} } = res;
        console.log('data', data);
        this.list = data.list || [];
        this.inviterCount = data.inviterCount || 0;
      });
    },
  },
  computed: {
    ...mapState({
      userInfo: (state) => (state.login.userInfo.nickname ? state.login.userInfo : null) || context.getSessionData('userInfo')
    })
  }
};
</script>

<style lang="scss">
@import "~assets/scss/index.scss";
.van-image-preview__image{
  >img{
    padding: 30px;
  }
}
.myInvite-container{
  .header-nav{
    background-image: linear-gradient(180deg, #4974F8 0%, #4A77FA 100%);
    >h1{
      color: #fff;
    }
  }
}
.myInvite-main{
  margin-top: 0 !important;
  padding-top: 2.7rem;
  .myInvite-banner{
    width: 100%;
    height: 320px;
    background: url("http://dssq.ikeqiao.net/data/image/dongsheng/invateBg.png") no-repeat;
    background-size: 100% 100%;
  }
  .myInvite-share{
    background-image: linear-gradient(180deg, #425CE9 0%, #3832D6 100%);
    padding: 12px;
    .myInvite-share-top{
      background-image: linear-gradient(180deg, #526FFF 0%, #201BC6 2%, #201BC6 100%);
      box-shadow: 0px 1px 8px 0px rgba(19,43,107,0.2);
      border-radius: 8px;
      padding: 10px;
      margin-bottom: 10px;
    }
    .myInvite-title{
      position: relative;
      margin: 0px auto;
      width: 140px;
      height: 46px;
      line-height: 46px;
      font-size: 16px;
      color: #FFFFFF;
      text-align: center;
      line-height: 12px;
      font-weight: 500;
      >svg{
        width: 100%;
        height: 100%;
        position: auto;
      }
      .title-name{
        position: absolute;
        width: 100%;
        line-height: 34px;
        top: 0px;
      }
    }
    .share-content{
      >div{
        margin-top: 10px;
        background-image: linear-gradient(180deg, #F1F3F9 0%, #FFFFFF 100%);
        box-shadow: 0px 1px 8px 0px rgba(19,43,107,0.2);
        border-radius: 8px;
        padding: 20px 12px;
        width: 100%;
        display: flex;
        .share-title{
          line-height: 30px;
          font-weight: 600;
          font-size: 16px;
          color: rgba(0,0,0,0.85);
        }
        .share-desc{
          line-height: 20px;
          color: rgba(0,0,0,0.45);
          font-size: 12px;
        }
        .share-image{
          width: 50px;
          margin-left: auto;
          margin-right: 10px;
          img{
            width: 50px !important;
            height: 50px !important;
          }
        }
        .share-image2{
          position: absolute;
          z-index: -10000;
          top: -500px;
          left: -500px;
        }
        .share-btn{
          margin-top: 10px;
          width: 100px;
          height: 30px;
          line-height: 30px;
          background: #4479ED;
          box-shadow: 0px 1px 8px 0px rgba(19,43,107,0.2);
          border-radius: 2px;
          font-size: 14px;
          color: #FFFFFF;
          font-weight: 400;
          text-align: center;
        }
      }
    }
    .lc-content{
      margin-top: 10px;
      padding: 10px;
      display: flex;
      color: rgba(255,255,255,0.85);
      .point{
        margin: 0 auto;
        width: 20px;
        height: 20px;
        margin-top: 20px;
        >svg{
          width: 100%;
          height: 100%;
        }
      }
      .item{
        width: 30%;
        text-align: center;
        padding: 0 10px;
        .image{
          margin: 0 auto;
          width: 50px;
          height: 50px;
          margin-bottom: 5px;
          >svg{
            width: 100%;
            height: 100%;
          }
        }
        .text{
          line-height: 20px;
        }
      }
    }
    .jl-count{
      font-size: 12px;
      line-height: 20px;
      color: #FFFFFF;
      letter-spacing: 0;
      font-weight: 400;
    }
    .jl-content{
      .jl-list{
        width: 100%;
        .item{
          background: #4b47d0;
          border-radius: 8px;
          padding: 0 10px;
          width: 100%;
          display: flex;
          height: 60px;
          line-height: 60px;
          border-bottom: 1px solid rgba(0,0,0,0.1);
          margin-bottom: 10px;
          color: #fff;
          .icon{
            margin-top: 10px;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            margin-right: 10px;
            >img{
              width: 100%;
              height: 100%;
            }
          }
          .name{
            width: calc(100% - 220px);
            margin-right: 10px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .time{
            text-align: right;
            width: 160px;
          }
        }
      }
    }
  }
}
</style>
