<template>
  <div class="publish-need-container">
    <van-nav-bar title="发布先锋" left-arrow @click-left="onClickLeft" />
    <div class="my-vanguard-body-container">
      <div class="image-bar">
        发布先锋
        <p>提交后会进行审核，通过后展示在手机端</p>
      </div>
      <van-form @submit="onSubmit">
        <div class="section" style="padding: 0.8rem;">
          <div class="title"><span class="required">*</span>先锋内容</div>
          <van-field
            size="large"
            v-model="remark"
            rows="3"
            autosize
            name="issueContent"
            type="textarea"
            maxlength="200"
            placeholder="请填写您的先锋内容"
            show-word-limit
            :rules="[{ required: true, message: '请填写您的先锋内容' }]"
          />
        </div>
        <div class="section" style="padding: 0.8rem">
          <div class="title">相关图片</div>
          <van-uploader
            v-model="imageList"
            name="imageList"
            multiple
            :max-count="5"
          />
        </div>
        <div class="section" style="padding: 0.8rem;">
          <div class="title">发布位置(<i style="color: rgba(0,0,0,0.45);font-size: 14px;">非必填</i>)</div>
          <van-field
            size="large"
            v-model="detailPlace"
            autosize
            name="detailPlace"
            placeholder="请填写发布位置"
          />
        </div>
        <van-checkbox class="checkbox" name="anonymous" v-model="anonymous"
          >匿名发布</van-checkbox
        >
        <div class="footer-button">
          <van-button
            block
            type="info"
            style="font-size: 16px"
            native-type="submit"
            >提交审核</van-button
          >
        </div>
      </van-form>
    </div>
  </div>
</template>

<script>
import api from "api";
import commonApi from "api/common";
import { mapMutations, mapState } from "vuex";
export default {
  data() {
    return {
      remark: "",
      detailPlace: "",
      imageList: [],
      anonymous: false,
      id: '',
      isAll: true,
      data: {}
    };
  },
  computed: {},
  components: {},
  mounted() {
    const { id = '' } = this.$route.query;
    if(id) {
      this.id = id;
      this.getDetail({ id });
    }
  },
  watch: {},
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    getDetail({ id = this.id, isAll }) {
      api.getVanguardDetail({
        id,
        isAll: true,
      }).then(res => {
        this.data = res.data;
        this.remark = res.data.issueContent || '';
        this.imageList = res.data.photoVO ? res.data.photoVO.map(item => ({url: item})) : [];
        this.detailPlace = res.data.detailPlace || '';
        this.anonymous = res.data.faceLess;
      })
    },
    addVanguard(params) {
      api.addVanguard(params).then(res => {
        if (res.flag) {
          this.$toast('发布成功');
          this.$router.go(-1);
        }
      })
    },
    editVanguard(params) {
      api.editVanguard(params).then(res => {
        if (res.flag) {
          this.$toast('修改成功');
          this.$router.go(-1);
        }
      })
    },
    onSubmit(values) {
      if (this.imageList.length) {
        const formData = new FormData();
        const urlList = [];
        let hasFile = false;
        this.imageList.forEach(file => {
          if(file.file) {
            hasFile = true;
            formData.append("files", file.file);
          } else if(file.url) {
            urlList.push(file.url);
          }
        });
        if(hasFile){
          commonApi.uploadMultiFile(formData).then(res => {
            if (res.flag) {
              values.photoDTO = res.data.split('#').concat(urlList);
              this.addVanguard({ ...values, faceLess: this.anonymous, isDraft: false });
            }
          });
        } else {
          values.photoDTO = urlList;
          this.addVanguard({ ...values, faceLess: this.anonymous, isDraft: false });
        }
        
      } else {
        if(this.id) {
          this.editVanguard({...values, faceLess: this.anonymous, isDraft: false, id: this.id });
        } else {
          this.addVanguard({...values, faceLess: this.anonymous, isDraft: false });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.publish-need-container {
  height: 100vh;
  width: 100vw;
  .my-vanguard-body-container {
    height: calc(100vh - 2.7rem);
    width: 100vw;
    padding-bottom: 10px;
    background: url("http://dssq.ikeqiao.net/data/image/h5/images/coordination/coordination-bg.png") no-repeat;
    background-size: 100% 100%;
    overflow-y: auto;
    overflow-x: hidden;

    .image-bar {
      width: 100%;
      height: 92px;
      background: url("http://dssq.ikeqiao.net/data/image/h5/images/coordination/form-top-bg.png") no-repeat;
      background-size: 100% 100%;
      font-size: 20px;
      color: #ffffff;
      font-weight: 500;
      text-align: center;
      padding: 20px;
      line-height: 24px;
      > p {
        font-size: 14px;
      }
    }
    .section {
      width: 100%;
      margin-bottom: 0.8rem;
      background: #ffffff;
      .title {
        margin: 0.5rem 0;
        font-size: 16px;
        color: rgba(0, 0, 0, 0.85);
        letter-spacing: 0;
        .required {
          color: #e13f30;
        }
      }
    }
    .checkbox {
      font-size: 16px;
      color: rgba(0, 0, 0, 0.85);
      margin-left: 0.8rem;
      margin-top: 0.8rem;
    }
    .footer-button {
      position: fixed;
      bottom: 0.8rem;
      right: 0.8rem;
      left: 0.8rem;
    }
  }
}
</style>
