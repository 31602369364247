<template>
  <div class="education-source-container">
    <van-nav-bar
      class="education-source-nav-bar"
      title="汇 资源"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="education-source-body-container">
      <div class="image-bar">汇 资源</div>
      <!-- 按钮栏 -->
      <div class="icon-bar">
        <div v-for="(icon, index) in iconList" @click="icon.link ? openUrl(icon.link) : gotoLink(icon.path)" :key="`${icon.name}_${index}`">
          <img class="icon" :src="icon.icon" :alt="icon.name">
          <p>{{icon.name}}</p>
        </div>
      </div>
      <!-- 学习地图 -->
      <!-- <div class="coin-bar-con">
        <div class="coin-bar">
          <h1>学习地图</h1>
          <h3>汇聚周边优质学校、机构</h3>
          <button @click="gotoLink('educationMap')">查看</button>
        </div>
      </div> -->
      <!-- 最新活动 -->
      <div class="card-bar">
        <div class="card-bar-title">
          <span class="text-title">最新活动<i class="new"></i></span>
          <span class="text-more" @click="gotoLink('huodongzhongxin')">
            更多
            <van-icon name="arrow" color="rgba(0,0,0,0.45)" size="14px" />
          </span>
        </div>
        <div class="card-bar-content">
          <activityList></activityList>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import homeApi from 'api/home';
import { mapMutations, mapState } from "vuex";
import activityList from './activityList.vue';
export default {
  data() {
    return {
      iconList: [
        {
          name: '社区学堂',
          icon: 'http://dssq.ikeqiao.net/data/image/dongsheng/indexPage/gxkt.svg',
          path: 'sdbxt',
        },
        {
          name: '教学资源',
          icon: require('../../../assets/images/educate/jxzy.png'),
          link: 'https://zjedu.iclass30.com/basic_edu'
        },
        {
          name: '微课点播',
          icon: require('../../../assets/images/educate/wkdb.png'),
          link: 'http://zjvod.zjjy.xyz/'
        },
        {
          name: '学习资源',
          icon: require('../../../assets/images/educate/xxzy.png'),
          path: 'studySource',
          link: 'https://ykt.eduyun.cn/ykt/sjykt/index.html',
        },
      ],
    };
  },
  computed: {
  },
  components: { activityList },
  mounted() {
    
  },
  watch: {
   
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    handleTab() {

    },
    gotoLink(path) {
      if(path) {
        this.$router.push(path);
      }
    },
    openUrl(link) {
      window.location.href = link;
    },
  }
};
</script>

<style lang="scss" scoped>
.education-source-container {
  height: 100vh;
  width: 100vw;
  .education-source-nav-bar {
    background-image: linear-gradient(90deg, #6097F3 0%, #5464E3 100%);
    ::v-deep .van-nav-bar__title {
      color: white;
    }
    ::v-deep .van-icon {
      color: white;
    }
  }
  .education-source-body-container {
    height: calc(100vh - 2.7rem);
    width: 100vw;
    padding-bottom: 10px;
    background: url('http://dssq.ikeqiao.net/data/image/h5/images/coordination/coordination-bg.png') no-repeat;
    background-size: 100% 100%;
    overflow-y: auto;
    overflow-x: hidden;

    .image-bar {
      width: 100%;
      height: 176px;
      background: url('http://dssq.ikeqiao.net/data/image/h5/images/educationSource/source-bar.png') no-repeat;
      background-size: 100% 100%;
      font-size: 32px;
      color: #FFFFFF;
      padding: 38px 34px;
      line-height: 32px;
      text-shadow: 0 2px 4px rgba(0,0,0,0.13);
      font-weight: 600;
    }

    .icon-bar {
      margin: 10px;
      width: calc(100% - 20px);
      height: 108px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      background: #FFF;
      border-radius: 4px;
      >div {
        width: 20%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 15px;
        .icon {
          width: 47px;
          height: 47px;
          margin-bottom: 5px;
        }
        >p {
          text-align: center;
          font-size: 10px;
          font-weight: 400;
        }
      }
    }

    .report-bar {
      width: calc(100% - 20px);
      margin: 10px;
      padding: 0 10px;
      height: 42px;
      background: url('http://dssq.ikeqiao.net/data/image/h5/images/building/report-bg.png') no-repeat;
      background-size: 100% 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .report-title {
        font-size: 18px;
        color: #FFF;
      }
      .report-arrow {
        color: #2a93ed;
        font-size: 16px;
        display: flex;
        align-items: center;
      }
    }

    .coin-bar-con {
      display: flex;
      .coin-bar {
        width: 100%;
        margin: 10px;
        height: 100px;
        background: url('http://dssq.ikeqiao.net/data/image/h5/images/educationSource/study-map.png') no-repeat;
        background-size: 100% 100%;
        padding: 10px;
        color: #FFF;
        >h1 {
          font-size: 16px;
          letter-spacing: 0;
          line-height: 32px;
          text-shadow: 0 2px 4px rgba(0,0,0,0.13);
          font-weight: 600;
        }
        >h3 {
          font-size: 12px;
          letter-spacing: 0.92px;
          text-align: left;
          font-weight: 200;
        }
        >button {
          border: 0.5px solid #FFFFFF;
          box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.07);
          border-radius: 14px;
          padding: 2px 8px;
          font-size: 12px;
          letter-spacing: 0.92px;
          text-align: center;
          background: none;
          margin-top: 10px;
        }
      }

      .map {
        background: url('http://dssq.ikeqiao.net/data/image/h5/images/building/map-bg.png') no-repeat;
        background-size: 100% 100%;
        margin-left: 0;
      }
    }
    

    .card-bar {
      width: calc(100% - 20px);
      margin: 10px;
      border-radius: 10px;
      .card-bar-title {
        width: 100%;
        height: 33px;
        padding: 10px;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        .text-title {
          font-size: 16px;
          color: #4479ED;
          width: 50%;
          font-weight: 500;
          .new {
            width: 21px;
            height: 12px;
            background: url(http://dssq.ikeqiao.net/data/image/h5/images/educationSource/new.png) no-repeat;
            background-size: 100% 100%;
            display: inline-block;
            margin-left: 5px;
          }
        }
        .text-more {
          color: rgba(0,0,0,0.45);
          font-size: 14px;
          float: right;
          display: flex;
          align-items: center;
        }
      }
      .card-bar-content {
        width: 100%;
        
        
      }
    }
  }
}
</style>
