<template>
  <div class="jfrule-page-container">
    <van-nav-bar title="积分规则" left-arrow @click-left="goBack" />
    <div class="jfrule-body">
      <div class="list-title">积分规则</div>
      <p>1.积分兑换标准及兑换规则均以兑换当时最新活动公告为准，公告如有有效期的，逾期则不得兑换；</p>
      <p>1.2.部分兑换商品有数量限制，兑完为止；</p>
      <p>1.3.利用系统漏洞或以其他方式恶意赚取积分，经查后，将追缴相关积分，并保留追究相关法律责任的权利；</p>
      <p>1.4.我方保留对积分细则的解释权，并有权根据需要对积分细则予以修订和废除。</p>
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant';
export default {
  data() {
    return {
      
    };
  },
  components: {},
  mounted() {},
  methods: {
    gotoLink(path) {
      this.$router.push(path);
    },
    goBack() {
      this.$router.go(-1);
    },
    showAlert() {
      this.$messagebox("提示", "系统正在建设中，尽情期待~");
    },
    
  },
};
</script>

<style lang="scss" scoped>
.jfrule-page-container {
  height: 100vh;
  width: 100vw;
  font-size: 0.9rem;
  color: rgba(0, 0, 0, 0.85);
  line-height: 1.5rem;
  .jfrule-body {
    height: calc(100vh - 46px);
    width: 100vw;
    padding: 12px;
    // padding-bottom: 10px;
    background: url('http://dssq.ikeqiao.net/data/image/dongsheng/jfdh/ds-jfdh-jfRule-bg.png') no-repeat;
    background-size: 100% 100%;
    overflow-y: auto;
    overflow-x: hidden;
    .list-title {
      border-left: 4px solid #4479ed;
      padding-left: 8px;
      font-size: 1.07rem;
      line-height: 18px;
      color: rgba(0, 0, 0, 0.85);
    }
    >p {
      margin: 20px 0;
    }
  }
  
}
</style>
