<template>
  <div id="index">
    <div class="wjys-add-page-container">
      <div class="header-nav">
        <i @click="goBack"></i>
        <h1>场地预约</h1>
      </div>
      <van-form @submit="onSubmit">
        <van-field
          v-model="reserveName"
          name="reserveName"
          label="姓名"
          required
          placeholder="请填写"
          input-align="right"
          :rules="[{ required: true, message: '' }]"
        />
        <van-field
          v-model="phone"
          name="reservePhone"
          label="联系方式"
          required
          type="tel"
          placeholder="请填写"
          input-align="right"
          :rules="[{ required: true, message: '' }]"
        />
        <van-field
          v-model="meetDate"
          name="reserveDate"
          label="预约日期"
          placeholder="请选择"
          input-align="right"
          is-link
          readonly
          required
          @click="showDate = true"
          :rules="[{ required: true, message: '' }]"
        />
        <div style="display: flex;">
          <van-field
            v-model="time1"
            name="reserveStartTime"
            label="开始时间"
            placeholder="请选择"
            input-align="right"
            is-link
            readonly
            required
            @click="showTime1 = true"
          :rules="[{ required: true, message: '' }]"
          />
          <div style="width: 1px;height: 100%;color: #fefefe;"></div>
          <van-field
            v-model="time2"
            name="reserveEndTime"
            label="结束时间"
            placeholder="请选择"
            input-align="right"
            is-link
            readonly
            required
            @click="handleTime2"
            :rules="[{ required: true, message: '' }]"
          />
        </div>
        <div style="font-size: 12px; line-height: 24px; color:rgba(0,0,0,0.5); padding: 0 10px;">预约时间段：{{info.businessTime}}</div>
        <div class="footer-info">
          <div style="width: 50vw;">预约时间：{{reserveDuration}}分钟</div>
          <div>总价：<span style="color: #ce1a21;font-size: 1.2rem;">{{reserveTotalCost}}积分</span></div>
        </div>
        <div class="foot-button">
          <van-button block type="info" native-type="submit">提交</van-button>
        </div>
      </van-form>
    </div>
    <van-popup v-model="showDate" position="bottom">
      <van-datetime-picker
        type="date"
        title="选择预约日期"
        :min-date="minDate"
        :max-date="maxDate"
        @cancel="handleCancle('showDate')"
        @confirm="onDateConfirm($event, 'meetDate', handleCancle('showDate'))"
      />
    </van-popup>
    <van-popup v-model="showTime1" position="bottom">
      <van-datetime-picker
        type="time"
        title="选择开始时间"
        :min-hour="minHour"
        :filter="filter"
        @cancel="handleCancle('showTime1')"
        @confirm="onDateConfirm($event, 'time1', handleCancle('showTime1'))"
      />
    </van-popup>
    <van-popup v-model="showTime2" position="bottom">
      <van-datetime-picker
        type="time"
        :filter="filter"
        :min-hour="time1.substring(0,2)"
        :max-hour="maxHour"
        title="选择结束时间"
        @cancel="handleCancle('showTime2')"
        @confirm="onDateConfirm($event, 'time2', handleCancle('showTime2'))"
      />
    </van-popup>
  </div>
</template>

<script>
import { Toast } from "vant";
import cgApi from 'api/changguan';
import commonApi from "api/common";
import moment from 'moment';

export default {
  data() {
    return {
      reserveName: '',
      phone: '',
      time1: '',
      time2: '',
      meetDate: '',
      showDate: false,
      showTime1: false,
      showTime2: false,
      minDate: new Date(),
      maxDate: new Date(Date.now() + 1000 * 60 * 60 * 24 * 7), // 议事最大日期为六个月后
      minHour: '00',
      maxHour: '23',
      info: {},
      // reserveList: [],
      filter: (type, options) => {
        if (type === 'minute') {
          return options.filter((option) => Number(option) % 60 === 0);
        }
        return options;
      },
      // myReserve: [],
    };
  },
  components: {},
  mounted() {
    const {id} = this.$route.query;
    if (!id && id !== 0) {
      this.$router.go(-1);
    }
    this.id = id;
    this.getInfo(id);
    // this.getReserveList(id);
    // this.getMyReserve();
    const obj = JSON.parse(sessionStorage.getItem('userInfo'));
    // console.log(obj)
    const { userName = '', phone = '' } = obj || {};
    this.reserveName = userName;
    this.phone = phone;
  },
  computed: {
    reserveDuration() {
      if(this.time1 && this.time2) {
        return moment(this.time2, 'hh:mm').diff(moment(this.time1, 'hh:mm'), 'minute');
      }
      return 0;
    },
    reserveTotalCost() {
      if(this.time1 && this.time2) {
        return this.reserveDuration / 60 * this.info.venueCost * 1 || 1;
      }
      return 0;
    }
  },
  methods: {
    gotoLink(path) {
      this.$router.replace(path);
    },
    goBack() {
      this.$router.replace('/changguan');
    },
    callPhone(e, number) {
      e.stopPropagation();
      window.location.href = `tel://${number}`;
    },
    getInfo(id) {
      cgApi
        .getChangGuanList({
          page: 1,
          size: this.pageSize,
        })
        .then((res) => {          
          const { records = [] } =  res.data || {} ;   
          this.info = records.find(item => item.id == id);
          this.minHour = (this.info.venueStartTime || '00').slice(0,2);
          this.maxHour = (this.info.venueEndTime || '23').slice(0,2)
        })
        .catch((err) => {
          console.log('error====>', err);
        });
    },
    onSubmit(values) {
      cgApi.previewChangGuan({ ...values, reserveVenueId: this.info.id}).then(res => {
        if (res.flag) {
          Toast.success('提交成功');
          this.$router.replace(`/changguan/result?id=${this.info.id}`)
        }
      })
    },
    handleCancle(type) {
      this[type] = false;
    },
    onDateConfirm(value, name, callback = () => {}) {
      if(name === 'meetDate') {
        this.meetDate = this.formatDateTime(value);
      } else {
        console.log(value);
        
        this[name] = `${value}:00`;
      }
      callback();
    },
    formatDateTime(date) {
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      var d = date.getDate();
      d = d < 10 ? "0" + d : d;
      var h = date.getHours();
      var minute = date.getMinutes();
      minute = minute < 10 ? "0" + minute : minute;
      return y + "-" + m + "-" + d;
    },
    getReserveList(id) {
      cgApi.getReserveById({ venueId: id }).then(res => {
        if (res.data) {
          this.reserveList = res.data || [];
        }
      })
    },
    getMyReserve() {
      cgApi.getUserReserve().then(res => {
        if (res.data) {
          this.myReserve = (res.data || []).filter(item => item.reserveVenueId == this.id) || [];
          
        }
      })
    },
    handleOpen() {
      window.open('http://navo.top/Bn6NNj')
    },
    handleTime2() {
      if (!this.time1) {
        this.$toast('请先选择开始时间');
      } else {
        this.showTime2 = true
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.wjys-add-page-container {
  background: #f6f7fa;
  // padding: 0.6rem 0;
  height: calc(100vh - 40px);
  width: 100vw;
  margin-top: 40px;
  .white-page {
    height: 30rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    > img {
      width: 80vw;
    }
  }
  // .van-field__error-message {
  //   text-align: right;
  // }

  .section-textArea {
    background: #ffffff;
    width: 100%;
    border-top: 0.8rem solid #f6f7fa;

    .section-label {
      padding: 0.8rem 0 0 0.8rem;
      line-height: 2rem;

      font-size: 14px;
      color: #646566;
      letter-spacing: 0;
    }
    .van-cell {
      padding-top: 0;
    }
  }
  .section {
    background: #ffffff;
    width: 100%;
    border-top: 0.8rem solid #f6f7fa;
    .image-title {
      line-height: 2rem;

      font-size: 14px;
      color: #646566;
      letter-spacing: 0;
    }
  }
  .footer-info {
    position: fixed;
    bottom: 3.6rem;
    right: 0.8rem;
    left: 0.8rem;
    font-size: 0.9rem;
    line-height: 1.6rem;
    display: flex;

  }
  .foot-button {
    position: fixed;
    bottom: 0.8rem;
    right: 0.8rem;
    left: 0.8rem;
    height: 2.4rem;
    line-height: 2.4rem;
    text-align: center;
    background: #4479ed;
    border-radius: 8px;
    color: #ffffff;

    font-size: 18px;
    letter-spacing: 0;
  }
}
</style>
