<template>
  <div id="index"
       class="clearfix" style="background: #fff; height: 100vh">
    <div class="page-container clearfix">
      <div class="header-nav">
        <i @click="goBack()"></i>
        <h1>学堂实况</h1>
        <div class="divider"></div>
      </div>
      <div id="online_player" style="width: 100%; height: 240px; margin: 10px 0px; margin-top: 60px;" />
    </div>
    <div class="blank"></div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      vedioUrl: '',
    };
  },
  components: {},
  mounted () {
    const _this = this;
    let token = this.getSessionData("token");
    this.axios({
      method: 'post',
      url: '/api/hik/getVideo',
      headers: { 'Authorization': token },
      data: {
        cameraIndexCode: 'a7865b4725014b34b1a6a2f482f56415',
        protocol: 'hls',
      }
    })
    .then((res) => {
      _this.vedioUrl = res.data.data;
      _this.newAliplay(res.data.data);
    })
    .catch(function(err) {
      console.log('11111', err);
    });
  },
  methods: {
    gotoLink (path) {
      this.$router.push(path);
    },
    goBack () {
      this.$router.go(-1);
    },
    newAliplay (vedioUrl = '') {
      const { WXInlinePlayer } = window;
      console.log('视屏播放了', vedioUrl);
    this.player = new Aliplayer(
      {
        id: 'online_player',
        source: vedioUrl,
        // source: 'rtmp://58.200.131.2:1935/livetv/hunantv',
        // source: playUrl, // 调用视频
        width: '580px', // 播放器宽度
        height: '380px', // 播放器高度
        autoplay: true,
        rePlay: true,
        preload: true,
        autoPlayDelay: '',
        playsinline: true, //h5是否内置播放
        isLive: true,
        autoPlayDelayDisplayText: '',
        language: 'zh-cn',
        controlBarVisibility: 'hover',
        useH5Prism: true,
        trackLog: false, // 不打印日志
        snapshot: true, //flash截图
        showBuffer: true, //显示播放时缓冲图标
        extraInfo: {
          crossOrigin: 'anonymous',
        },
      },
    );
    },
  },
};
</script>

<style lang="scss">
@import "~assets/scss/index.scss";

.divider {
  height: 0.066rem;
  width: 100%;
  background-color: #e7e8e8;
  margin-bottom: 0.667rem;
}
.page-container {
  padding-left: 0.8rem;
  padding-right: 0.8rem;
}
.park-main {
  position: relative;
  .video:nth-child(1) {
    margin-top: 0.333rem;
    width: 23.4rem;
    height: 13.167rem;
    z-index: 1;
  }
  .play:nth-child(2) {
    width: 3.2rem;
    height: 3.2rem;
    position: absolute;
    top: 8.2rem;
    left: 10rem;
    z-index: 2;
  }
}
.name {
  margin-top: 1.1rem;
  font: 600 1.0667rem/1.467rem "PingFangSC-Medium";
  color: rgba(0, 0, 0, 0.85);
}
.time {
  margin-top: 0.4rem;
  color: rgba(0, 0, 0, 0.65);
}
.blank {
  height: calc(100vw - 13rem);
  width: 100%;
  background-color: #fff;
}
</style>
