<template>
  <div id="index">
    <div class="page-container">
      <div class="header-nav">
        <i @click="goBack"></i>
        <h1>三三工作法</h1>
      </div>
      <div class="park-main">
        <div class="banner">
          <img src="http://dssq.ikeqiao.net/data/image/h5/images/ssgzf-banner.png" />
        </div>
        <div class="nav clearfix">
          <div class="item">
            <img src="http://dssq.ikeqiao.net/data/image/h5/images/ssgzf-icon1.png" />
          </div>
          <div class="item">
            <img src="http://dssq.ikeqiao.net/data/image/h5/images/ssgzf-icon2.png" />
          </div>
          <div class="item">
            <img src="http://dssq.ikeqiao.net/data/image/h5/images/ssgzf-icon3.png" />
          </div>
        </div>
        <div class="content-box">
          <div class="w-tab">三三工作法简介</div>
          <div class="wxparse-box">
            浣东街道东盛社区成立于2010年9月，涵盖蝶庄、香水湾、润泽大院、香林花园、镜水湾等五个小区,共有住户6332户,常住人口19000多人。东盛社区始终坚持以党建为引领，以创建“全国一流社区”为宗旨，紧紧围绕“五星达标、和美家园”的创建标准，不断探索“三三工作法”，即“三支队伍、三治融合、三社联动”，推进社区各项工作有序发展，为社区居民打造了一个温馨和谐的美好家园。社区先后获得全国综合减灾社区、省级绿色社区、省级卫生社区、五星级党组织等诸多荣誉。社区因地制宜、挖掘资源，突出亮点、发挥特色，形成了“长乐东盛”特色党建品牌，实现基层面貌“蝶变”。
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  components: {},
  mounted() {},
  methods: {
    gotoLink(path) {
      this.$router.push(path);
    },goBack(){
          this.$router.go(-1);
      }
  },
};
</script>

<style lang="scss">
@import "~assets/scss/index.scss";
</style>
