<template>
  <div id="index" class="garbg">
    <div class="page-container">
      <div class="header-nav">
        <i @click="goBack"></i>
        <h1>垃圾分类指南</h1>
      </div>
      <div class="gar-contnet">
        <div class="gar-search"></div>
        <div class="gar-main clearfix">
          <div @click="gotoLink('khs')">
            <img src="http://dssq.ikeqiao.net/data/image/h5/images/khs.png" />
          </div>
          <div @click="gotoLink('yf')">
            <img src="http://dssq.ikeqiao.net/data/image/h5/images/yf.png" />
          </div>
          <div @click="gotoLink('yh')">
            <img src="http://dssq.ikeqiao.net/data/image/h5/images/yh.png" />
          </div>
          <div @click="gotoLink('qt')">
            <img src="http://dssq.ikeqiao.net/data/image/h5/images/qt.png" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  components: {},
  mounted() {},
  methods: {
    gotoLink(path) {
      this.$router.push(path);
    }, goBack(){
          this.$router.go(-1);
      }
  },
};
</script>

<style lang="scss">
@import "~assets/scss/index.scss";
</style>
