<template>
  <div class="court-detail-container">
    <van-nav-bar
      title="诉求详情"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="court-detail-body-container">
      <div class="court-detail-card">
        <div class="court-detail-card-top">
          <span>{{typeObj[data.litigationType]}}</span>
          <span :style="{color: colors[data.status]}">{{statusList[data.status]}}</span>
        </div>
        <div class="court-detail-card-row" style="display: flex;">
          <section style="width: 50%;">
            <span class="label">原告姓名</span>
            <span>{{data.plaintiffName}}</span>
          </section>
          <section style="width: 50%;">
            <span class="label">联系电话</span>
            <span>{{data.plaintiffPhonenumber}}</span>
          </section>
        </div>
        <div class="court-detail-card-row" style="display: flex;">
          <section style="width: 50%;">
            <span class="label">被告姓名</span>
            <span>{{data.defendantName}}</span>
          </section>
          <section style="width: 50%;">
            <span class="label">联系电话</span>
            <span>{{data.defendantPhonenumber}}</span>
          </section>
        </div>
        <div class="court-detail-card-row">
          <span class="label">原告主诉</span>
        </div>
        <div class="court-detail-card-row">
          <span>{{data.matter}}</span>
        </div>
        <div class="court-detail-card-row" style="display: flex;width: 100%;flex-wrap: wrap;">
          <van-image
            width="30%"
            height="28vw"
            fit="cover"
            position="center"
            style="margin: 0 1.5% 10px"
            v-for="image in (data.attachedPicture ? data.attachedPicture.split('#') : [])"
            :key="image"
            :src="image"
          />
        </div>
        <div class="court-detail-card-row">
          <span class="label">提交时间</span>
          <span>{{data.submitTime}}</span>
        </div>
        <!-- <div class="court-detail-card-row">
          <span class="label">归还时间：</span>
          <span>{{data.returnTime}}</span>
        </div>
        <div class="court-detail-card-row">
          <span class="label">借用用途：</span>
          <span>{{data.rentReason}}</span>
        </div> -->
      </div>
      <div v-if="data.status === '2'" class="court-detail-card" style="padding: 10px">
        <div style="font-weight: 600">驳回原因</div>
        <div>{{data.dismissReason}}</div>
      </div>
      <div class="court-detail-footer">
        <button class="refuse-btn" v-if="data.status === '0'" @click="handleCancel(data)">撤回诉求</button>
        <button class="refuse-btn" v-else @click="handleCancel(data)">删除</button>
      </div>
    </div>
  </div>
</template>

<script>
import courtApi from 'api/court';
import { mapMutations, mapState } from "vuex";
import {data} from 'assets/json/court';
export default {
  data() {
    return {
      id: '',
      colors: {
        '3': '#4479ED',
        '0': '#E5B20A',
        '2': '#ce1a21',
        '1': '#4479ED',
      },
      statusList: {
        '0': '审核中',
        '1': '已通过',
        '2': '已驳回',
      },
      typeObj: {
        '0': '经济纠纷',
        '1': '宠物纠纷',
        '2': '买卖纠纷',
        '3': '刑事纠纷'
      },
      data: {},
    };
  },
  computed: {
  },
  components: {},
  mounted() {
    const { id = '' } = this.$route.query;
    this.getData({ id });
    this.id = id;
  },
  watch: {
   
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    handleTab() {

    },
    getData({ id = this.id } = {}) {
      courtApi.getCountInfo({
        litigationId: id,
      }).then(res => {
        if(res.data) {
          this.data = res.data || {};
        }
      })
    },
    gotoLink(path) {
      if(path) {
        this.$router.push(path);
      }
    },
    handleCancel(data) {
      const type = data.status === '0' ? '撤回' : '删除';
      this.$dialog.confirm({
        title: '提示',
        message: `是否${type}本次诉求`,
        confirmButtonText: `立即${type}`
      }).then(() => {
        courtApi.cancelCourt({ litigationId: data.litigationId }).then(res => {
          if (res.data) {
            this.$toast(`${type}成功`);
            this.$router.go(-1);
          }
        })

      })
      .catch(() => {

      })
    }
  }
};
</script>

<style lang="scss" scoped>
.court-detail-container {
  height: 100vh;
  width: 100vw;
  
  .court-detail-body-container {
    height: calc(100vh - 46px);
    width: 100vw;
    padding-bottom: 10px;
    background: url('http://dssq.ikeqiao.net/data/image/h5/images/coordination/coordination-bg.png') no-repeat;
    background-size: 100% 100%;
    overflow-y: auto;
    overflow-x: hidden;
    .court-detail-card {
      padding: 0.3rem 0;
      // border-bottom: 1px solid #ddd;
      font: 500 0.9rem/1.4rem "microsoft yahei";
      background-color: #FFF;
      margin: 10px;
      border-radius: 4px;
      .court-detail-card-top {
        height: 36px;
        line-height: 36px;
        display: flex;
        justify-content: space-between;
        border-bottom: 0.5px solid rgba(0,0,0,0.1);
        padding: 0 10px;
        margin-bottom: 10px;
        font-size: 16px;
      }
      .court-detail-card-row {
        line-height: 32px;
        color: rgab(0,0,0,0.85);
        font-size: 14px;
        padding: 0 10px;
        .label {
          color: rgba(0,0,0,0.45);
          margin-right: 5px;
        }
      }
    }
    .court-detail-footer {
      display: flex;
      padding: 10px;
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      margin-top: 10px;
      justify-content: space-around;
      .refuse-btn, .agree-btn, .delete-btn {
        flex: 1;
        height: 40px;
        border-radius: 20px;
        text-align: center;
        line-height: 40px;
        border: 1px solid #ce1a21;
        color: #ce1a21;
        background: #FFF;
      }
      .delete-btn {
        border: 1px solid rgba(0,0,0,0.45);
        color: rgba(0,0,0,0.45);
      }
      .agree-btn {
        border: 1px solid #4479ed;
        color: #4479ed;
      }
    }
  }
}
</style>
