<template>
  <div class="wylt-form-con">
    <van-form @submit="onSubmit">
      <div class="radio-bar">
          <span
            :class="['radio', { selected: person === 'relation' }]"
            @click="handleRadio('relation')"
          >
            给亲友报名
          </span>
          <span
            :class="['radio', { selected: person === 'self' }]"
            @click="handleRadio('self')"
          >
            为自己报名
          </span>
      </div>
      <van-field
        v-model="createUserName"
        name="createUserName"
        label="需求人"
        placeholder="请输入"
        :rules="[{ required: true, message: '请填写需求人名称' }]"
        input-align="right"
      />
      <van-field
        v-model="createUserPhone"
        name="createUserPhone"
        label="联系方式"
        placeholder="请输入"
        input-align="right"
        :rules="[{ required: true, message: '请填写联系方式' }]"
      />
      <van-field
        v-model="age"
        name="age"
        label="陪聊人年龄"
        placeholder="请输入"
        :rules="[{ required: true, message: '请输入陪聊人年龄' }]"
        input-align="right"
        type="number"
      />
      <div class="section-textArea">
        <div class="section-label">需求原因</div>
        <van-field
          v-model="ability"
          name="ability"
          rows="2"
          autosize
          label
          type="textarea"
          maxlength="50"
          placeholder="请输入"
          :rules="[{ required: true, message: '请输入需求原因' }]"
          show-word-limit
        />
      </div>
      <div class="section-textArea">
        <div class="section-label">陪聊人爱好</div>
        <van-field
          v-model="hobby"
          name="hobby"
          rows="2"
          autosize
          label
          type="textarea"
          maxlength="50"
          placeholder="请输入"
          :rules="[{ required: true, message: '请输入陪聊人爱好' }]"
          show-word-limit
        />
      </div>
      <van-field
        v-model="type"
        name="type"
        is-link
        readonly
        label="陪聊类型"
        placeholder="请选择"
        @click="showType = true"
        :rules="[{ required: true, message: '请选择陪聊类型' }]"
        class="input-bar"
      />
      <van-field
        v-model="address"
        name="address"
        label="陪聊地点"
        placeholder="请输入"
        :rules="[{ required: true, message: '请输入陪聊地点' }]"
        input-align="right"
      />
      <div class="foot-button">
        <van-button block type="info" native-type="submit">立即发布</van-button>
      </div>
    </van-form>
    <van-popup v-model="showType" position="bottom">
      <van-picker
        title="陪聊类型"
        show-toolbar
        :columns="typeList"
        @confirm="onTypeConfirm"
        @cancel="onCancel('showType')"
      />
    </van-popup>
  </div>
</template>

<script>
import xlyzApi from "api/llyjr/xlyz";
import { Toast } from "vant";
import { mapState } from "vuex";
import context from '@/main';

export default {
  data() {
    return {
      classChildrenName: "",
      createUserName: "",
      createUserPhone: "",
      invitationMoney: "",
      ability: "",
      columns: ["心理咨询", "家庭陪聊"],
      typeList: ['入户', '陪聊室'],
      showPicker: false,
      showType: false,
      type: "",
      address: "",
      age: "",
      hobby: "",
      person: 'self', // 为谁报名
    };
  },
  computed: {
    ...mapState({
      userInfo: state =>
        (state.login.userInfo.userName ? state.login.userInfo : null) ||
        (context.getSessionData("userInfo"))
    })
  },
  components: {},
  mounted() {
    const { userName = '', phone = '' } = this.userInfo || {};
    this.createUserName = userName;
    this.createUserPhone = phone;
  },
  methods: {
    gotoLink(path) {
      this.$router.push(path);
    },
    goBack() {
      this.$router.go(-1);
    },
    showAlert() {
      this.$messagebox("提示", "系统正在建设中，尽情期待~");
    },
    handleRadio(value) {
      this.person = value;
      if(value === 'self') {
        const { userName = '', phone = '' } = this.userInfo || {};
        this.createUserName = userName;
        this.createUserPhone = phone;
      } else {
        this.createUserName = '';
        this.createUserPhone = '';
      }
    },
    onSubmit(values) {
      const { createUserName, createUserPhone, age, ability, hobby, type, address } = this;
      this.$api.createChat({
        needName: createUserName,
        needPhone: createUserPhone,
        age,
        needReason: ability,
        chatHobby: hobby,
        chatType: type,
        chatPlace: address,
        ...values
      }).then(res => {
        if(!res.flag) {
          // this.$toast('申请提交失败，请稍后重拾');
          return;
        }
        Toast('发布成功');
        this.initState();
      })
      .catch(error => {
        console.log('error=====>', error);
        // this.$toast('申请提交失败，请稍后重拾');
      })
    },
    initState() {
      this.classChildrenName = "";
      this.createUserName = "";
      this.createUserPhone = "";
      this.invitationMoney = "";
      this.ability = "";
      this.showPicker = false;
      this.showType = false;
      this.type = "";
      this.address = "";
      this.age = "";
      this.hobby = "";
      this.person = 'self'; // 为谁报名
    },
    onConfirm(value) {
      this.classChildrenName = value;
      this.showPicker = false;
    },
    onCancel(type) {
      this[type] = false;
    },
    onTypeConfirm(value, index) {
      this.type = value;
      this.showType = false;
    },
  }
};
</script>

<style lang="scss" scoped>
.wylt-form-con {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  .section-textArea {
    width: 100%;
    border-top: 0.8rem solid #f6f7fa;

    .section-label {
      padding: 0.8rem 0 0 0.8rem;
      line-height: 2rem;

      font-size: 14px;
      color: #646566;
      letter-spacing: 0;
    }
    .van-cell {
      padding-top: 0;
    }
  }
  .radio-bar {
    display: flex;
    justify-content: space-around;
    box-sizing: border-box;
    width: 100%;
    padding: 10px 16px;
    overflow: hidden;
    color: #323233;
    font-size: 14px;
    line-height: 24px;
    background-color: #fff;
    .radio {
      height: 2rem;
      width: 45vw;
      text-align: center;
      border-radius: 4px;
      // background: #4479ED;
      color: rgba(0, 0, 0, 0.35);
      border: 1px solid rgba(0, 0, 0, 0.35);
      padding: 0.1rem 1.2rem;
    }
    .selected {
      background: #4479ed;
      color: #ffffff;
      border: 1px solid #4479ed;
    }

  }
  .section {
    width: 100%;
    border-top: 0.8rem solid #f6f7fa;
    .image-title {
      line-height: 2rem;

      font-size: 14px;
      color: #646566;
      letter-spacing: 0;
    }
  }


  .foot-button {
    margin: 2rem 1rem; 
    height: 2.4rem;
    line-height: 2.4rem;
    text-align: center;
    background: #4479ed;
    border-radius: 8px;
    color: #ffffff;
    font-size: 18px;
    letter-spacing: 0;
  }
}

</style>
